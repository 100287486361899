<template>
    <TopBar :title="$t('edit-object', { type: 'user' })"></TopBar>
    <div class="p-4">
        <UserUpdateForm :userId="userId" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import UserUpdateForm from '../forms/UserUpdateForm.vue';
defineProps<{ userId: string }>();

useHeadSafe({ title: () => `${$t('edit-object', { type: 'user' })} - ${$t('users')}` });
</script>
