<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h4.85v1H3v10h4.85v1H3Zm8.1-3.083-.717-.717 1.7-1.7H6.25v-1h5.8l-1.7-1.7.717-.717L14 8.017l-2.9 2.9Z"
        />
    </svg>
</template>

<script setup lang="ts">
type LogoutIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<LogoutIconProps>();
</script>
