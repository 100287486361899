import { $t } from '@/plugins/fluent';

export type Environment = 'dev' | 'stage' | 'devdb' | 'local' | 'prod';
export const DEV_ENVS = ['local', 'dev', 'devdb'];

export function getEnvironmentShort(environment: Environment): string {
    switch (environment) {
        case 'dev':
            return $t('environment-dev-short');

        case 'stage':
            return $t('environment-stage-short');

        case 'devdb':
            return $t('environment-devdb-short');

        case 'local':
            return $t('environment-local-short');

        case 'prod':
            return $t('environment-prod-short');

        default:
            throw new Error(`Unexpected ENV_NAME: ${environment}`);
    }
}

export function getEnvironmentLong(environment: Environment): string {
    switch (environment) {
        case 'dev':
            return $t('environment-dev-long');

        case 'stage':
            return $t('environment-stage-long');

        case 'devdb':
            return $t('environment-devdb-long');

        case 'local':
            return $t('environment-local-long');

        case 'prod':
            return $t('environment-prod-long');

        default:
            throw new Error(`Unexpected ENV_NAME: ${environment}`);
    }
}
