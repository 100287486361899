<template>
    <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-300">
                        <thead class="bg-gray-50">
                            <tr>
                                <th
                                    scope="col"
                                    class="py-3.5 pl-4 pr-3 text-left text-sm font-medium text-gray-900 sm:pl-6"
                                >
                                    {{ $t('name') }}
                                </th>
                                <th scope="col" class="px-3 py-3.5 text-left text-sm font-medium text-gray-900">
                                    {{ $t('period') }}
                                </th>
                                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200 bg-white">
                            <tr v-for="ce in calendarEvents" :key="ce.id">
                                <td class="break-normal pl-4 pr-3 text-sm flex items-center justify-center py-4">
                                    <div class="ml-4 flex-auto">
                                        <div class="font-normal text-gray-900 flex gap-2 items-center">
                                            <div
                                                v-if="colorHex"
                                                class="h-4 w-4 rounded-lg"
                                                :style="{ 'background-color': colorHex }"
                                            />
                                            {{ ce.name }}
                                        </div>
                                        <div class="text-gray-500">
                                            {{ ce.description }}
                                        </div>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    <div class="text-gray-900">
                                        {{ formatDateRange(ce.start, ce.end) }}
                                    </div>
                                </td>
                                <td
                                    class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-normal sm:pr-6"
                                >
                                    <div class="flex justify-end gap-2">
                                        <Button
                                            variant="Danger-Light"
                                            size="Base"
                                            @click="onDelete(ce.id, ce.name)"
                                            :disabled-explanation="buttonsDisabledExplanation"
                                        >
                                            <template #icon><DeleteForeverIcon /></template>
                                            {{ $t('delete') }}
                                        </Button>
                                        <Button
                                            variant="Info-Strong"
                                            size="Base"
                                            @click="onEdit(ce.id)"
                                            :disabled-explanation="buttonsDisabledExplanation"
                                        >
                                            <template #icon><EditIcon /></template>
                                            {{ $t('edit') }}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import Button from '@/components/Button/Button.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import EditIcon from '@/components/Icon/EditIcon.vue';
import { DeleteCalendarEventDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { formatDateLong, isSameDay } from '@/utils/date';
import { useMutation } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { CalendarEvent } from '../constraints';

const props = defineProps<{ calendarEvents: CalendarEvent[]; canEdit: boolean; colorHex?: string }>();

const router = useRouter();
const toast = useToast();
const deleteCalendarEvent = useMutation(DeleteCalendarEventDocument, { refetchQueries: ['GetCalendar'] });

function formatDateRange(start: string, end: string) {
    if (isSameDay(start, end)) return formatDateLong(start);
    return `${formatDateLong(start)} - ${formatDateLong(end)}`;
}

function onEdit(id: string) {
    router.push({
        name: 'calendar-event-edit',
        params: { calendarEventId: id },
    });
}

async function onDelete(id: string, name: string) {
    if (!props.canEdit) return;
    if (!confirm($t('confirm-delete-object', { type: 'calendarevent', name }))) return;
    const res = await deleteCalendarEvent.mutate({ id });
    if (res?.data) {
        toast.success($t('delete-success-notification', { type: 'calendarevent', name }));
    }
}

const buttonsDisabledExplanation = computed(() => (props.canEdit ? undefined : $t('no-permission-to-edit')));
</script>
