import auth from '@/auth/auth';
import { DeleteUserDocument, GetUserDocument, SearchUsersDocument, UpdateUserDocument } from '@/generated/graphql';
import { useFileUpload } from '@/upload/upload';
import { useResult } from '@/utils/graphql';
import { getImageUrl } from '@/utils/s3';
import { useApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { Ref, computed, reactive, ref, toRefs, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

type UserSearchResult = {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    icon: { original: string } | null;
};

export type UserUpdate = {
    firstName: string;
    lastName: string;
    email: string;
};

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    name: string;
    email: string;
    icon?:
        | {
              original: string;
          }
        | null
        | undefined;
    iconUpload?: string | undefined;
    iconId?: string;
    isAdmin: boolean;
};

export function useUserSearch(searchCancelsPrevSearch = true) {
    const client = useApolloClient().client;
    const currentSearch = ref();
    const loading = computed(() => currentSearch.value !== undefined);
    const items = ref<UserSearchResult[]>([]);
    const search = ref('');
    const router = useRouter();

    function onChange(userId: string) {
        if (userId) {
            router.replace({ name: 'user-details', params: { userId } });
        }
    }

    watch(search, (query) => {
        currentSearch.value = query;
        client.query({ query: SearchUsersDocument, variables: { query: `%${query}%` } }).then((res) => {
            if (currentSearch.value === query || !searchCancelsPrevSearch) {
                items.value = (res.data.users?.nodes ?? []) as UserSearchResult[];
                currentSearch.value = undefined;
            }
        });
    });

    return {
        onChange,
        loading,
        search,
        items,
    };
}

export function useUser(userId: Ref<string>) {
    const fileUpload = useFileUpload('image/*');
    const toast = useToast();
    const fluent = useFluent();
    const router = useRouter();
    const isAdmin = auth.isAdmin();

    const getQuery = useQuery(
        GetUserDocument,
        computed(() => ({ id: userId.value })),
    );

    const oldUser = useResult(getQuery.result, undefined, (x) => x.user);
    const newUser = () =>
        reactive<User>({
            id: '',
            firstName: '',
            lastName: '',
            name: '',
            email: '',
            isAdmin: false,
        });
    const state = reactive({ user: newUser(), loading: fileUpload.loading });
    watch(
        oldUser,
        (value) => {
            if (value) {
                state.user = Object.assign(newUser(), value);
                fileUpload.abort();
            }
        },
        { immediate: true },
    );

    const updateMutation = useMutation(UpdateUserDocument, {
        refetchQueries: ['GetUsers', 'GetUser'],
    });

    const iconUrl = computed(() => fileUpload.fileUrl.value ?? getImageUrl(state.user.icon?.original) ?? '');

    const saveUser = async () => {
        const res = await updateMutation.mutate(state.user);
        const { id, name } = res?.data?.updateUser?.user ?? {};
        if (id && name) {
            toast.success(fluent.$t('update-success-notification', { type: 'user', name }));
            router.push({ name: 'user-details', params: { userId: id } });
        }
    };
    async function uploadIcon(event: Event) {
        if (state.user.id) {
            var file = await fileUpload.pickAndUpload(event);
            state.user.iconUpload = file ?? '';
        }
    }

    async function deleteIcon() {
        if (state.user.id) {
            state.user.iconUpload = '-';
        }
    }

    const editDisabledExplanation = computed(() => {
        return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
    });
    const deleteDisabledExplanation = computed(() => {
        const authUser = auth.getUser();
        if (!isAdmin) {
            return fluent.$t('no-permission-to-edit');
        }
        if (state.user.email === authUser.email) {
            return fluent.$t('delete-own-user-disabled');
        }
        return undefined;
    });

    const deleteMutation = useMutation(DeleteUserDocument, { refetchQueries: ['GetUsers'] });
    const deleteUser = async () => {
        const authUser = auth.getUser();
        if (state.user.email === authUser.email) return;
        if (!confirm(fluent.$t('confirm-delete-object', { type: 'user', name: state.user.name }))) return;
        const res = await deleteMutation.mutate({ userId: state.user.id });
        const { id, name } = res?.data?.deleteUser?.user ?? {};
        if (id && name) {
            toast.success(fluent.$t('delete-success-notification', { type: 'user', name }));
            router.replace({ name: 'users' });
        }
    };

    return {
        getQuery,
        ...toRefs(state),
        iconUrl,
        canEdit: isAdmin,
        canDelete: isAdmin,
        updateMutation,
        uploadIcon,
        deleteIcon,
        saveUser,
        deleteUser,
        deleteDisabledExplanation,
        editDisabledExplanation,
    };
}
