<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2.333 13.333a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7h1v1Zm-1-2.483V9.433h1v1.417h-1Zm0-2.883V6.55h1v1.417h-1Zm0-2.884V3.667c0-.267.1-.5.3-.7.2-.2.434-.3.7-.3h1.384v1H2.333v1.416h-1Zm2.467 8.25v-1h1.367v1H3.8Zm1.367-9.666v-1H6.55v1H5.167Zm1.466 9.666v-1H8v1H6.633ZM8 6.567v-3.9h5.667c.266 0 .5.1.7.3.2.2.3.433.3.7v2.9H8Zm1.467 6.766v-1h1.366v1H9.467Zm2.833 0v-1h1.367v-1.416h1v1.416c0 .267-.1.5-.3.7-.2.2-.434.3-.7.3H12.3Zm1.367-3.883V8.033h1V9.45h-1Z"
        />
    </svg>
</template>

<script setup lang="ts">
type PartitionIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PartitionIconProps>();
</script>
