<template>
    <HeaderCardsLayout
        v-if="event"
        :headline="event.nameShort"
        :logo-url="iconUrl ?? ''"
        :logo-alt="`Logo ${event.nameShort}`"
        variant="Logo"
    >
        <template #headline-extra>
            <BadgeDropDown
                :label="$t('event-status-label')"
                class="text-base font-normal"
                :current-value="getEventStatusItem(event.status)"
                :values="getEventStatusItems(event.status)"
                :status="statusDropDownStatus"
                @change-value="(status: string) => onStatusChange(status)"
            />
        </template>
        <template #breadcrumb>
            <Breadcrumb
                :headline="$t('breadcrumb')"
                :current-page="event.nameShort"
                :current-page-url="$route.fullPath"
            >
                <BreadcrumbLink :target="{ name: 'events' }">
                    <BallotIcon class="mr-1" />
                    {{ $t('event-wiki') }}
                </BreadcrumbLink>
                <BreadcrumbLink
                    v-if="event.series"
                    :target="{ name: 'event-series-overview', params: { seriesId: event.series.id } }"
                >
                    <SingleEventIcon v-if="event.series.singleEvent" class="mr-1" />
                    {{ event.series.nameShort }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>
        <template #buttons>
            <Button
                variant="Danger-Strong"
                size="Base"
                :disabled-explanation="deleteDisabledExplanation"
                @click="onDeleteEvent"
            >
                <template #icon>
                    <DeleteForeverIcon />
                </template>
                {{ $t('delete') }}
            </Button>
        </template>
        <template #tab-navigation>
            <TabNavigation
                :headline="$t('tab')"
                :items="tabNavigationItems"
                tab-panel-id="eventTabs"
                :tabs-disabled-text="tabsDisabledText"
                @focus-tab-panel="focusTabPanel"
            />
        </template>
        <template #body>
            <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                <RouterView v-slot="{ Component }">
                    <component
                        :is="Component"
                        :active-card="activeCard"
                        :event="event"
                        :errors="errors"
                        :multilinks="multilinks"
                        @enter-edit-mode="onEnterEditMode"
                        @leave-edit-mode="onLeaveEditMode"
                        @discard-changes="onDiscardChanges"
                        @save="onSaveForm"
                        @handle-form-field="handleFormField"
                        @handle-checkbox-input="handleCheckboxInput"
                        @handle-select-change="handleSelectChange"
                        @handle-multilink="handleMultilink"
                        @delete-link="deleteMultilinkLink"
                        @upload-icon="uploadIcon"
                        @delete-icon="deleteIcon"
                    ></component>
                </RouterView>
            </TabPanel>
        </template>
    </HeaderCardsLayout>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import BallotIcon from '@/components/Icon/BallotIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import SingleEventIcon from '@/components/Icon/SingleEventIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { useEventForm } from '@/event/composables/forms';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { getEventStatusItem, getEventStatusItems } from '../event';
import { eventCardFunctionsKey, fileUploadLoadingKey, iconKey } from '../keys';
import { EventStatus } from '../types';

const props = defineProps<{ eventId: string }>();
const fluent = useFluent();

const tabPanelRef = ref();
const eventId = computed(() => props.eventId);
const isAdmin = auth.isAdmin();

const {
    event,
    errors,
    multilinks,
    activeCard,
    onEnterEditMode,
    onLeaveEditMode,
    onDiscardChanges,
    uploadIcon,
    deleteIcon,
    onSaveForm,
    handleFormField,
    handleCheckboxInput,
    handleSelectChange,
    handleMultilink,
    deleteMultilinkLink,
    onDeleteEvent,
    getDisabledExplanation,
    iconUrl,
    iconFromParent,
    fileUploadLoading,
    updateActiveCard,
    updateStatus,
    updateStatusLoading,
    updateStatusError,
} = useEventForm(eventId);

const tabPanelId = nanoid();

const deleteDisabledExplanation = computed(() => (isAdmin ? undefined : fluent.$t('no-permission-to-edit')));
const tabsDisabledText = computed(() => fluent.$t('tabs-disabled', { name: activeCard.value.label }));
const changeStatusDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});

const statusDropDownStatus = computed(() => {
    if (changeStatusDisabledExplanation.value) return 'Disabled';
    if (updateStatusLoading.value) return 'Loading';
    if (updateStatusError.value) return 'Retry';
    return 'Default';
});

provide(fileUploadLoadingKey, fileUploadLoading);
provide(iconKey, ref({ url: iconUrl, fromParent: iconFromParent }));
provide(eventCardFunctionsKey, { updateActiveCard });

onBeforeRouteLeave(() => {
    if (activeCard.value.id && activeCard.value.status === 'Edit-Touched') {
        if (!confirm(fluent.$t('leave-page-warning'))) return false;
    }
});

const isAllocationsTabDisabled = computed(() => Boolean(getDisabledExplanation('event-allocations-tab').value));
const isMasterDataTabDisabled = computed(() => Boolean(getDisabledExplanation('event-masterdata-tab').value));
const isOrganisationTabDisabled = computed(() => Boolean(getDisabledExplanation('event-organisation-tab').value));
const isLinksTabDisabled = computed(() => Boolean(getDisabledExplanation('event-links-tab').value));
const isEventVariantsTabDisabled = computed(() => Boolean(getDisabledExplanation('event-variants-tab').value));

const tabNavigationItems = ref([
    {
        target: { name: 'event-masterdata-tab' },
        routeName: 'event-masterdata-tab',
        label: $t('master-data'),
        isDisabled: isMasterDataTabDisabled,
    },
    {
        target: { name: 'event-allocations-tab' },
        routeName: 'event-allocations-tab',
        label: $t('allocations'),
        isDisabled: isAllocationsTabDisabled,
    },
    {
        target: { name: 'event-variants-tab' },
        routeName: 'event-variants-tab',
        label: $t('event-variants'),
        isDisabled: isEventVariantsTabDisabled,
    },
    {
        target: { name: 'event-organisation-tab' },
        routeName: 'event-organisation-tab',
        label: $t('organisational-structure'),
        isDisabled: isOrganisationTabDisabled,
    },
    {
        target: { name: 'event-links-tab' },
        routeName: 'event-links-tab',
        label: $t('links'),
        isDisabled: isLinksTabDisabled,
    },
]);

const route = useRoute();
const activeTabHeadline = computed(() => {
    return $t('tab-panel', {
        activeTabName: tabNavigationItems.value.find((item) => item.routeName === route.name)?.label ?? '',
    });
});

function focusTabPanel() {
    tabPanelRef.value.focus();
}

useHeadSafe({
    title: () => `${event.value.nameShort} - ${event.value.series?.nameShort} - ${fluent.$t('event-wiki')}`,
});

async function onStatusChange(status: string) {
    await updateStatus(status as EventStatus);
}
</script>
