import { GetMyUserDocument } from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

export function useMyUser() {
    const getMyUser = useQuery(GetMyUserDocument);
    const currentUser = computed(() => getMyUser.result.value?.myUser);
    const currentUserId = computed(() => currentUser.value?.id ?? '');
    const currentUserName = computed(() => currentUser.value?.name ?? '');
    const currentUserIcon = computed(() => currentUser.value?.icon?.original ?? '');

    return {
        currentUser,
        currentUserId,
        currentUserName,
        currentUserIcon,
    };
}
