<template>
    <TopBar :title="calendar.name">
        <template #actions>
            <Button
                size="Base"
                variant="Danger-Strong"
                :disabled-explanation="deleteDisabledExplanation"
                @click="onDelete"
            >
                <template #icon><DeleteForeverIcon /></template>
                {{ $t('delete') }}
            </Button>
            <BaseButton
                :to="{ name: 'calendar-edit', params: { calendarId } }"
                icon="edit"
                size="xs"
                class="mx-2"
                :enabled="canEdit"
            ></BaseButton>
            <BaseButton :to="{ name: 'calendar-event-add' }" icon="add" size="xs" :enabled="canEdit"></BaseButton>
        </template>
    </TopBar>
    <div class="px-4 sm:px-6">
        <div class="sm:flex sm:items-center">
            <div class="sm:flex-auto">
                <p class="mt-2 text-sm text-gray-700">{{ calendar.description }}</p>
            </div>
        </div>
        <p class="mt-2 text-sm text-gray-700"></p>
        <CalendarEventList
            v-if="events?.length"
            :calendarEvents="events"
            :canEdit="canEdit"
            :colorHex="calendar.colorHex"
        ></CalendarEventList>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import BaseButton from '@/components/BaseButton.vue';
import Button from '@/components/Button/Button.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import { DeleteCalendarDocument, GetCalendarDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import CalendarEventList from './CalendarEventList.vue';

const props = defineProps<{ calendarId: string }>();
const canEdit = auth.isAdmin();
const toast = useToast();
const router = useRouter();

const { result } = useQuery(
    GetCalendarDocument,
    computed(() => ({ id: props.calendarId })),
);
const deleteCalendar = useMutation(DeleteCalendarDocument, { refetchQueries: ['GetCalendars'] });
const calendar = computed(() => result.value?.calendar || { id: '', name: '', description: '', colorHex: '' });
const events = computed(() => result.value?.calendar?.calendarEvents.nodes);

const deleteDisabledExplanation = computed(() => {
    if (!canEdit) return $t('no-permission-to-edit');
    if (events.value && events.value?.length > 0) return $t('calendar-has-events');
    return undefined;
});

const onDelete = async () => {
    if (!canEdit) return;
    const name = calendar.value.name ?? '';
    if (!confirm($t('confirm-delete-object', { type: 'calendar', name }))) return;
    const res = await deleteCalendar.mutate({ id: calendar.value.id });
    if (res?.data) {
        toast.success($t('delete-success-notification', { type: 'calendar', name }));
        router.replace({ name: 'calendars' });
    }
};

useHeadSafe({ title: () => `${calendar.value.name} - ${$t('external-events')}` });
</script>
