<template>
    <top-bar :title="$t('location-overview')">
        <template #actions> </template>
    </top-bar>
    <div class="flex flex-col gap-2 p-6">
        <report-link :to="{ name: 'spaces' }" :title="$t('quality-assurance-locations')" />
        <LocationDownloadButton></LocationDownloadButton>
    </div>
</template>
<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import ReportLink from '@/report/components/ReportLink.vue';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import LocationDownloadButton from '../components/LocationDownloadButton.vue';

const fluent = useFluent();

useHeadSafe({ title: () => fluent.$t('location-wiki') });
</script>
