import LevelEditPage from '@/level/pages/LevelEditPage.vue';
import LevelPage from '@/level/pages/LevelPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import RoomAddPage from '@/room/pages/RoomAddPage.vue';

export const levelRoutes = [
    {
        path: 'level/:levelId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'level-details',
                components: {
                    default: LocationsPage,
                    detail: LevelPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'level-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: LevelEditPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'add-room',
                name: 'room-add',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: RoomAddPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
