<template>
    <nav :aria-labelledby="headlineId">
        <h2 :id="headlineId" class="sr-only">{{ props.headline }}</h2>

        <ol role="list" class="flex flex-row gap-2 items-center">
            <slot />

            <li class="flex">
                <RouterLink
                    class="border-b border-b-transparent text-gray-500 hover:border-b hover:border-b-gray-700 hover:text-gray-700 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700 focus-visible:border-b focus-visible:border-b-gray-700 active:text-gray-500 active:border-b-gray-500"
                    aria-current="page"
                    :to="props.currentPageUrl"
                >
                    <slot name="currentIcon" />
                    {{ props.currentPage }}
                </RouterLink>
            </li>
        </ol>
    </nav>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { RouteLocationRaw, RouterLink } from 'vue-router';

type BreadcrumbProps = {
    headline: string;
    currentPage: string;
    currentPageUrl: RouteLocationRaw;
};

const props = defineProps<BreadcrumbProps>();

const headlineId = nanoid();
</script>
