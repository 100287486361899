import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { isArrayEqual } from '@/utils/compare';
import { Ref } from 'vue';
import { useRoute } from 'vue-router';

export function useChangeVisible(
    variantsRef: Ref<TimelineVariant[]>,
    visibleVariants: Ref<string[]>,
    defaultActiveVariants: Ref<TimelineVariant[]>,
    setVisibleVariants: (value: string[]) => void,
    setVisibleParam: (value: string | undefined) => void,
) {
    const route = useRoute();

    function onChangeVisibility(params: { allocationVariantId: string; isVisible: boolean }) {
        const { allocationVariantId, isVisible } = params;
        const visibles = visibleVariants.value ?? [];
        if (isVisible === false) {
            const index = visibles.findIndex((vv) => vv === allocationVariantId);
            visibles.splice(index, 1);
        } else {
            if (!visibles.includes(allocationVariantId)) {
                visibles.push(allocationVariantId);
            }
        }
        setVisibleVariants(visibles);

        // remove url visible param, if filter represents the default state
        // else add visible indexes as url param
        if (
            isArrayEqual(
                [...visibles],
                defaultActiveVariants.value.map((v) => v.id),
            )
        ) {
            if ('visible' in route.query) {
                setVisibleParam(undefined);
            }
        } else {
            const visibleIndexes = variantsRef.value
                .filter((v) => visibleVariants.value.includes(v.id))
                .map((v) => v.index)
                .sort((a, b) => a - b);
            setVisibleParam(visibleIndexes.toString());
        }
    }

    return {
        onChangeVisibility,
    };
}
