<template>
    <BaseForm>
        <template #fields>
            <TextField
                :value="series.nameShort"
                :label="$t('name-short')"
                :error-messages="errors.nameShort"
                @input="$emit('handleFormField', { event: $event, fieldName: 'nameShort' })"
            />
            <TextField
                :value="series.nameLong"
                :label="$t('name-long')"
                :error-messages="errors.nameLong"
                @input="$emit('handleFormField', { event: $event, fieldName: 'nameLong' })"
            />
            <TextAreaField
                :value="series.subtitle"
                :label="$t('subtitle')"
                :error-messages="errors.subtitle"
                @input="$emit('handleFormField', { event: $event, fieldName: 'subtitle' })"
            />
            <TextField
                :value="series.numberShort"
                :label="$t('number-short')"
                :error-messages="errors.numberShort"
                :help-button-text="$t('number-short-help')"
                @input="$emit('handleFormField', { event: $event, fieldName: 'numberShort' })"
            />
            <NumberField
                :value="series.foundationYear ?? ''"
                :label="$t('foundation-year')"
                :error-messages="errors.foundationYear"
                @input="$emit('handleFormField', { event: $event, fieldName: 'foundationYear' })"
            />
            <TextAreaField
                :value="series.notes"
                :label="$t('notes')"
                :error-messages="errors.notes"
                @input="$emit('handleFormField', { event: $event, fieldName: 'notes' })"
            />
            <TextAreaField
                :value="series.description"
                :label="$t('description')"
                :error-messages="errors.description"
                @input="$emit('handleFormField', { event: $event, fieldName: 'description' })"
            />
            <FileUpload
                :label="$t('logo')"
                :url="icon?.url"
                :loading="fileUploadLoading"
                :clear-button-text="$t('delete-series-icon')"
                @change="$emit('uploadIcon', $event)"
                @clear="$emit('deleteIcon')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import FileUpload from '@/components/Form/FileUpload.vue';
import NumberField from '@/components/Form/NumberField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { fileUploadLoadingKey, iconKey } from '@/event/keys';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { inject } from 'vue';

defineProps<{ series: EventSeriesDetails; errors: EventSeriesDetailsErrors }>();
defineEmits<{
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'uploadIcon', v: Event): void;
    (e: 'deleteIcon'): void;
}>();

const fileUploadLoading = inject(fileUploadLoadingKey);
const icon = inject(iconKey);
</script>
