export const locationTypes = ['Site', 'Building', 'Level', 'Room', 'Zone', 'Area', 'Plot', 'RoomPartition'] as const;
export type LocationType = (typeof locationTypes)[number];

export const locationTypesCamelCase = [
    'site',
    'building',
    'zone',
    'area',
    'level',
    'plot',
    'room',
    'roomPartition',
] as const;
export type LocationTypesCamelCase = (typeof locationTypesCamelCase)[number];
