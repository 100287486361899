<template>
    <InputField
        :value="value"
        type="text"
        inputmode="numeric"
        :label="label"
        :disabled="disabled"
        :description="description"
        :help-button-text="helpButtonText"
        :min="min"
        :max="max"
        :step="step"
        :required="required"
        :placeholder="placeholder"
        :error-messages="errorMessages"
        :status="status"
        @input="$emit('input', $event)"
        @change="$emit('change', $event)"
        @blur="$emit('blur', $event)"
        @keydown="handleKeydown"
    />
</template>
<script setup lang="ts">
import InputField from './InputField.vue';

type NumberFieldProps = {
    value: string | number;
    label: string;
    disabled?: boolean;
    description?: string;
    helpButtonText?: string;
    min?: number;
    max?: number;
    step?: string;
    required?: boolean;
    placeholder?: string;
    errorMessages?: string[];
    status?: 'overridden';
};

const props = withDefaults(defineProps<NumberFieldProps>(), { step: '1' });
const emit = defineEmits<{
    (e: 'input', v: Event): void;
    (e: 'change', v: Event): void;
    (e: 'blur', v: Event): void;
    (e: 'keyDown', v: string): void;
}>();

const handleKeydown = (e: KeyboardEvent) => {
    const value = props.value ?? '0';
    const valueNumber = Number(value);
    const stepNumber = Number(props.step);
    var numDecimals = 0;
    if (!Number.isInteger(stepNumber)) {
        numDecimals = props.step?.split('.')[1].length;
    }
    switch (e.key) {
        case 'ArrowDown':
            emit('keyDown', (valueNumber - stepNumber).toFixed(numDecimals));
            break;
        case 'ArrowUp':
            emit('keyDown', (valueNumber + stepNumber).toFixed(numDecimals));
            break;
    }
};
</script>
