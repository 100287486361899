<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path fill="currentColor" d="M6 12V4l4 4-4 4Z" />
    </svg>
</template>

<script setup lang="ts">
type TriangleIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<TriangleIconProps>();
</script>
