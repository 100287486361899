import BuildingAddPage from '@/building/pages/BuildingAddPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import SiteEditPage from '@/site/pages/SiteEditPage.vue';
import SitePage from '@/site/pages/SitePage.vue';
import ZoneAddPage from '@/zone/pages/ZoneAddPage.vue';
import { RouterView } from 'vue-router';
import SiteAddPage from './pages/SiteAddPage.vue';

export const siteRoutes = [
    {
        path: 'site/',
        component: RouterView,
        children: [
            {
                path: ':siteId/',
                component: MasterDetailsLayout,
                children: [
                    {
                        path: '',
                        name: 'site-details',
                        components: {
                            default: LocationsPage,
                            detail: SitePage,
                        },
                        props: { default: false, detail: true },
                    },
                    {
                        path: 'edit',
                        name: 'site-edit',
                        beforeEnter: requireAdmin,
                        components: {
                            default: LocationsPage,
                            detail: SiteEditPage,
                        },
                        props: { default: false, detail: true },
                    },
                    {
                        path: 'add-building',
                        name: 'building-add',
                        beforeEnter: requireAdmin,
                        components: {
                            default: LocationsPage,
                            detail: BuildingAddPage,
                        },
                        props: { default: false, detail: true },
                    },
                    {
                        path: 'add-zone',
                        name: 'zone-add',
                        beforeEnter: requireAdmin,
                        components: {
                            default: LocationsPage,
                            detail: ZoneAddPage,
                        },
                        props: { default: false, detail: true },
                    },
                ],
            },
            {
                path: 'add',
                component: MasterDetailsLayout,
                children: [
                    {
                        path: '',
                        name: 'site-add',
                        beforeEnter: requireAdmin,
                        components: {
                            default: LocationsPage,
                            detail: SiteAddPage,
                        },
                        props: { default: false, detail: true },
                    },
                ],
            },
        ],
    },
];
