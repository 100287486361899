<template>
    <div class="container">
        <div
            v-for="item in flatTree"
            :key="item.node.key"
            :style="{ 'padding-left': `${item.position.depth * levelMargin}px` }"
            :class="[
                'flex items-center justify-start text-sm space-x-2 hover:bg-primary/[.1] rounded-sm',
                { 'bg-primary/[.2]': isActive(item.node.to) },
            ]"
            @click="if (!item.node.to && isExpandable(item)) expandNode(item);"
        >
            <button
                class="rounded-full h-8 w-8 mr-0.5 p-2 flex items-center justify-center text-gray-500 transition-all ease-in-out"
                :class="[isExpanded(item) ? 'rotate-90' : '', isExpandable(item) ? 'visible' : 'invisible']"
                @click.stop="expandNode(item)"
            >
                <icon v-if="loadingKeys?.has(item.node.key)" class="animate-spin">autorenew</icon>
                <icon v-else>arrow_right</icon>
            </button>
            <slot name="item" :position="item.position" :node="item.node">
                <RouterLink
                    class="select-none flex-auto flex gap-2 align-middle items-center"
                    :to="item.node.to ?? {}"
                    :class="item.node.subtitle ? 'my-1' : ''"
                >
                    <template #default>
                        <Icon v-if="item.node.icon" size="20" class="opacity-50">{{ item.node.icon }}</Icon>
                        <slot name="label" :item="item">
                            <div class="flex flex-col">
                                <span class="leading-none">{{ item.node.title }}</span>
                                <span v-if="item.node.subtitle" class="text-xs text-gray-500">{{
                                    item.node.subtitle
                                }}</span>
                            </div>
                        </slot>
                    </template>
                </RouterLink>
            </slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import Icon from './Icon.vue';

export type TreePosition = {
    depth: number;
    parent?: TreeNode;
};

export type TreeNode = {
    id: string;
    key: string;
    title: string;
    subtitle?: string;
    icon?: string;
    to?: RouteLocationRaw;
    selectable?: boolean;
};

export type TreeItem = {
    position: TreePosition;
    node: TreeNode;
};

type Props = {
    nodes: ReadonlyArray<TreeNode>;
    childMap: Map<string, TreeNode[]>;
    levelMargin?: number;
    expandedKeys?: Set<string>;
    loadingKeys?: Set<string>;
    selectedKeys?: Set<string>;
};

const props = withDefaults(defineProps<Props>(), {
    withBorder: false,
    levelMargin: 8,
    expandedKeys: undefined,
    loadingKeys: undefined,
    selectedKeys: undefined,
});

const router = useRouter();

const emit = defineEmits<{
    (e: 'expand', type: TreeItem): void;
    (e: 'collapse', type: TreeItem): void;
    (e: 'select', type: TreeItem): void;
    (e: 'deselect', type: TreeItem): void;
}>();

const isExpanded = (item: TreeItem) => props.expandedKeys?.has(item.node.key) ?? false;
const isExpandable = (item: TreeItem) => {
    const children = props.childMap.get(item.node.key);
    return children === undefined || children.length > 0;
};

const flatTree = computed(() => {
    var items = [] as TreeItem[];

    function addItem(item: TreeItem) {
        items.push(item);
        if (isExpanded(item)) {
            const childPos = { depth: item.position.depth + 1, parent: item.node };
            props.childMap.get(item.node.key)?.forEach((x) => addItem({ node: x, position: childPos }));
        }
    }

    props.nodes.forEach((x) => addItem({ node: x, position: { depth: 0 } }));
    return items;
});

const expandNode = (item: TreeItem) => {
    if (!isExpanded(item)) {
        emit('expand', item);
    } else {
        emit('collapse', item);
    }
};

const isActive = (to?: RouteLocationRaw) => {
    if (to) {
        return router.resolve(to).path === router.currentRoute.value.path;
    }
    return false;
};
</script>
