<template>
    <TopBar :title="$t('edit-object', { type: 'calendarevent' })" />
    <div class="p-5">
        <CalendarEventEditForm v-if="calendarEvent" :calendarEvent="calendarEvent"></CalendarEventEditForm>
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetCalendarEventDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import CalendarEventEditForm from '../forms/CalendarEventEditForm.vue';

const props = defineProps<{ calendarId: string; calendarEventId: string }>();

// TODO handle loading and error state
const getCalendarEvent = useQuery(
    GetCalendarEventDocument,
    computed(() => ({ id: props.calendarEventId })),
);

const calendarEvent = computed(() => getCalendarEvent.result.value?.calendarEvent);
useHeadSafe({ title: () => `${$t('edit-object', { type: 'calendarevent' })} - ${$t('external-events')}` });
</script>
