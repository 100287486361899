import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { RouteLocationRaw } from 'vue-router';
import { ExternalEvent, PhaseForm } from '../Timeline.types';
import { TimelineExternalEventDay } from '../TimelineView.types';
import { insertInvisibleAllocations } from './continuingAllocationsLayout';

export const EXTERNAL_EVENTS_ROW_ID = 'external-events-row';

export function calculateExternalEventDays(
    externalEvents: ExternalEvent[],
    startDateTime: DateTime,
    endDateTime: DateTime,
    durationInDays: number,
): TimelineExternalEventDay[] {
    if (externalEvents.length === 0) {
        return [];
    }

    let tempExternalEventDays: TimelineExternalEventDay[] = [];
    let enduringEvents: {
        label: string;
        totalDurationInDays: number;
        remainingDays: number;
        eventId: string;
        isEditable: boolean;
        linkTarget: RouteLocationRaw;
    }[] = [];

    for (let dayIndex = 0; dayIndex < durationInDays; dayIndex++) {
        // Calculate form of events
        let eventPartsOnThisDay: {
            label: string;
            form: PhaseForm;
            totalDurationInDays: number;
            eventId: string;
            isEditable: boolean;
            linkTarget: RouteLocationRaw;
        }[] = [];
        const dateTime = startDateTime.plus({ days: dayIndex });

        const eventStartsOnThisDay = externalEvents.filter(
            (event) => event.startDateTime.diff(dateTime, 'days').days === 0,
        );

        enduringEvents = enduringEvents
            .map((event) => {
                if (event.remainingDays > 1) {
                    eventPartsOnThisDay = [...eventPartsOnThisDay, { ...event, form: 'Middle' as const }];
                } else {
                    eventPartsOnThisDay = [...eventPartsOnThisDay, { ...event, form: 'End' as const }];
                }

                return {
                    ...event,
                    remainingDays: event.remainingDays - 1,
                };
            })
            .filter((event) => event.remainingDays > 0);

        eventStartsOnThisDay.forEach((event) => {
            if (event.cellSpan === 1) {
                // Single day event
                eventPartsOnThisDay = [
                    ...eventPartsOnThisDay,
                    {
                        label: event.label,
                        form: 'Single' as const,
                        totalDurationInDays: event.cellSpan,
                        eventId: event.id,
                        isEditable: event.isEditable,
                        linkTarget: event.linkTarget,
                    },
                ];
            } else {
                // Event spans across multiple days
                eventPartsOnThisDay = [
                    ...eventPartsOnThisDay,
                    {
                        label: event.label,
                        form: 'Start' as const,
                        totalDurationInDays: event.cellSpan,
                        eventId: event.id,
                        isEditable: event.isEditable,
                        linkTarget: event.linkTarget,
                    },
                ];

                enduringEvents = [
                    ...enduringEvents,
                    {
                        label: event.label,
                        remainingDays: event.cellSpan - 1,
                        totalDurationInDays: event.cellSpan,
                        eventId: event.id,
                        isEditable: event.isEditable,
                        linkTarget: event.linkTarget,
                    },
                ];
            }
        });

        tempExternalEventDays = [
            ...tempExternalEventDays,
            {
                isWorkDay: dateTime.weekday !== 6 && dateTime.weekday !== 7,
                allocations: eventPartsOnThisDay.map((event) => ({
                    id: event.eventId,
                    phaseType: 'ExternalEvent',
                    allocationType: 'None',
                    allocationVariantId: 'external-event',
                    allocationVariantLabel: event.label,
                    specialization: null,
                    label: event.label,
                    isLabelVisible: event.form === 'Start' || event.form === 'Single',
                    labelCellSpan: event.totalDurationInDays,
                    labelCellSpanType: event.form === 'Single' ? 'entire' : 'start-middle',
                    isPhaseEnd: false,
                    form: event.form,
                    isVisible: true,
                    startDateTime,
                    endDateTime,
                    phases: [
                        {
                            id: nanoid(),
                            cellSpan: event.totalDurationInDays,
                            type: 'ExternalEvent',
                            specialization: null,
                        },
                    ],
                    isEditable: false,
                    linkTarget: event.linkTarget,
                    variant: 'Solid',
                })),
                rowId: EXTERNAL_EVENTS_ROW_ID,
                columnId: `column-${dayIndex}`,
                isFirstColumn: dayIndex === 0,
                startDateTime: dateTime,
                endDateTime: dateTime.plus({ days: 1 }),
            },
        ];
    }

    return insertInvisibleAllocations(startDateTime, endDateTime, tempExternalEventDays);
}

export function countExternalEventsPerDay(externalEventsPerDay: TimelineExternalEventDay[]): number {
    let max = 0;

    externalEventsPerDay.forEach((day) => {
        if (day.allocations.length > max) {
            max = day.allocations.length;
        }
    });

    return max;
}
