<template>
    <BaseForm @submit.prevent="onSubmit">
        <template #fields>
            <TextField
                :value="calendarEvent.name"
                :label="$t('name')"
                :error-messages="nameErrors"
                @input="nameChange"
            />
            <TextAreaField
                :value="calendarEvent.description"
                :label="$t('description')"
                :error-messages="descriptionErrors"
                @input="descriptionChange"
            />
            <DateTimeField
                :value="calendarEvent.start"
                :label="$t('start')"
                :error-messages="startErrors"
                @change="startChange"
            />
            <DateTimeField
                :value="calendarEvent.end"
                :label="$t('end')"
                :error-messages="endErrors"
                @change="endChange"
            />
        </template>
        <template #buttons>
            <BaseButton variant="outlined" exact @click="$router.back()">{{ $t('cancel') }}</BaseButton>
            <BaseButton submit color="primary" type="submit" :loading="createCalendarEvent.loading.value">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard :mutation="createCalendarEvent" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import DateTimeField from '@/components/Form/DateTimeField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { CreateCalendarEventDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { getDurationFromStartEnd, responseDurationToObject } from '../constraints';
import { CalendarEventCreateSchema } from './schemas';

const props = defineProps<{ calendarId: string }>();

const calendarEvent = ref({
    name: '',
    description: '',
    start: '',
    end: '',
    duration: responseDurationToObject({
        seconds: undefined,
        minutes: undefined,
        hours: undefined,
        days: undefined,
        months: undefined,
        years: undefined,
    }),
    calendarId: props.calendarId,
});
const createCalendarEvent = useMutation(CreateCalendarEventDocument);
const router = useRouter();

const nameErrors = ref<string[]>();
const descriptionErrors = ref<string[]>();
const startErrors = ref<string[]>();
const endErrors = ref<string[]>();

const nameChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    nameErrors.value = undefined;
    calendarEvent.value.name = value;
    const parse = CalendarEventCreateSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.format()._errors;
    }
};

const descriptionChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendarEvent.value.description = value;
    const parse = CalendarEventCreateSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.format()._errors;
    }
};

const startChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    startErrors.value = undefined;
    calendarEvent.value.start = value;
    const parse = CalendarEventCreateSchema.shape.start.safeParse(value);
    if (!parse.success) {
        startErrors.value = parse.error.format()._errors;
    }
};

const endChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    endErrors.value = undefined;
    calendarEvent.value.end = value;
    const parse = CalendarEventCreateSchema.shape.end.safeParse(value);
    if (!parse.success) {
        endErrors.value = parse.error.format()._errors;
    }
};

const onSubmit = async () => {
    const duration = getDurationFromStartEnd(calendarEvent.value.start, calendarEvent.value.end);
    calendarEvent.value = {
        ...calendarEvent.value,
        duration,
    };
    const parse = CalendarEventCreateSchema.safeParse(calendarEvent.value);
    if (!parse.success) {
        // FIXME handle a whole error object
        const flatErrors = parse.error.flatten().fieldErrors;
        nameErrors.value = flatErrors.name;
        descriptionErrors.value = flatErrors.description;
        startErrors.value = flatErrors.start;
        endErrors.value = flatErrors.end;
        return;
    }

    const res = await createCalendarEvent.mutate(calendarEvent.value);
    const { id, name } = res?.data?.createCalendarEvent?.calendarEvent ?? {};
    if (id && name) {
        router.push({ name: 'calendar-detail', params: { calendarId: props.calendarId } });
    }
};
</script>
