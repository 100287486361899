<template>
    <div class="h-8 grid gap-3 items-center grid-cols-[1rem_1fr_min-content]">
        <FileIcon class="text-gray-500" />

        <div class="flex flex-row gap-1 items-center">
            <p class="font-semibold text-sm text-gray-700 line-clamp-1">
                {{ props.label }}
            </p>
            <HelpButton v-if="props.helpButtonText" class="text-gray-500" :text="props.helpButtonText" />

            <p class="ml-2 whitespace-nowrap text-gray-500">{{ props.fileSize }}</p>
        </div>

        <Button size="Small" variant="Neutral-Strong">
            <template #icon>
                <DownloadIcon />
            </template>

            {{ $t('download') }}
        </Button>
    </div>
</template>

<script setup lang="ts">
import Button from '../Button/Button.vue';
import HelpButton from '../HelpButton/HelpButton.vue';
import DownloadIcon from '../Icon/DownloadIcon.vue';
import FileIcon from '../Icon/FileIcon.vue';

type FileAttachmentProps = {
    label: string;
    fileSize: string;
    helpButtonText?: string;
};

const props = defineProps<FileAttachmentProps>();
</script>
