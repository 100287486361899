<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M11.47 7V5.53H10V4.452h1.47V3h1.077v1.453H14v1.076h-1.453V7h-1.076ZM2 5.5v-1h4v1H2Z"
        />
        <path
            fill="currentColor"
            fill-rule="evenodd"
            d="M2 9v4h12V9H2Zm3.717 3H3v-2h2.717v2Zm3.55 0h-2.55v-2h2.55v2ZM13 12h-2.717v-2H13v2Z"
            clip-rule="evenodd"
        />
    </svg>
</template>

<script setup lang="ts">
type PhaseMinusPlusIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<PhaseMinusPlusIconProps>();
</script>
