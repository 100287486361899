<template>
    <BaseForm>
        <template #fields>
            <Toggle
                :value="series.singleProject"
                :label="$t('limit-to-single-project')"
                :help-button-text="$t('limit-to-single-project-help')"
                @change="handleToggleField($event, 'singleProject')"
            ></Toggle>
            <FieldGroup :title="$t('identifiers')">
                <TextField
                    :value="series.nameShort"
                    :label="$t('name-short')"
                    :errorMessages="errors.nameShort"
                    @input="handleFormField($event, 'nameShort')"
                />
                <TextField
                    :value="series.nameLong"
                    :label="$t('name-long')"
                    :errorMessages="errors.nameLong"
                    @input="handleFormField($event, 'nameLong')"
                />
            </FieldGroup>
        </template>
    </BaseForm>
    <div class="p-3 flex justify-end gap-3">
        <Button size="Base" variant="Info-Light" @click="$router.back">
            <template #icon>
                <UndoIcon />
            </template>
            {{ $t('card-abort') }}
        </Button>
        <Button
            size="Base"
            variant="Success-Light"
            :disabled-explanation="saveButtonDisabledExplanation"
            @click="onSave"
        >
            <template #icon>
                <AllDoneIcon />
            </template>
            {{ $t('card-save') }}
        </Button>
    </div>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import FieldGroup from '@/components/Form/FieldGroup.vue';
import TextField from '@/components/Form/TextField.vue';
import Toggle from '@/components/Form/Toggle.vue';
import AllDoneIcon from '@/components/Icon/AllDoneIcon.vue';
import UndoIcon from '@/components/Icon/UndoIcon.vue';
import { CreateProjectSeriesDocument } from '@/generated/graphql';
import { goToProjectSeries } from '@/project/project';
import { ProjectSeriesCreateErrors, ProjectSeriesCreateSchema, ProjectSeriesCreateSchemaType } from '@/project/schemas';
import { ProjectSeriesCreate } from '@/project/types';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, reactive } from 'vue';
import { useToast } from 'vue-toastification';

const fluent = useFluent();
const toast = useToast();
const createProjectSeries = useMutation(CreateProjectSeriesDocument);
const isAdmin = auth.isAdmin();

const series = reactive<ProjectSeriesCreate>({
    singleProject: false,
    nameShort: '',
    nameLong: '',
});

const errors = reactive<ProjectSeriesCreateErrors>({
    singleProject: [],
    nameShort: [],
    nameLong: [],
});

function handleFormField(event: Event, fieldName: string) {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    const parse = ProjectSeriesCreateSchema.shape[fieldName as keyof ProjectSeriesCreateSchemaType].safeParse(value);
    if (parse.success) {
        errors[fieldName as keyof ProjectSeriesCreateErrors] = [];
        series[fieldName] = parse.data;
    } else {
        errors[fieldName as keyof ProjectSeriesCreateErrors] = parse.error.format()._errors;
        series[fieldName] = value;
    }
}

function handleToggleField(value: boolean, fieldName: string) {
    const parse = ProjectSeriesCreateSchema.shape[fieldName as keyof ProjectSeriesCreateSchemaType].safeParse(value);
    if (parse.success) {
        errors[fieldName as keyof ProjectSeriesCreateErrors] = [];
        series[fieldName] = parse.data;
    } else {
        errors[fieldName as keyof ProjectSeriesCreateErrors] = parse.error.format()._errors;
        series[fieldName] = value;
    }
}

async function onSave() {
    const parse = ProjectSeriesCreateSchema.safeParse(series);
    if (!parse.success) {
        const flattenedErrors = parse.error.flatten().fieldErrors;
        Object.assign(errors, flattenedErrors);
        return;
    }
    const res = await createProjectSeries.mutate(parse.data);
    const { id, nameShort } = res?.data?.createProjectSeries?.projectSeries ?? {};
    if (id && nameShort) {
        toast.success(fluent.$t('create-success-notification', { type: 'projectseries', name: nameShort }));
        goToProjectSeries(id);
    }
}

const saveButtonDisabledExplanation = computed(() => {
    return isAdmin ? undefined : fluent.$t('no-permission-to-edit');
});
</script>
