<template>
    <div v-if="area">
        <TopBar :title="$t('plot-add')" :breadcrumbs="locationBreadcrumbs(area)" transparent />
        <LocationCreateForm type="Plot" :location-types="plotTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetPlotForCreateDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ areaId: string }>();

const query = useQuery(GetPlotForCreateDocument, { areaId: props.areaId });
const area = useResult(query.result, undefined, (x) => x.area);
const plotTypes = useResult(query.result, [], (x) => x.plotTypes.nodes);
const parent = { key: 'areaId', value: props.areaId };
</script>
