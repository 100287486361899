import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import { AllocationPart } from '../Timeline.types';

export type DayWithAllocations = {
    isWorkDay: boolean;
    allocations: AllocationPart[];
};

const ANY_INDEX = -1;

export function insertInvisibleAllocations<T extends DayWithAllocations>(
    startDateTime: DateTime,
    endDateTime: DateTime,
    days: T[],
): T[] {
    const reservedIndexesByIndex: Map<number, string> = new Map();
    const reservedIndexesByAllocationId: Map<string, number> = new Map();
    const daysWithInvisibleAllocations: DayWithAllocations[] = Array(days.length).fill({
        allocations: [],
    });

    for (let dayIndex = 0; dayIndex < days.length; dayIndex++) {
        let index = 0;
        const unplacedAllocationParts: Map<number, AllocationPart[]> = new Map();
        const placedAllocationParts: AllocationPart[] = [];

        for (const allocationPart of days[dayIndex].allocations) {
            const reservedIndex = reservedIndexesByAllocationId.get(allocationPart.id);
            if (reservedIndex !== undefined) {
                unplacedAllocationParts.set(reservedIndex, [allocationPart]);
            } else {
                const anyIndexParts = unplacedAllocationParts.get(ANY_INDEX);
                unplacedAllocationParts.set(ANY_INDEX, [...(anyIndexParts ?? []), allocationPart]);
            }
        }

        while (unplacedAllocationParts.size > 0) {
            if (!reservedIndexesByIndex.has(index)) {
                const anyIndexParts = unplacedAllocationParts.get(ANY_INDEX);

                if (anyIndexParts && anyIndexParts.length > 0) {
                    const [allocationPart, ...remainingParts] = anyIndexParts;

                    placedAllocationParts.push({ ...allocationPart, isVisible: true });
                    if (remainingParts.length > 0) {
                        unplacedAllocationParts.set(ANY_INDEX, remainingParts);
                    } else {
                        unplacedAllocationParts.delete(ANY_INDEX);
                    }

                    if (allocationPart.form === 'Start' || allocationPart.form === 'Middle') {
                        reservedIndexesByIndex.set(index, allocationPart.id);
                        reservedIndexesByAllocationId.set(allocationPart.id, index);
                    }
                } else {
                    placedAllocationParts.push({
                        id: `Invisible-${nanoid()}`,
                        phaseType: 'Empty',
                        allocationType: 'None',
                        specialization: null,
                        label: '',
                        isLabelVisible: false,
                        isPhaseEnd: false,
                        form: 'Middle',
                        isVisible: false,
                        startDateTime,
                        endDateTime,
                        phases: [],
                        isEditable: false,
                        linkTarget: '',
                        allocationVariantId: 'invisible',
                        variant: 'Solid',
                    });
                }
            } else {
                const allocationParts = unplacedAllocationParts.get(index);

                if (!allocationParts) {
                    throw new Error('Allocation not found, this should not happen');
                }

                const allocationPart = allocationParts[0];

                placedAllocationParts.push({ ...allocationPart, isVisible: true });
                unplacedAllocationParts.delete(index);

                if (allocationPart.form === 'End') {
                    reservedIndexesByIndex.delete(index);
                    reservedIndexesByAllocationId.delete(allocationPart.id);
                }
            }

            index++;
        }

        daysWithInvisibleAllocations[dayIndex] = {
            ...days[dayIndex],
            allocations: placedAllocationParts,
        };
    }

    return daysWithInvisibleAllocations as T[];
}
