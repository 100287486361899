<template>
    <BaseForm @submit.prevent="onSubmit" v-if="calendar">
        <template #fields>
            <TextField :value="calendar.name" :label="$t('name')" :errorMessages="nameErrors" @input="nameChange" />
            <TextField
                :value="calendar.description"
                :label="$t('description')"
                :errorMessages="descriptionErrors"
                @input="descriptionChange"
            />
            <ColorField
                :value="calendar.colorHex"
                :label="$t('color')"
                :errorMessages="colorErrors"
                @input="colorHexChange"
            />
        </template>
        <template #buttons>
            <BaseButton @click="$router.back()" variant="outlined" exact>{{ $t('cancel') }}</BaseButton>
            <BaseButton submit color="primary" type="submit" :loading="updateCalendar.loading.value">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard :mutation="updateCalendar" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import ColorField from '@/components/Form/ColorField.vue';
import TextField from '@/components/Form/TextField.vue';
import { UpdateCalendarDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { Constraint } from '../constraints';
import { CalendarEditSchema } from './schemas';

const props = defineProps<{ calendar: Constraint }>();
const router = useRouter();
const calendar = ref({
    id: props.calendar.id,
    name: props.calendar.name,
    description: props.calendar.description ?? '',
    colorHex: props.calendar.colorHex,
});

const nameErrors = ref<string[]>();
const descriptionErrors = ref<string[]>();
const colorErrors = ref<string[]>();

const nameChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    nameErrors.value = undefined;
    calendar.value.name = value;
    const parse = CalendarEditSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const descriptionChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendar.value.description = value;
    const parse = CalendarEditSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const colorHexChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendar.value.colorHex = value;
    const parse = CalendarEditSchema.shape.colorHex.safeParse(value);
    if (!parse.success) {
        colorErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const updateCalendar = useMutation(UpdateCalendarDocument);

const onSubmit = async () => {
    let res = await updateCalendar.mutate(calendar.value);
    const { id, name } = res?.data?.updateCalendar?.calendar ?? {};
    if (id && name) {
        router.push({ name: 'calendar-detail', params: { calendarId: id } });
    }
};
</script>
