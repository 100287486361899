<template>
    <SidePanel :title="$t('calendar-sidepanel-title')">
        <template #actions>
            <BaseButton :to="{ name: 'calendar-add' }" icon="add" size="xs" :enanbled="isAdmin"></BaseButton>
        </template>
        <SearchField v-model="search"></SearchField>
        <CalendarList v-if="calendars" :calendars="calendars"></CalendarList>
    </SidePanel>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import BaseButton from '@/components/BaseButton.vue';
import SearchField from '@/components/SearchField.vue';
import SidePanel from '@/components/SidePanel.vue';
import { GetCalendarsDocument } from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import CalendarList from './CalendarList.vue';

const search = ref('');
const query = useQuery(
    GetCalendarsDocument,
    computed(() => ({ q: search.value })),
);
const calendars = computed(() => query.result.value?.calendars?.nodes);
const isAdmin = auth.isAdmin();
</script>

<fluent locale="de">
calendar-sidepanel-title=Externe Termine
</fluent>
