<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M13.667 4.808v7.653c0 .337-.117.622-.35.856-.234.233-.519.35-.856.35H3.538c-.336 0-.621-.117-.855-.35a1.163 1.163 0 0 1-.35-.856V3.538c0-.336.117-.621.35-.855.234-.233.519-.35.855-.35h7.654l2.475 2.475Zm-1 .425-1.9-1.9H3.538a.2.2 0 0 0-.147.058.2.2 0 0 0-.058.147v8.923a.2.2 0 0 0 .058.148.2.2 0 0 0 .147.058h8.923a.2.2 0 0 0 .148-.058.2.2 0 0 0 .058-.148V5.233ZM8 11.513c.461 0 .855-.163 1.18-.487a1.61 1.61 0 0 0 .487-1.18c0-.461-.163-.855-.488-1.18A1.606 1.606 0 0 0 8 8.18c-.46 0-.854.163-1.179.488a1.607 1.607 0 0 0-.487 1.18c0 .46.163.854.487 1.179a1.61 1.61 0 0 0 1.18.487ZM4.256 6.59h5.475V4.256H4.256V6.59Zm-.923-1.357v7.434-9.334 1.9Z"
        />
    </svg>
</template>

<script setup lang="ts">
type SaveIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<SaveIconProps>();
</script>
