import { createMachine } from 'xstate';

export function createStateMachine(onCreateAllocation: () => Promise<void>) {
    return createMachine({
        /** @xstate-layout N4IgpgJg5mDOIC5QGEBOYCGAXMBBANvgPYDG2AlkQHYB0amOAxPdmAAQaGkXUDaADAF1EoAA5FY5LJSoiQAD0QBGACwBOGgHYAHEoBMAVgBsRgMxqjhgwBoQAT2UH+NNZqMGD2les0rTOgF8A2xYcAmIyaWoaAAUwKghyKihGCGowGiSANyIAawzQvC5ImVj4xOSEbO4oqgFBerlxSVq5RQQlTQ0vf0s9fl1+fiM1WwcO-lMaFTcDX06Td009IJD0VnCa0riEpJSwVFQiVBpRfGwAM2OAWzp1sOKeWh2KqCqqHJK+IUakEGapDI2ognAYaMNDHo9GZ+podCoxspTHpwUM0aYZto9EolKZViBCpsvrQAGIYcj4SCMABKYCwqDsbBI93YnAiT1+YgkgOowIQoPBlgMUJh-DhXkRCF00zUsu0Xk0+n8KiMQWCICoRAgcDkhMerT+AINoHaAFojJLTWDZTbbXa8eq9ezancGGAmtzjQpECo9JLVFNzMi9KZcSMuvL8U6ttEXnsPS0gX92qZTEZUUMcQZTPKjEptP7VDQPGp5Sp+Gp+EojPw9JooyyiU8aGSKZAEzzZMnEJpQ8W1P5Fd5+AYB6N7MoVNoaEHOhY85YRmqAkA */
        id: 'CreateAllocation',
        initial: 'Create',
        states: {
            Create: {
                on: {
                    'Create allocation': {
                        target: 'Pending',
                    },
                },
            },

            Pending: {
                invoke: {
                    src: onCreateAllocation,
                },
                on: {
                    'Create allocation succeeded': {
                        target: 'Create',
                    },
                    'Create allocation failed': {
                        target: 'Failed',
                    },
                },
            },

            Failed: {
                on: {
                    'Retry create allocation': {
                        target: 'Pending',
                    },
                },
            },
        },
        predictableActionArguments: true,
        preserveActionOrder: true,
        tsTypes: {} as import('./Cell.state.typegen').Typegen0,
        schema: {
            events: {} as
                | { type: 'Create allocation' }
                | { type: 'Create allocation succeeded' }
                | { type: 'Create allocation failed' }
                | { type: 'Retry create allocation' },
        },
    });
}
