<template>
    <div v-if="site">
        <TopBar :title="$t('building-add')" :breadcrumbs="locationBreadcrumbs(site)" transparent />
        <LocationCreateForm v-if="site" type="Building" :location-types="buildingTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetCreateBuildingParamsDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ siteId: string }>();

const query = useQuery(GetCreateBuildingParamsDocument, { siteId: props.siteId });
const site = useResult(query.result, undefined, (x) => x.site);
const buildingTypes = useResult(query.result, [], (x) => x.buildingTypes.nodes);
const parent = { key: 'siteId', value: props.siteId };
</script>
