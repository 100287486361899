<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M11.333 14a2.568 2.568 0 0 1-1.883-.783 2.568 2.568 0 0 1-.783-1.884c0-.733.26-1.36.783-1.883a2.568 2.568 0 0 1 1.883-.783c.734 0 1.361.26 1.884.783.522.522.783 1.15.783 1.883a2.57 2.57 0 0 1-.783 1.884 2.568 2.568 0 0 1-1.884.783Zm0-1.333c.367 0 .68-.13.942-.392.261-.261.392-.575.392-.942 0-.366-.13-.68-.392-.941a1.284 1.284 0 0 0-.942-.392c-.366 0-.68.13-.941.392a1.284 1.284 0 0 0-.392.941c0 .367.13.68.392.942.26.261.575.392.941.392ZM4.667 12a2.568 2.568 0 0 1-1.884-.783A2.568 2.568 0 0 1 2 9.333c0-.733.261-1.36.783-1.883a2.568 2.568 0 0 1 1.884-.783c.733 0 1.36.26 1.883.783.522.522.783 1.15.783 1.883 0 .734-.26 1.361-.783 1.884A2.568 2.568 0 0 1 4.667 12Zm0-1.333c.366 0 .68-.13.941-.392A1.28 1.28 0 0 0 6 9.333c0-.366-.13-.68-.392-.941A1.284 1.284 0 0 0 4.667 8c-.367 0-.68.13-.942.392a1.284 1.284 0 0 0-.392.941c0 .367.13.68.392.942.261.261.575.392.942.392Zm2.666-4a2.568 2.568 0 0 1-1.883-.784A2.568 2.568 0 0 1 4.667 4c0-.733.26-1.361.783-1.883a2.568 2.568 0 0 1 1.883-.784c.734 0 1.361.261 1.884.784C9.739 2.639 10 3.267 10 4c0 .733-.261 1.361-.783 1.883a2.568 2.568 0 0 1-1.884.784Zm0-1.334c.367 0 .68-.13.942-.391.261-.261.392-.575.392-.942s-.13-.68-.392-.942a1.284 1.284 0 0 0-.942-.391c-.366 0-.68.13-.941.391A1.284 1.284 0 0 0 6 4c0 .367.13.68.392.942.26.26.575.391.941.391Z"
        />
    </svg>
</template>

<script setup lang="ts">
type PlotIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PlotIconProps>();
</script>
