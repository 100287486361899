import { FluentBundle, FluentResource } from '@fluent/bundle';
import { createFluentVue } from 'fluent-vue';
import deAllocationDesignerMessages from '../allocation-designer/AllocationDesigner.de.ftl?raw';
import deMessages from '../assets/de.ftl?raw';
import deAllocationVariantIndexMessages from '../components/AllocationVariantIndex/AllocationVariantIndex.de.ftl?raw';
import deAllocationVariantSelectionPanelMessages from '../components/AllocationVariantSelectionPanel/AllocationVariantSelectionPanel.de.ftl?raw';
import deCardMessages from '../components/Card/Card.de.ftl?raw';
import deEnvironmentWarnBannerMessages from '../components/EnvironmentWarnBanner/EnvironmentWarnBanner.de.ftl?raw';
import deFeedMessages from '../components/Feed/Feed.de.ftl?raw';
import deFormMessages from '../components/Form/Form.de.ftl?raw';
import deMultiLinkMessages from '../components/MultiLink/multilink.de.ftl?raw';
import deNavigationSidebarMessages from '../components/NavigationSidebar/NavigationSidebar.de.ftl?raw';
import deSearchBarMessages from '../components/SearchBar/SearchBar.de.ftl?raw';
import deTimelineMessages from '../components/Timeline/Timeline.de.ftl?raw';
import deTwoLineDropDownMessages from '../components/TwoLineDropDown/TwoLineDropDown.de.ftl?raw';
import deEventDetailMessages from '../event/components/EventDetail.de.ftl?raw';
import deEventMessages from '../event/event.de.ftl?raw';
import deLocationMessages from '../location/location.de.ftl?raw';
import deProjectMessages from '../project/project.de.ftl?raw';
import deReportMessages from '../report/report.de.ftl?raw';
import deVariantMessages from '../variant/variant.de.ftl?raw';

const deBundle = new FluentBundle('de');
deBundle.addResource(new FluentResource(deMessages));
deBundle.addResource(new FluentResource(deAllocationDesignerMessages));
deBundle.addResource(new FluentResource(deAllocationVariantIndexMessages));
deBundle.addResource(new FluentResource(deAllocationVariantSelectionPanelMessages));
deBundle.addResource(new FluentResource(deCardMessages));
deBundle.addResource(new FluentResource(deEnvironmentWarnBannerMessages));
deBundle.addResource(new FluentResource(deFeedMessages));
deBundle.addResource(new FluentResource(deFormMessages));
deBundle.addResource(new FluentResource(deNavigationSidebarMessages));
deBundle.addResource(new FluentResource(deSearchBarMessages));
deBundle.addResource(new FluentResource(deTimelineMessages));
deBundle.addResource(new FluentResource(deTwoLineDropDownMessages));
deBundle.addResource(new FluentResource(deEventDetailMessages));
deBundle.addResource(new FluentResource(deLocationMessages));
deBundle.addResource(new FluentResource(deEventMessages));
deBundle.addResource(new FluentResource(deReportMessages));
deBundle.addResource(new FluentResource(deMultiLinkMessages));
deBundle.addResource(new FluentResource(deProjectMessages));
deBundle.addResource(new FluentResource(deVariantMessages));

// Create plugin instance
const fluent = createFluentVue({
    bundles: [deBundle],
    warnMissing: (key) => {
        throw new Error(`Missing translation key: ${key}`);
    },
});

export const $t = fluent.format;
export default fluent;
