<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M11.132 8.724 9.266 6.857h2.952l-1.086 1.867ZM9.19 6.78 4.6 2.21V0h7.618L9.19 6.78ZM6.885 16V9.143H4.599V5.429L.58 1.409l.819-.8 14.02 14.02-.8.8L9.95 10.78 6.885 16Z"
        />
    </svg>
</template>

<script setup lang="ts">
type FlashOffIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<FlashOffIconProps>();
</script>
