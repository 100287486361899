<template>
    <TopBar :title="$t('scenario-edit')" />
    <PageContent size="sm" class="p-4">
        <ScenarioUpdateForm v-if="scenario" :scenario="scenario"></ScenarioUpdateForm>
    </PageContent>
</template>

<script setup lang="ts">
import PageContent from '@/components/PageContent.vue';
import TopBar from '@/components/TopBar.vue';
import { GetScenarioDocument } from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import ScenarioUpdateForm from '../forms/ScenarioUpdateForm.vue';

const props = defineProps<{ scenarioId: string }>();
const getScenario = useQuery(
    GetScenarioDocument,
    computed(() => ({ id: props.scenarioId })),
);
const scenario = computed(() => getScenario.result.value?.scenario);
</script>

<fluent locale="de">
scenario-edit=Szenario bearbeiten
</fluent>
