<template>
    <NumberField
        :value="field.value ?? ''"
        :label="fullLabel"
        :step="float ? '0.01' : '1.0'"
        :helpButtonText="field.helper"
        :errorMessages="field.errorMessages"
        @input="onInput"
        @keyDown="handleKeyDown"
    />
</template>

<script setup lang="ts">
import NumberField from '@/components/Form/NumberField.vue';
import { FloatFormField, NumberFormField } from '@/location/forms/types';
import { computed } from 'vue';

const props = defineProps<{
    field: NumberFormField | FloatFormField;
    float: boolean;
    unit?: string;
}>();

const fullLabel = computed(() => (props.unit ? `${props.field.title} [${props.unit}]` : props.field.title));

const emit = defineEmits<{ (e: 'valueChanged', id: string, value: any): void }>();

const handleKeyDown = (value: string) => {
    emit('valueChanged', props.field.dbName, value);
};

const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('valueChanged', props.field.dbName, target.value);
};
</script>
