<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3.667 13.333a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V9.95h1v2.383h8.666V9.95h1v2.383c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H3.667ZM8 10.783 4.783 7.567 5.5 6.85l2 2V2.667h1V8.85l2-2 .717.717L8 10.783Z"
        />
    </svg>
</template>

<script setup lang="ts">
type DownloadIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<DownloadIconProps>();
</script>
