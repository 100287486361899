<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M.633 13.333v-1.566c0-.39.1-.742.3-1.059.2-.316.478-.552.834-.708.81-.356 1.541-.611 2.191-.767A8.599 8.599 0 0 1 5.967 9c.689 0 1.355.078 2 .233.644.156 1.372.411 2.183.767a1.902 1.902 0 0 1 1.15 1.767v1.566H.633Zm11.667 0v-1.566c0-.7-.178-1.275-.533-1.725a3.903 3.903 0 0 0-1.4-1.092 16.6 16.6 0 0 1 2.166.392c.678.172 1.228.37 1.65.591.367.211.656.473.867.784.211.31.317.66.317 1.05v1.566H12.3Zm-6.333-5.35c-.734 0-1.334-.233-1.8-.7-.467-.466-.7-1.066-.7-1.8 0-.733.233-1.333.7-1.8.466-.466 1.066-.7 1.8-.7.733 0 1.333.234 1.8.7.466.467.7 1.067.7 1.8 0 .734-.234 1.334-.7 1.8-.467.467-1.067.7-1.8.7Zm6-2.5c0 .734-.234 1.334-.7 1.8-.467.467-1.067.7-1.8.7-.123 0-.259-.008-.409-.025a1.763 1.763 0 0 1-.408-.091c.267-.278.47-.62.608-1.025.14-.406.209-.859.209-1.359s-.07-.941-.209-1.325A3.543 3.543 0 0 0 8.65 3.1a3.517 3.517 0 0 1 .817-.117c.733 0 1.333.234 1.8.7.466.467.7 1.067.7 1.8Zm-10.334 6.85H10.3v-.566a.953.953 0 0 0-.158-.517.871.871 0 0 0-.392-.35c-.8-.356-1.472-.594-2.017-.717A8.06 8.06 0 0 0 5.967 10c-.634 0-1.225.061-1.775.183-.55.123-1.225.361-2.025.717a.827.827 0 0 0-.384.35.991.991 0 0 0-.15.517v.566Zm4.334-5.35c.433 0 .791-.141 1.075-.425.283-.283.425-.641.425-1.075 0-.433-.142-.791-.425-1.075-.284-.283-.642-.425-1.075-.425-.434 0-.792.142-1.075.425-.284.284-.425.642-.425 1.075 0 .434.141.792.425 1.075.283.284.641.425 1.075.425Z"
        />
    </svg>
</template>

<script setup lang="ts">
type UsersIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<UsersIconProps>();
</script>
