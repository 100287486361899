<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 14V4.7h4.117V3.167L8 1.333l1.883 1.834v4.3H14V14H2Zm1-1h1.767v-1.767H3V13Zm0-2.767h1.767V8.467H3v1.766Zm0-2.766h1.767V5.7H3v1.767ZM7.117 13h1.766v-1.767H7.117V13Zm0-2.767h1.766V8.467H7.117v1.766Zm0-2.766h1.766V5.7H7.117v1.767Zm0-2.767h1.766V2.933H7.117V4.7Zm4.116 8.3H13v-1.767h-1.767V13Zm0-2.767H13V8.467h-1.767v1.766Z"
        />
    </svg>
</template>

<script setup lang="ts">
type BuildingIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<BuildingIconProps>();
</script>
