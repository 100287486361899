import CreateEventSeriesPage from '@/event/pages/CreateEventSeriesPage.vue';
import EventOverviewPage from '@/event/pages/EventOverviewPage.vue';
import EventPage from '@/event/pages/EventPage.vue';
import EventSeriesPage from '@/event/pages/EventSeriesPage.vue';
import EventsPage from '@/event/pages/EventsPage.vue';
import AppointmentTab from '@/event/tabs/series/AppointmentTab.vue';
import DetailsTab from '@/event/tabs/series/DetailsTab.vue';
import LocationTab from '@/event/tabs/series/LocationTab.vue';
import OrganisationTab from '@/event/tabs/series/OrganisationTab.vue';
import MasterDetailLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import CreateEventPage from './pages/CreateEventPage.vue';
import EventAllocationsTab from './tabs/event/EventAllocationsTab.vue';
import EventVariantsTab from './tabs/event/EventVariantsTab.vue';
import EventLinksTab from './tabs/event/LinksTab.vue';
import MasterDataTab from './tabs/event/MasterDataTab.vue';
import EventOrganisationTab from './tabs/event/OrganisationTab.vue';
import SeriesLinksTab from './tabs/series/LinksTab.vue';
import OverviewTab from './tabs/series/OverviewTab.vue';

export const eventRoutes = <RouteRecordRaw[]>[
    {
        path: '/events/:eventId',
        component: EventPage,
        props: true,
        children: [
            {
                path: '',
                redirect: { name: 'event-masterdata-tab' },
            },
            {
                path: 'master-data',
                name: 'event-masterdata-tab',
                component: MasterDataTab,
                props: true,
            },
            {
                path: 'allocations',
                name: 'event-allocations-tab',
                component: EventAllocationsTab,
                props: true,
            },
            {
                path: 'organisation',
                name: 'event-organisation-tab',
                component: EventOrganisationTab,
                props: true,
            },
            {
                path: 'links',
                name: 'event-links-tab',
                component: EventLinksTab,
                props: true,
            },
            {
                path: 'variants',
                name: 'event-variants-tab',
                component: EventVariantsTab,
                props: true,
            },
        ],
    },
    {
        path: '/events/add',
        name: 'add-event',
        beforeEnter: requireAdmin,
        component: CreateEventPage,
        props: (r: RouteLocationNormalized) => ({
            initialSeriesId: r.query.seriesId,
        }),
    },
    {
        path: '/events',
        children: [
            {
                path: 'series/add',
                name: 'add-event-series',
                beforeEnter: requireAdmin,
                component: CreateEventSeriesPage,
            },
            {
                path: 'series/:seriesId',
                component: EventSeriesPage,
                props: true,
                children: [
                    {
                        path: '',
                        redirect: { name: 'event-series-overview' },
                    },
                    {
                        path: 'overview',
                        name: 'event-series-overview',
                        component: OverviewTab,
                        props: true,
                    },
                    {
                        path: 'details',
                        name: 'event-series-details',
                        component: DetailsTab,
                        props: true,
                    },
                    {
                        path: 'org',
                        name: 'event-series-org',
                        component: OrganisationTab,
                        props: true,
                    },
                    {
                        path: 'appointment',
                        name: 'event-series-appointment',
                        component: AppointmentTab,
                        props: true,
                    },
                    {
                        path: 'location',
                        name: 'event-series-location',
                        component: LocationTab,
                        props: true,
                    },
                    {
                        path: 'links',
                        name: 'event-series-links',
                        component: SeriesLinksTab,
                        props: true,
                    },
                ],
            },
        ],
    },
    {
        path: '/events',
        component: MasterDetailLayout,
        children: [
            {
                path: '',
                name: 'events',
                components: {
                    default: EventsPage,
                    detail: EventOverviewPage,
                },
            },
        ],
    },
];
