<template>
    <div v-if="room">
        <TopBar :title="$t('room-partition-add')" :breadcrumbs="locationBreadcrumbs(room)" transparent />
        <LocationCreateForm type="RoomPartition" :location-types="roomPartitionTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';
import { GetCreateRoomPartitionParamsDocument } from '../../generated/graphql';

const props = defineProps<{ roomId: string }>();

const query = useQuery(GetCreateRoomPartitionParamsDocument, { roomId: props.roomId });
const room = useResult(query.result, undefined, (x) => x.room);
const roomPartitionTypes = useResult(query.result, [], (x) => x.roomPartitionTypes.nodes);
const parent = { key: 'roomId', value: props.roomId };
</script>
