<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M11.333 14.667v-2h-2v-1.334h2v-2h1.334v2h2v1.334h-2v2h-1.334Zm-8-1.334c-.366 0-.68-.13-.941-.391A1.284 1.284 0 0 1 2 12V4c0-.367.13-.68.392-.942.26-.26.575-.391.941-.391H4V1.333h1.333v1.334h4V1.333h1.334v1.334h.666c.367 0 .68.13.942.391.261.261.392.575.392.942v4.067a4.489 4.489 0 0 0-1.334 0v-1.4h-8V12H8c0 .222.017.444.05.667.033.222.094.444.183.666h-4.9Zm0-8h8V4h-8v1.333Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CalendarAddIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<CalendarAddIconProps>();
</script>
