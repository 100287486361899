<template>
    <BaseForm v-if="series && errors && multilinks">
        <template #fields>
            <MultiLink
                v-if="multilinks.otherDocuments"
                :multiLink="multilinks.otherDocuments"
                :label="$t('other-documents')"
                @input="onMultilinkInput($event.event, $event.link, $event.fieldName, 'otherDocuments')"
                @delete="onDelete($event)"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import MultiLink from '@/components/MultiLink/MultiLink.vue';
import { FormLink } from '@/components/MultiLink/types';
import { formFunctions, projectSeriesData } from '@/project/keys';
import { inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;
const multilinks = data?.multilinks;

const functions = inject(formFunctions);
const formFields = ['otherDocuments'];

function onMultilinkInput(event: Event, link: FormLink, property: string, fieldName: string) {
    functions?.handleMultilink(event, link, property, fieldName);
    functions?.isFormTouched(formFields);
}

function onDelete(link: FormLink) {
    functions?.deleteLink(link);
    functions?.isFormTouched(formFields);
}
</script>
