import {
    Form,
    FormField,
    FormFieldGroup,
    LocationDetailsResult,
    TimeVariantField,
    isTimeVariantField,
} from '@/location/forms/types';
import { $t } from '@/plugins/fluent';
import { z } from 'zod';
import { LocationType } from './locationTypes';

type Schema = (Omit<FormFieldGroup, 'fields'> & {
    fields: (FormField & { only?: LocationType[]; exclude?: LocationType[] })[];
})[];

type LocationAttributeTemplateValues = {
    types?: readonly { id: string; name: string }[];
    contactBookingChoices?: readonly { id: string; name: string }[];
    keyIssuanceChoices?: readonly { id: string; name: string }[];
};

const ZOD_FLOAT_DEFAULT = z.coerce
    .string()
    .regex(/([0-9]{1,2}(\.[0-9]{1,6})?)?/)
    .optional()
    .transform((val, ctx) => {
        if (!val) return 0.0;
        const convertedFromGerman = val.replace(',', '.');
        const parsed = parseFloat(convertedFromGerman);
        if (isNaN(parsed)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: $t('expected-number'),
            });
        }
        return parsed;
    });

const ZOD_INT_DEFAULT = z.coerce
    .string()
    .optional()
    .transform((val, ctx) => {
        if (!val) return 0;
        const parsed = parseInt(val);
        if (isNaN(parsed)) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: $t('expected-number'),
            });
        }
        return parsed;
    });

const ZOD_BOOLEAN_DEFAULT = z.boolean().optional();
const ZOD_STRING_DEFAULT = z
    .string()
    .max(250, $t('max-characters', { count: 250 }))
    .optional();

const ZOD_SELECT_DEFAULT = z.string().min(1, $t('input-required'));

// TODO we should think about the whole structure of this schema, as soon as there is
// time for it. For now we have to leave it as is, to get the forms going
function createSchema(template: LocationAttributeTemplateValues): Schema {
    return [
        {
            title: $t('identifiers'),
            groups: [
                { id: 'internalIdentifiers', title: $t('internal-identifiers') },
                { id: 'externalIdentifiers', title: $t('external-identifiers') },
                { id: 'otherIdentifiers', title: $t('other-identifiers') },
                { id: 'responsibilities', title: $t('responsibilities') },
            ],
            fields: [
                {
                    group: 'internalIdentifiers',
                    title: $t('name-short'),
                    dbName: 'nameShort',
                    isTimeVariant: false,
                    defaultValue: '',
                    type: 'string',
                    long: false,
                    schema: z
                        .string()
                        .min(1, $t('input-required'))
                        .max(50, $t('max-characters', { count: 50 })),
                    helper: $t('name-short-help'),
                },
                {
                    group: 'internalIdentifiers',
                    title: $t('name-long'),
                    dbName: 'nameLong',
                    isTimeVariant: false,
                    defaultValue: '',
                    type: 'string',
                    long: false,
                    schema: z
                        .string()
                        .min(1, $t('input-required'))
                        .max(100, $t('max-characters', { count: 100 })),
                    helper: $t('name-long-help'),
                },
                {
                    group: 'internalIdentifiers',
                    exclude: ['Site'],
                    title: $t('location-type'),
                    dbName: 'typeId',
                    isTimeVariant: false,
                    defaultValue: undefined,
                    type: 'select',
                    items: template.types ?? [],
                    schema: ZOD_SELECT_DEFAULT,
                    gqlPathToTitle: ['type', 'name'],
                    helper: $t('location-type-help'),
                },
                {
                    group: 'internalIdentifiers',
                    title: $t('description'),
                    dbName: 'description',
                    isTimeVariant: false,
                    defaultValue: '',
                    type: 'string',
                    long: true,
                    schema: z
                        .string()
                        .max(10000, $t('max-characters', { count: 10000 }))
                        .optional(),
                    helper: $t('description-help'),
                },
                {
                    group: 'internalIdentifiers',
                    title: $t('name-extra'),
                    dbName: 'nameExtra',
                    isTimeVariant: true,
                    defaultValue: '',
                    type: 'string',
                    long: false,
                    schema: ZOD_STRING_DEFAULT,
                    helper: $t('name-extra-help'),
                },
                {
                    group: 'externalIdentifiers',
                    title: $t('name-marketing'),
                    dbName: 'nameKeyMarketing',
                    type: 'string',
                    long: false,
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: $t('name-marketing-help'),
                },
                {
                    group: 'externalIdentifiers',
                    title: $t('external-id'),
                    dbName: 'extId',
                    type: 'string',
                    long: false,
                    isTimeVariant: false,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: $t('external-id-help'),
                    readonly: true,
                },
                {
                    group: 'otherIdentifiers',
                    title: $t('name-cad'),
                    dbName: 'nameKeyCad',
                    type: 'string',
                    long: false,
                    schema: ZOD_STRING_DEFAULT,
                    isTimeVariant: true,
                    defaultValue: '',
                    helper: $t('name-cad-help'),
                },
                {
                    group: 'otherIdentifiers',
                    title: $t('name-ebms'),
                    dbName: 'nameKeyEbms',
                    type: 'string',
                    long: false,
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: '',
                },
                {
                    group: 'otherIdentifiers',
                    title: $t('name-cafm'),
                    dbName: 'nameKeyCafm',
                    type: 'string',
                    long: false,
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: $t('name-cafm-help'),
                },
                {
                    group: 'responsibilities',
                    only: ['Room', 'Area'],
                    title: $t('contact-booking'),
                    dbName: 'contactBooking',
                    type: 'select',
                    items: template.contactBookingChoices ?? [],
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    gqlPathToTitle: ['type', 'name'],
                    helper: $t('contact-booking-help'),
                },
                {
                    group: 'responsibilities',
                    only: ['Room', 'Area'],
                    title: $t('clearance-constructions'),
                    dbName: 'clearanceConstruction',
                    type: 'string',
                    long: true,
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: '',
                },
                {
                    group: 'responsibilities',
                    only: ['Room', 'Area'],
                    title: $t('key-issuance'),
                    dbName: 'keyIssuance',
                    type: 'select',
                    items: template.keyIssuanceChoices ?? [],
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    gqlPathToTitle: ['type', 'name'],
                    helper: $t('key-issuance-help'),
                },
                {
                    group: 'responsibilities',
                    only: ['Room', 'Area'],
                    title: $t('key-issuance-notes'),
                    dbName: 'keyIssuanceNotes',
                    type: 'string',
                    long: true,
                    isTimeVariant: true,
                    schema: ZOD_STRING_DEFAULT,
                    defaultValue: '',
                    helper: '',
                },
            ],
        },
        {
            title: $t('metrics'),
            layout: {
                sameRow: [
                    ['cadLengthMin', 'cadLengthMax'],
                    ['cadWidthMin', 'cadWidthMax'],
                    ['cadHeightMin', 'cadHeightMax'],
                ],
            },
            groups: [
                { id: 'dimensions', title: $t('dimensions') },
                { id: 'structuralOpenings', title: $t('structural-openings') },
                { id: 'personCapacity', title: $t('person-capacity') },
                { id: 'vehicleCapacity', title: $t('vehicle-capacity') },
            ],
            fields: [
                {
                    group: 'dimensions',
                    only: ['Room', 'Zone', 'Area', 'Plot', 'RoomPartition'],
                    title: $t('cad-gross-space'),
                    dbName: 'cadGrossSpaceMin',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-gross-space-help'),
                    unit: 'm²',
                },
                {
                    group: 'dimensions',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cleaning-gross-space'),
                    dbName: 'cleaningGrossSpace',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cleaning-gross-space-help'),
                    unit: 'm²',
                },
                {
                    group: 'dimensions',
                    only: ['Room', 'RoomPartition'],
                    title: $t('sbv-gross-space'),
                    dbName: 'sbvGrossSpace',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('sbv-gross-space-help'),
                    unit: 'm²',
                } /*
                {
                    exclude: ['Site'],
                    title: 'Für Gäste zugängliche Fläche',
                    dbName: 'publicGrossSpace',
                    isTimeVariant: true,
                    type: 'float',
                    schema: number(),
                    unit: 'm²',
                },*/,
                {
                    group: 'dimensions',
                    only: ['Level'],
                    title: $t('fkm-gross-space'),
                    dbName: 'fkmGrossSpace',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('fkm-gross-space-help'),
                    unit: 'm²',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-length-min'),
                    dbName: 'cadLengthMin',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-length-min-help'),
                    unit: 'm',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-length-max'),
                    dbName: 'cadLengthMax',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-length-max-help'),
                    unit: 'm',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-width-min'),
                    dbName: 'cadWidthMin',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-width-min-help'),
                    unit: 'm',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-width-max'),
                    dbName: 'cadWidthMax',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-width-max-help'),
                    unit: 'm',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-height-min'),
                    dbName: 'cadHeightMin',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-height-min-help'),
                    unit: 'm',
                },
                {
                    group: 'structuralOpenings',
                    only: ['Room', 'RoomPartition'],
                    title: $t('cad-height-max'),
                    dbName: 'cadHeightMax',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('cad-height-max-help'),
                    unit: 'm',
                },
                {
                    group: 'personCapacity',
                    only: ['Level', 'Room', 'RoomPartition'],
                    title: $t('capacity-pax'),
                    dbName: 'capacityPax',
                    isTimeVariant: true,
                    type: 'number',
                    schema: ZOD_INT_DEFAULT,
                    helper: $t('capacity-pax-help'),
                },
                {
                    group: 'vehicleCapacity',
                    only: ['Room', 'Zone'],
                    title: $t('capacity-cars'),
                    dbName: 'capacityCars',
                    isTimeVariant: true,
                    type: 'number',
                    schema: ZOD_INT_DEFAULT,
                    helper: $t('capacity-cars'),
                },
                {
                    group: 'vehicleCapacity',
                    only: ['Room', 'Zone'],
                    title: $t('capacity-trucks'),
                    dbName: 'capacityTrucks',
                    isTimeVariant: true,
                    type: 'number',
                    schema: ZOD_INT_DEFAULT,
                    helper: $t('capacity-trucks-help'),
                },
            ],
        },
        {
            title: $t('load-catalog'),
            layout: {
                sameRow: [
                    ['tireLoadCar', 'tireLoadTruck', 'tireLoadForklift'],
                    ['maxLoadFloor', 'maxLoadCeiling'],
                ],
            },
            groups: [
                { id: 'load', title: $t('permissible-loads') },
                { id: 'noVehicles', title: $t('no-vehicles') },
            ],
            fields: [
                {
                    group: 'load',
                    only: ['Room', 'Zone'],
                    title: $t('max-load-floor'),
                    dbName: 'maxLoadFloor',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    helper: $t('max-load-floor-help'),
                    unit: 'kN/m²',
                },
                {
                    group: 'load',
                    only: ['Room'],
                    title: $t('max-load-ceiling'),
                    dbName: 'maxLoadCeiling',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    unit: 'kg',
                },
                {
                    group: 'load',
                    only: ['Room', 'Zone'],
                    title: $t('tire-load-car'),
                    dbName: 'tireLoadCar',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    unit: 't',
                },
                {
                    group: 'load',
                    only: ['Room', 'Zone'],
                    title: $t('tire-load-truck'),
                    dbName: 'tireLoadTruck',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    unit: 't',
                },
                {
                    group: 'load',
                    only: ['Room', 'Zone'],
                    title: $t('tire-load-forklift'),
                    dbName: 'tireLoadForklift',
                    isTimeVariant: true,
                    type: 'float',
                    schema: ZOD_FLOAT_DEFAULT,
                    unit: 't',
                },
                {
                    group: 'noVehicles',
                    only: ['Room', 'Zone'],
                    title: $t('no-vehicles'),
                    dbName: 'noVehicles',
                    isTimeVariant: true,
                    type: 'checkbox',
                    schema: ZOD_BOOLEAN_DEFAULT,
                },
            ],
        },
        {
            title: $t('equipment'),
            groups: [
                { id: 'lighting', title: $t('lighting') },
                { id: 'communication', title: $t('communication') },
            ],
            fields: [
                /*
                {
                    only: ['Room'],
                    title: 'Luftwechselrate',
                    dbName: 'airChangeRate',
                    isTimeVariant: true,
                    type: 'float',
                    schema: number(),
                    unit: '1/h',
                },
                {
                    only: ['Room'],
                    title: 'Klimaanlage',
                    dbName: 'airConditioning',
                    type: 'checkbox',
                    isTimeVariant: true,
                    schema: bool(),
                    defaultValue: false,
                },*/
                {
                    group: 'lighting',
                    only: ['Room'],
                    title: $t('daylight'),
                    dbName: 'daylight',
                    type: 'checkbox',
                    isTimeVariant: true,
                    schema: ZOD_BOOLEAN_DEFAULT,
                    helper: $t('daylight-help'),
                },
                {
                    group: 'lighting',
                    only: ['Room'],
                    title: $t('light-shutters'),
                    dbName: 'lightShutters',
                    type: 'checkbox',
                    isTimeVariant: true,
                    schema: ZOD_BOOLEAN_DEFAULT,
                    helper: $t('light-shutters-help'),
                },
                {
                    group: 'communication',
                    only: ['Room'],
                    title: $t('phone-numbers'),
                    dbName: 'phoneNumbers',
                    isTimeVariant: true,
                    defaultValue: '',
                    type: 'string',
                    long: true,
                    schema: z
                        .string()
                        .max(10000, $t('max-characters', { count: 10000 }))
                        .optional(),
                },
            ],
        },
    ];
}

// type TimeVariantKeys<T> = {
//     [K in keyof T]: T[K] extends TimeVariantField ? K : never;
// }[keyof T];

function setValueAndValueLabel(location: LocationDetailsResult | undefined, field: FormField) {
    if (!location) return field.defaultValue;
    const key = field.dbName as keyof typeof location;
    let value = location[key];
    if (isTimeVariantField(value)) {
        const values = value as TimeVariantField;
        if (values && value.nodes.length) {
            // at first attempt get the first one, later on we should filter by start/end
            const firstValue = values.nodes[0];
            field.value = firstValue.value;
            field.timeVariantId = firstValue.id;
        }
    } else {
        field.value = value ?? field.defaultValue;
    }
    if ('items' in field && field.items.length) {
        let item = field.items.find((i) => i.id === field.value);
        if (item) {
            field.valueLabel = item.name;
        }
    }
}

export function formForLocationType(
    type: LocationType,
    location: LocationDetailsResult | undefined,
    template: LocationAttributeTemplateValues,
): Form {
    return createSchema(template).flatMap((x) => {
        const fields = <FormField[]>[];

        for (const field of x.fields) {
            if ('only' in field) {
                if (field.only!.includes(type)) {
                    setValueAndValueLabel(location, field);
                    fields.push(field);
                }
            } else if ('exclude' in field) {
                if (!field.exclude!.includes(type)) {
                    setValueAndValueLabel(location, field);
                    fields.push(field);
                }
            } else {
                setValueAndValueLabel(location, field);
                fields.push(field);
            }
        }

        if (fields.length) return [{ ...x, fields }];
        else return [];
    });
}
