export function distinctBy<S, T>(array: readonly T[], getter: (item: T) => S) {
    const seen = new Set<S>();

    return array.filter((x) => {
        const key = getter(x);
        if (seen.has(key)) return false;
        seen.add(key);
        return true;
    });
}

export function distinct<T>(array: readonly T[]) {
    const seen = new Set<T>();

    return array.filter((x) => {
        if (seen.has(x)) return false;
        seen.add(x);
        return true;
    });
}
