import BuildingEditPage from '@/building/pages/BuildingEditPage.vue';
import BuildingPage from '@/building/pages/BuildingPage.vue';
import LevelAddPage from '@/level/pages/LevelAddPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';

export const buildingRoutes = [
    {
        path: 'building/:buildingId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'building-details',
                components: {
                    default: LocationsPage,
                    detail: BuildingPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'building-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: BuildingEditPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'add-level',
                name: 'level-add',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: LevelAddPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
