<template>
    <div v-if="effectiveError">
        <card class="px-4 py-2 !bg-red-100">
            <div class="flex">
                <icon class="mr-4 text-red-600">error</icon>
                <span class="flex-auto text-sm" style="flex: 1">{{ effectiveError }}</span>
            </div>
        </card>
    </div>
</template>

<script setup lang="ts">
import Card from '@/components/Card.vue';
import Icon from '@/components/Icon.vue';
import { prettyPrintApolloError, useMutationError } from '@/utils/hooks/gqlHooks';
import { ApolloError } from '@apollo/client/core';
import { UseMutationReturn } from '@vue/apollo-composable';
import { computed, ref } from 'vue';

const props = defineProps<{
    error?: String;
    apolloError?: ApolloError | null;
    mutation?: UseMutationReturn<any, any>;
    formatMutationError?: (res: any) => string | undefined;
}>();

let mutError = ref();
if (props.mutation) {
    mutError = useMutationError(props.mutation, props.formatMutationError ?? ((x) => undefined));
}

const effectiveError = computed(
    () => mutError.value ?? props.error ?? (props.apolloError ? prettyPrintApolloError(props.apolloError) : undefined),
);
</script>
