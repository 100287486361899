<template>
    <SidePanel :title="$t('title')">
        <template #actions>
            <base-button :to="{ name: 'add-scenario' }" size="xs" icon="add" :enabled="isAdmin" />
        </template>
        <!--
        <template #header>
            <v-autocomplete
                class="mt-2"
                prepend-icon="search"
                append-icon=""
                :label="$t('search')"
                v-bind="search.attrs.value"
                :search-input.sync="search.search.value"
                auto-select-first
                hide-no-data
                clearable
                dense
            >
                <template #item="{ item }">
                    <ScenarioSearchTile
                        :scenario="(item as any)"
                        :to="{ name: 'scenario-details', params: { scenarioId: (item as any).id } }"
                    />
                </template>
            </v-autocomplete>

        </template>-->
        <GQLLoadingList :query="query" nav dense>
            <ScenarioListTile
                v-for="scenario in scenarios"
                :key="scenario.id"
                :scenario="scenario"
                :to="{ name: 'scenario-details', params: { scenarioId: scenario.id } }"
            />
        </GQLLoadingList>
    </SidePanel>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import BaseButton from '@/components/BaseButton.vue';
import SidePanel from '@/components/SidePanel.vue';
import { GetScenariosDocument } from '@/generated/graphql';
import GQLLoadingList from '@/utils/components/GQLLoadingList.vue';
import { useResult } from '@/utils/graphql';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import ScenarioListTile from '../components/ScenarioListTile.vue';
const query = useQuery(GetScenariosDocument);
const scenarios = useResult(query.result, [], (x) => x.scenarios.nodes);
const isAdmin = auth.isAdmin();
const fluent = useFluent();

useHeadSafe({ title: () => fluent.$t('scenarios') });
</script>

<style scoped>
.list-bg {
    background-color: var(--v-primary-lighten4);
}
</style>

<fluent locale="de">
title=Szenarien
add=Neu
search=Suche
search-no-data=Keine Suchergebnisse
</fluent>
