<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4.667 14.667v-6.1a2.749 2.749 0 0 1-1.425-.934A2.496 2.496 0 0 1 2.667 6V1.333H4V6h.667V1.333H6V6h.667V1.333H8V6c0 .622-.192 1.167-.575 1.633A2.749 2.749 0 0 1 6 8.567v6.1H4.667Zm6.666 0V9.333h-2V4.667c0-.923.325-1.709.975-2.359a3.213 3.213 0 0 1 2.359-.975v13.334h-1.334Z"
        />
    </svg>
</template>

<script setup lang="ts">
type RestaurantIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<RestaurantIconProps>();
</script>
