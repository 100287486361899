<template>
    <BaseForm v-if="scenario">
        <template #fields>
            <TextField :value="scenario.name" :label="$t('name')" :errorMessages="nameErrors" @input="nameChange" />
            <TextField
                :value="scenario.description"
                :label="$t('description')"
                :errorMessages="descriptionErrors"
                @input="descriptionChange"
            />
        </template>
        <template #buttons>
            <BaseButton @click="$router.back()" variant="outlined">{{ $t('cancel') }}</BaseButton>
            <BaseButton @click="onSave" :loading="updateMutation.loading.value" class="ml-3">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard class="mt-4" :mutation="updateMutation" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import { EditScenarioDocument, GetScenarioDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { ScenarioBaseData } from '../scenario';
import { scenarioUpdateSchema } from './schemas';

const props = defineProps<{ scenario: ScenarioBaseData }>();
const toast = useToast();
const fluent = useFluent();
const router = useRouter();

const scenario = ref({ id: props.scenario.id, name: props.scenario.name, description: props.scenario.description });

let nameErrors = ref<string[]>();
let descriptionErrors = ref<string[]>();

const nameChange = (e: Event) => {
    nameErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    scenario.value.name = value;
    const parse = scenarioUpdateSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const descriptionChange = (e: Event) => {
    descriptionErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    scenario.value.description = value;
    const parse = scenarioUpdateSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const updateMutation = useMutation(EditScenarioDocument, {
    refetchQueries: [{ query: GetScenarioDocument, variables: { scenarioId: props.scenario.id } }],
});

const onSave = async () => {
    const parse = scenarioUpdateSchema.safeParse(scenario.value);
    if (!parse.success) return;
    const res = await updateMutation.mutate(scenario.value);
    const { id, name } = res?.data?.updateScenario?.scenario ?? {};
    if (id && name) {
        toast.success(fluent.$t('update-success-notification', { type: 'scenario', name: name }), {
            onClick: () => router.push({ name: 'scenario-details', params: { scenarioId: id } }),
        });
        router.push({ name: 'scenario-details', params: { scenarioId: id } });
    }
};
</script>
