<template>
    <div v-if="area">
        <TopBar :title="area.nameShort" :breadcrumbs="locationBreadcrumbs(area)" transparent />
        <LocationUpdateForm :location="area" :location-types="areaTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetAreaForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ areaId: string }>();

const query = useQuery(GetAreaForEditDocument, () => ({ id: props.areaId }));
const area = useResult(query.result, undefined, (x) => x.area);
const areaTypes = useResult(query.result, [], (x) => x.areaTypes.nodes);
</script>
