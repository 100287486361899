<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 15.333V.667h1V2h10V.667h1v14.666h-1V14H3v1.333H2Zm1-7.816h1.833V4.85H8.5v2.667H13V3H3v4.517ZM3 13h4.5v-2.667h3.667V13H13V8.517H3V13Zm2.833-5.483H7.5V5.85H5.833v1.667ZM8.5 13h1.667v-1.667H8.5V13Z"
        />
    </svg>
</template>

<script setup lang="ts">
type StorageRoomIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<StorageRoomIconProps>();
</script>
