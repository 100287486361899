import AreaEditPage from '@/area/pages/AreaEditPage.vue';
import AreaPage from '@/area/pages/AreaPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import PlotAddPage from '@/plot/pages/PlotAddPage.vue';
import { requireAdmin } from '@/plugins/router';

export const areaRoutes = [
    {
        path: 'area/:areaId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'area-details',
                components: {
                    default: LocationsPage,
                    detail: AreaPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'area-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: AreaEditPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'add-plot',
                name: 'plot-add',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: PlotAddPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
