<template>
    <div
        class="border-l-4 p-4"
        :class="{
            'border-red-400 bg-red-50': status === 'error',
            'border-sky-700 bg-sky-50': status === 'info',
        }"
    >
        <div class="flex">
            <div class="flex-shrink-0">
                <CrisisAlertIcon v-if="status === 'error'" class="h-5 w-5 text-red-400" aria-hidden="true" />
                <InfoIcon v-if="status === 'info'" class="h-5 w-5 text-sky-500" aria-hidden="true" />
            </div>
            <div class="ml-3">
                <h3
                    v-if="$props.headline"
                    class="text-sm font-medium mb-2"
                    :class="{ 'text-red-800': status === 'error', 'text-sky-800': status === 'info' }"
                >
                    {{ props.headline }}
                </h3>
                <div class="text-sm" :class="{ 'text-red-700': status === 'error', 'text-sky-700': status === 'info' }">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import CrisisAlertIcon from '../Icon/CrisisAlertIcon.vue';
import InfoIcon from '../Icon/InfoIcon.vue';

type MessageProps = {
    headline?: string;
    status: 'error' | 'info';
};

const props = defineProps<MessageProps>();
</script>
