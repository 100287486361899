import { DeleteRoomDocument, GetRoomForRoomDetailsPageDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useRoom(roomId: Ref<string>) {
    const getRoomQuery = useQuery(
        GetRoomForRoomDetailsPageDocument,
        computed(() => ({ id: unref(roomId) })),
    );
    const room = useResult(getRoomQuery.result, undefined, (data) => data.room);
    const roomTypes = computed(() => getRoomQuery.result.value?.roomTypes?.nodes ?? []);

    const roomToDelete = computed(() => ({
        __typename: room.value?.__typename ?? '',
        id: room.value?.id ?? '',
        nameShort: room.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteRoomDocument);
    const routeToParent = computed(() => ({ name: 'level-details', params: { levelId: room.value?.level.id } }));
    const deleteRoom = useDeleteLocation(roomToDelete, deleteMutation, routeToParent);

    return {
        room,
        roomTypes,
        getRoomQuery,
        deleteRoom,
    };
}
