<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="dayOrder" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('dayOrder')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('dayOrder')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'dayOrder', label: $t('day-order') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'dayOrder')"
                    @save="$emit('save', 'dayOrder')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('day-order') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <DayOrderForm
                        v-if="showForm('dayOrder')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('notes-dayorder')"
                            :text="valueToString(series.notesDayOrder)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-setup')"
                            :text="valueToString(series.notesSetup)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-early-construction-internal')"
                            :text="valueToString(series.notesEarlyConstructionInternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-early-construction-external')"
                            :text="valueToString(series.notesEarlyConstructionExternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-construction')"
                            :text="valueToString(series.notesConstruction)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-execution')"
                            :text="valueToString(series.notesExecution)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-dismantling')"
                            :text="valueToString(series.notesDismantling)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-extended-dismantling-external')"
                            :text="valueToString(series.notesExtendedDismantlingExternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-extended-dismantling-internal')"
                            :text="valueToString(series.notesExtendedDismantlingInternal)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-technical-dismantling')"
                            :text="valueToString(series.notesTechnicalDismantling)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-dayorder-history')"
                            :text="valueToString(series.notesDayOrderHistory)"
                            :help-text="$t('notes-dayorder-history-help')"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="appointmentRules" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('appointmentRules')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('appointmentRules')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'appointmentRules', label: $t('appointment-rules') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'appointmentRules')"
                    @save="$emit('save', 'appointmentRules')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('appointment-rules') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <AppointmentRulesForm
                        v-if="showForm('appointmentRules')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('notes-appointment-rules')"
                            :text="valueToString(series.notesAppointmentRules)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-months')"
                            :text="valueToString(series.notesMonths)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-holidays-nrw')"
                            :text="valueToString(series.notesHolidaysNrw)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-holidays-national')"
                            :text="valueToString(series.notesHolidaysNational)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-holidays-international')"
                            :text="valueToString(series.notesHolidaysInternational)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-public-holidays-nrw')"
                            :text="valueToString(series.notesPublicHolidaysNrw)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-public-holidays-national')"
                            :text="valueToString(series.notesPublicHolidaysNational)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-public-holidays-international')"
                            :text="valueToString(series.notesPublicHolidaysInternational)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('other-notes-appointment-rules')"
                            :text="valueToString(series.otherNotesAppointmentRules)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('appointment-history')"
                            :text="valueToString(series.appointmentHistory)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="cycle" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('cycle')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('cycle')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'cycle', label: $t('cycle') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'cycle')"
                    @save="$emit('save', 'cycle')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('cycle') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <CycleForm
                        v-if="showForm('cycle')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                        @handle-select-change="$emit('handleSelectChange', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('cycle')"
                            :text="idToName(series.cycle, CYCLE_ITEMS)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('cycle-changes')"
                            :text="valueToString(series.cycleChanges)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { idToName, valueToString } from '@/event/event';
import { CYCLE_ITEMS } from '@/event/forms/schemas';
import AppointmentRulesForm from '@/event/forms/series/AppointmentRulesForm.vue';
import CycleForm from '@/event/forms/series/CycleForm.vue';
import DayOrderForm from '@/event/forms/series/DayOrderForm.vue';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { computed } from 'vue';

const props = defineProps<{
    series: EventSeriesDetails;
    errors: EventSeriesDetailsErrors;
    activeCard: ActiveCard;
}>();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'handleSelectChange', v: { item: ComboboxItem | null; fieldName: string }): void;
    (e: 'save', v: string): void;
    (e: 'discardChanges', v: string): void;
}>();

const activeCard = computed(() => props.activeCard);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);
</script>
