<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        :tabindex="props['aria-labelledby'] ? 0 : undefined"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path fill="currentColor" d="M7 5v1c.207 0 .5-.23.5-.5S7.207 5 7 5Z" />
        <path
            fill="currentColor"
            d="M2.221 13.775a.72.72 0 0 0 .525.225h7.5a.72.72 0 0 0 .525-.225.72.72 0 0 0 .225-.525V9c0-.207-.226-.5-.496-.5s-.5.293-.5.5v4.004H3V6h4V5H2.746a.72.72 0 0 0-.525.225.72.72 0 0 0-.225.525v7.5c0 .2.075.375.225.525Z"
        />
        <path fill="currentColor" d="M7.25 9.25 6.5 8.5 12.25 3H9V2h5.004v5H13V3.75l-5.75 5.5Z" />
    </svg>
</template>

<script setup lang="ts">
type ExternalLinkIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ExternalLinkIconProps>();
</script>
