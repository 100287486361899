<template>
    <div v-if="!loading && variantsRef.length > 0 && currentUser" class="space-y-4">
        <VariantCard
            v-for="variant in variantsRef"
            :key="variant.id"
            :active-card="activeCard"
            :expanded-cards="expandedCards"
            :object-id="eventId"
            :variant="variant"
            :current-user="currentUser"
            :variants-ref="variantsRef"
            :variant-type="variantType"
            :set-variants-ref="setVariantsRef"
            :set-active-card="setActiveCard"
            :set-expanded-cards="setExpandedCards"
        ></VariantCard>
    </div>
</template>
<script setup lang="ts">
import { useMyUser } from '@/user/composables/useMyUser';
import VariantCard from '@/variant/components/VariantCard.vue';
import { useVariantsWithComments } from '@/variant/composables/useVariantsWithComments';
import { computed } from 'vue';

const props = defineProps<{ eventId: string }>();
const eventId = computed(() => props.eventId);
const variantType = 'Event';

const { currentUser } = useMyUser();
const { variantsRef, activeCard, expandedCards, loading, setVariantsRef, setActiveCard, setExpandedCards } =
    useVariantsWithComments(eventId, 'Event');
</script>
