import { z } from 'zod';
import { $t } from '../../plugins/fluent';

export const CalendarCreateSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
    colorHex: z.string(), // TODO check for hex color
});

export const CalendarEditSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
    colorHex: z.string(), // TODO check for hex color
});

export const CalendarEventCreateSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
    start: z.coerce.date({ invalid_type_error: $t('datetime-invalid'), required_error: $t('datetime-required') }),
    end: z.coerce.date({ invalid_type_error: $t('datetime-invalid'), required_error: $t('datetime-required') }),
});

export const CalendarEventEditSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
    start: z.coerce.date({ invalid_type_error: $t('datetime-invalid'), required_error: $t('datetime-required') }),
    end: z.coerce.date({ invalid_type_error: $t('datetime-invalid'), required_error: $t('datetime-required') }),
});
