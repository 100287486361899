<template>
    <i class="material-icons" :style="sizeStyle"
        ><slot>{{ icon }}</slot></i
    >
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = withDefaults(
    defineProps<{
        size?: number | string;
        icon?: string;
    }>(),
    {
        size: 24,
    },
);

// todo: Tailwind's "text-[xxxpx]" doesn't work here for some reason. Maybe Vuetify related?
const sizeStyle = computed(() => ({ 'font-size': `${props.size}px !important` }));
</script>
