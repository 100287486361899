<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(title || props['aria-labelledby'])"
        :tabindex="props['aria-labelledby'] ? 0 : undefined"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="m3.883 14.667 1.084-4.684-3.634-3.15 4.8-.416L8 2l1.867 4.417 4.8.416-3.634 3.15 1.084 4.684L8 12.183l-4.117 2.484Z"
        />
    </svg>
</template>

<script setup lang="ts">
type StarFilledIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<StarFilledIconProps>();
</script>
