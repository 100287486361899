<template>
    <Tooltip :is-disabled="!props.tooltipText">
        <template #trigger="tooltipProps">
            <span
                :aria-describedby="tooltipProps['aria-labelledby'] ? tooltipProps['aria-labelledby'] : undefined"
                class="inline-flex items-center rounded-md font-medium ring-1 ring-inset"
                :class="[variantClasses, sizeClasses]"
                :tabindex="props.tooltipText ? 0 : undefined"
            >
                <slot />
            </span>
        </template>

        <template #text>
            <div class="w-max">{{ props.tooltipText }}</div>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Tooltip from '../Tooltip/Tooltip.vue';

type BadgeProps = {
    variant: 'Green' | 'Yellow' | 'Red' | 'Gray' | 'Purple';
    size: 'Extra-Small' | 'Small' | 'Base'; // The size is named as the font size classes in Tailwind CSS (e.g. 'Small' === 'text-sm'), Extra-Small = 24px height, Small = 32px height, Base = 40px height
    tooltipText?: string;
};

const props = defineProps<BadgeProps>();

const variantClasses = computed(() => {
    switch (props.variant) {
        case 'Green':
            return `
                bg-green-50 text-green-700 ring-green-600/10
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-green-700
            `;

        case 'Yellow':
            return `
                bg-yellow-50 text-yellow-700 ring-yellow-600/10
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-yellow-700
                `;

        case 'Red':
            return `
                bg-red-50 text-red-700 ring-red-600/10
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-red-700
            `;

        case 'Gray':
            return `
                bg-gray-50 text-gray-700 ring-gray-600/10
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-gray-700
            `;

        case 'Purple':
            return `
                bg-purple-50 text-purple-700 ring-purple-600/10
                focus-visible:outline focus-visible:outline-2 focus-visible:outline-purple-700
            `;

        default:
            throw new Error(`Unknown Badge variant: ${props.variant}`);
    }
});

const sizeClasses = computed(() => {
    switch (props.size) {
        case 'Extra-Small':
            return 'text-xs px-2 py-1';

        case 'Small':
            return 'text-sm px-2.5 py-1.5';

        case 'Base':
            return 'text-base px-3 py-2';

        default:
            throw new Error(`Unknown Badge size: ${props.size}`);
    }
});
</script>
