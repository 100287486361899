<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M.667 14 8 1.333 15.333 14H.667Zm2.3-1.333h10.066L8 4l-5.033 8.667ZM8 12a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.191.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475c0 .19.064.348.192.475A.645.645 0 0 0 8 12Zm-.667-2h1.334V6.667H7.333V10Z"
        />
    </svg>
</template>

<script setup lang="ts">
type WarningIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<WarningIconProps>();
</script>
