<template>
    <div class="w-max inline-grid gap-px grid-rows-[1fr]" :style="gridTemplateStyle">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { columnWidthInRem } from '../sharedStyles';

type TimelineRowProps = {
    isFirstRow: boolean;
    daysCount: number;
};

const props = defineProps<TimelineRowProps>();

const gridTemplateStyle = computed(() => {
    return `grid-template-columns: repeat(${props.daysCount}, ${columnWidthInRem}rem);`;
});
</script>
