import { onMounted, onUnmounted, ref } from 'vue';

export function useTimer(intervallMillis: number, callback: () => {}, callNow = true): void {
    var handle = ref();
    onMounted(() => {
        handle.value = setInterval(callback, intervallMillis);
        if (callNow) callback();
    });

    onUnmounted(() => clearInterval(handle.value));
}
