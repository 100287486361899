<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h10c.267 0 .5.1.7.3.2.2.3.433.3.7v10c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H3Zm0-1h10V3H3v10Z"
        />
        <path fill="currentColor" d="M3.5 3.5V10v-.5h4v.5-6.5h-4Zm5 0v4h4v-4h-4Zm-5 6.5v2.5h4V10h-4Zm5-2v4.5h4V8h-4Z" />
    </svg>
</template>

<script setup lang="ts">
type ExhibitionAreaIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ExhibitionAreaIconProps>();
</script>
