<template>
    <BaseForm v-if="series && errors">
        <template #fields>
            <TextAreaField
                :value="series.notesDayOrder"
                :label="$t('project-notes-dayorder')"
                :errorMessages="errors.notesDayOrder"
                @input="onInput($event, 'notesDayOrder')"
            />
            <TextAreaField
                :value="series.notesAppointment"
                :label="$t('project-notes-appointment')"
                :errorMessages="errors.notesAppointment"
                @input="onInput($event, 'notesAppointment')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import { formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesAppointmentSchema, ProjectSeriesAppointmentSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectSeriesAppointmentSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectSeriesAppointmentSchemaType;
    const schema = ProjectSeriesAppointmentSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
