<template>
    <div class="py-3 px-4 items-start grid" :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }">
        <p class="text-sm text-gray-500">
            {{ props.text }}
        </p>
    </div>
</template>

<script setup lang="ts">
type CardRowInfoProps = {
    text: string;
    hasBorderBottom: boolean;
};

const props = defineProps<CardRowInfoProps>();
</script>
