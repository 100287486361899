import { getUsageIdFromName } from '@/allocation/allocation';
import { Ref, toValue } from 'vue';
import { AllocationPhaseUpdate, AllocationType } from '../Timeline.types';
import { AllocationUsage } from '../allocation/types';

export function useCreateAllocationUpdateRequestPhases(
    phases: AllocationPhaseUpdate[],
    usages: Ref<AllocationUsage[]>,
    allocationType: AllocationType,
) {
    function createRequestPhases() {
        let dayOffsetCount = 0;
        // TODO this mapping with increasing dayOffsetCount seems a bit ugly and difficult to read
        const remainingPhases = phases
            .filter((phase) => phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter > 0)
            .map((phase) => {
                const phaseObject = {
                    ...phase,
                    dayOffsetCount,
                };
                dayOffsetCount = dayOffsetCount + phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter;
                return phaseObject;
            });

        const phasesToCreate = remainingPhases
            .filter((phase) => phase.id.startsWith('temporary-new-phase'))
            .map((phase) => ({
                duration: {
                    days: phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter,
                    hours: 0,
                    minutes: 0,
                    months: 0,
                    seconds: 0,
                    years: 0,
                },
                offset: { days: phase.dayOffsetCount, hours: 0, minutes: 0, months: 0, seconds: 0, years: 0 },
                usageId: getUsageIdFromName(toValue(usages), phase.type, allocationType),
                specializationType: phase.specialization ?? '',
            }));

        const updatePhases = remainingPhases.filter((phase) => !phase.id.startsWith('temporary-new-phase'));
        const phasesToUpdate = updatePhases.map((phase) => ({
            id: phase.id,
            duration: {
                days: phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter,
                hours: 0,
                minutes: 0,
                months: 0,
                seconds: 0,
                years: 0,
            },
            offset: {
                days: phase.dayOffsetCount,
                hours: 0,
                minutes: 0,
                months: 0,
                seconds: 0,
                years: 0,
            },
            usageId: getUsageIdFromName(toValue(usages), phase.type, allocationType),
            specializationType:
                phase.newSpecialization !== phase.specialization ? phase.newSpecialization : phase.specialization ?? '',
        }));
        const phasesToDelete = phases
            .filter((phase) => phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter <= 0)
            .map((phase) => ({
                id: phase.id,
            }));
        return {
            phasesToCreate,
            updatePhases,
            phasesToUpdate,
            phasesToDelete,
            remainingPhases,
        };
    }

    return {
        createRequestPhases,
    };
}
