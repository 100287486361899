import { BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import { ActiveCard, EditableCardStatus } from '@/components/Card/types';
import {
    checkForLinkErrors,
    emptyLink,
    emptyMultilink,
    isMultiLinkDirty,
    toFormMultilink,
} from '@/components/MultiLink/multilink';
import { LinkSchema, LinkSchemaType } from '@/components/MultiLink/schema';
import { FormLink, Multilink } from '@/components/MultiLink/types';
import { EmitFormField } from '@/event/types';
import {
    CreateLinkDocument,
    CreateMultiLinkDocument,
    DeleteLinkDocument,
    GetProjectDocument,
    GetProjectQuery,
    GetProjectSeriesDocument,
    GetProjectSeriesQuery,
    UpdateLinkDocument,
    UpdateProjectDocument,
    UpdateProjectMultilinksDocument,
    UpdateProjectSeriesDocument,
    UpdateProjectSeriesMultilinksDocument,
    UpdateProjectStatusDocument,
} from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import router from '@/plugins/router';
import { setProperty } from '@/utils/properties';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { Ref, computed, reactive, toRefs, unref, watch } from 'vue';
import { useToast } from 'vue-toastification';
import { z } from 'zod';
import { ProjectSeriesCreateSchemaType } from './schemas';
import {
    Project,
    ProjectErrors,
    ProjectListItem,
    ProjectMultilinks,
    ProjectSeries,
    ProjectSeriesErrors,
    ProjectSeriesListItem,
    ProjectSeriesMultilinks,
    ProjectSeriesStatus,
    ProjectStatus,
    ProjectVariantStatus,
    UpdateProjectSeriesMultilinkParams,
    projectStates,
} from './types';

export const DEFAULT_PROJECT_STATUS = 'active-option';

export function emptyProjectSeriesCreate() {
    return reactive<ProjectSeriesCreateSchemaType>({
        nameShort: '',
        nameLong: '',
        singleProject: false,
    });
}

export function emptyProjectSeries() {
    return reactive<ProjectSeries>({
        id: '',
        nameShort: '',
        nameLong: '',
        notes: '',
        description: '',
        category: '',
        type: '',
        companyGroup: '',
        businessDomain: '',
        department: '',
        organisationalChanges: '',
        cycle: '',
        notesCycle: '',
        notesDayOrder: '',
        notesAppointment: '',
        locationRequirements: '',
        allocationHints: '',
        singleProject: false,
        projects: {
            nodes: [],
        },
    });
}

function emptyProjectSeriesErrors() {
    return reactive<ProjectSeriesErrors>({
        nameShort: [],
        nameLong: [],
        notes: [],
        description: [],
        websites: [],
        category: [],
        type: [],
        companyGroup: [],
        businessDomain: [],
        department: [],
        organisationalChanges: [],
        cycle: [],
        notesCycle: [],
        notesDayOrder: [],
        notesAppointment: [],
        locationRequirements: [],
        allocationHints: [],
        otherDocuments: [],
        otherSystems: [],
        otherLinks: [],
        singleProject: [],
    });
}

const emptyProjectSeriesMultilinks = () =>
    reactive<ProjectSeriesMultilinks>({
        websites: emptyMultilink(),
        otherDocuments: emptyMultilink(),
        otherSystems: emptyMultilink(),
        otherLinks: emptyMultilink(),
    });

export function goToCreateProject(seriesId: string) {
    router.push({ name: 'project-add', query: { seriesId } });
}

export function goToCreateProjectSeries() {
    router.push({ name: 'project-series-add' });
}

export function getProjectSeriesLink(seriesId: string) {
    return { name: 'project-series-overview-tab', params: { seriesId } };
}

export function goToProjectSeries(seriesId: string) {
    const to = getProjectSeriesLink(seriesId);
    router.push(to);
}

export function getProjectLink(projectId: string) {
    return { name: 'project-details-tab', params: { projectId } };
}

export function goToProject(projectId: string) {
    const to = getProjectLink(projectId);
    router.push(to);
}

export function getSeriesStatus(projects: ProjectListItem[]) {
    if (!projects.length) return undefined;
    const activeStates = ['active-option', 'active-fix', 'active-idea'];
    if (projects.some((p) => activeStates.includes(p.status))) return 'active';
    if (projects.some((p) => p.status === 'completed')) return 'completed';
    return 'discontinued';
}

export function getSeriesBadgeLabel(series: ProjectSeriesListItem) {
    const status = getSeriesStatus(series.projects?.nodes ?? []);
    if (status) return $t(`project-series-status-${status}`);
    return '';
}

export function projectSeriesStatusToBadgeVariant(status: ProjectSeriesStatus) {
    switch (status) {
        case 'active':
            return 'Green';
        case 'discontinued':
            return 'Red';
        case 'completed':
            return 'Gray';
        default:
            return 'Green';
    }
}

export function getSeriesBadgeVariant(series: ProjectSeriesListItem) {
    const status = getSeriesStatus(series.projects?.nodes ?? []);
    if (status) return projectSeriesStatusToBadgeVariant(status);
    return 'Green';
}

export function projectStatusToBadgeVariant(status: ProjectStatus) {
    switch (status) {
        case 'active-option':
            return 'Green';
        case 'active-fix':
            return 'Green';
        case 'active-idea':
            return 'Green';
        case 'completed':
            return 'Gray';
        case 'discontinued':
            return 'Red';
    }
}

export function getProjectStatusItems(status: ProjectStatus): (BadgeDropDownValue & { isSelected: boolean })[] {
    return projectStates.map((s) => ({
        id: s,
        label: $t(`project-status-${s}`),
        variant: projectStatusToBadgeVariant(s),
        isSelected: status === s,
    }));
}

export function getProjectStatusLabel(status: ProjectStatus) {
    return $t(`project-status-${status}`);
}

export function getProjectStatusComboboxItems() {
    return projectStates.map((s) => ({
        id: s,
        name: getProjectStatusLabel(s),
    }));
}

export function getProjectStatusItem(status: ProjectStatus) {
    return { id: status, label: $t(`project-status-${status}`), variant: projectStatusToBadgeVariant(status) };
}

export function createNavItems(tabItems: { route: string; label: string }[], activeCard: Ref<ActiveCard>) {
    const items = [];
    for (const item of tabItems) {
        items.push({
            target: { name: item.route },
            routeName: item.route,
            label: item.label,
            isDisabled: computed(() => isTabDisabled(item.route, activeCard)),
        });
    }
    return items;
}

export function isTabDisabled(tabRouteName: string, activeCard: Ref<ActiveCard>) {
    if (activeCard.value.id !== '' && activeCard.value.status !== 'View') {
        const navItemRouteName = router.currentRoute.value.name;
        if (navItemRouteName && navItemRouteName !== tabRouteName) {
            return true;
        }
    }
    return false;
}

function processMultilink(
    event: Event,
    link: FormLink,
    property: string,
    fieldName: string,
    multilinks: ProjectSeriesMultilinks | ProjectMultilinks,
) {
    const target = event.target as HTMLInputElement;
    const value = target.value;

    const parseField = LinkSchema.shape[property as keyof LinkSchemaType].safeParse(value);
    if (!parseField.success) {
        link.errors[property as keyof LinkSchemaType] = parseField.error.format()._errors;
        link[property] = value;
    } else {
        link.errors[property as keyof LinkSchemaType] = [];
        link[property] = parseField.data;
    }

    // check complete link too
    const parse = LinkSchema.safeParse(link);
    if (!parse.success) {
        const flattenedErrors = parse.error.flatten().fieldErrors;
        Object.assign(link.errors, flattenedErrors);
    } else {
        link.errors = { name: [], description: [], to: [] };
    }

    // check if the last link of the multlink field was filled
    const multilink = multilinks[fieldName as keyof typeof multilinks];
    const nodes = multilink.links?.nodes;
    if (nodes) {
        const lastLink = nodes[nodes.length - 1];
        if (lastLink.name.length > 0 || lastLink.to.length > 0 || lastLink.description.length > 0) {
            nodes.push(emptyLink(multilink.id));
        }
    }
}

export function useProjectSeries(seriesId: Ref<string>) {
    const toast = useToast();
    const fluent = useFluent();

    const MULTILINK_FIELDS = ['websites', 'otherDocuments', 'otherSystems', 'otherLinks'];

    const { result: getProjectSeries, refetch } = useQuery(
        GetProjectSeriesDocument,
        computed(() => ({ id: unref(seriesId) })),
    );
    const loadedSeries = computed(() => getProjectSeries.value?.projectSeries);
    const updateSeries = useMutation(UpdateProjectSeriesDocument);
    const createMultilink = useMutation(CreateMultiLinkDocument);
    const updateProjectSeriesMultilink = useMutation(UpdateProjectSeriesMultilinksDocument);

    // multilinks
    const deleteLink = useMutation(DeleteLinkDocument);
    const createLink = useMutation(CreateLinkDocument);
    const updateLink = useMutation(UpdateLinkDocument);

    const state = reactive({
        series: emptyProjectSeries(),
        errors: emptyProjectSeriesErrors(),
        activeCard: emptyActiveCard(),
        multilinks: emptyProjectSeriesMultilinks(),
    });

    const createMultiLink = async (field: string) => {
        const res = await createMultilink.mutate({ name: field });
        return res?.data?.createMultilink?.multilink?.id;
    };

    const createInitialMultiLinks = async () => {
        const params: UpdateProjectSeriesMultilinkParams = { id: unref(seriesId) };
        for (const f of MULTILINK_FIELDS) {
            const multilink = state.series[f as keyof ProjectSeries] as Multilink | undefined | null;
            if (!multilink || !multilink.id) {
                const id = await createMultiLink(f);
                const key = `${f}Id` as keyof UpdateProjectSeriesMultilinkParams;
                params[key] = id;
            }
        }
        if (Object.keys(params).length > 1) {
            await updateProjectSeriesMultilink.mutate(params);
        }
    };

    const setInitialLinks = () => {
        for (const f of MULTILINK_FIELDS) {
            const multilink = state.series[f as keyof ProjectSeries] as Multilink | undefined | null;
            if (multilink && multilink.id) {
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[f] = formMultilink;
            }
        }
    };

    watch(
        loadedSeries,
        async (data) => {
            if (data) {
                state.series = Object.assign(emptyProjectSeries(), data);
                if (state.series.id) {
                    // creation of initial multilinks if they are missing
                    await createInitialMultiLinks();
                    setInitialLinks();
                }
            }
        },
        { immediate: true },
    );

    const seriesMultiLinks = computed(() => {
        const result: FormLink[] = [];
        for (const f of MULTILINK_FIELDS) {
            const multilinks = state.multilinks[f as keyof ProjectSeriesMultilinks];
            result.push(...(multilinks.links?.nodes ?? []));
        }
        return result;
    });

    const linksToCreate = computed(() =>
        seriesMultiLinks.value.filter(
            (link) => link.status === 'new' && link.name.length > 0 && link.to.length > 0 && link.multilinkId,
        ),
    );
    const linksToDelete = computed(() => seriesMultiLinks.value.filter((link) => link.id && link.status === 'deleted'));
    const linksToUpdate = computed(() => seriesMultiLinks.value.filter((link) => link.id && link.status === 'prop'));

    // card logic

    function updateCard(id: string, label: string, status: EditableCardStatus) {
        state.activeCard = { id, label, status };
    }

    function resetCard() {
        state.activeCard = { id: '', label: '', status: 'View' };
    }

    function resetCardFields(keys: string[]) {
        for (const key of keys) {
            if (MULTILINK_FIELDS.includes(key)) {
                const multilink = state.series[key as keyof ProjectSeries] as Multilink;
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[key as keyof ProjectSeriesMultilinks] = formMultilink;
            } else {
                if (loadedSeries.value) {
                    state.series[key as keyof ProjectSeries] =
                        loadedSeries.value[key as keyof GetProjectSeriesQuery['projectSeries']];
                }
            }
        }
        state.errors = emptyProjectSeriesErrors();
    }

    function discardChanges<T extends z.AnyZodObject>(schema: T) {
        resetCardFields(schema.keyof().options);
        resetCard();
    }

    // form handling

    function isFieldTouched(key: string) {
        if (MULTILINK_FIELDS.includes(key)) {
            const currentMultilink = state.multilinks[key];
            const originalMultilink = state.series[key as keyof ProjectSeries] as Multilink;
            return isMultiLinkDirty(currentMultilink, originalMultilink);
        } else {
            const oldSeries = loadedSeries.value ?? {};
            const oldValue = oldSeries[key as keyof GetProjectSeriesQuery['projectSeries']];
            const newValue = state.series[key as keyof ProjectSeries];
            return newValue !== oldValue;
        }
    }

    function isFormTouched(fields: string[]) {
        if (fields?.some((f) => isFieldTouched(f))) {
            state.activeCard.status = 'Edit-Touched';
        } else {
            state.activeCard.status = 'Edit-Untouched';
        }
    }

    // form field handling

    function handleFormField<T extends z.ZodTypeAny>(formField: EmitFormField, schema: T) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const parse = schema.safeParse(value);
        if (!parse.success) {
            state.errors[fieldName as keyof ProjectSeriesErrors] = parse.error.format()._errors;
            setProperty(state.series, fieldName as keyof ProjectSeries, value);
        } else {
            state.errors[fieldName as keyof ProjectSeriesErrors] = [];
            setProperty(state.series, fieldName as keyof ProjectSeries, parse.data);
        }
    }

    function handleMultilink(event: Event, link: FormLink, property: string, fieldName: string) {
        processMultilink(event, link, property, fieldName, state.multilinks);
    }

    function deleteMultilinkLink(link: FormLink) {
        link.status = 'deleted';
    }

    async function saveMultiLinks() {
        for (const link of linksToCreate.value) {
            await createLink.mutate({
                multiLinkId: link.multilinkId,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
        for (const link of linksToDelete.value) {
            await deleteLink.mutate({ id: link.id });
        }
        for (const link of linksToUpdate.value) {
            await updateLink.mutate({
                id: link.id,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
    }

    async function save<T extends z.ZodTypeAny>(schema: T | undefined, withMultilinks: boolean = false) {
        if (withMultilinks) {
            if (checkForLinkErrors(seriesMultiLinks.value)) return;
            await saveMultiLinks();
        }

        if (schema) {
            const parse = schema.safeParse(state.series);

            if (!parse.success) {
                const flattenedErrors = parse.error.flatten().fieldErrors;
                Object.assign(state.errors, flattenedErrors);
                return;
            }
            // trim all strings
            const data = parse.data;
            Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));
            const res = await updateSeries.mutate({ id: unref(seriesId), ...data });
            const { id, nameShort } = res?.data?.updateProjectSeries?.projectSeries ?? {};
            if (id && nameShort) {
                toast.success(fluent.$t('update-success-notification', { type: 'projectseries', name: nameShort }));
            }
        }

        refetch();
        resetCard();
    }

    return {
        ...toRefs(state),
        handleFormField,
        handleMultilink,
        deleteMultilinkLink,
        isFormTouched,
        updateCard,
        resetCard,
        discardChanges,
        save,
    };
}

export function emptyProject(): Project {
    return reactive({
        id: '',
        nameShort: '',
        nameLong: '',
        subtitle: '',
        executionYear: undefined,
        description: '',
        category: '',
        type: '',
        disturbanceNoise: '',
        disturbanceDust: '',
        disturbanceOther: '',
        companyGroup: '',
        businessDomain: '',
        department: '',
        organisationalChanges: '',
        notesDayOrder: '',
        notesAppointment: '',
        locationRequirements: '',
        allocationHints: '',
        otherDocumentsId: '',
        otherSystemsId: '',
        otherLinksId: '',
        status: 'active-option',
        series: {
            id: '',
            nameShort: '',
        },
    });
}

function emptyProjectErrors() {
    return reactive<ProjectErrors>({
        nameShort: [],
        nameLong: [],
        subtitle: [],
        executionYear: [],
        description: [],
        category: [],
        type: [],
        companyGroup: [],
        businessDomain: [],
        department: [],
        organisationalChanges: [],
        disturbanceNoise: [],
        disturbanceDust: [],
        disturbanceOther: [],
        notesDayOrder: [],
        notesAppointment: [],
        locationRequirements: [],
        allocationHints: [],
        otherDocuments: [],
        otherSystems: [],
        otherLinks: [],
    });
}

const emptyProjectMultilinks = () =>
    reactive<ProjectMultilinks>({
        otherDocuments: emptyMultilink(),
        otherSystems: emptyMultilink(),
        otherLinks: emptyMultilink(),
    });

export function useProject(projectId: Ref<string>) {
    const toast = useToast();
    const fluent = useFluent();

    const MULTILINK_FIELDS = ['otherDocuments', 'otherSystems', 'otherLinks'];

    const { result: getProject, refetch } = useQuery(
        GetProjectDocument,
        computed(() => ({ id: unref(projectId) })),
    );
    const loadedProject = computed(() => getProject.value?.project);
    const updateProject = useMutation(UpdateProjectDocument);
    const createMultilink = useMutation(CreateMultiLinkDocument);
    const updateProjectMultilink = useMutation(UpdateProjectMultilinksDocument);
    const updateProjectStatus = useMutation(UpdateProjectStatusDocument);

    // multilinks
    const deleteLink = useMutation(DeleteLinkDocument);
    const createLink = useMutation(CreateLinkDocument);
    const updateLink = useMutation(UpdateLinkDocument);

    const state = reactive({
        project: emptyProject(),
        errors: emptyProjectErrors(),
        activeCard: emptyActiveCard(),
        multilinks: emptyProjectMultilinks(),
    });

    const createMultiLink = async (field: string) => {
        const res = await createMultilink.mutate({ name: field });
        return res?.data?.createMultilink?.multilink?.id;
    };

    const createInitialMultiLinks = async () => {
        const params: UpdateProjectSeriesMultilinkParams = { id: unref(projectId) };
        for (const f of MULTILINK_FIELDS) {
            const multilink = state.project[f as keyof Project] as Multilink | undefined | null;
            if (!multilink || !multilink.id) {
                const id = await createMultiLink(f);
                const key = `${f}Id` as keyof UpdateProjectSeriesMultilinkParams;
                params[key] = id;
            }
        }
        if (Object.keys(params).length > 1) {
            await updateProjectMultilink.mutate(params);
        }
    };

    const setInitialLinks = () => {
        for (const f of MULTILINK_FIELDS) {
            const multilink = state.project[f as keyof Project] as Multilink | undefined | null;
            if (multilink && multilink.id) {
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[f] = formMultilink;
            }
        }
    };

    watch(
        loadedProject,
        async (data) => {
            if (data) {
                state.project = Object.assign(emptyProject(), data);
                if (state.project.id) {
                    // creation of initial multilinks if they are missing
                    await createInitialMultiLinks();
                    setInitialLinks();
                }
            }
        },
        { immediate: true },
    );

    const projectMultilinks = computed(() => {
        const result: FormLink[] = [];
        for (const f of MULTILINK_FIELDS) {
            const multilinks = state.multilinks[f as keyof ProjectMultilinks];
            result.push(...(multilinks.links?.nodes ?? []));
        }
        return result;
    });

    const linksToCreate = computed(() =>
        projectMultilinks.value.filter(
            (link) => link.status === 'new' && link.name.length > 0 && link.to.length > 0 && link.multilinkId,
        ),
    );
    const linksToDelete = computed(() =>
        projectMultilinks.value.filter((link) => link.id && link.status === 'deleted'),
    );
    const linksToUpdate = computed(() => projectMultilinks.value.filter((link) => link.id && link.status === 'prop'));

    // card logic

    function updateCard(id: string, label: string, status: EditableCardStatus) {
        state.activeCard = { id, label, status };
    }

    function resetCard() {
        state.activeCard = { id: '', label: '', status: 'View' };
    }

    function resetCardFields(keys: string[]) {
        for (const key of keys) {
            if (MULTILINK_FIELDS.includes(key)) {
                const multilink = state.project[key as keyof Project] as Multilink;
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[key as keyof ProjectMultilinks] = formMultilink;
            } else {
                if (loadedProject.value) {
                    state.project[key as keyof Project] = loadedProject.value[key as keyof GetProjectQuery['project']];
                }
            }
        }
        state.errors = emptyProjectErrors();
    }

    function discardChanges<T extends z.AnyZodObject>(schema: T) {
        resetCardFields(schema.keyof().options);
        resetCard();
    }

    // form handling

    function isFieldTouched(key: string) {
        if (MULTILINK_FIELDS.includes(key)) {
            const currentMultilink = state.multilinks[key];
            const originalMultilink = state.project[key as keyof Project] as Multilink;
            return isMultiLinkDirty(currentMultilink, originalMultilink);
        } else {
            const oldObject = loadedProject.value ?? {};
            const oldValue = oldObject[key as keyof GetProjectQuery['project']];
            const newValue = state.project[key as keyof Project];
            return newValue !== oldValue;
        }
    }

    function isFormTouched(fields: string[]) {
        if (fields?.some((f) => isFieldTouched(f))) {
            state.activeCard.status = 'Edit-Touched';
        } else {
            state.activeCard.status = 'Edit-Untouched';
        }
    }

    // form field handling

    function handleFormField<T extends z.ZodTypeAny>(formField: EmitFormField, schema: T) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const parse = schema.safeParse(value);
        if (!parse.success) {
            state.errors[fieldName as keyof ProjectErrors] = parse.error.format()._errors;
            setProperty(state.project, fieldName as keyof Project, value);
        } else {
            state.errors[fieldName as keyof ProjectErrors] = [];
            setProperty(state.project, fieldName as keyof Project, parse.data);
        }
    }

    function handleMultilink(event: Event, link: FormLink, property: string, fieldName: string) {
        processMultilink(event, link, property, fieldName, state.multilinks);
    }

    function deleteMultilinkLink(link: FormLink) {
        link.status = 'deleted';
    }

    async function saveMultiLinks() {
        for (const link of linksToCreate.value) {
            await createLink.mutate({
                multiLinkId: link.multilinkId,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
        for (const link of linksToDelete.value) {
            await deleteLink.mutate({ id: link.id });
        }
        for (const link of linksToUpdate.value) {
            await updateLink.mutate({
                id: link.id,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
    }

    async function save<T extends z.ZodTypeAny>(schema: T | undefined, withMultilinks: boolean = false) {
        if (withMultilinks) {
            if (checkForLinkErrors(projectMultilinks.value)) return;
            await saveMultiLinks();
        }

        if (schema) {
            const parse = schema.safeParse(state.project);

            if (!parse.success) {
                const flattenedErrors = parse.error.flatten().fieldErrors;
                Object.assign(state.errors, flattenedErrors);
                return;
            }
            // trim all strings
            const data = parse.data;
            Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));
            const res = await updateProject.mutate({ id: unref(projectId), ...data });
            const { id, nameShort } = res?.data?.updateProject?.project ?? {};
            if (id && nameShort) {
                toast.success(fluent.$t('update-success-notification', { type: 'project', name: nameShort }));
            }
        }

        refetch();
        resetCard();
    }

    async function updateStatus(status: ProjectStatus) {
        if (state.project.status === status) return;
        const id = state.project.id;
        await updateProjectStatus.mutate({ id, status });
    }

    return {
        ...toRefs(state),
        handleFormField,
        handleMultilink,
        isFormTouched,
        deleteMultilinkLink,
        updateCard,
        resetCard,
        discardChanges,
        save,
        updateStatus,
        updateStatusLoading: updateProjectStatus.loading,
        updateStatusError: updateProjectStatus.error,
    };
}

export function projectVariantStatusToBadgeVariant(status: ProjectVariantStatus) {
    switch (status) {
        case 'active-1':
            return 'Green';
        case 'active-2':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'discontinued':
            return 'Red';
    }
}

export function getProjectVariantRoute(projectId: string, variantIndex: number) {
    return { name: 'project-variants-tab', params: { projectId }, hash: `#v${variantIndex}` };
}
