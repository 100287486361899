<template>
    <HeaderCardsLayout v-if="series" :headline="series.nameShort" variant="No-Logo">
        <template #breadcrumb>
            <Breadcrumb
                :headline="$t('breadcrumb')"
                :current-page="series.nameShort"
                :current-page-url="$route.fullPath"
            >
                <template #currentIcon>
                    <SingleEventIcon v-if="series.singleProject" class="inline-block align-sub" />
                </template>
                <BreadcrumbLink :target="{ name: 'projects' }">
                    <ConstructionIcon class="mr-1" />
                    {{ $t('project-wiki') }}
                </BreadcrumbLink>
            </Breadcrumb>
        </template>

        <template v-if="series.singleProject" #icon><SingleEventIcon class="h-12 w-12" /></template>
        <template #headline-extra>
            <Badge
                v-if="getSeriesStatus(series.projects?.nodes ?? [])"
                :tooltip-text="$t('project-series-status-calculated')"
                size="Small"
                :variant="getSeriesBadgeVariant(series)"
            >
                {{ getSeriesBadgeLabel(series) }}
            </Badge>
            <div v-else class="text-gray-500 text-sm">({{ $t('no-projects') }})</div>
        </template>

        <template #buttons>
            <Button
                variant="Info-Light"
                size="Base"
                :disabled-explanation="convertDisabledExplanation"
                @click="toggleSingleProject"
            >
                <template #icon>
                    <SingleEventIcon v-if="!series.singleProject" />
                </template>
                {{ convertButtonLabel }}
            </Button>
            <Button
                variant="Danger-Strong"
                size="Base"
                :disabled-explanation="deleteDisabledExplanation"
                @click="deleteSeries"
            >
                <template #icon>
                    <DeleteForeverIcon />
                </template>
                {{ $t('delete') }}
            </Button>
            <Button
                variant="Info-Strong"
                size="Base"
                :disabled-explanation="createProjectDisabledExplanation"
                @click="goToCreateProject(seriesId)"
            >
                <template #icon><EditIcon /></template>
                {{ createEventButtonLabel }}
            </Button>
        </template>

        <template #tab-navigation>
            <TabNavigation
                :headline="$t('tab')"
                :items="tabNavigationItems"
                tab-panel-id="eventSeriesTabs"
                :tabs-disabled-text="tabsDisabledText"
                @focus-tab-panel="focusTabPanel"
            />
        </template>

        <template #body>
            <TabPanel :id="tabPanelId" ref="tabPanelRef" :headline="activeTabHeadline">
                <RouterView />
            </TabPanel>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import Badge from '@/components/Badge/Badge.vue';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbLink from '@/components/Breadcrumb/BreadcrumbLink.vue';
import Button from '@/components/Button/Button.vue';
import ConstructionIcon from '@/components/Icon/ConstructionIcon.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import EditIcon from '@/components/Icon/EditIcon.vue';
import SingleEventIcon from '@/components/Icon/SingleEventIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import TabNavigation from '@/components/TabNavigation/TabNavigation.vue';
import TabPanel from '@/components/TabNavigation/TabPanel.vue';
import { DeleteProjectSeriesDocument, SetSingleProjectDocument } from '@/generated/graphql';
import { useHeadSafe } from '@unhead/vue';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { nanoid } from 'nanoid';
import { computed, provide, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { activeCardData, activeCardFunctions, formFunctions, projectSeriesData } from '../keys';
import {
    createNavItems,
    getSeriesBadgeLabel,
    getSeriesBadgeVariant,
    getSeriesStatus,
    goToCreateProject,
    useProjectSeries,
} from '../project';

const toast = useToast();
const fluent = useFluent();
const isAdmin = auth.isAdmin();
const router = useRouter();

const props = defineProps<{ seriesId: string }>();
const tabPanelRef = ref();
const tabPanelId = nanoid();

const projectSeriesId = computed(() => props.seriesId);
const {
    series,
    errors,
    multilinks,
    handleFormField,
    handleMultilink,
    deleteMultilinkLink,
    activeCard,
    updateCard,
    resetCard,
    discardChanges,
    isFormTouched,
    save,
} = useProjectSeries(projectSeriesId);

provide(projectSeriesData, { series, errors, multilinks });
provide(formFunctions, { handleFormField, handleMultilink, isFormTouched, save, deleteLink: deleteMultilinkLink });
provide(activeCardFunctions, { updateCard, resetCard, discardChanges });
provide(activeCardData, activeCard);

const setSingleProject = useMutation(SetSingleProjectDocument);

useHeadSafe({ title: () => `${series.value.nameShort} - ${fluent.$t('project-wiki')}` });

const toggleSingleProject = async () => {
    const res = await setSingleProject.mutate({
        id: projectSeriesId.value,
        singleProject: !series.value.singleProject,
    });
    const { id, nameShort } = res?.data?.updateProjectSeries?.projectSeries ?? {};
    if (id && nameShort) {
        toast.success(fluent.$t('update-success-notification', { type: 'projectseries', name: nameShort }));
    }
};

const convertDisabledExplanation = computed(() => {
    if (!isAdmin) {
        return fluent.$t('no-permission-to-edit');
    }
    if (!series.value.singleProject && series.value.projects && series.value.projects.nodes.length > 1) {
        return fluent.$t('series-contains-multiple-events');
    }
    return undefined;
});

const createEventButtonLabel = computed(() =>
    series.value.singleProject ? fluent.$t('single-project-create') : fluent.$t('project-create'),
);

const convertButtonLabel = computed(() =>
    series.value.singleProject ? fluent.$t('allow-multiple-projects') : fluent.$t('limit-to-single-project'),
);

const deleteProjectSeries = useMutation(DeleteProjectSeriesDocument);
async function deleteSeries() {
    if (!isAdmin) return;
    const name = series.value.nameShort ?? '';
    if (!confirm(fluent.$t('confirm-delete-project-series', { name }))) return;
    const res = await deleteProjectSeries.mutate({ seriesId: props.seriesId });
    if (res?.data) {
        toast.success(fluent.$t('delete-success-notification', { type: 'projectseries', name }));
        router.replace({ name: 'projects' });
    }
}

const deleteDisabledExplanation = computed(() => {
    if (!isAdmin) return fluent.$t('no-permission-to-edit');
    if (series.value.projects && series.value.projects.nodes.length > 0)
        return fluent.$t('project-series-has-projects');
    return undefined;
});

const createProjectDisabledExplanation = computed(() => {
    if (!isAdmin) {
        return fluent.$t('no-permission-to-create');
    }
    if (series.value.singleProject && series.value.projects && series.value.projects.nodes.length >= 1) {
        return fluent.$t('single-project-limit-reached');
    }
    return undefined;
});

const tabs = [
    { route: 'project-series-overview-tab', label: fluent.$t('overview') },
    { route: 'project-series-details-tab', label: fluent.$t('master-data') },
    { route: 'project-series-org-tab', label: fluent.$t('organisational-structure') },
    { route: 'project-series-appointment-tab', label: fluent.$t('appointment-and-location') },
    { route: 'project-series-links-tab', label: fluent.$t('links') },
];

const tabNavigationItems = reactive(createNavItems(tabs, activeCard));

function focusTabPanel() {
    tabPanelRef.value.focus();
}

const tabsDisabledText = computed(() => fluent.$t('tabs-disabled', { name: activeCard.value.label }));

const route = useRoute();

const activeTabHeadline = computed(() => {
    return fluent.$t('tab-panel', {
        activeTabName: tabNavigationItems.find((item) => item.routeName === route.name)?.label ?? '',
    });
});
</script>
