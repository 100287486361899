<template>
    <BaseForm>
        <template #fields>
            <TextField :value="scenario.name" :errorMessages="nameErrors" :label="$t('name')" @input="nameChange" />
            <TextField
                :value="scenario.description"
                :errorMessages="descriptionErrors"
                :label="$t('description')"
                @input="descriptionChange"
            />
        </template>
        <template #buttons>
            <BaseButton @click="$router.back()" variant="outlined">{{ $t('cancel') }}</BaseButton>
            <BaseButton @click="onSave" :loading="createMutation.loading.value" class="ml-3">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard class="mt-4" :mutation="createMutation" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import { CreateScenarioDocument, GetScenariosDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { scenarioCreateSchema } from './schemas';

const toast = useToast();
const fluent = useFluent();
const router = useRouter();

const scenario = ref({ name: '', description: '' });

let nameErrors = ref<string[]>();
let descriptionErrors = ref<string[]>();

const nameChange = (e: Event) => {
    nameErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    scenario.value.name = value;
    const parse = scenarioCreateSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const descriptionChange = (e: Event) => {
    descriptionErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    scenario.value.description = value;
    const parse = scenarioCreateSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const createMutation = useMutation(CreateScenarioDocument, { refetchQueries: [{ query: GetScenariosDocument }] });

const onSave = async () => {
    const parse = scenarioCreateSchema.safeParse(scenario.value);
    if (!parse.success) return;
    const res = await createMutation.mutate(scenario.value);
    const { id, name } = res?.data?.createScenario?.scenario ?? {};
    if (id && name) {
        toast.success(fluent.$t('create-success-notification', { type: 'scenario', name: name }), {
            onClick: () => router.push({ name: 'scenario-details', params: { scenarioId: id } }),
        });
        router.push({ name: 'scenario-details', params: { scenarioId: id } });
    }
};
</script>
