import { mapStringToSpecializationType } from '@/components/Timeline/allocation/SpecializationMappings';
import { AllocationUsage } from '@/components/Timeline/allocation/types';
import { getDurationInDays } from '@/components/Timeline/logic/allocations';
import { convertToDisplayedDateTime } from '@/components/Timeline/logic/durations';
import { getTransparencyVariant } from '@/components/Timeline/timeline';
import { Allocation, AllocationType, PhaseType } from '@/components/Timeline/Timeline.types';
import {
    TimelineEventAllocationAreaFragment,
    TimelineEventAllocationRoomFragment,
    TimelineProjectAllocationAreaFragment,
    TimelineProjectAllocationRoomFragment,
} from '@/generated/graphql';
import { DateTime } from 'luxon';
import { RouteLocationRaw } from 'vue-router';

const defaultUsageName = {
    Project: 'Projekt',
    Event: 'Durchführung',
    None: undefined,
};

export function getUsageIdFromName(usages: AllocationUsage[], name: string, allocationType: AllocationType) {
    if (name === 'default') {
        const defaultName = defaultUsageName[allocationType];
        if (defaultName) name = defaultName;
    }
    const usage = usages.find((u) => u.name === name);
    if (!usage) throw new Error(`Unknow usage name! ${name}`);
    return usage.id;
}

export function mapAllocationForTimeline(
    allocation:
        | TimelineProjectAllocationRoomFragment
        | TimelineEventAllocationRoomFragment
        | TimelineProjectAllocationAreaFragment
        | TimelineEventAllocationAreaFragment,
    isFromCurrentVariant: boolean,
    variantLabel: string,
    allocationType: AllocationType,
    label: string,
    highlightedVariantId: string,
    linkTarget: RouteLocationRaw,
): Allocation {
    const allocationStart = DateTime.fromISO(allocation.start);
    const allocationEnd = convertToDisplayedDateTime(DateTime.fromISO(allocation.end));

    return {
        id: allocation.id,
        allocationType,
        allocationVariantId: allocation.variant.id,
        label,
        phases: allocation.phases.nodes.map((phase) => ({
            id: phase.id,
            cellSpan: getDurationInDays(phase.duration),
            type: phase.usage.name as PhaseType,
            specialization: mapStringToSpecializationType(phase.specializationType),
        })),
        startDateTime: allocationStart,
        endDateTime: allocationEnd,
        locationId: allocation.locationId,
        isEditable: true,
        linkTarget,
        variant: getTransparencyVariant(highlightedVariantId, allocation.variant.id, isFromCurrentVariant),
        allocationVariantLabel: variantLabel,
    };
}
