import { areaRoutes } from '@/area/routes';
import { buildingRoutes } from '@/building/routes';
import { levelRoutes } from '@/level/routes';
import LocationOverviewPage from '@/location/pages/LocationOverviewPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailLayout from '@/navigation/components/MasterDetailLayout.vue';
import { plotRoutes } from '@/plot/routes';
import { roomRoutes } from '@/room/routes';
import { roomPartitionRoutes } from '@/room_partition/routes';
import { siteRoutes } from '@/site/routes';
import { zoneRoutes } from '@/zone/routes';
import { RouteRecordRaw, RouterView } from 'vue-router';

export const locationRoutes = <RouteRecordRaw[]>[
    {
        path: '/locations',
        component: RouterView,
        children: [
            {
                path: '',
                component: MasterDetailLayout,
                children: [
                    {
                        path: '',
                        name: 'locations',
                        components: {
                            default: LocationsPage,
                            detail: LocationOverviewPage,
                        },
                    },
                ],
            },
            ...buildingRoutes,
            ...siteRoutes,
            ...areaRoutes,
            ...zoneRoutes,
            ...plotRoutes,
            ...roomRoutes,
            ...levelRoutes,
            ...roomPartitionRoutes,
        ],
    },
];
