import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { Ref, toValue } from 'vue';
import { useRoute } from 'vue-router';

export function useChangeHighlight(
    variantsRef: Ref<TimelineVariant[]>,
    setHighlightedVariantId: (value: string) => void,
    setHighlightParam: (value: string | undefined) => void,
) {
    const route = useRoute();

    function onChangeHighlight(variantId: string | null) {
        if (variantId === null) {
            variantId = 'No-Highlight-Id';
        }
        setHighlightedVariantId(variantId);

        if (variantId === 'No-Highlight-Id' && 'highlight' in route.query) {
            setHighlightParam(undefined);
        } else {
            const variant = toValue(variantsRef).find((v) => v.id === variantId);
            if (variant) {
                setHighlightParam(variant.index.toString());
            }
        }
    }

    return {
        onChangeHighlight,
    };
}
