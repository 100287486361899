<template>
    <dt class="text-xs font-normal text-gray-500 flex justify-between gap-2">
        <div>
            {{ field.title }} <span v-if="'unit' in field">[{{ field.unit }}]</span>
        </div>
        <HelpButton class="items-start" v-if="field.helper" :text="field.helper" />
    </dt>
    <dd class="text-sm text-gray-900 sm:mt-0 break-normal flex items-center gap-2 whitespace-break-spaces">
        <span>{{ valueAsString }}</span>
    </dd>
</template>

<script setup lang="ts">
import HelpButton from '@/components/HelpButton/HelpButton.vue';
import { FormField } from '@/location/forms/types';
import { computed } from '@vue/reactivity';
import { useFluent } from 'fluent-vue';
const fluent = useFluent();

const props = withDefaults(
    defineProps<{
        field: FormField;
        placeholder?: string;
    }>(),
    { placeholder: '-' },
);

const valueAsString = computed(() => {
    const placeholder = props.placeholder ?? '';
    const fieldValue = props.field.value;
    if (fieldValue === undefined) return placeholder;

    if ('valueLabel' in props.field && props.field.valueLabel != '') {
        return props.field.valueLabel;
    }

    switch (props.field.type) {
        case 'checkbox':
            return fluent.$t(fieldValue ? 'true' : 'false');
        case 'string':
            return (fieldValue as string).length ? fieldValue : placeholder;
        case 'float':
            return Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
                fieldValue as number,
            );
        case 'number':
            return String(fieldValue);
    }
});
</script>

<fluent locale="de">
true=Ja
false=Nein
prev=Vorher (bis {$before}): { $prev }
</fluent>
