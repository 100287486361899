<template>
    <BaseForm>
        <template #fields>
            <TextField
                :value="user.firstName"
                :label="$t('first-name')"
                :errorMessages="firstNameErrors"
                @input="firstNameChange"
            />
            <TextField
                :value="user.lastName"
                :label="$t('last-name')"
                :errorMessages="lastNameErrors"
                @input="lastNameChange"
            />
            <EmailField :value="user.email" :label="$t('email')" :errorMessages="emailErrors" @input="emailChange" />
            <CheckboxField :value="user.isAdmin" :label="$t('is-admin')" @input="isAdminChange" />
            <FileUpload :label="$t('avatar')" :url="iconUrl ?? undefined" @change="uploadIcon" @clear="deleteIcon" />
        </template>
        <template #buttons>
            <base-button @click="$router.back()" variant="outlined">{{ $t('cancel') }}</base-button>
            <base-button @click="onSave" color="primary" :loading="loading">
                {{ $t('save') }}
            </base-button>
        </template>
    </BaseForm>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import CheckboxField from '@/components/Form/CheckboxField.vue';
import EmailField from '@/components/Form/EmailField.vue';
import TextField from '@/components/Form/TextField.vue';
import { computed, ref } from 'vue';
import { useUser } from '../user';
import { userUpdateSchema } from './schemas';
import FileUpload from '@/components/Form/FileUpload.vue';

const props = defineProps<{ userId: string }>();
const { saveUser, user, updateMutation, uploadIcon, deleteIcon, iconUrl } = useUser(computed(() => props.userId));
const loading = updateMutation.loading;

let firstNameErrors = ref<string[]>();
let lastNameErrors = ref<string[]>();
let emailErrors = ref<string[]>();
let isAdminErrors = ref<string[]>();

const firstNameChange = (e: Event) => {
    firstNameErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    user.value.firstName = value;
    const parse = userUpdateSchema.shape.firstName.safeParse(value);
    if (!parse.success) {
        firstNameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const lastNameChange = (e: Event) => {
    lastNameErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    user.value.lastName = value;
    const parse = userUpdateSchema.shape.lastName.safeParse(value);
    if (!parse.success) {
        lastNameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const emailChange = (e: Event) => {
    emailErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.value;
    user.value.email = value;
    const parse = userUpdateSchema.shape.email.safeParse(value);
    if (!parse.success) {
        emailErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const isAdminChange = (e: Event) => {
    isAdminErrors.value = undefined;
    const target = e.target as HTMLInputElement;
    const value = target.checked;
    user.value.isAdmin = value;
    const parse = userUpdateSchema.shape.isAdmin.safeParse(value);
    if (!parse.success) {
        isAdminErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const onSave = async () => {
    const parse = userUpdateSchema.safeParse(user.value);
    if (!parse.success) {
        const flattenedErrors = parse.error.flatten().fieldErrors;
        firstNameErrors.value = flattenedErrors.firstName;
        lastNameErrors.value = flattenedErrors.lastName;
        emailErrors.value = flattenedErrors.email;
        isAdminErrors.value = flattenedErrors.isAdmin;
        return;
    }
    // TODO only update user if data changed
    saveUser();
};
</script>
