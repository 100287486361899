<template>
    <TopBar :title="$t('edit-object', { type: 'calendar' })" />
    <div class="p-5">
        <CalendarEditForm v-if="calendar" :calendar="calendar"></CalendarEditForm>
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetCalendarDocument } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import CalendarEditForm from '../forms/CalendarEditForm.vue';

const props = defineProps<{ calendarId: string }>();
const getCalendar = useQuery(
    GetCalendarDocument,
    computed(() => ({ id: props.calendarId })),
);
const calendar = computed(() => getCalendar.result.value?.calendar);

useHeadSafe({ title: () => `${$t('edit-object', { type: 'calendar' })} - ${$t('external-events')}` });
</script>
