export const columnWidthClass = 'w-[2.1875rem]'; // 35px
export const columnWidthInRem = 2.1875;

export const allocationHeightClass = 'h-3'; // 12px
export const allocationHeightInRem = 0.75;

export const allocationInnerContainerHeightClass = 'h-1.5'; // 6px
export const allocationInnerContainerHeightInRem = 0.375;

export const specializationIconSizeClass = 'size-3'; // 12px
export const specializationIconSizeInRem = 0.75;

export const insertPhaseButtonIconSizeClass = 'size-3'; // 12px
export const insertPhaseButtonIconSizeInRem = 0.75;

export const insertPhaseBarHeightClass = 'h-[0.3125rem]'; // 5px
export const insertPhaseBarHeightInRem = 0.3125;
