<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="identifiers" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('identifiers')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('identifiers')"
                    :save-button-disabled-explanation="saveButtonDisabledExplanation"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'identifiers', label: $t('identifiers') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'identifiers')"
                    @save="$emit('save', 'identifiers')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('identifiers') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <IdentifiersForm
                        v-if="showForm('identifiers')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                        @upload-icon="$emit('uploadIcon', $event)"
                        @delete-icon="$emit('deleteIcon')"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('name-short')"
                            :text="valueToString(series.nameShort)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('name-long')"
                            :text="valueToString(series.nameLong)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('subtitle')"
                            :text="valueToString(series.subtitle)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('number-short')"
                            :text="valueToString(series.numberShort)"
                            :help-text="$t('number-short-help')"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('foundation-year')"
                            :text="valueToString(series.foundationYear?.toString())"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes')"
                            :text="valueToString(series.notes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('description')"
                            :text="valueToString(series.description)"
                            :has-border-bottom="true"
                        />
                        <CardRowImage
                            :label="$t('logo')"
                            :label-hint="$t('img-hint-bigger-version')"
                            :image-url="icon?.url ?? ''"
                            :image-alt="`Logo ${series.nameShort}`"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="typeDefinitions" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('typeDefinitions')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('typeDefinitions')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'typeDefinitions', label: $t('type-definition') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'typeDefinitions')"
                    @save="$emit('save', 'typeDefinitions')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('type-definition') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <TypeDefinitionForm
                        v-if="showForm('typeDefinitions')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        @handle-select-change="$emit('handleSelectChange', $event)"
                        @handle-checkbox-input="$emit('handleCheckboxInput', $event)"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('event-category')"
                            :text="idToName(series.category, CATEGORY_ITEMS)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('event-type')"
                            :text="idToName(series.type, TYPE_ITEMS)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('is-public')"
                            :text="nullBooleanToString(series.isPublic)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('is-technical')"
                            :text="nullBooleanToString(series.isTechnical)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="eventPartners" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('eventPartners')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('eventPartners')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'eventPartners', label: $t('event-partners') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'eventPartners')"
                    @save="$emit('save', 'eventPartners')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('event-partners') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <PartnersForm
                        v-if="showForm('eventPartners')"
                        class="p-5"
                        :series="series"
                        :errors="errors"
                        :multilinks="multilinks"
                        @handle-form-field="$emit('handleFormField', $event)"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowLink
                            v-if="series.partnersOverviewLink"
                            :label="$t('partners-overview')"
                            :link-target="series.partnersOverviewLink"
                            :link-text="valueToString(series.partnersOverviewName)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            v-else
                            :label="$t('partners-overview')"
                            :text="valueToString(series.partnersOverviewName)"
                            :has-border-bottom="true"
                        />
                        <CardRowAttachmentGroup
                            v-if="organizers.length"
                            :label="$t('organizers')"
                            :items="organizers"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('organizers')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="executors.length"
                            :label="$t('executors')"
                            :items="executors"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('executors')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="sponsors.length"
                            :label="$t('sponsors')"
                            :items="sponsors"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('sponsors')" text="-" :has-border-bottom="true" />
                        <CardRowText
                            :label="$t('additional-partners')"
                            :text="valueToString(series.additionalPartners)"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowAttachmentGroup from '@/components/Card/CardRowAttachmentGroup.vue';
import CardRowImage from '@/components/Card/CardRowImage.vue';
import CardRowLink from '@/components/Card/CardRowLink.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import { toCardRowAttachmentLink } from '@/components/MultiLink/multilink';
import { FormLink } from '@/components/MultiLink/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { idToName, nullBooleanToString, valueToString } from '@/event/event';
import { CATEGORY_ITEMS, TYPE_ITEMS } from '@/event/forms/schemas';
import IdentifiersForm from '@/event/forms/series/IdentifiersForm.vue';
import PartnersForm from '@/event/forms/series/PartnersForm.vue';
import TypeDefinitionForm from '@/event/forms/series/TypeDefinitionForm.vue';
import { fileUploadLoadingKey, iconKey } from '@/event/keys';
import { EventSeriesDetails, EventSeriesDetailsErrors, EventSeriesMultiLinks } from '@/event/types';
import { useFluent } from 'fluent-vue';
import { computed, inject } from 'vue';

const props = defineProps<{
    series: EventSeriesDetails;
    errors: EventSeriesDetailsErrors;
    multilinks: EventSeriesMultiLinks;
    activeCard: ActiveCard;
}>();

const fluent = useFluent();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges', v: string): void;
    (e: 'save', v: string): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'handleSelectChange', v: { item: ComboboxItem | null; fieldName: string }): void;
    (e: 'handleCheckboxInput', v: { event: Event; fieldName: string }): void;
    (e: 'uploadIcon', v: Event): void;
    (e: 'deleteIcon'): void;
    (e: 'handleMultilink', v: { input: { event: Event; link: FormLink; fieldName: string }; field: string }): void;
    (e: 'deleteLink', v: { link: FormLink; fieldName: string }): void;
}>();

const activeCard = computed(() => props.activeCard);

const fileUploadLoading = inject(fileUploadLoadingKey);
const icon = inject(iconKey);

const saveButtonDisabledExplanation = computed(() => {
    if (fileUploadLoading?.value) {
        return fluent.$t('upload-in-progress');
    }
    return undefined;
});

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);

const organizers = computed(() => toCardRowAttachmentLink(props.series.organizers?.links?.nodes));
const executors = computed(() => toCardRowAttachmentLink(props.series.executors?.links?.nodes));
const sponsors = computed(() => toCardRowAttachmentLink(props.series.sponsors?.links?.nodes));
</script>
