import { $t } from '@/plugins/fluent';
import { z } from 'zod';

export const LinkSchema = z.object({
    name: z.string().min(1, $t('input-required')),
    to: z.string().min(1, $t('input-required')),
    description: z.string(),
});

export type LinkSchemaType = z.infer<typeof LinkSchema>;
