import {
    GetAreaChildrenForTreeDocument,
    GetAreaChildrenForTreeQuery,
    GetAreaTreePathDocument,
    GetBuildingChildrenForTreeDocument,
    GetBuildingChildrenForTreeQuery,
    GetBuildingTreePathDocument,
    GetBuildingTypeChildrenForTreeDocument,
    GetBuildingTypeChildrenForTreeQuery,
    GetLevelChildrenForTreeDocument,
    GetLevelChildrenForTreeQuery,
    GetLevelTreePathDocument,
    GetLocationTreeRootsDocument,
    GetPlotTreePathDocument,
    GetRoomChildrenForTreeDocument,
    GetRoomChildrenForTreeQuery,
    GetRoomPartitionTreePathDocument,
    GetRoomTreePathDocument,
    GetSiteChildrenForTreeDocument,
    GetSiteChildrenForTreeQuery,
    GetZoneChildrenForTreeDocument,
    GetZoneChildrenForTreeQuery,
    GetZoneTreePathDocument,
    GetZoneTypeChildrenForTreeDocument,
    GetZoneTypeChildrenForTreeQuery,
} from '@/generated/graphql';
import { useResult } from '@/utils/graphql';
import { ApolloClient, ObservableQuery } from '@apollo/client/core';
import { useApolloClient, useQuery } from '@vue/apollo-composable';
import { Ref, reactive, watch } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { Subscription } from 'zen-observable-ts';
import { iconForType, routeForLocation } from '../location';
import { LocationType, locationTypesCamelCase } from '../locationTypes';

export type LocationTreeItem = {
    id: string;
    key: string;
    title: string;
    subtitle?: string;
    type?: LocationType;
    icon?: string;
    hasChildren: boolean;
    to?: RouteLocationRaw;
    heading?: HeadingType;
};

type HeadingType = { type: LocationType; subType: string; parentId: string };

type AbstractLocation = {
    id: string;
    nameShort: string;
    nameLong: string;
    type?: { id: string; name: string } | undefined | null;
};

type ChildMap = Map<string, LocationTreeItem[]>;
type ChildQuery<T> = { query: ObservableQuery<T, any>; mapResult: (res: T) => LocationTreeItem[] };

export type ItemToOpenPath = { id: string; type: LocationType };
export type ItemToExpand = { id: string; type?: LocationType; key: string; heading?: HeadingType };

export function useLocationTree(itemToOpen: Ref<ItemToOpenPath | undefined>) {
    const client = useApolloClient().client;
    const root = _useRootNodes();
    const expandedKeys = reactive(new Set<string>());
    const childMap = reactive<ChildMap>(new Map());
    const loadingKeys = reactive(new Set<string>());
    const _queries = reactive(new Map<string, Subscription>());

    function expandItem(item: { id: string; key: string }) {
        expandedKeys.add(item.key);
        _loadChildren(item);
    }

    function collapseItem(item: { key: string }) {
        expandedKeys.delete(item.key);
        _queries.get(item.key)?.unsubscribe();
        _queries.delete(item.key);
    }

    async function _loadChildren(item: ItemToExpand) {
        const res = _makeChildQuery(client, item);
        if (!res) return;

        loadingKeys.add(item.key);
        const sub = res.query.subscribe({
            next: (x) => {
                if (x.data) {
                    const children = res.mapResult(x.data);
                    childMap.set(item.key, children);
                    children.filter((x) => !x.hasChildren).forEach((x) => childMap.set(x.key, []));
                    loadingKeys.delete(item.key);
                }
            },
        });

        _queries.set(item.key, sub);
    }

    function _useRootNodes() {
        const query = useQuery(GetLocationTreeRootsDocument);
        const nodes = useResult(query.result, <LocationTreeItem[]>[], (x) =>
            x.sites.nodes.map((site) =>
                _toTreeItem(site, 'Site', site.buildings.nodes.length + site.zones.nodes.length > 0),
            ),
        );

        watch(nodes, (n) => {
            n.filter((x) => !x.hasChildren).forEach((x) => childMap.set(x.key, []));
        });

        return nodes;
    }

    watch(
        itemToOpen,
        async (item) => {
            if (item) {
                const path = await _pathForItem(client, item);
                path.forEach(expandItem);
            }
        },
        { immediate: true },
    );

    return {
        root,
        expandedKeys,
        childMap,
        expandItem,
        collapseItem,
        loadingKeys,
    };
}

async function _pathForItem(client: ApolloClient<any>, item: ItemToOpenPath): Promise<ItemToExpand[]> {
    const toOpen = <ItemToExpand[]>[];

    function _makeQuery() {
        const variables = { id: item.id };
        switch (item.type!) {
            case 'Building':
                return client.query({ query: GetBuildingTreePathDocument, variables });
            case 'Level':
                return client.query({ query: GetLevelTreePathDocument, variables });
            case 'Room':
                return client.query({ query: GetRoomTreePathDocument, variables });
            case 'Zone':
                return client.query({ query: GetZoneTreePathDocument, variables });
            case 'Area':
                return client.query({ query: GetAreaTreePathDocument, variables });
            case 'Plot':
                return client.query({ query: GetPlotTreePathDocument, variables });
            case 'RoomPartition':
                return client.query({ query: GetRoomPartitionTreePathDocument, variables });
            default:
                return undefined;
        }
    }

    function _extractParent(position: any) {
        toOpen.push({
            id: position.id,
            key: _key(position.__typename, position.id),
            type: position.__typename,
        });
        if ('typeId' in position) {
            const parentId = position.site.id;
            const key = _headerKey(parentId, position.__typename, position.typeId);
            toOpen.push({
                id: key,
                key: key,
                heading: { type: position.__typename, subType: position.typeId, parentId },
            });
        }

        for (var typename of locationTypesCamelCase) {
            if (typename in position) {
                const parent = position[typename];
                if (parent) {
                    _extractParent(parent);
                }
            }
        }
    }

    const query = _makeQuery();
    if (!query) return toOpen;

    const res = (await query).data;
    if (!res) return toOpen;

    _extractParent(res);

    // remove the first 2 items:
    // the first is a dummy wrapper from the gql query
    // the second is the item itself (we don't want to open it on click)
    toOpen.splice(0, 2);

    return toOpen;
}

function _makeChildQuery(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<any> | undefined {
    if (item.heading) {
        switch (item.heading.type) {
            case 'Building':
                return _loadBuildingTypeChildren(client, item, item.heading);
            case 'Zone':
                return _loadZoneTypeChildren(client, item, item.heading);
        }
    }

    switch (item.type) {
        case 'Site':
            return _loadSiteChildren(client, item);
        case 'Building':
            return _loadBuildingChildren(client, item);
        case 'Level':
            return _loadLevelChildren(client, item);
        case 'Zone':
            return _loadZoneChildren(client, item);
        case 'Area':
            return _loadAreaChildren(client, item);
        case 'Room':
            return _loadRoomChildren(client, item);
        case 'Plot':
        case 'RoomPartition':
        case undefined:
            // nothing to do
            return undefined;
    }
}

function _loadSiteChildren(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<GetSiteChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetSiteChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const buildingTypes = x.buildingTypes?.nodes ?? [];
            const zoneTypes = x.zoneTypes?.nodes ?? [];

            return [
                ...buildingTypes
                    .filter((x) => x.buildingsByTypeId.nodes.length > 0)
                    .map((x) => ({
                        id: _headerKey(item.id, 'Building', x.id),
                        key: _headerKey(item.id, 'Building', x.id),
                        title: x.name,
                        hasChildren: x.buildingsByTypeId.nodes.length > 0,
                        icon: undefined,
                        heading: <HeadingType>{ type: 'Building', subType: x.id, parentId: item.id },
                    })),
                ...zoneTypes
                    .filter((x) => x.zonesByTypeId.nodes.length > 0)
                    .map((x) => ({
                        id: _headerKey(item.id, 'Zone', x.id),
                        key: _headerKey(item.id, 'Zone', x.id),
                        title: x.name,
                        hasChildren: x.zonesByTypeId.nodes.length > 0,
                        icon: undefined,
                        heading: <HeadingType>{ type: 'Zone', subType: x.id, parentId: item.id },
                    })),
            ];
        },
    };
}

function _loadBuildingTypeChildren(
    client: ApolloClient<any>,
    item: ItemToExpand,
    header: HeadingType,
): ChildQuery<GetBuildingTypeChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetBuildingTypeChildrenForTreeDocument,
            variables: { siteId: header.parentId, typeId: header.subType },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const buildings = x.buildingType?.buildingsByTypeId?.nodes ?? [];
            return buildings.map((b) => _toTreeItem(b, 'Building', b.levels.nodes.length > 0));
        },
    };
}

function _loadZoneTypeChildren(
    client: ApolloClient<any>,
    item: ItemToExpand,
    header: HeadingType,
): ChildQuery<GetZoneTypeChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetZoneTypeChildrenForTreeDocument,
            variables: { siteId: header.parentId, typeId: header.subType },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const zones = x.zoneType?.zonesByTypeId?.nodes ?? [];
            return zones.map((z) => _toTreeItem(z, 'Zone', z.areas.nodes.length > 0));
        },
    };
}

function _loadBuildingChildren(
    client: ApolloClient<any>,
    item: ItemToExpand,
): ChildQuery<GetBuildingChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetBuildingChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const levels = x.building?.levels?.nodes ?? [];
            return levels.map((l) => _toTreeItem(l, 'Level', l.rooms.nodes.length > 0));
        },
    };
}

function _loadLevelChildren(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<GetLevelChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetLevelChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const rooms = x.level?.rooms?.nodes ?? [];

            return [...rooms.map((r) => _toTreeItem(r, 'Room', r.roomPartitions.nodes.length > 0))];
        },
    };
}

function _loadZoneChildren(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<GetZoneChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetZoneChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const areas = x.zone?.areas?.nodes ?? [];
            return areas.map((a) => _toTreeItem(a, 'Area', a.plots.nodes.length > 0));
        },
    };
}

function _loadAreaChildren(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<GetAreaChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetAreaChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const plots = x.area?.plots?.nodes ?? [];
            return plots.map((a) => _toTreeItem(a, 'Plot'));
        },
    };
}

function _loadRoomChildren(client: ApolloClient<any>, item: ItemToExpand): ChildQuery<GetRoomChildrenForTreeQuery> {
    return {
        query: client.watchQuery({
            query: GetRoomChildrenForTreeDocument,
            variables: { id: item.id },
            notifyOnNetworkStatusChange: true,
        }),
        mapResult: (x) => {
            const roomPartitions = x.room?.roomPartitions?.nodes ?? [];
            return roomPartitions.map((a) => _toTreeItem(a, 'RoomPartition'));
        },
    };
}

function _toTreeItem(location: AbstractLocation, type: LocationType, hasChildren: boolean = false): LocationTreeItem {
    return {
        id: location.id,
        title: location.nameShort,
        subtitle: location.type?.name,
        type: type,
        key: _key(type, location.id),
        to: routeForLocation(type, location.id),
        hasChildren: hasChildren,
        icon: iconForType(type),
    };
}

const _key = (type: LocationType, id: string) => type + id;
const _headerKey = (itemId: string, locationType: LocationType, typeId: string) =>
    `${typeId}-bytype-${locationType}-${itemId}`;
