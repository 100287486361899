<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M6.667 14.667H2c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.942v-4H2v4h4.667v1.334Zm2.666 0v-1.334H14v-4h1.333v4c0 .367-.13.68-.391.942a1.284 1.284 0 0 1-.942.392H9.333Zm-8.666-8v-4c0-.367.13-.68.391-.942.262-.261.575-.392.942-.392h4.667v1.334H2v4H.667Zm13.333 0v-4H9.333V1.333H14c.367 0 .68.13.942.392.26.261.391.575.391.942v4H14Z"
        />
    </svg>
</template>

<script setup lang="ts">
type AreaIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<AreaIconProps>();
</script>
