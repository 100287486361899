<template>
    <nav :aria-labelledby="headlineId">
        <h2 :id="headlineId" class="sr-only">{{ props.headline }}</h2>

        <ul role="tablist" aria-orientation="horizontal" class="flex flex-row gap-2 items-center">
            <TabNavigationItem
                v-for="(item, index) in props.items"
                :key="index"
                :target="item.target"
                :route-name="item.routeName"
                :is-focused="focusedTabIndex === index"
                :is-manual-focus-change="isManualFocusChange"
                :tab-panel-id="props.tabPanelId"
                :disabled-explanation="item.isDisabled ? props.tabsDisabledText : undefined"
                @focus-first-tab="focusFirstTab"
                @focus-last-tab="focusLastTab"
                @focus-previous-tab="focusPreviousTab"
                @focus-next-tab="focusNextTab"
                @focus-tab="(isManualFocusChange: boolean) => focusTab(index, isManualFocusChange)"
                @focus-tab-panel="emit('focus-tab-panel')"
            >
                {{ item.label }}
            </TabNavigationItem>
        </ul>
    </nav>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed, ref } from 'vue';
import TabNavigationItem from './TabNavigationItem.vue';
import { TabNavigationItem as TabNavigationItemType } from './types';

type TabNavigationProps = {
    headline: string;
    items: TabNavigationItemType[];
    tabPanelId: string;
    tabsDisabledText: string;
};

const props = defineProps<TabNavigationProps>();
const emit = defineEmits<{
    (e: 'focus-tab-panel'): void;
}>();
const headlineId = nanoid();

const lastTabIndex = computed(() => props.items.filter((item) => !item.isDisabled).length - 1);
const focusedTabIndex = ref(-1);
const isManualFocusChange = ref(false);

function focusFirstTab() {
    focusedTabIndex.value = 0;
    isManualFocusChange.value = true;
}

function focusLastTab() {
    focusedTabIndex.value = lastTabIndex.value;
    isManualFocusChange.value = true;
}

function focusPreviousTab() {
    if (focusedTabIndex.value > 0) {
        focusedTabIndex.value = focusedTabIndex.value - 1;
        isManualFocusChange.value = true;
    }
}

function focusNextTab() {
    if (focusedTabIndex.value < lastTabIndex.value) {
        focusedTabIndex.value = focusedTabIndex.value + 1;
        isManualFocusChange.value = true;
    }
}

function focusTab(tabIndex: number, isManualChange: boolean) {
    focusedTabIndex.value = tabIndex;
    isManualFocusChange.value = isManualChange;
}
</script>
