<template>
    <div v-if="plot">
        <TopBar :title="plot.nameShort" :breadcrumbs="locationBreadcrumbs(plot)" transparent />
        <LocationUpdateForm :location="plot" :location-types="plotTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetPlotForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ plotId: string }>();
const query = useQuery(GetPlotForEditDocument, { id: props.plotId });
const plot = useResult(query.result, undefined, (x) => x.plot);
const plotTypes = useResult(query.result, [], (x) => x.plotTypes.nodes);
</script>
