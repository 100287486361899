import auth from '@/auth/auth';
import { ActiveCard } from '@/components/Card/types';
import { useFluent } from 'fluent-vue';
import { Ref } from 'vue';

export function useCard(activeCard: Ref<ActiveCard>) {
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();

    function getCardStatus(id: string) {
        return activeCard.value.id === id ? activeCard.value.status : 'View';
    }

    function showForm(id: string) {
        return getCardStatus(id) !== 'View';
    }

    function editButtonDisabledMessage(key: string) {
        if (!isAdmin) return fluent.$t('no-permission-to-edit');
        if (activeCard.value.id !== '' && activeCard.value.id !== key) return fluent.$t('other-card-is-editing');
        return '';
    }

    return {
        getCardStatus,
        showForm,
        editButtonDisabledMessage,
    };
}
