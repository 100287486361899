<template>
    <div v-if="event" class="space-y-4">
        <FlexibleCard headline-id="baseData" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('baseData')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('baseData')"
                    :save-button-disabled-explanation="saveButtonDisabledExplanation"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'baseData', label: $t('base-data') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'baseData')"
                    @save="$emit('save', 'baseData')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('base-data') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <BaseDataForm
                        v-if="showForm('baseData')"
                        class="p-5"
                        :event="event"
                        :errors="errors"
                        @handle-form-field="$emit('handleFormField', $event)"
                        @upload-icon="$emit('uploadIcon', $event)"
                        @delete-icon="$emit('deleteIcon')"
                    />
                    <div v-else>
                        <CardRowText
                            :label="$t('name-short')"
                            :text="valueToString(event.nameShort)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('name-long')"
                            :text="valueToString(event.nameLong)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('subtitle')"
                            :text="valueToString(event.subtitle)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('execution-year')"
                            :text="valueToString(event.executionYear?.toString())"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('description')"
                            :text="valueToString(event.description)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('annual-specifics')"
                            :text="valueToString(event.annualSpecifics)"
                            :has-border-bottom="true"
                        />
                        <CardRowImage
                            :label="$t('logo')"
                            :label-hint="$t('img-hint-bigger-version')"
                            :image-url="icon?.url ?? ''"
                            :image-alt="`Logo ${event.nameShort}`"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowImage from '@/components/Card/CardRowImage.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/event/event';
import BaseDataForm from '@/event/forms/event/BaseDataForm.vue';
import { fileUploadLoadingKey, iconKey } from '@/event/keys';
import { EventUpdate, EventUpdateErrors } from '@/event/types';
import { useFluent } from 'fluent-vue';
import { computed, inject } from 'vue';

const props = defineProps<{
    event: EventUpdate;
    errors: EventUpdateErrors;
    activeCard: ActiveCard;
}>();

const fluent = useFluent();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges', v: string): void;
    (e: 'save', v: string): void;
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'handleCheckboxInput', v: { event: Event; fieldName: string }): void;
    (e: 'uploadIcon', v: Event): void;
    (e: 'deleteIcon'): void;
}>();

const activeCard = computed(() => props.activeCard);

const fileUploadLoading = inject(fileUploadLoadingKey);
const icon = inject(iconKey);

const saveButtonDisabledExplanation = computed(() => {
    if (fileUploadLoading?.value) {
        return fluent.$t('upload-in-progress');
    }
    return undefined;
});

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);
</script>
