<template>
    <div v-if="room">
        <TopBar :title="room.nameShort" :breadcrumbs="locationBreadcrumbs(room)" transparent />
        <LocationUpdateForm :location="room" :location-types="roomTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetRoomForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ roomId: string }>();

const query = useQuery(GetRoomForEditDocument, { id: props.roomId });
const room = useResult(query.result, undefined, (x) => x.room);
const roomTypes = useResult(query.result, [], (x) => x.roomTypes.nodes);
</script>
