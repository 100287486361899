import { AllocationPhaseInput, AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationLocationType, AllocationType } from '@/components/Timeline/Timeline.types';
import {
    CreateEventAllocationAreaDocument,
    CreateEventAllocationRoomDocument,
    CreateProjectAllocationAreaDocument,
    CreateProjectAllocationRoomDocument,
} from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { Ref, toValue } from 'vue';
import { getUsageIdFromName } from '../allocation';

export function useCreateAllocation(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    editableVariant: Ref<TimelineVariant | undefined>,
    usages: Ref<AllocationUsage[]>,
    allocationType: AllocationType,
) {
    const mutations = {
        Project: {
            Room: useMutation(CreateProjectAllocationRoomDocument),
            Area: useMutation(CreateProjectAllocationAreaDocument),
        },
        Event: {
            Room: useMutation(CreateEventAllocationRoomDocument),
            Area: useMutation(CreateEventAllocationAreaDocument),
        },
        None: { Room: undefined, Area: undefined },
    } satisfies Record<AllocationType, { Room: unknown; Area: unknown }>;

    async function onCreateAllocation(
        locationId: string,
        startDateTime: DateTime,
        endDateTime: DateTime,
        allocationVariantId: string,
        phases: AllocationPhaseInput[],
        allocationLocationType: AllocationLocationType,
        onDone: (isSuccess: boolean) => void,
    ) {
        phases = phases.map((p) => ({ ...p, usageId: getUsageIdFromName(toValue(usages), p.usageId, allocationType) }));
        try {
            const createMutation = mutations[allocationType][allocationLocationType];
            if (createMutation) {
                const result = await createMutation.mutate({
                    start: startDateTime.toISO(),
                    end: endDateTime.toISO(),
                    locationId,
                    variantId: allocationVariantId,
                    phases,
                });

                if (result?.data && editableVariant.value) {
                    const allocation = result.data.create?.allocation;
                    if (allocation) {
                        const newValue = variantsRef.value.map((v) => {
                            if (v.id === allocationVariantId) {
                                if (allocationLocationType === 'Room') {
                                    return {
                                        ...v,
                                        rooms: {
                                            nodes: [...v.rooms.nodes, allocation],
                                        },
                                    };
                                }
                                if (allocationLocationType === 'Area') {
                                    return {
                                        ...v,
                                        areas: {
                                            nodes: [...v.areas.nodes, allocation],
                                        },
                                    };
                                }
                            }
                            return v;
                        });
                        setVariantsRef(newValue);
                    }
                }
                onDone(true);
            }
        } catch (error) {
            onDone(false);
            throw new Error('Could not create new allocation');
        }
    }

    return {
        onCreateAllocation,
    };
}
