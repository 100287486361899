<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M12.8 14 8.617 9.817l.95-.95 4.183 4.183-.95.95Zm-9.683 0-.95-.95L7 8.217 5.217 6.433l-.384.384-.733-.734V7.5l-.4.4-2.033-2.033.4-.4H3.5l-.8-.8 2.183-2.184c.19-.189.395-.316.617-.383.222-.067.467-.1.733-.1.267 0 .511.047.734.142.222.094.427.236.616.425L5.8 4.35l.8.8-.4.4 1.733 1.733L9.967 5.25a1.853 1.853 0 0 1-.209-.5 2.336 2.336 0 0 1-.075-.6c0-.589.214-1.097.642-1.525a2.083 2.083 0 0 1 1.525-.642c.167 0 .308.017.425.05a.946.946 0 0 1 .292.134L11.15 3.583l1.25 1.25 1.417-1.416a1.476 1.476 0 0 1 .2.766 2.08 2.08 0 0 1-.642 1.525 2.083 2.083 0 0 1-1.525.642c-.2 0-.372-.014-.517-.042a1.77 1.77 0 0 1-.4-.125L3.117 14Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ConstructionIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ConstructionIconProps>();
</script>
