<!-- A combobox with an "add" button next to it. It has no bindable model - just an onSelect event -->
<template>
    <div class="flex space-x-2">
        <Combobox v-model="selectedRef" as="div" :disabled="disabled">
            <div class="relative">
                <ComboboxInput
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    autocomplete="off"
                    :display-value="(i: any) => _toString(items[i])"
                    :disabled="disabled"
                    @change="query = $event.target.value"
                    @keyup.enter.stop="onEnter"
                />
                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions
                    v-if="filteredItems.length > 0"
                    class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                >
                    <ComboboxOption
                        v-for="(item, index) in filteredItems"
                        :key="_toString(item)"
                        v-slot="{ active, selected }"
                        :value="index"
                        as="template"
                    >
                        <li
                            class="text-left"
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-primary-600 text-white' : 'text-gray-900',
                            ]"
                        >
                            <slot name="item" :item="item" :selected="selected">
                                <span :class="['block truncate', selected && 'font-medium']">
                                    {{ _toString(item) }}
                                </span>

                                <span
                                    v-if="selected"
                                    :class="[
                                        'absolute inset-y-0 right-0 flex items-center pr-4',
                                        active ? 'text-white' : 'text-primary-600',
                                    ]"
                                >
                                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                </span>
                            </slot>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>
        <BaseButton icon="add" :enabled="selectedRef !== undefined" size="xs" @click="_pick(selectedRef!)">
        </BaseButton>
    </div>
</template>

<script setup lang="ts">
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { CheckIcon, SelectorIcon } from '@heroicons/vue/solid';
import { computed, ref } from 'vue';
import BaseButton from './BaseButton.vue';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ItemType = Record<string, any> | string | number | undefined;

type Props = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    items: readonly any[];
    itemText?: string;
    disabled?: boolean;
};

const props = defineProps<Props>();
const emit = defineEmits<{ (e: 'select', type: ItemType): void }>();

const selectedRef = ref<number | undefined>(undefined);
const query = ref('');

const filteredItems = computed(() =>
    query.value === ''
        ? props.items
        : props.items.filter((item) => {
              return _toString(item).toLowerCase().includes(query.value.toLowerCase());
          }),
);

function onEnter() {
    if (selectedRef.value !== undefined) {
        _pick(selectedRef.value);
    }
}

function _pick(idx: number) {
    emit('select', props.items[idx]);
    query.value = '';
    selectedRef.value = undefined;
}

function _toString(item: ItemType) {
    if (!item) return '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (props.itemText) return (item as any)[props.itemText] as string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (item as any).title ?? '';
}
</script>
