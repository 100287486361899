import { DateTime } from 'luxon';
import { computed } from 'vue';

// Within our app, there are 3 different date formats. All of them are variations of the iso format:
// - GQL uses the full iso format
// - Inputs of type "date" use the "iso day" format
// - Inputs of type "datetime-local" use the full iso format without timezone

type IsoDateString = string;
type Date = DateTime | IsoDateString;

const _timezone = 'Europe/Berlin';
const _locale = 'de';

// Format functions
export function formatDate(date?: Date, format: string = 'dd.MM.yy', placeholder: string = '-'): string {
    if (!date) return placeholder;

    const realDate = typeof date === 'string' ? toDateTime(date) : date;
    _validateTimeZone(realDate);
    return realDate.toFormat(format, { locale: _locale });
}

export function formatDateLong(date?: Date): string {
    return formatDate(date, 'dd.MM.yyyy');
}

export function formatWeekDay(date: DateTime) {
    const names = { 1: 'Mo', 2: 'Di', 3: 'Mi', 4: 'Do', 5: 'Fr', 6: 'Sa', 7: 'So' } as const;
    return names[date.weekday];
}

export function formatDateRange(from: Date, to: Date, placeholder: string = '-', separator: string = ' - '): string {
    if (!from && !to) return placeholder;
    if (!to) return `${formatDate(from)} - ∞`;
    if (!from) return `∞ - ${formatDate(from)}`;
    return `${formatDate(from)}${separator}${formatDate(to)}`;
}

// Create datetime objects
export function nowGermany(): DateTime {
    return DateTime.now().setZone(_timezone);
}

export function germanDateFromMillis(millis: number): DateTime {
    return DateTime.fromMillis(millis, { zone: _timezone });
}

// Conversion between date types
export function toDateTime(date: Date): DateTime {
    const isDateTime = typeof date !== 'string';
    if (isDateTime) {
        _validateTimeZone(date);
        return date;
    }

    return DateTime.fromISO(date, { zone: _timezone });
}

export function toIso(date: Date): IsoDateString {
    return toDateTime(date).toISO();
}

export function toHtmlDate(date: Date): string {
    return toDateTime(date).toISODate();
}

export function toHtmlDateTimeLocal(date: Date) {
    const realDate = toDateTime(date);
    _validateTimeZone(realDate);
    return realDate.toISO({
        suppressMilliseconds: true,
        suppressSeconds: true,
        includePrefix: true,
        includeOffset: false,
    });
}

// Helper
export function isSameDay(date1: Date, date2: Date): boolean {
    return toDateTime(date1).startOf('day').equals(toDateTime(date2).startOf('day'));
}

export function useDateFieldModel({ get, set }: { get: () => DateTime | undefined; set: (date: DateTime) => void }) {
    return computed({
        get: () => {
            const val = get();
            return !!val ? toHtmlDate(val) : '';
        },
        set: (x) => set(toDateTime(x)),
    });
}

function _validateTimeZone(date: DateTime) {
    // We convert all dates to german timezone during parse/creation. If a date is not in german timezone during output, this is a bug.
    if (import.meta.env.DEV && date.zoneName !== _timezone) {
        console.warn(`Tried to format a non-german date: ${date}`);
    }
}
