<!-- Using the Disclosure component here from headlessui: https://headlessui.com/vue/disclosure -->
<template>
    <div class="w-full px-4 pt-4">
        <div class="mx-auto w-full max-w-md rounded-4 bg-white">
            <Disclosure v-for="item in items" :key="item.id" v-slot="{ open }" as="div">
                <DisclosureButton
                    class="flex w-full justify-between p-2 text-left hover:cursor-pointer hover:bg-primary/[.12] hover:text-primary"
                >
                    <span class="text-sm">{{ item.label }}</span>
                    <ChevronRightIcon
                        v-if="item.children?.length"
                        :class="open ? 'rotate-90 transform' : ''"
                        class="h-5 w-5"
                    />
                </DisclosureButton>
                <DisclosurePanel>
                    <SidePanelList>
                        <LocationListTile
                            v-for="child in item.children"
                            :key="child.id"
                            :location="child"
                        ></LocationListTile>
                    </SidePanelList>
                </DisclosurePanel>
            </Disclosure>
        </div>
    </div>
</template>

<script lang="ts" setup>
import SidePanelList from '@/components/SidePanelList.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';
import { useLocationSearch } from '../location';
import LocationListTile from './LocationListTile.vue';

const props = defineProps<{ search: string }>();

// TODO add loading spinner
const { items, loading } = useLocationSearch(computed(() => props.search));
</script>
