<template>
    <TopBar :title="$t('user-overview')" />
</template>
<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';

useHeadSafe({ title: () => `${$t('user-overview')} - ${$t('users')}}` });
</script>
