<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3.767 13.333c-.311 0-.573-.105-.784-.316a1.064 1.064 0 0 1-.316-.784c0-.31.105-.572.316-.783.211-.211.473-.317.784-.317.31 0 .572.106.783.317.211.211.317.472.317.783 0 .311-.106.572-.317.784a1.064 1.064 0 0 1-.783.316Zm4.233 0c-.311 0-.572-.105-.783-.316a1.064 1.064 0 0 1-.317-.784c0-.31.106-.572.317-.783.21-.211.472-.317.783-.317.311 0 .572.106.783.317.211.211.317.472.317.783 0 .311-.106.572-.317.784a1.064 1.064 0 0 1-.783.316Zm4.233 0c-.31 0-.572-.105-.783-.316a1.064 1.064 0 0 1-.317-.784c0-.31.106-.572.317-.783.211-.211.472-.317.783-.317.311 0 .572.106.784.317.21.211.316.472.316.783 0 .311-.105.572-.316.784a1.064 1.064 0 0 1-.784.316ZM3.767 9.1c-.311 0-.573-.106-.784-.317A1.064 1.064 0 0 1 2.667 8c0-.311.105-.572.316-.783.211-.211.473-.317.784-.317.31 0 .572.106.783.317.211.21.317.472.317.783 0 .311-.106.572-.317.783a1.064 1.064 0 0 1-.783.317ZM8 9.1c-.311 0-.572-.106-.783-.317A1.064 1.064 0 0 1 6.9 8c0-.311.106-.572.317-.783.21-.211.472-.317.783-.317.311 0 .572.106.783.317.211.21.317.472.317.783 0 .311-.106.572-.317.783A1.064 1.064 0 0 1 8 9.1Zm4.233 0c-.31 0-.572-.106-.783-.317A1.064 1.064 0 0 1 11.133 8c0-.311.106-.572.317-.783.211-.211.472-.317.783-.317.311 0 .572.106.784.317.21.21.316.472.316.783 0 .311-.105.572-.316.783a1.064 1.064 0 0 1-.784.317ZM3.767 4.867c-.311 0-.573-.106-.784-.317a1.064 1.064 0 0 1-.316-.783c0-.311.105-.573.316-.784a1.07 1.07 0 0 1 .784-.316c.31 0 .572.105.783.316.211.211.317.473.317.784 0 .31-.106.572-.317.783a1.064 1.064 0 0 1-.783.317Zm4.233 0c-.311 0-.572-.106-.783-.317a1.064 1.064 0 0 1-.317-.783c0-.311.106-.573.317-.784.21-.21.472-.316.783-.316.311 0 .572.105.783.316.211.211.317.473.317.784 0 .31-.106.572-.317.783A1.064 1.064 0 0 1 8 4.867Zm4.233 0c-.31 0-.572-.106-.783-.317a1.064 1.064 0 0 1-.317-.783c0-.311.106-.573.317-.784.211-.21.472-.316.783-.316.311 0 .572.105.784.316.21.211.316.473.316.784 0 .31-.105.572-.316.783a1.064 1.064 0 0 1-.784.317Z"
        />
    </svg>
</template>

<script setup lang="ts">
type AppsIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<AppsIconProps>();
</script>
