<template>
    <TopBar :title="site?.nameShort ?? ''">
        <template #actions>
            <DropDownSelector :options="actions" :disabled="!isAdmin" />
        </template>
    </TopBar>
    <GQLLoading
        :query="siteQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'site', id: siteId })"
    >
        <LocationDetails
            v-if="site"
            :location="site"
            :toEdit="{ name: 'site-edit', params: { siteId: siteId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import DropDownSelector from '@/components/DropDownSelector.vue';
import TopBar from '@/components/TopBar.vue';
import { GetSiteForDetailsPageDocument } from '@/generated/graphql';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { $t } from '@/plugins/fluent';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';

const props = defineProps<{ siteId: string }>();
var siteQuery = useQuery(GetSiteForDetailsPageDocument, () => ({ id: props.siteId }));
const site = useResult(siteQuery.result, undefined, (x) => x.site);
const fluent = useFluent();
const actions = [
    {
        title: fluent.$t('building'),
        description: fluent.$t('building-add-to-site'),
        to: { name: 'building-add', params: { siteId: props.siteId } },
    },
    {
        title: fluent.$t('zone'),
        description: fluent.$t('zone-add-to-site'),
        to: { name: 'zone-add', params: { siteId: props.siteId } },
    },
];
const isAdmin = auth.isAdmin();
</script>
