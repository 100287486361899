<template>
    <BaseForm>
        <template #fields>
            <TextField
                :value="event.nameShort"
                :label="$t('name-short')"
                :error-messages="errors.nameShort"
                @input="$emit('handleFormField', { event: $event, fieldName: 'nameShort' })"
            />
            <TextField
                :value="event.nameLong"
                :label="$t('name-long')"
                :error-messages="errors.nameLong"
                @input="$emit('handleFormField', { event: $event, fieldName: 'nameLong' })"
            />
            <TextAreaField
                :value="event.subtitle"
                :label="$t('subtitle')"
                :error-messages="errors.subtitle"
                @input="$emit('handleFormField', { event: $event, fieldName: 'subtitle' })"
            />
            <NumberField
                :value="event.executionYear ?? ''"
                :label="$t('execution-year')"
                :error-messages="errors.executionYear"
                @input="$emit('handleFormField', { event: $event, fieldName: 'executionYear' })"
            />
            <TextAreaField
                :value="event.description"
                :label="$t('description')"
                :error-messages="errors.description"
                @input="$emit('handleFormField', { event: $event, fieldName: 'description' })"
            />
            <TextAreaField
                :value="event.annualSpecifics"
                :label="$t('annual-specifics')"
                :error-messages="errors.annualSpecifics"
                @input="$emit('handleFormField', { event: $event, fieldName: 'annualSpecifics' })"
            />
            <FileUpload
                :label="$t('logo')"
                :url="icon?.url"
                :loading="fileUploadLoading"
                :clear-disabled-explanation="clearDisabledExplanation"
                :clear-button-text="clearButtonText"
                @change="$emit('uploadIcon', $event)"
                @clear="$emit('deleteIcon')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import FileUpload from '@/components/Form/FileUpload.vue';
import NumberField from '@/components/Form/NumberField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { fileUploadLoadingKey, iconKey } from '@/event/keys';
import { EventUpdate, EventUpdateErrors } from '@/event/types';
import { useFluent } from 'fluent-vue';
import { computed, inject } from 'vue';

defineProps<{ event: EventUpdate; errors: EventUpdateErrors }>();
defineEmits<{
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'uploadIcon', v: Event): void;
    (e: 'deleteIcon'): void;
}>();
const fluent = useFluent();

const fileUploadLoading = inject(fileUploadLoadingKey);
const icon = inject(iconKey);
const clearDisabledExplanation = computed(() => (icon?.value.fromParent ? fluent.$t('icon-from-parent') : undefined));
const clearButtonText = computed(() =>
    icon?.value.fromParent ? fluent.$t('delete-series-icon') : fluent.$t('delete-event-icon'),
);
</script>
