<template>
    <BaseForm v-if="project && errors && multilinks">
        <template #fields>
            <TextField
                :value="project.nameShort"
                :label="$t('name-short')"
                :errorMessages="errors.nameShort"
                @input="onInput($event, 'nameShort')"
            />
            <TextField
                :value="project.nameLong"
                :label="$t('name-long')"
                :errorMessages="errors.nameLong"
                @input="onInput($event, 'nameLong')"
            />
            <TextField
                :value="project.subtitle"
                :label="$t('notes')"
                :errorMessages="errors.subtitle"
                @input="onInput($event, 'subtitle')"
            />
            <NumberField
                :value="project.executionYear ?? ''"
                :label="$t('execution-year')"
                :errorMessages="errors.executionYear"
                @input="onInput($event, 'executionYear')"
            />
            <TextAreaField
                :value="project.description"
                :label="$t('description')"
                :errorMessages="errors.description"
                @input="onInput($event, 'description')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import NumberField from '@/components/Form/NumberField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { formFunctions, projectData } from '@/project/keys';
import { ProjectIdentifiersSchema, ProjectIdentifiersSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectData);
const project = data?.project;
const errors = data?.errors;
const multilinks = data?.multilinks;

const functions = inject(formFunctions);
const formFields = ProjectIdentifiersSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectIdentifiersSchemaType;
    const schema = ProjectIdentifiersSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
