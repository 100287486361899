<template>
    <side-panel :title="$t('title')">
        <template #actions>
            <Button
                size="Base"
                variant="Info-Strong"
                :disabled-explanation="createSeriesDisabledExplanation"
                :icon-description="$t('event-series-create')"
                @click="goToCreateSeries()"
            >
                <template #icon><PlusIcon /></template>
            </Button>
        </template>
        <search-field v-model="searchEvent"></search-field>
        <event-list v-if="filteredEvents.length" :events="filteredEvents"></event-list>
        <event-tree v-else :series="series"> </event-tree>
    </side-panel>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import PlusIcon from '@/components/Icon/PlusIcon.vue';
import SearchField from '@/components/SearchField.vue';
import SidePanel from '@/components/SidePanel.vue';
import { GetEventsDocument } from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref } from 'vue';
import EventList from '../components/EventList.vue';
import EventTree from '../components/EventTree.vue';
import { goToCreateSeries, toEventItem, toSeriesItem } from '../event';
const searchEvent = ref('');
const loadEvents = useQuery(GetEventsDocument);
const loadedEvents = computed(() => loadEvents.result.value?.events?.nodes);
const loadedSeries = computed(() => loadEvents.result.value?.eventSerieses?.nodes);
const fluent = useFluent();

// TODO simply search in the already loaded events for now. Basic replacement for
// the autocomplete vuetify search field
const filteredEvents = computed(() => {
    if (loadedEvents.value && searchEvent.value) {
        return loadedEvents.value
            .filter((e) => e.nameShort.toLocaleLowerCase().includes(searchEvent.value.toLocaleLowerCase()))
            .map((e) => toEventItem(e));
    }
    return [];
});

const series = computed(() => {
    if (loadedSeries.value) {
        return loadedSeries.value.map((s) => toSeriesItem(s, loadedEvents.value));
    }
    return [];
});

const createSeriesDisabledExplanation = computed(() =>
    auth.isAdmin() ? undefined : fluent.$t('no-permission-to-create'),
);
</script>
