<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(title || props['aria-labelledby'])"
        :tabindex="props['aria-labelledby'] ? 0 : undefined"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M5.9 11.883 8 10.617l2.1 1.283-.55-2.4 1.85-1.6-2.433-.217L8 5.417l-.967 2.25-2.433.216L6.45 9.5l-.55 2.383Zm-2.017 2.784 1.084-4.684-3.634-3.15 4.8-.416L8 2l1.867 4.417 4.8.416-3.634 3.15 1.084 4.684L8 12.183l-4.117 2.484Z"
        />
    </svg>
</template>

<script setup lang="ts">
type StarEmptyIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<StarEmptyIconProps>();
</script>
