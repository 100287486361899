<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8.167 2.833c0 .323-.114.598-.342.825A1.124 1.124 0 0 1 7 4a1.11 1.11 0 0 1-.408-.075 1.371 1.371 0 0 1-.359-.208c-.3.177-.55.405-.75.683-.2.278-.305.589-.316.933H14L13.333 10h-2.9V9h2.084c.066-.444.13-.889.191-1.333.061-.445.125-.89.192-1.334H3.1c.067.445.13.89.192 1.334.06.444.125.889.191 1.333h2.084v1h-2.9L2 5.333h2.167a2.66 2.66 0 0 1 .475-1.483A3.247 3.247 0 0 1 5.833 2.8a1.14 1.14 0 0 1 .35-.808c.223-.217.495-.325.817-.325.322 0 .597.114.825.341.228.228.342.503.342.825ZM6.233 13h3.534l.466-4.667H5.767L6.233 13Zm-.9 1-.55-5.567a.99.99 0 0 1 .267-.766.95.95 0 0 1 .733-.334h4.434a.95.95 0 0 1 .733.334.99.99 0 0 1 .267.766L10.667 14H5.333Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ConferenceIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ConferenceIconProps>();
</script>
