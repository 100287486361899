<template>
    <div
        class="py-2 px-4 items-start grid gap-4"
        :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }"
        :style="gridTemplateColumnsStyle"
    >
        <strong class="text-sm font-medium text-gray-500 flex flex-row items-center gap-1">
            {{ props.label }}
            <HelpButton v-if="props.helpText" :text="props.helpText" />
        </strong>

        <p class="text-sm text-gray-900 flex justify-between">
            <slot />
        </p>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';

type CardLabelButtonProps = {
    label: string;
    labelWidthInRem?: number;
    helpText?: string;
    buttonLabel: string;
    hasBorderBottom: boolean;
};

const props = defineProps<CardLabelButtonProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: ${props.labelWidthInRem ?? 15}rem 1fr;`;
});
</script>
