import { ref } from 'vue';

export function useImageError() {
    const hasLoadingImageFailed = ref(false);

    function onImageError(event: Event) {
        hasLoadingImageFailed.value = true;
        const image = event.target as HTMLImageElement;
        throw new Error(`Error on image: ${image.src}`);
    }

    function setHasLoadingImageFailed(value: boolean) {
        hasLoadingImageFailed.value = value;
    }

    return {
        hasLoadingImageFailed,
        onImageError,
        setHasLoadingImageFailed,
    };
}
