<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M5.5 14 2 10.5v-5L5.5 2h5L14 5.5v5L10.5 14h-5Zm.6-3.167 1.9-1.9 1.9 1.9.933-.933-1.9-1.9 1.9-1.9-.933-.933-1.9 1.9-1.9-1.9-.933.933 1.9 1.9-1.9 1.9.933.933Zm-.033 1.834h3.866l2.734-2.734V6.067L9.933 3.333H6.067L3.333 6.067v3.866l2.734 2.734Z"
        />
    </svg>
</template>

<script setup lang="ts">
type DangerousIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<DangerousIconProps>();
</script>
