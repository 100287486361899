<template>
    <div>
        <div class="rounded-t outside-box-border-bottom" :class="[variantClasses]">
            <div class="px-1.5 py-1">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type ReviewSectionProps = {
    variant: 'Error' | 'Neutral';
};

const props = defineProps<ReviewSectionProps>();

const variantClasses = computed(() => {
    switch (props.variant) {
        case 'Error':
            return 'bg-red-700/70 outside-box-border-bottom-red-700-70';

        case 'Neutral':
        default:
            return 'bg-gray-400/70 outside-box-border-bottom-red-400-70';
    }
});
</script>

<style scoped>
.outside-box-border-bottom {
    position: relative;
}

.outside-box-border-bottom::after {
    position: absolute;
    bottom: -0.5rem;
    height: 0.5rem;
    content: '';
    width: 100%;
    z-index: -10;
}

.outside-box-border-bottom-red-700-70::after {
    background-color: theme('colors.red.700 / 70%');
}

.outside-box-border-bottom-red-400-70::after {
    background-color: theme('colors.gray.400 / 70%');
}
</style>
