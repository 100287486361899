import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationType } from '@/components/Timeline/Timeline.types';
import { EventVariantStatus } from '@/event/types';
import { ProjectVariantStatus } from '@/project/types';
import { isArrayEqual } from '@/utils/compare';
import { computed, ref, Ref, toValue } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { ExistingAllocationVariantEntry, SelectionPanelEntries } from '../types';

function getLinkTarget(allocationType: AllocationType, objectId: string, index: number): RouteLocationRaw {
    if (allocationType === 'Event') {
        return { name: 'event-variants-tab', params: { eventId: objectId }, hash: `#${index}` };
    }
    if (allocationType === 'Project') {
        return { name: 'project-variants-tab', params: { projectId: objectId }, hash: `#${index}` };
    }
    // TODO what route do we use for allocations of type None?
    return '';
}

export function useVariantSelectionPanel(
    variantsRef: Ref<TimelineVariant[]>,
    allocationType: AllocationType,
    objectId: Ref<string>,
) {
    const activeStates = ['active-1', 'active-2'];
    const visibleVariants: Ref<string[]> = ref([]);
    const highlightedVariantId = ref('No-Highlight-Id');

    const dropDownStatus = computed(() => {
        const numVariants = variantsRef.value.length;
        const numVisibleVariants = visibleVariants.value.length;

        if (numVariants === 1) {
            if (numVisibleVariants === 0) {
                return { status: 'Error' as const, display: 'all-hidden' };
            }

            if (visibleVariantsWithAllocations.value.length === 0) {
                return {
                    status: 'Warning' as const,
                    display: 'warning',
                };
            }

            return { status: 'Default' as const, display: 'all-visible' };
        }

        if (numVisibleVariants <= 0) {
            return { status: 'Error' as const, display: 'all-hidden' };
        } else {
            if (visibleVariantsWithAllocations.value.length === 0) {
                return {
                    status: 'Warning' as const,
                    display: 'warning',
                };
            }

            const visibleVariantsAreDefault = isArrayEqual(
                [...visibleVariants.value],
                defaultActiveVariants.value.map((v) => v.id),
            );

            if (highlightedVariant.value || !visibleVariantsAreDefault) {
                return { status: 'Active' as const, display: 'highlighted' };
            }
        }

        return { status: 'Default' as const, display: 'all-visible' };
    });

    const currentVariant = computed(() => {
        const variants = toValue(variantsRef);
        if (variants.length <= 0) return;
        const current = variants.find((v) => v.isCurrent === true);
        if (!current) throw new Error('There must be exactly one main variant!');
        return current;
    });

    const mappedCurrentVariant = computed(() => {
        const variant = currentVariant.value;
        if (variant) {
            return { id: variant.id, name: variant.name || '-', index: variant.index };
        }
    });

    const highlightedVariant = computed(() => {
        return toValue(variantsRef).find((v) => v.id === highlightedVariantId.value);
    });

    // Editing in the timeline changes the highlighted variant.
    // If no variant is highlighted it changes the currentVariant.
    const editableVariant = computed(() => highlightedVariant.value ?? currentVariant.value);

    const editableVariantLabel = computed(() => {
        if (!editableVariant.value) {
            return null;
        }

        return getUnstyledAllocationVariantLabel(editableVariant.value.index, editableVariant.value.name);
    });
    const defaultActiveVariants = computed(() => toValue(variantsRef).filter((v) => activeStates.includes(v.status)));

    const selectionPanelVariants = computed(() => {
        let elements: SelectionPanelEntries = [];
        if (variantsRef.value) {
            elements.push({ type: 'No-Highlight' as const });
            const mappedResult = variantsRef.value.map((v) => ({
                type: 'Existing-Allocation-Variant' as const,
                id: v.id,
                index: v.index,
                variantName: v.name || '-',
                isVisible: visibleVariants.value.includes(v.id),
                isMainVariant: v.isCurrent,
                status: v.status as EventVariantStatus | ProjectVariantStatus,
                linkTarget: getLinkTarget(allocationType, toValue(objectId), v.index),
                hasAllocations: v.rooms.nodes.length > 0 || v.areas.nodes.length > 0,
            }));
            // splice mainVariant out and put it back in to be at the first place
            const mainVariantIndex = mappedResult.findIndex((v) => v.isMainVariant);
            const mainVariant = mappedResult.splice(mainVariantIndex, 1);
            elements.unshift(mainVariant[0]);
            elements = elements.concat(mappedResult);
        }
        return elements;
    });

    const visibleVariantsWithAllocations = computed(() =>
        selectionPanelVariants.value
            .filter(
                (variant): variant is ExistingAllocationVariantEntry => variant.type === 'Existing-Allocation-Variant',
            )
            .filter((variant) => variant.isVisible)
            .filter((variant) => variant.hasAllocations),
    );

    function setVisibleVariants(value: string[]) {
        visibleVariants.value = value;
    }

    function setHighlightedVariantId(value: string) {
        highlightedVariantId.value = value;
    }

    function onDeleteFilter() {
        highlightedVariantId.value = 'No-Highlight-Id';
        visibleVariants.value = variantsRef.value.filter((v) => activeStates.includes(v.status)).map((v) => v.id) ?? [];
    }

    return {
        dropDownStatus,
        visibleVariants,
        defaultActiveVariants,
        editableVariant,
        editableVariantLabel,
        highlightedVariantId,
        highlightedVariant,
        mappedCurrentVariant,
        selectionPanelVariants,
        setVisibleVariants,
        setHighlightedVariantId,
        onDeleteFilter,
    };
}
