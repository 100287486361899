<template>
    <div
        class="py-2 px-4 items-start grid grid-cols-[10rem_1fr] gap-4"
        :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }"
        :style="gridTemplateColumnsStyle"
    >
        <strong class="text-sm font-medium text-gray-500 flex flex-row items-center gap-1">
            {{ props.label }}
            <HelpButton v-if="props.helpText" :text="props.helpText" />
        </strong>
        <p :class="textStyle">{{ text }}</p>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';

type CardRowTextProps = {
    label: string;
    labelWidthInRem?: number;
    helpText?: string;
    text: string;
    hasBorderBottom: boolean;
    textColor?: string;
};

const props = defineProps<CardRowTextProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: ${props.labelWidthInRem ?? 15}rem 1fr;`;
});

const textStyle = computed(() => {
    if (props.textColor) {
        return `text-sm ${props.textColor} whitespace-break-spaces`;
    }
    return 'text-sm text-gray-900 whitespace-break-spaces';
});
</script>
