<template>
    <div>
        <RouterLink v-if="to" v-slot="{ navigate }" custom :to="to">
            <button
                role="link"
                :type="type"
                class="inline-flex items-center justify-center font-medium disabled:opacity-50 space-x-2"
                :class="classObject"
                :disabled="reallyDisabled"
                @click="navigate"
            >
                <div class="flex flex-row items-center align-center space-x-2">
                    <circular-progress-indicator v-if="loading" />
                    <icon v-if="icon">{{ icon }}</icon>
                    <div v-if="$slots.default">
                        <slot />
                    </div>
                </div>
            </button>
        </RouterLink>
        <button
            v-else
            :type="type"
            class="inline-flex items-center justify-center font-medium disabled:opacity-50 space-x-2"
            :class="classObject"
            :disabled="reallyDisabled"
            @click.stop="$emit('click', $event)"
        >
            <div class="flex flex-row items-center align-center space-x-2">
                <circular-progress-indicator v-if="loading" />
                <icon v-if="icon">{{ icon }}</icon>
                <div v-if="$slots.default">
                    <slot />
                </div>
            </div>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';
import CircularProgressIndicator from './CircularProgressIndicator.vue';
import Icon from './Icon.vue';

export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
export type Variant = 'primary' | 'outlined' | 'icon' | 'text';
export type Intent = 'action' | 'info' | 'destructive';

interface ButtonProps {
    size?: ButtonSize;
    variant?: Variant;
    enabled?: boolean;
    icon?: string;
    intent?: Intent;
    to?: RouteLocationRaw;
    type?: 'submit' | 'reset' | 'button';
    loading?: boolean;
}

interface ButtonEmits {
    (e: 'click', ev: MouseEvent): void;
}

const props = withDefaults(defineProps<ButtonProps>(), {
    size: 'md',
    variant: 'primary',
    enabled: true,
    intent: 'action',
    to: undefined,
    type: 'button',
});
defineEmits<ButtonEmits>();

const sizeClasses = {
    xs: ['px-2.5', 'py-1.5', 'text-xs', 'rounded'],
    sm: ['px-3', 'py-2', 'text-sm', 'leading-4', 'rounded-md'],
    md: ['px-4', 'py-2', 'text-sm', 'rounded-md'],
    lg: ['px-4', 'py-2', 'text-base', 'rounded-md'],
    xl: ['px-6', 'py-3', 'text-base', 'rounded-md'],
};

const reallyDisabled = computed(() => !props.enabled || props.loading);

const foregroundColor = computed(() => {
    switch (props.intent) {
        case 'action':
            return 'white';
        case 'destructive':
            return 'white';
        case 'info':
            return 'gray';
    }
});

const backgroundColor = computed(() => {
    switch (props.intent) {
        case 'action':
            return 'sky-700';
        case 'destructive':
            return 'red';
        case 'info':
            return 'gray';
    }
});

function variantClasses(variant: Variant) {
    const bg = backgroundColor.value;
    const fg = foregroundColor.value;
    const disabled = reallyDisabled.value;

    switch (variant) {
        case 'primary':
            return [
                'border border-transparent',
                'shadow-sm',
                `text-${fg}`,
                `bg-${bg}`,
                disabled ? '' : `hover:bg-${bg}-dark`,
                'focus:outline-none',
            ];
        case 'outlined':
            return [
                'px-2.5 py-1.5',
                'border border-primary-100',
                'shadow-sm',
                `text-${bg}`,
                disabled ? '' : 'hover:bg-gray-50',
                'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary',
            ];
        case 'icon':
            return ['!px-0', 'border-0', `text-${bg}`];
        case 'text':
            return [
                'px-4',
                'py-3',
                disabled ? '' : 'hover:bg-gray-100',
                'rounded-md',
                `text-${bg}`,
                'bg-white-50',
                'text-sm',
                'no-border',
                'leading-4',
            ];
    }
}

const classObject = computed(() => [...sizeClasses[props.size], ...variantClasses(props.variant)]);
</script>
