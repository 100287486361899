<template>
    <HeaderCardsLayout headline="TODO Switcher" variant="No-Logo">
        <template #icon>
            <TacticIcon class="size-12" />
        </template>

        <template #buttons>TODO Filter & Buttons</template>

        <template #body>
            <main class="bg-gray-50 h-full -mx-4 p-4">
                <div class="flex flex-row items-end gap-4">
                    <TimelineIntervalChanger
                        :current-quarter="quarter"
                        :current-year="year"
                        :minimal-year="MINIMAL_YEAR"
                        :maximal-year="MAXIMAL_YEAR"
                        :warning-no-allocations="warningNoAllocations"
                        :is-time-span-modified="isTimeSpanModified"
                        @change-quarter="onChangeQuarter"
                        @change-year="onChangeYear"
                        @click-previous-quarter="onClickPreviousQuarter"
                        @click-next-quarter="onClickNextQuarter"
                    />

                    <TimelineOptionSwitch
                        name="timeline-locations-display"
                        :active-index="activeLocationsDisplayIndex"
                        :label="$t('timeline-locations-display-label')"
                        @change-option="(index) => (activeLocationsDisplayIndex = index)"
                    >
                        <TimelineOptionSwitchEntry
                            :icon="markRaw(GroupedEntriesIcon)"
                            :index="0"
                            :label="$t('timeline-locations-display-sections-label')"
                        />
                        <TimelineOptionSwitchEntry
                            :icon="markRaw(NestedEntriesIcon)"
                            :index="1"
                            :label="$t('timeline-locations-display-hierarchy-label')"
                        />
                    </TimelineOptionSwitch>
                </div>

                <Message v-if="timelineDataLoading" status="info" class="mt-4">
                    {{ $t('loading-timeline-data') }}
                </Message>

                <Message
                    v-if="!timelineDataLoading && timelineDataError"
                    class="mt-4"
                    status="error"
                    :headline="$t('error-fetching-timeline-data')"
                >
                    {{ timelineDataError }}
                </Message>

                <Timeline
                    v-if="timelineDataResult && site"
                    class="mt-4"
                    :site-name="site.nameShort"
                    :site-link-target="routeForLocation('Site', site.id)"
                    :start-date-time="startDateTime"
                    :end-date-time="endDateTime"
                    :previous-month-button="previousMonthButton"
                    :next-month-button="nextMonthButton"
                    :locations="locations"
                    :allocations="allocations"
                    :external-events="externalEvents"
                    :sections="showSections ? sections : undefined"
                    :allocation-variant-id="''"
                    :current-allocation-variant-label="''"
                    :has-create-buttons="false"
                    @toggle-section-expanded="toggleSectionExpanded"
                    @create-allocation="onCreateAllocation"
                    @delete-allocation="onDeleteAllocation"
                    @update-phases="onUpdatePhases"
                    @expand-to-previous-month="onExpandToPreviousMonth"
                    @expand-to-next-month="onExpandToNextMonth"
                >
                    <template #site-icon>
                        <TerrainIcon class="w-5 h-5" :title="$t('site-icon-alt')" />
                    </template>
                </Timeline>
            </main>
        </template>
    </HeaderCardsLayout>
</template>

<script setup lang="ts">
import { mapAllocationForTimeline } from '@/allocation/allocation';
import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import GroupedEntriesIcon from '@/components/Icon/GroupedEntriesIcon.vue';
import NestedEntriesIcon from '@/components/Icon/NestedEntriesIcon.vue';
import TacticIcon from '@/components/Icon/TacticIcon.vue';
import TerrainIcon from '@/components/Icon/TerrainIcon.vue';
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import Message from '@/components/Message/Message.vue';
import { LocationType } from '@/components/Timeline/Timeline.types';
import Timeline from '@/components/Timeline/Timeline.vue';
import { useSections } from '@/components/Timeline/composables/useSections';
import { useTimelineRange } from '@/components/Timeline/composables/useTimelineRange';
import { convertToDisplayedDateTime } from '@/components/Timeline/logic/durations';
import { getSectionId } from '@/components/Timeline/timeline';
import TimelineIntervalChanger from '@/components/TimelineIntervalChanger/TimelineIntervalChanger.vue';
import TimelineOptionSwitch from '@/components/TimelineOptionSwitch/TimelineOptionSwitch.vue';
import TimelineOptionSwitchEntry from '@/components/TimelineOptionSwitch/TimelineOptionSwitchEntry.vue';
import { getExternalEventLink } from '@/constraints/constraints';
import { getEventMainRoute } from '@/event/event';
import { GetSectionsDataForAllocationDesignerTimelineDocument } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { $t } from '@/plugins/fluent';
import { getProjectLink } from '@/project/project';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { DateTime } from 'luxon';
import { computed, markRaw, ref } from 'vue';

const currentScenario = ref('Ungespeichert');

useHeadSafe({ title: () => `${currentScenario.value} - ${$t('allocation-designer')}` });

// TODO Persist view options in URL

const {
    startDateTime,
    endDateTime,
    isTimeSpanModified,
    quarter,
    year,
    onChangeQuarter,
    onChangeYear,
    onClickPreviousQuarter,
    onClickNextQuarter,
    onExpandToPreviousMonth,
    onExpandToNextMonth,
} = useTimelineRange();

const MINIMAL_YEAR = 2000;
const MAXIMAL_YEAR = 2099;

const previousMonthButton = computed(() => {
    const isDisabled = startDateTime.value.year === MINIMAL_YEAR && startDateTime.value.month === 1;

    return isDisabled ? 'Disabled' : 'Visible';
});
const nextMonthButton = computed(() => {
    const isDisabled = endDateTime.value.year === MAXIMAL_YEAR && endDateTime.value.month === 12;

    return isDisabled ? 'Disabled' : 'Visible';
});

const activeLocationsDisplayIndex = ref(0);
const showSections = computed(() => activeLocationsDisplayIndex.value === 0);

const {
    result: timelineDataResult,
    loading: timelineDataLoading,
    error: timelineDataError,
} = useQuery(
    GetSectionsDataForAllocationDesignerTimelineDocument,
    computed(() => ({
        startDateTime: startDateTime.value.toISO(),
        endDateTime: endDateTime.value.toISO(),
    })),
);

const warningNoAllocations = computed(() => {
    const countAllocations = timelineDataResult.value?.allocationRooms?.nodes.length ?? 0;

    if (timelineDataLoading.value || countAllocations > 0) {
        return null;
    }

    return {
        buttonJumpToPreviousAllocations: 'Hidden' as const,
        buttonJumpToNextAllocations: 'Hidden' as const,
    };
});

const site = computed(() => {
    if (!timelineDataResult.value) {
        return null;
    }

    const sites = timelineDataResult.value?.sites;

    if (!sites) {
        throw new Error('Missing site');
    }

    return sites.nodes[0]; // We only have a single site for now
});

const locations = computed(() => {
    if (showSections.value) {
        const rooms =
            timelineDataResult.value?.rooms?.nodes.map((room) => ({
                id: room.id,
                label: room.nameShort,
                level: 0,
                typeCategory: 'Room' as const,
                type: room.type?.name as LocationType,
                isLeafLocation: true,
                nestedLocations: [],
                sectionId: getSectionId(room.type?.name ?? 'no-room-type'),
                linkTarget: routeForLocation('Room', room.id),
            })) ?? [];
        const areas =
            timelineDataResult.value?.areas?.nodes.map((room) => ({
                id: room.id,
                label: room.nameShort,
                level: 0,
                typeCategory: 'Room' as const,
                type: room.type?.name as LocationType,
                isLeafLocation: true,
                nestedLocations: [],
                sectionId: getSectionId(room.type?.name ?? 'no-room-type'),
                linkTarget: routeForLocation('Room', room.id),
            })) ?? [];
        return rooms.concat(areas);
    }

    return (
        site.value?.buildings.nodes.map((building) => {
            return {
                id: `building-${building.id}`,
                label: building.nameShort,
                level: 0,
                typeCategory: 'Building' as const,
                isLeafLocation: false,
                nestedLocations: building.levels.nodes.map((level) => {
                    return {
                        id: `floor-${level.id}`,
                        label: level.nameShort,
                        level: 1,
                        typeCategory: 'Floor' as const,
                        isLeafLocation: false,
                        nestedLocations: level.rooms.nodes.map((room) => {
                            return {
                                id: `room-${room.id}`,
                                label: room.nameShort,
                                level: 2,
                                typeCategory: 'Room' as const,
                                isLeafLocation: true,
                                nestedLocations: [],
                            };
                        }),
                    };
                }),
            };
        }) ?? []
    );
});

const { sections, toggleSectionExpanded } = useSections();

const allocations = computed(() => {
    const result = timelineDataResult.value;
    const eventAllocations =
        result?.allocationRooms?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.event.nameShort;
            const linkTarget = getEventMainRoute(variant.event.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                variantLabel,
                'Event',
                label,
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const eventAreaAllocatons =
        result?.allocationAreas?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.event.nameShort;
            const linkTarget = getEventMainRoute(variant.event.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                variantLabel,
                'Event',
                label,
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const projectAllocations =
        result?.projectAllocationRooms?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.project.nameShort;
            const linkTarget = getProjectLink(variant.project.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                variantLabel,
                'Project',
                label,
                variant.id,
                linkTarget,
            );
        }) ?? [];
    const projectAreaAllocations =
        result?.projectAllocationAreas?.nodes.map((allocation) => {
            const variant = allocation.variant;
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            const label = variant.project.nameShort;
            const linkTarget = getProjectLink(variant.project.id);
            return mapAllocationForTimeline(
                allocation,
                variant.isCurrent,
                variantLabel,
                'Project',
                label,
                variant.id,
                linkTarget,
            );
        }) ?? [];
    return eventAllocations.concat(projectAllocations).concat(eventAreaAllocatons).concat(projectAreaAllocations);
});

const externalEvents = computed(() => {
    return (
        timelineDataResult.value?.calendarEvents?.nodes.map((externalEvent) => {
            const startDateTime = DateTime.fromISO(externalEvent.start).startOf('day');
            // TODO Test if the duration is correctly calculated for edge cases --> Once https://koelnmesse.atlassian.net/browse/SFE-919 is implemented, we can use the same logic everywhere
            const endDateTime = convertToDisplayedDateTime(DateTime.fromISO(externalEvent.end)).startOf('day');
            const durationInDays = endDateTime.diff(startDateTime, 'days').days + 1;

            return {
                startDateTime,
                cellSpan: durationInDays,
                label: externalEvent.name,
                id: externalEvent.id,
                isEditable: false,
                linkTarget: getExternalEventLink(externalEvent.id), // TODO Might be necessary to adapt once we've reimplemented the external events
            };
        }) ?? []
    );
});

function onCreateAllocation() {
    // TODO Implement once there is a concept how (and if) this should work here
}

function onDeleteAllocation() {
    // TODO Implement once there is a concept how (and if) this should work here
}

function onUpdatePhases() {
    // TODO Implement once there is a concept how (and if) this should work here
}
</script>
