<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 14.667a6.492 6.492 0 0 1-2.6-.525 6.732 6.732 0 0 1-2.117-1.425A6.733 6.733 0 0 1 1.858 10.6 6.492 6.492 0 0 1 1.333 8c0-.922.175-1.789.525-2.6a6.732 6.732 0 0 1 1.425-2.117c.6-.6 1.306-1.075 2.117-1.425A6.492 6.492 0 0 1 8 1.333a6.55 6.55 0 0 1 2.05.317 6.851 6.851 0 0 1 1.783.883l-.966.984a5.733 5.733 0 0 0-1.35-.625A5.034 5.034 0 0 0 8 2.667c-1.478 0-2.736.52-3.775 1.558C3.186 5.264 2.667 6.522 2.667 8c0 1.478.519 2.736 1.558 3.775C5.264 12.814 6.522 13.333 8 13.333c1.478 0 2.736-.52 3.775-1.558 1.039-1.039 1.558-2.297 1.558-3.775 0-.2-.01-.4-.033-.6a4.898 4.898 0 0 0-.1-.583l1.083-1.084c.123.356.217.723.284 1.1.066.378.1.767.1 1.167 0 .922-.175 1.789-.525 2.6a6.733 6.733 0 0 1-1.425 2.117c-.6.6-1.306 1.075-2.117 1.425a6.492 6.492 0 0 1-2.6.525Zm-.933-3.6L4.233 8.233l.934-.933 1.9 1.9 6.666-6.683.934.933-7.6 7.617Z"
        />
    </svg>
</template>

<script setup lang="ts">
type TaskCircleIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<TaskCircleIconProps>();
</script>
