<template>
    <TopBar v-if="area" :title="area.nameShort" :breadcrumbs="locationBreadcrumbs(area)">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabled-explanation="disabledExplanation"
                    @click="deleteArea"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
                <DropDownSelector :options="actions" :disabled="!isAdmin" />
            </div>
        </template>
    </TopBar>
    <GQLLoading
        :query="getAreaQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'area', id: areaId })"
    >
        <LocationDetails
            v-if="area"
            :location="area"
            :location-types="areaTypes"
            :to-edit="{ name: 'area-edit', params: { areaId: areaId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DropDownSelector from '@/components/DropDownSelector.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useArea } from '../area';

const props = defineProps<{ areaId: string }>();
const fluent = useFluent();
const isAdmin = auth.isAdmin();

const { area, areaTypes, getAreaQuery, deleteArea } = useArea(computed(() => props.areaId));

const actions = [
    {
        title: fluent.$t('plot'),
        description: fluent.$t('plot-add-to-area'),
        to: { name: 'plot-add', params: { areaId: props.areaId } },
    },
];

const canDelete = computed(() => {
    const hasAllocations = area.value?.plots.nodes.some((p) => p.allocationPlots.nodes.length > 0);
    return isAdmin && !hasAllocations;
});

const disabledExplanation = computed(() => {
    if (canDelete.value) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
