<template>
    <div style="display: grid">
        <span :class="{ small: small }" class="logo">{{ logoText }}</span>
        <span class="claim" v-if="!small">Powered by space.flow</span>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    small: {
        type: Boolean,
        default: false,
    },
    mini: {
        type: Boolean,
        default: false,
    },
});

const logoText = computed(() => {
    if (props.mini) return 'C';
    return 'space.cap';
});
</script>

<style scoped>
.logo {
    font-family: Poppins;
    font-weight: 700;
    font-size: 100px;
    line-height: 150px;
    letter-spacing: -0.02em;
    grid-column: 1;
    grid-row: 1;
}
.small {
    font-size: 34px;
    line-height: 48px;
}
.claim {
    position: relative;
    top: 106px;
    left: 50px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.02em;
    grid-column: 1;
    grid-row: 1;
    height: fit-content;
}
</style>
