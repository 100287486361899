<!-- TODO Adapt for allocation plots -->

<template>
    <div class="flex" :class="[backgroundClass, borderTopClasses, borderBottomClasses]">
        <div class="flex flex-col gap-y-[0.1875rem] h-full w-full" :class="[borderLeftClasses, borderRightClasses]">
            <template v-for="(allocation, allocationIndex) in props.allocations" v-if="props.allocations.length > 0">
                <Allocation
                    v-if="allocation.form === 'Start' || allocation.form === 'Single'"
                    :key="allocation.id"
                    :class="{ 'mt-0.5': allocationIndex === 0 }"
                    :phases="allocation.phases"
                    :allocation-id="allocation.id"
                    :allocation-type="allocation.allocationType"
                    :allocation-label="allocation.label"
                    :allocation-variant-id="allocation.allocationVariantId"
                    :allocation-variant-label="allocation.allocationVariantLabel"
                    :start-date-time="allocation.startDateTime"
                    :end-date-time="allocation.endDateTime"
                    :is-editable="allocation.isEditable"
                    :link-target="allocation.linkTarget"
                    :variant="allocation.variant"
                    :location-id="props.locationId"
                    :is-inside="props.isLocationInside"
                    @mouseover.stop="emit('allocation-mouseover')"
                />

                <AllocationPlaceholder
                    v-if="allocation.form !== 'Start' && allocation.form !== 'Single'"
                    :key="allocation.id"
                    :class="{ 'mt-0.5': allocationIndex === 0 }"
                    class="shrink-0"
                    type="Invisible"
                    :is-phase-end="false"
                />
            </template>

            <FullHeightCreateButton
                v-if="props.hasCreateButton"
                :is-full-height="props.allocations.length === 0"
                :status="status"
                :current-allocation-variant-label="props.currentAllocationVariantLabel"
                @click="onCreateButtonClick"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { useMachine } from '@xstate/vue';
import { DateTime } from 'luxon';
import { computed, inject } from 'vue';
import { AllocationPart, ConflictMarker } from '../Timeline.types';
import Allocation from '../allocation/Allocation.vue';
import AllocationPlaceholder from '../allocation/AllocationPlaceholder.vue';
import { emitCreateAllocationKey } from '../injectionKeys';
import { createStateMachine } from './Cell.state';
import FullHeightCreateButton from './FullHeightCreateButton.vue';

type CellProps = {
    isInHoveredColumn: boolean;
    isInHoveredRow: boolean;
    isConflictHovered: boolean;
    isWorkDay: boolean;
    isInFirstColumn: boolean;
    isInFirstRow: boolean;
    allocations: AllocationPart[];
    conflictMarker: ConflictMarker;
    hasCreateButton: boolean;
    allocationVariantId: string;
    locationId: string;
    isLocationInside: boolean;
    startDateTime: DateTime;
    endDateTime: DateTime;
    currentAllocationVariantLabel: string | null;
};

const props = defineProps<CellProps>();

const emit = defineEmits<{
    (e: 'allocation-mouseover'): void;
}>();

const createAllocation = inject(emitCreateAllocationKey);
function onDone(isSuccess: boolean) {
    if (isSuccess) {
        send('Create allocation succeeded');
    } else {
        send('Create allocation failed');
    }
}
async function onCreateAllocation() {
    if (!createAllocation) {
        throw new Error('Missing injected createAllocation function');
    }

    createAllocation(
        props.locationId,
        props.startDateTime,
        props.endDateTime,
        props.allocationVariantId,
        [
            {
                offset: {
                    days: 0,
                },
                duration: {
                    days: 1,
                },
                usageId: 'default',
            },
        ],
        props.isLocationInside ? 'Room' : 'Area',
        onDone,
    );
}

const stateMachine = computed(() => createStateMachine(onCreateAllocation));
const { state, send } = useMachine(stateMachine.value);

function onCreateButtonClick() {
    if (state.value.matches('Create')) {
        send('Create allocation');
    }

    if (state.value.matches('Failed')) {
        send('Retry create allocation');
    }
}

const status = computed(() => {
    switch (true) {
        case state.value.matches('Create'):
            return 'Create';

        case state.value.matches('Pending'):
            return 'Loading';

        case state.value.matches('Failed'):
            return 'Retry';

        default:
            throw new Error(`Unexpected state: ${state.value.value}`);
    }
});

const backgroundClass = computed(() => {
    // This very cell is hovered
    if (props.isInHoveredColumn && props.isInHoveredRow) {
        if (props.conflictMarker !== 'no-conflict') {
            return 'bg-red-400';
        } else {
            return 'bg-blue-400';
        }
    }

    // A cell in either the column or the row is hovered
    if (props.isInHoveredColumn || props.isInHoveredRow) {
        if (props.isConflictHovered) {
            return 'bg-red-100';
        } else {
            return 'bg-blue-100';
        }
    }

    if (!props.isWorkDay) {
        return 'bg-gray-100';
    }

    return 'bg-gray-50';
});

const borderLeftClasses = computed(() => {
    const classes: string[] = [];

    if (props.conflictMarker === 'conflict-start' || props.conflictMarker === 'conflict-single') {
        classes.push('cell-border-left cell-border-left-2 cell-border-left-red-600 cell-border-left-z-20');
    }

    // Left border of hovered column
    if (props.isInHoveredColumn) {
        if (props.isConflictHovered) {
            classes.push('cell-border-left cell-border-left-1 cell-border-left-red-200');
        } else {
            classes.push('cell-border-left cell-border-left-1 cell-border-left-blue-200');
        }
    }

    if (props.isInFirstColumn && props.isInHoveredRow) {
        classes.push('cell-border-left cell-border-left-1 cell-border-left-blue-200');
    }

    return classes;
});

const borderRightClasses = computed(() => {
    const classes: string[] = [];

    if (props.conflictMarker === 'conflict-end' || props.conflictMarker === 'conflict-single') {
        classes.push('cell-border-right cell-border-right-2 cell-border-right-red-600 cell-border-right-z-20');
    }

    // Right border of hovered column + "Middle" (technically: right) borders in hovered row
    if (props.isInHoveredColumn || props.isInHoveredRow) {
        if (props.isConflictHovered) {
            classes.push('cell-border-right cell-border-right-1 cell-border-right-red-200');
        } else {
            classes.push('cell-border-right cell-border-right-1 cell-border-right-blue-200');
        }
    }

    return classes;
});

const borderTopClasses = computed(() => {
    const classes: string[] = [];

    if (props.conflictMarker !== 'no-conflict') {
        classes.push('cell-border-top cell-border-top-2 cell-border-top-red-600 cell-border-top-z-20');
    }

    // Top border of hovered row
    if (props.isInHoveredRow) {
        if (props.isConflictHovered) {
            classes.push('cell-border-top cell-border-top-1 cell-border-top-red-200');
        } else {
            classes.push('cell-border-top cell-border-top-1 cell-border-top-blue-200');
        }
    }

    return classes;
});

const borderBottomClasses = computed(() => {
    const classes: string[] = [];

    if (props.conflictMarker !== 'no-conflict') {
        classes.push('cell-border-bottom cell-border-bottom-2 cell-border-bottom-red-600 cell-border-bottom-z-20');
    }

    // Bottom border of hovered row + "Middle" (technically: bottom) borders in hovered column
    if (props.isInHoveredRow || props.isInHoveredColumn) {
        if (props.isConflictHovered) {
            classes.push('cell-border-bottom cell-border-bottom-1 cell-border-bottom-red-200');
        } else {
            classes.push('cell-border-bottom cell-border-bottom-1 cell-border-bottom-blue-200');
        }
    }

    return classes;
});
</script>

<style scoped>
/**
 * Using CSS' border property causes the following issues:
 * - The intersection between a 1px and a 2px border creates a tiny triangle which looks ugly
 * - The visual border is created by the background color of the CSS grid "shining through", so the cell border must be placed 1px outside of the actual component border
 *
 * Therefore, the implementation uses 2 nested elements, each having a ::before and ::after pseudo element, so we have in total 4 pseudo elements, one for each edge:
 */

.cell-border-left,
.cell-border-right,
.cell-border-top,
.cell-border-bottom {
    position: relative;
}

.cell-border-left::before {
    content: '';
    position: absolute;
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
}

.cell-border-right::after {
    content: '';
    position: absolute;
    height: calc(100% + 2px);
    top: -1px;
    right: -1px;
}

.cell-border-top::before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    top: -1px;
    left: -1px;
}

.cell-border-bottom::after {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    bottom: -1px;
    left: -1px;
}

.cell-border-left-blue-200::before {
    background-color: theme('colors.blue.200');
}

.cell-border-left-red-200::before {
    background-color: theme('colors.red.200');
}

.cell-border-left-red-600::before {
    background-color: theme('colors.red.600');
}

.cell-border-left-1::before {
    width: 1px;
}

.cell-border-left-2::before {
    width: 2px;
}

.cell-border-left-z-20::before {
    z-index: 20;
}

.cell-border-right-blue-200::after {
    background-color: theme('colors.blue.200');
}

.cell-border-right-red-200::after {
    background-color: theme('colors.red.200');
}

.cell-border-right-red-600::after {
    background-color: theme('colors.red.600');
}

.cell-border-right-1::after {
    width: 1px;
}

.cell-border-right-2::after {
    width: 2px;
}

.cell-border-right-z-20::after {
    z-index: 20;
}

.cell-border-top-blue-200::before {
    background-color: theme('colors.blue.200');
}

.cell-border-top-red-200::before {
    background-color: theme('colors.red.200');
}

.cell-border-top-red-600::before {
    background-color: theme('colors.red.600');
}

.cell-border-top-1::before {
    height: 1px;
}

.cell-border-top-2::before {
    height: 2px;
}

.cell-border-top-z-20::before {
    z-index: 20;
}

.cell-border-bottom-blue-200::after {
    background-color: theme('colors.blue.200');
}

.cell-border-bottom-red-200::after {
    background-color: theme('colors.red.200');
}

.cell-border-bottom-red-600::after {
    background-color: theme('colors.red.600');
}

.cell-border-bottom-1::after {
    height: 1px;
}

.cell-border-bottom-2::after {
    height: 2px;
}

.cell-border-bottom-z-20::after {
    z-index: 20;
}
</style>
