<template>
    <div>
        <div class="grid grid-cols-[1fr_200px] gap-4 p-4 grid-rows-[auto_auto]">
            <div class="flex col-span-2">
                <Tabs v-model="tab" :tabs="tabs" class="flex-auto overflow-x-auto overflow-y-hidden mr-4" />
                <base-button icon="edit" size="xs" :to="toEdit" :enabled="canEdit" @click="$emit('edit')" />
            </div>
            <div class="flex-col space-y-4">
                <card
                    v-for="card in selectedTabCards"
                    :key="card.group.id"
                    :title="card.group.title"
                    class="self-start"
                >
                    <card-content>
                        <DescriptionList>
                            <LocationDetailListItem v-for="field in card.fields" :key="field.dbName" :field="field" />
                        </DescriptionList>
                    </card-content>
                </card>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import Card from '@/components/Card.vue';
import CardContent from '@/components/CardContent.vue';
import DescriptionList from '@/components/DescriptionList.vue';
import Tabs from '@/components/Tabs.vue';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { computed, ref } from 'vue';
import { RouteLocationRaw } from 'vue-router';
import { LocationDetailsResult, LocationSubType } from '../forms/types';
import { getContactBookingChoices, getKeyIssuanceChoices } from '../location';
import { formForLocationType } from '../locationAttributes';
import LocationDetailListItem from './LocationDetailListItem.vue';

type Props = {
    location: LocationDetailsResult;
    locationTypes?: LocationSubType[];
    toEdit?: RouteLocationRaw;
    canEdit: boolean;
};

const props = defineProps<Props>();
defineEmits<{ (e: 'edit', type: void): void }>();

const fluent = useFluent();

const tab = ref(0);
const spec = computed(() =>
    formForLocationType(props.location.__typename, props.location, {
        types: props.locationTypes?.slice().sort((a, b) => a.name.localeCompare(b.name)),
        contactBookingChoices: getContactBookingChoices(),
        keyIssuanceChoices: getKeyIssuanceChoices(),
    }),
);
const tabs = computed(() => spec.value.map((x, i) => ({ id: i, name: x.title })));
const selectedTab = computed(() => spec.value[tab.value]);

const selectedTabCards = computed(() => {
    const fields = selectedTab.value.fields;
    return (selectedTab.value.groups ?? [])
        .map((g) => ({ group: g, fields: fields.filter((f) => f.group === g.id) }))
        .filter((g) => g.fields.length);
});

useHeadSafe({ title: () => `${props.location.nameShort} - ${fluent.$t('location-wiki')}` });
</script>
