<!--
  Tailwind UI "Tabs with underline and icons"
  https://tailwindui.com/components/application-ui/navigation/tabs#component-aed2acb28998d158d7aa898cb19540e4
  Modifications:
   - added "center" property to center the tabs
   - show the icons above the labels (and use v-icon)
-->
<template>
    <div>
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
            <select
                id="tabs"
                name="tabs"
                class="block w-full focus:ring-primary focus:border-primary border-gray-300 rounded-md"
            >
                <option v-for="tab in tabs" :key="tab.name" :selected="tab.id == selected">{{ tab.name }}</option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="no-border">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs" :class="{ 'justify-center': center }">
                    <button
                        v-for="tab in tabs"
                        :key="tab.name"
                        :class="[
                            tab.id == selected
                                ? 'border-primary text-primary border-b-2 border-solid'
                                : 'text-gray-500 hover:text-primary-400 hover:border-primary-400',
                            'group flex-col items-stretch py-2 px-1 border-b-2 text-base',
                        ]"
                        :aria-current="tab.id == selected ? 'page' : undefined"
                        @click="selectTab(tab)"
                    >
                        <icon
                            v-if="tab.icon"
                            :icon="tab.icon"
                            :class="[
                                tab.id == selected ? 'text-primary' : 'text-gray-400 group-hover:text-primary-400',
                                '-ml-0.5 mr-2 h-5 w-5',
                            ]"
                            aria-hidden="true"
                        />
                        <span>{{ tab.name }}</span>
                    </button>
                </nav>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import Icon from './Icon.vue';

export type Tab = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    id: any;
    name: string;
    icon?: string;
};

const props = withDefaults(
    defineProps<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        modelValue?: any;
        tabs: Tab[];
        center?: boolean;
    }>(),
    { center: false },
);
const emit = defineEmits<{
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: 'update:modelValue', type: any): void;
}>();

const selected = computed(() => props.modelValue ?? props.tabs.at(0)?.id);

function selectTab(tab: Tab) {
    emit('update:modelValue', tab.id);
}
</script>
