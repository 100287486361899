import auth from '@/auth/auth';
import { TableCol, TableRow } from '@/components/SimpleTable.vue';
import { getEventMainRoute, getEventVariantRoute, nameForVariant } from '@/event/event';
import {
    AddVariantToScenarioDocument,
    ChangeScenarioEventDocument,
    DeleteScenarioEventDocument,
    GetScenarioDocument,
    ScenarioSearchDocument,
} from '@/generated/graphql';
import { useResult } from '@/utils/graphql';
import { useApolloClient, useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { Ref, computed, ref, shallowRef, watch } from 'vue';
import { object, string } from 'yup';

type ScenarioSearchResult = {
    id: string;
    name: string;
    mainVariant: { nodes: Array<{ startDate: string; end: string }> };
};

export type ScenarioBaseData = {
    id: string;
    name: string;
    description: string;
};

export const scenarioFormSchema = object({
    name: string().required(),
    description: string(),
});

export function useScenarioSearch(searchCancelsPrevSearch = true) {
    const client = useApolloClient().client;
    const currentSearch = shallowRef();
    const search = ref('');
    const loading = computed(() => currentSearch.value !== undefined);
    const items = ref<ScenarioSearchResult[]>([]);

    function _doSearch(query: string) {
        currentSearch.value = query;
        client.query({ query: ScenarioSearchDocument, variables: { query: `%${query}%` } }).then((res) => {
            if (currentSearch.value === query || !searchCancelsPrevSearch) {
                items.value = (res.data.scenarios?.nodes ?? []) as ScenarioSearchResult[];
                currentSearch.value = undefined;
            }
        });
    }
    watch(search, _doSearch);

    return {
        search,
        attrs: computed(() => ({
            'item-text': 'name',
            'item-value': 'id',
            loading: loading.value,
            items: items.value,
        })),
    };
}

export function useScenario(scenarioId: Ref<string>) {
    const fluent = useFluent();
    const query = useQuery(
        GetScenarioDocument,
        computed(() => ({ id: scenarioId.value })),
    );

    const scenario = useResult(query.result, undefined, (data) => data.scenario);
    const deleteVariantMutation = useMutation(DeleteScenarioEventDocument, { refetchQueries: ['GetScenario'] });
    const addVariantMutation = useMutation(AddVariantToScenarioDocument, { refetchQueries: ['GetScenario'] });
    const changeScenarioEventMutation = useMutation(ChangeScenarioEventDocument, { refetchQueries: ['GetScenario'] });
    const isAdmin = auth.isAdmin();

    const events = useResult(query.result, [], (x) => x.events.nodes);

    // don't allow to add events already added
    const addableEvents = computed(() => {
        const usedEventIds = new Set(scenario.value?.scenarioEvents.nodes.map((x) => x.eventVariant.event.id) ?? []);
        return events.value.filter((x) => !usedEventIds.has(x.id));
    });

    const variants = computed(() => {
        return scenario.value?.scenarioEvents.nodes.map((x) => ({
            id: x.id,
            key: x.eventVariant.id,
            eventId: x.eventVariant.event.id,
            eventName: x.eventVariant.event.nameShort,
            variant: {
                id: x.eventVariant.id,
                name: x.eventVariant.name,
                isCurrent: x.eventVariant.isCurrent,
                version: x.eventVariant.version,
            },
            variants: x.eventVariant.event.eventVariants.nodes.map((x) => ({
                id: x.id,
                title: nameForVariant(x),
                isCurrent: x.isCurrent,
            })),
            allocations: x.eventVariant.allocationPlots.nodes.length + x.eventVariant.allocationRooms.nodes.length,
            toEvent: getEventMainRoute(x.eventVariant.event.id),
            toVariant: getEventVariantRoute(x.eventVariant.event.id, x.eventVariant.index),
        }));
    });
    const variantIds = computed(() => variants.value?.map((x) => x.variant.id) ?? []);

    const table = computed(() => {
        const cols: TableCol[] = [
            {
                title: fluent.$t('event-label'),
                type: 'string',
                bold: true,
            },
            {
                title: fluent.$t('variant-label'),
                type: 'dropdown',
            },
            {
                title: fluent.$t('allocations-label'),
                type: 'number',
            },
            {
                title: fluent.$t('delete-label'),
                type: 'button',
            },
        ];
        const rows: TableRow[] =
            variants.value?.map((x) => [
                { value: x.eventName, to: x.toEvent },
                {
                    value: nameForVariant(x.variant),
                    to: x.toVariant,
                    values: x.variants,
                    onSelect: (v: any) => updateVariant(v.id),
                },
                { value: x.allocations },
                { icon: 'close', onClick: () => removeVariant(x.id) },
            ]) ?? [];

        return { cols, rows };
    });

    function addEvent(event: { id: string; mainVariant: { nodes: Array<{ id: string }> } }) {
        addVariantMutation.mutate({
            eventId: event.id,
            eventVariantId: event.mainVariant.nodes[0].id,
            scenarioId: scenarioId.value,
        });
    }

    function updateVariant(variantId: string) {
        changeScenarioEventMutation.mutate({ eventVariantId: variantId, scenarioId: scenarioId.value });
    }

    function removeVariant(scenarioEventId: string) {
        deleteVariantMutation.mutate({ id: scenarioEventId });
    }

    return {
        scenario,
        query,
        variants,
        variantIds,
        removeVariant,
        table,
        isAdmin,
        addEvent,
        addableEvents,
    };
}
