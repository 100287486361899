<template>
    <BaseForm v-if="series && errors">
        <template #fields>
            <TextField
                :value="series.cycle"
                :label="$t('cycle')"
                :errorMessages="errors.cycle"
                @input="onInput($event, 'cycle')"
            />
            <TextAreaField
                :value="series.notesCycle"
                :label="$t('notes-cycle')"
                :errorMessages="errors.notesCycle"
                @input="onInput($event, 'notesCycle')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesCycleSchema, ProjectSeriesCycleSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectSeriesCycleSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectSeriesCycleSchemaType;
    const schema = ProjectSeriesCycleSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
