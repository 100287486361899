import { TimelineVariant } from '@/components/Timeline/allocation/types';
import { AllocationLocationType, AllocationType } from '@/components/Timeline/Timeline.types';
import {
    DeleteEventAllocationAreaDocument,
    DeleteEventAllocationRoomDocument,
    DeleteProjectAllocationAreaDocument,
    DeleteProjectAllocationRoomDocument,
} from '@/generated/graphql';
import { useMutation } from '@vue/apollo-composable';
import { Ref, toValue } from 'vue';

export function useDeleteAllocation(
    variantsRef: Ref<TimelineVariant[]>,
    setVariantsRef: (value: TimelineVariant[]) => void,
    allocationType: AllocationType,
) {
    const mutations = {
        Project: {
            Room: useMutation(DeleteProjectAllocationRoomDocument),
            Area: useMutation(DeleteProjectAllocationAreaDocument),
        },
        Event: {
            Room: useMutation(DeleteEventAllocationRoomDocument),
            Area: useMutation(DeleteEventAllocationAreaDocument),
        },
        None: { Room: undefined, Area: undefined },
    } satisfies Record<AllocationType, { Room: unknown; Area: unknown }>;

    async function onDeleteAllocation(
        allocationId: string,
        allocationLocationType: AllocationLocationType,
        onDone: (isSuccess: boolean) => void,
    ) {
        const deleteMutation = mutations[allocationType][allocationLocationType];
        if (deleteMutation) {
            const result = await deleteMutation.mutate(
                { allocationId },
                {
                    update(cache, { data }) {
                        const allocation = data?.delete?.allocation;

                        if (!allocation) {
                            throw new Error(`Expected deleted allocation, got ${allocation}`);
                        }
                        cache.evict({
                            id: cache.identify(allocation),
                        });
                        cache.gc();
                    },
                },
            );
            if (result?.errors) {
                onDone(false);
            } else {
                const variantId = result?.data?.delete?.allocation?.variantId;
                const deletedAllocationId = result?.data?.delete?.allocation?.id;
                if (deletedAllocationId && variantId) {
                    const newValue = toValue(variantsRef).map((v) => {
                        if (v.id === variantId) {
                            if (allocationLocationType === 'Room') {
                                return {
                                    ...v,
                                    rooms: {
                                        nodes: v.rooms.nodes.filter((r) => r.id !== deletedAllocationId),
                                    },
                                };
                            }
                            if (allocationLocationType === 'Area') {
                                return {
                                    ...v,
                                    areas: {
                                        nodes: v.areas.nodes.filter((r) => r.id !== deletedAllocationId),
                                    },
                                };
                            }
                        }
                        return v;
                    });
                    setVariantsRef(newValue);
                }
                onDone(true);
            }
        }
    }
    return {
        onDeleteAllocation,
    };
}
