// We roll our own version of apollo`s useResult here. This is a copy of the latest apollo sourcecode
// https://github.com/vuejs/apollo/blob/v4.0.0-alpha.16/packages/vue-apollo-composable/src/useResult.ts
// We use this, because useResult removes the "undefined" case from the gql relations. If we just used computed,
// all fields of the result can be undefined and the user needs to check for undefined everywhere (even though the field will never be undefined)

import type { DeepNonNullable, DeepRequired } from 'ts-essentials';
import { computed, Ref } from 'vue';

/**
 * Check if a type is a union, and return true if so, otherwise false.
 */
export type IsUnion<T, U = T> = U extends any ? ([T] extends [U] ? false : true) : never;

/**
 * Extracts an inner type if T has a single key K, otherwise it returns T.
 */
export type ExtractSingleKey<
    T,
    K extends keyof T = keyof T,
    KWithoutTypename extends K = Exclude<K, '__typename'>,
> = IsUnion<KWithoutTypename> extends true ? T : T[KWithoutTypename];

export type NodesType<TField extends undefined | null | { nodes: Array<any> }> = NonNullable<TField>['nodes'][0];
export type UseResultReturn<T> = Readonly<Ref<Readonly<T>>>;

/**
 * Resolve a `result`, returning either the first key of the `result` if there
 * is only one, or the `result` itself. The `value` of the ref will be
 * `undefined` until it is resolved.
 *
 * @example
 * const { result } = useQuery(...)
 * const user = useResult(result)
 * // user is `undefined` until the query resolves
 *
 * @param  {Ref<TResult>} result A `result` returned from `useQuery` to resolve.
 * @returns Readonly ref with `undefined` or the resolved `result`.
 */
export function useResult<TResult, TResultKey extends keyof NonNullable<TResult> = keyof NonNullable<TResult>>(
    result: Ref<TResult>,
): UseResultReturn<undefined | ExtractSingleKey<NonNullable<TResult>, TResultKey>>;

/**
 * Resolve a `result`, returning either the first key of the `result` if there
 * is only one, or the `result` itself. The `value` of the ref will be
 * `defaultValue` until it is resolved.
 *
 * @example
 * const { result } = useQuery(...)
 * const profile = useResult(result, {})
 * // profile is `{}` until the query resolves
 *
 * @param  {Ref<TResult>} result A `result` returned from `useQuery` to resolve.
 * @param  {TDefaultValue} defaultValue The default return value before `result` is resolved.
 * @returns Readonly ref with the `defaultValue` or the resolved `result`.
 */
export function useResult<
    TResult,
    TDefaultValue,
    TResultKey extends keyof NonNullable<TResult> = keyof NonNullable<TResult>,
>(
    result: Ref<TResult>,
    defaultValue: TDefaultValue,
): UseResultReturn<TDefaultValue | ExtractSingleKey<NonNullable<TResult>, TResultKey>>;

/**
 * Resolve a `result`, returning the `result` mapped with the `pick` function.
 * The `value` of the ref will be `defaultValue` until it is resolved.
 *
 * @example
 * const { result } = useQuery(...)
 * const comments = useResult(result, undefined, (data) => data.comments)
 * // user is `undefined`, then resolves to the result's `comments`
 *
 * @param  {Ref<TResult>} result A `result` returned from `useQuery` to resolve.
 * @param  {TDefaultValue} defaultValue The default return value before `result` is resolved.
 * @param  {(data:TResult)=>TReturnValue} pick The function that receives `result` and maps a return value from it.
 * @returns Readonly ref with the `defaultValue` or the resolved and `pick`-mapped `result`
 */
export function useResult<TResult, TDefaultValue, TReturnValue>(
    result: Ref<TResult>,
    defaultValue: TDefaultValue | undefined,
    pick: (data: DeepRequired<DeepNonNullable<TResult>>) => TReturnValue,
): UseResultReturn<TDefaultValue | TReturnValue>;

export function useResult<TResult, TDefaultValue, TReturnValue>(
    result: Ref<TResult>,
    defaultValue?: TDefaultValue,
    pick?: (data: DeepRequired<DeepNonNullable<TResult>>) => TReturnValue,
): UseResultReturn<TResult | TResult[keyof TResult] | TDefaultValue | TReturnValue | undefined> {
    return computed(() => {
        const value = result.value;
        if (value) {
            if (pick) {
                try {
                    return pick(value as DeepRequired<DeepNonNullable<TResult>>);
                } catch (e) {
                    // Silent error
                }
            } else {
                const keys = Object.keys(value);
                if (keys.length === 1) {
                    // Automatically take the only key in result data
                    return value[keys[0] as keyof TResult];
                } else {
                    // Return entire result data
                    return value;
                }
            }
        }
        return defaultValue;
    });
}

export function gqlPick<TResult, TReturnValue>(
    result: TResult,
    defaultValue: TReturnValue,
    pick: (data: DeepRequired<DeepNonNullable<TResult>>) => TReturnValue,
): TReturnValue {
    const value = result;
    if (value) {
        try {
            return pick(value as DeepRequired<DeepNonNullable<TResult>>);
        } catch (e) {
            // Silent error
        }
    }

    return defaultValue;
}
