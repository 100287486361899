import auth from '@/auth/auth';
import { ActiveCard, EditableCardStatus } from '@/components/Card/types';
import {
    checkForLinkErrors,
    emptyLink,
    emptyMultilink,
    isMultiLinkDirty,
    toFormMultilink,
} from '@/components/MultiLink/multilink';
import { LinkSchema, LinkSchemaType } from '@/components/MultiLink/schema';
import { FormLink, Multilink } from '@/components/MultiLink/types';
import {
    EVENT_MULTILINK_FIELDS,
    cardFieldsMapping as eventCardFieldsMapping,
    getCardNameFromField as getEventCardNameFromField,
    getEventSchemaFromCard,
} from '@/event/event';
import { EventSchema, EventSeriesCreateSchema, EventSeriesSchema } from '@/event/forms/schemas';
import {
    SERIES_MULTILINK_FIELDS,
    getEventSeriesSchemaFromCard,
    getCardNameFromField as getSeriesCardNameFromField,
    cardFieldsMapping as seriesCardFieldsMapping,
} from '@/event/series';
import {
    EmitDeleteLink,
    EmitFormField,
    EmitMultilinkField,
    EmitSelectField,
    EmitToggleField,
    EventMultiLinks,
    EventSeriesDetails,
    EventSeriesDetailsErrors,
    EventSeriesMultiLinks,
    EventSeriesStatus,
    EventStatus,
    EventUpdate,
    EventUpdateErrors,
    UpdateEventMultilinkParams,
    UpdateEventSeriesMultilinkParams,
} from '@/event/types';
import {
    CreateEventSeriesDocument,
    CreateLinkDocument,
    CreateMultiLinkDocument,
    DeleteEventDocument,
    DeleteLinkDocument,
    DeleteSeriesDocument,
    GetEventDocument,
    GetEventQuery,
    GetSeriesDocument,
    GetSeriesQuery,
    SetSingleEventDocument,
    UpdateEventDocument,
    UpdateEventMultiLinkDocument,
    UpdateEventSeriesDocument,
    UpdateEventSeriesMultiLinkDocument,
    UpdateEventSeriesStatusDocument,
    UpdateEventStatusDocument,
    UpdateLinkDocument,
} from '@/generated/graphql';
import { useFileUpload } from '@/upload/upload';
import { getImageUrl } from '@/utils/s3';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { Ref, computed, reactive, ref, toRefs, unref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { z } from 'zod';

function processMultilink(formField: EmitMultilinkField, multilinks: EventSeriesMultiLinks | EventMultiLinks) {
    const { input, field } = formField;
    const { event, link, fieldName } = input;

    const target = event.target as HTMLInputElement;
    const value = target.value;

    const parse = LinkSchema.shape[fieldName as keyof LinkSchemaType].safeParse(value);
    if (!parse.success) {
        link.errors[fieldName as keyof LinkSchemaType] = parse.error.format()._errors;
        link[fieldName] = value;
    } else {
        link.errors[fieldName as keyof LinkSchemaType] = [];
        link[fieldName] = parse.data;
    }

    // check complete link too
    const linkParse = LinkSchema.safeParse(link);
    if (!linkParse.success) {
        const flattenedErrors = linkParse.error.flatten().fieldErrors;
        Object.assign(link.errors, flattenedErrors);
    } else {
        link.errors = { name: [], description: [], to: [] };
    }

    // check if the last link of the multlink field was filled
    const multilink = multilinks[field as keyof typeof multilinks];
    const nodes = multilink.links?.nodes;
    if (nodes) {
        const lastLink = nodes[nodes.length - 1];
        if (lastLink.name.length > 0 || lastLink.to.length > 0 || lastLink.description.length > 0) {
            nodes.push(emptyLink(multilink.id));
        }
    }
}

export function useEventSeriesForm(seriesId: Ref<string>) {
    const toast = useToast();
    const fluent = useFluent();
    const isAdmin = auth.isAdmin();
    const router = useRouter();
    const fileUpload = useFileUpload('image/*');

    const { result: getSeries, refetch } = useQuery(
        GetSeriesDocument,
        computed(() => ({ id: unref(seriesId) })),
    );

    const createSeries = useMutation(CreateEventSeriesDocument);
    const updateSeries = useMutation(UpdateEventSeriesDocument);
    const setSingleEvent = useMutation(SetSingleEventDocument);
    const createMultilink = useMutation(CreateMultiLinkDocument);
    const updateEventSeriesMultilink = useMutation(UpdateEventSeriesMultiLinkDocument);

    // multilinks
    const deleteLink = useMutation(DeleteLinkDocument);
    const createLink = useMutation(CreateLinkDocument);
    const updateLink = useMutation(UpdateLinkDocument);

    const loadedSeries = computed(() => getSeries.value?.eventSeries);

    const newSeries = () =>
        reactive<EventSeriesDetails>({
            id: '',
            nameShort: '',
            nameLong: '',
            subtitle: '',
            status: 'active',
            numberShort: '',
            foundationYear: undefined,
            notes: '',
            description: '',
            category: '',
            type: '',
            isTechnical: undefined,
            isPublic: undefined,
            partnersOverviewName: '',
            partnersOverviewLink: '',
            additionalPartners: '',
            companyGroup: '',
            businessDomain: '',
            department: '',
            organisationalChanges: '',
            notesDayOrder: '',
            notesSetup: '',
            notesEarlyConstructionInternal: '',
            notesEarlyConstructionExternal: '',
            notesConstruction: '',
            notesExecution: '',
            notesDismantling: '',
            notesExtendedDismantlingExternal: '',
            notesExtendedDismantlingInternal: '',
            notesTechnicalDismantling: '',
            notesDayOrderHistory: '',
            notesAppointmentRules: '',
            notesMonths: '',
            notesHolidaysNrw: '',
            notesHolidaysNational: '',
            notesHolidaysInternational: '',
            notesPublicHolidaysNrw: '',
            notesPublicHolidaysNational: '',
            notesPublicHolidaysInternational: '',
            otherNotesAppointmentRules: '',
            appointmentHistory: '',
            cycle: '',
            cycleChanges: '',
            allocationRules: '',
            locationHistory: '',
            otherAllocationNeeds: '',
            notesHallExhibitionAreas: '',
            notesBoulevardSections: '',
            notesPassages: '',
            notesEntrances: '',
            notesServiceCenters: '',
            notesConferenceRooms: '',
            notesOfficesInternal: '',
            notesStorageInternal: '',
            notesOfficesExternal: '',
            notesStorageExternal: '',
            notesAreas: '',
            notesParking: '',
            iconUpload: '',
            events: {
                nodes: [],
            },
            icon: {
                original: '',
            },
            singleEvent: false,
        });
    const newErrors = () =>
        reactive<EventSeriesDetailsErrors>({
            id: [],
            nameShort: [],
            nameLong: [],
            subtitle: [],
            status: [],
            numberShort: [],
            foundationYear: [],
            notes: [],
            description: [],
            category: [],
            type: [],
            isTechnical: [],
            isPublic: [],
            icon: [],
            iconUpload: [],
            partnersOverviewName: [],
            partnersOverviewLink: [],
            additionalPartners: [],
            companyGroup: [],
            businessDomain: [],
            department: [],
            organisationalChanges: [],
            notesDayOrder: [],
            notesSetup: [],
            notesEarlyConstructionInternal: [],
            notesEarlyConstructionExternal: [],
            notesConstruction: [],
            notesExecution: [],
            notesDismantling: [],
            notesExtendedDismantlingExternal: [],
            notesExtendedDismantlingInternal: [],
            notesTechnicalDismantling: [],
            notesDayOrderHistory: [],
            notesAppointmentRules: [],
            notesMonths: [],
            notesHolidaysNrw: [],
            notesHolidaysNational: [],
            notesHolidaysInternational: [],
            notesPublicHolidaysNrw: [],
            notesPublicHolidaysNational: [],
            notesPublicHolidaysInternational: [],
            otherNotesAppointmentRules: [],
            appointmentHistory: [],
            cycle: [],
            cycleChanges: [],
            allocationRules: [],
            locationHistory: [],
            otherAllocationNeeds: [],
            notesHallExhibitionAreas: [],
            notesBoulevardSections: [],
            notesPassages: [],
            notesEntrances: [],
            notesServiceCenters: [],
            notesConferenceRooms: [],
            notesOfficesInternal: [],
            notesStorageInternal: [],
            notesOfficesExternal: [],
            notesStorageExternal: [],
            notesAreas: [],
            notesParking: [],
            events: [],
            singleEvent: [],
        });

    const newMultiLinks = () =>
        reactive<EventSeriesMultiLinks>({
            organizers: emptyMultilink(),
            executors: emptyMultilink(),
            sponsors: emptyMultilink(),
            extraDocuments: emptyMultilink(),
            extraSystems: emptyMultilink(),
            externalData: emptyMultilink(),
            internalData: emptyMultilink(),
            subeventsLocal: emptyMultilink(),
            subeventsOtherLocations: emptyMultilink(),
            fairAbroad: emptyMultilink(),
            fairAbroadCooperation: emptyMultilink(),
            competitionNational: emptyMultilink(),
            otherCompetitionNational: emptyMultilink(),
            competitionInternational: emptyMultilink(),
            otherCompetitionInternational: emptyMultilink(),
            favoredParallelsInternal: emptyMultilink(),
            favoredParallelsExternal: emptyMultilink(),
            otherLinksInternal: emptyMultilink(),
            otherLinksExternal: emptyMultilink(),
        });

    const state = reactive({
        series: newSeries(),
        errors: newErrors(),
        activeCard: ref<ActiveCard>({ id: '', status: 'View', label: '' }),
        fileUploadLoading: fileUpload.loading,
        oldIcon: '',
        multilinks: newMultiLinks(),
    });

    const createMultiLink = async (field: string) => {
        const res = await createMultilink.mutate({ name: field });
        return res?.data?.createMultilink?.multilink?.id;
    };

    const createInitialMultiLinks = async () => {
        const params: UpdateEventSeriesMultilinkParams = { id: unref(seriesId) };
        for (const f of SERIES_MULTILINK_FIELDS) {
            const multilink = state.series[f as keyof Multilink] as Multilink | undefined | null;
            if (!multilink || !multilink.id) {
                const id = await createMultiLink(f);
                const key = `${f}Id`;
                params[key] = id;
            }
        }
        if (Object.keys(params).length > 1) {
            const res = await updateEventSeriesMultilink.mutate(params);
            if (res?.data?.updateEventSeries?.eventSeries) {
                return res?.data?.updateEventSeries?.eventSeries;
            }
        }
        return {};
    };

    const setInitialLinks = () => {
        for (const f of SERIES_MULTILINK_FIELDS) {
            const multilink = state.series[f as keyof Multilink] as Multilink | undefined | null;
            if (multilink && multilink.id) {
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[f as keyof EventSeriesMultiLinks] = formMultilink;
            }
        }
    };

    watch(
        loadedSeries,
        async (data) => {
            if (data) {
                state.series = Object.assign(newSeries(), data);
                if (state.series.id) {
                    // creation of initial multilinks if they are missing
                    await createInitialMultiLinks();
                    setInitialLinks();
                }
            }
        },
        { immediate: true },
    );

    const seriesMultiLinks = computed(() => {
        const result: FormLink[] = [];
        for (const f of SERIES_MULTILINK_FIELDS) {
            const multilinks = state.multilinks[f as keyof EventSeriesMultiLinks];
            result.push(...(multilinks.links?.nodes ?? []));
        }
        return result;
    });

    const linksToCreate = computed(() =>
        seriesMultiLinks.value.filter(
            (link) => link.status === 'new' && link.name.length > 0 && link.to.length > 0 && link.multilinkId,
        ),
    );
    const linksToDelete = computed(() => seriesMultiLinks.value.filter((link) => link.id && link.status === 'deleted'));
    const linksToUpdate = computed(() => seriesMultiLinks.value.filter((link) => link.id && link.status === 'prop'));

    function getDisabledExplanation(id: string) {
        if (state.activeCard.id !== '' && state.activeCard.status !== 'View') {
            const navItemRouteName = router.currentRoute.value.name;
            if (navItemRouteName && navItemRouteName !== id) {
                return ref(fluent.$t('tabs-disabled', { name: state.activeCard.label }));
            }
        }

        return ref('');
    }

    function isFieldDirty(fieldName: string) {
        if (SERIES_MULTILINK_FIELDS.includes(fieldName)) {
            const currentMultilink = state.multilinks[fieldName as keyof EventSeriesMultiLinks];
            const originalMultilink = state.series[fieldName as keyof EventSeriesMultiLinks] as Multilink;
            return isMultiLinkDirty(currentMultilink, originalMultilink);
        } else {
            const oldSeries = loadedSeries.value ?? {};
            const oldValue = oldSeries[fieldName as keyof GetSeriesQuery['eventSeries']];
            const newValue = state.series[fieldName];
            return newValue !== oldValue;
        }
    }

    function isFormDirty(cardId: string) {
        const fields = seriesCardFieldsMapping.get(cardId);
        return fields?.some((f) => isFieldDirty(f));
    }

    function checkCardStatus(fieldName: string) {
        const cardId = getSeriesCardNameFromField(fieldName);
        if (isFormDirty(cardId)) {
            state.activeCard.status = 'Edit-Touched';
        } else {
            state.activeCard.status = 'Edit-Untouched';
        }
    }

    // FORM HANDLING

    type schemaType = z.infer<typeof EventSeriesSchema>;

    // IdentifiersForm, TypeDefinitionForm (event type), CycleForm

    function handleSelectChange(formField: EmitSelectField) {
        const { item, fieldName } = formField;
        let value = '';
        if (item) value = item.id;
        const parse = EventSeriesSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.series[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.series[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleCheckboxInput(formField: EmitFormField) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.checked;
        const parse = EventSeriesSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.series[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.series[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleFormField(formField: EmitFormField) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const parse = EventSeriesSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.series[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.series[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleToggleField(formField: EmitToggleField) {
        const { value, fieldName } = formField;
        const parse = EventSeriesSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.series[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.series[fieldName] = parse.data;
        }
    }

    function handleMultilink(formField: EmitMultilinkField) {
        processMultilink(formField, state.multilinks);
        checkCardStatus(formField.field);
    }

    function deleteMultilinkLink(formField: EmitDeleteLink) {
        const { link, fieldName } = formField;
        link.status = 'deleted';
        checkCardStatus(fieldName);
    }

    function resetActiveCard() {
        state.activeCard.id = '';
        state.activeCard.label = '';
        state.activeCard.status = 'View';
    }

    async function saveMultiLinks() {
        for (const link of linksToCreate.value) {
            await createLink.mutate({
                multiLinkId: link.multilinkId,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
        for (const link of linksToDelete.value) {
            await deleteLink.mutate({ id: link.id });
        }
        for (const link of linksToUpdate.value) {
            await updateLink.mutate({
                id: link.id,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
    }

    async function onSaveForm(cardId: string) {
        // check basic fields for errors
        const saveSchema = getEventSeriesSchemaFromCard(cardId) ?? EventSeriesSchema;
        const parse = saveSchema.safeParse(state.series);
        if (!parse.success) {
            const flattenedErrors = parse.error.flatten().fieldErrors;
            Object.assign(state.errors, flattenedErrors);
            return;
        }

        if (checkForLinkErrors(seriesMultiLinks.value)) return;
        await saveMultiLinks();

        // trim all strings
        const data = parse.data;
        Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));

        const res = await updateSeries.mutate({ id: unref(seriesId), ...data });
        const { id, nameShort } = res?.data?.updateEventSeries?.eventSeries ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('update-success-notification', { type: 'eventseries', name: nameShort }));
            // TODO the apollo cache seems to update its cache correctly but vue does not register
            // the change of event.icon.original. why?
            refetch();
        }

        resetActiveCard();
    }

    async function onSaveCreateForm() {
        const parse = EventSeriesCreateSchema.safeParse(state.series);
        if (!parse.success) {
            const flattenedErrors = parse.error.flatten().fieldErrors;
            Object.assign(state.errors, flattenedErrors);
            return;
        }
        const res = await createSeries.mutate(parse.data);
        const { id, nameShort } = res?.data?.createEventSeries?.eventSeries ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('create-success-notification', { type: 'eventseries', name: nameShort }));
            return id;
        }
        return '';
    }

    function onEnterEditMode(data: { id: string; label: string }) {
        state.activeCard.id = data.id;
        state.activeCard.label = data.label;
        state.activeCard.status = 'Edit-Untouched';
    }

    function onLeaveEditMode() {
        resetActiveCard();
    }

    function resetCardFields(cardId: string) {
        const fields = seriesCardFieldsMapping.get(cardId);
        const oldSeries = loadedSeries.value ?? {};
        fields?.forEach((f) => {
            if (f === 'iconUpload') {
                fileUpload.abort();
                state.series.iconUpload = '';
                if (state.oldIcon) {
                    state.series.icon = { original: state.oldIcon };
                    state.oldIcon = '';
                }
            } else if (SERIES_MULTILINK_FIELDS.includes(f)) {
                const multilink = state.series[f as keyof EventSeriesMultiLinks] as Multilink;
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[f as keyof EventSeriesMultiLinks] = formMultilink;
            } else {
                state.series[f] = oldSeries[f as keyof GetSeriesQuery['eventSeries']];
            }
        });
        state.errors = newErrors();
    }

    function onDiscardChanges(cardId: string) {
        resetActiveCard();
        resetCardFields(cardId);
    }

    // FILE UPLOAD

    async function uploadIcon(event: Event) {
        const file = await fileUpload.pickAndUpload(event);
        state.oldIcon = state.series.icon?.original ?? '';
        state.series.iconUpload = file ?? '';
        checkCardStatus('iconUpload');
    }

    async function deleteIcon() {
        fileUpload.abort();
        state.series.iconUpload = '-';
        if (state.series.icon?.original) {
            state.oldIcon = state.series.icon?.original ?? '';
            state.series.icon = undefined;
        }
        checkCardStatus('iconUpload');
    }

    const createEventDisabledExplanation = computed(() => {
        if (!isAdmin) {
            return fluent.$t('no-permission-to-create');
        }
        if (state.series.singleEvent && state.series.events && state.series.events.nodes.length >= 1) {
            return fluent.$t('single-event-limit-reached');
        }
        return undefined;
    });

    const convertDisabledExplanation = computed(() => {
        if (!isAdmin) {
            return fluent.$t('no-permission-to-edit');
        }
        if (!state.series.singleEvent && state.series.events && state.series.events.nodes.length > 1) {
            return fluent.$t('series-contains-multiple-events');
        }
        return undefined;
    });

    const deleteDisabledExplanation = computed(() => {
        if (!isAdmin) return fluent.$t('no-permission-to-edit');
        if (state.series.events && state.series.events.nodes.length > 0) return fluent.$t('series-has-events');
        return undefined;
    });

    const deleteMutation = useMutation(DeleteSeriesDocument, {
        refetchQueries: ['GetEvents'],
    });
    async function deleteSeries() {
        if (!isAdmin) return;
        if (!confirm(fluent.$t('confirm-delete-series', { series: loadedSeries.value?.nameShort ?? '' }))) return;

        const name = state.series.nameShort ?? '';
        const res = await deleteMutation.mutate({ seriesId: unref(seriesId) });
        if (res?.data) {
            toast.success(fluent.$t('delete-success-notification', { type: 'eventseries', name }));
            router.replace({ name: 'events' });
        }
    }

    const iconUrl = computed(() => fileUpload.fileUrl.value ?? getImageUrl(state.series.icon?.original) ?? '');

    const toggleSingleEvent = async () => {
        const res = await setSingleEvent.mutate({ id: unref(seriesId), singleEvent: !state.series.singleEvent });
        const { id, nameShort } = res?.data?.updateEventSeries?.eventSeries ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('update-success-notification', { type: 'eventseries', name: nameShort }));
        }
    };

    const updateEventSeriesStatus = useMutation(UpdateEventSeriesStatusDocument);

    async function updateSeriesStatus(status: EventSeriesStatus) {
        if (status === state.series.status) return;
        const seriesId = state.series.id;
        const response = await updateEventSeriesStatus.mutate({ seriesId, status });
        if (response?.errors) throw new Error('Could not update event series status');
        const newStatus = response?.data?.updateEventSeries?.eventSeries?.status;
        if (newStatus) state.series.status = newStatus as EventSeriesStatus;
    }

    return {
        ...toRefs(state),
        handleSelectChange,
        handleFormField,
        handleCheckboxInput,
        handleToggleField,
        onSaveForm,
        onSaveCreateForm,
        onEnterEditMode,
        onLeaveEditMode,
        onDiscardChanges,
        uploadIcon,
        deleteIcon,
        deleteSeries,
        createEventDisabledExplanation,
        convertDisabledExplanation,
        deleteDisabledExplanation,
        getDisabledExplanation,
        iconUrl,
        toggleSingleEvent,
        handleMultilink,
        deleteMultilinkLink,
        updateStatus: updateSeriesStatus,
        updateStatusLoading: updateEventSeriesStatus.loading,
        updateStatusError: updateEventSeriesStatus.error,
    };
}

export function useEventForm(eventId: Ref<string>) {
    const toast = useToast();
    const fluent = useFluent();
    const router = useRouter();
    const fileUpload = useFileUpload('image/*');

    const { result: getEvent, refetch } = useQuery(
        GetEventDocument,
        computed(() => ({ id: unref(eventId) })),
    );
    const updateEvent = useMutation(UpdateEventDocument);
    const createMultilink = useMutation(CreateMultiLinkDocument);
    const updateEventMultilink = useMutation(UpdateEventMultiLinkDocument);

    // multilinks
    const deleteLink = useMutation(DeleteLinkDocument);
    const createLink = useMutation(CreateLinkDocument);
    const updateLink = useMutation(UpdateLinkDocument);

    const loadedEvent = computed(() => getEvent.value?.event);

    const newEvent = () =>
        reactive<EventUpdate>({
            id: '',
            nameShort: '',
            nameLong: '',
            subtitle: '',
            executionYear: undefined,
            description: '',
            annualSpecifics: '',
            companyGroup: '',
            businessDomain: '',
            department: '',
            responsible: '',
            assistant: '',
            teamChanges: '',
            status: 'active-option',
            iconUpload: '',
            series: undefined,
            icon: {
                original: '',
            },
        });
    const newErrors = () =>
        reactive<EventUpdateErrors>({
            id: [],
            nameShort: [],
            nameLong: [],
            subtitle: [],
            executionYear: [],
            description: [],
            annualSpecifics: [],
            companyGroup: [],
            businessDomain: [],
            department: [],
            responsible: [],
            assistant: [],
            teamChanges: [],
            status: [],
            iconUpload: [],
        });

    const newMultiLinks = () =>
        reactive<EventMultiLinks>({
            extraDocuments: emptyMultilink(),
            extraSystems: emptyMultilink(),
            externalData: emptyMultilink(),
            internalData: emptyMultilink(),
            subeventsLocal: emptyMultilink(),
            subeventsOtherLocations: emptyMultilink(),
            fairAbroad: emptyMultilink(),
            fairAbroadCooperation: emptyMultilink(),
            competitionNational: emptyMultilink(),
            otherCompetitionNational: emptyMultilink(),
            competitionInternational: emptyMultilink(),
            otherCompetitionInternational: emptyMultilink(),
            favoredParallelsInternal: emptyMultilink(),
            favoredParallelsExternal: emptyMultilink(),
            otherLinksInternal: emptyMultilink(),
            otherLinksExternal: emptyMultilink(),
        });

    const state = reactive({
        event: newEvent(),
        errors: newErrors(),
        activeCard: ref<ActiveCard>({ id: '', status: 'View', label: '' }),
        fileUploadLoading: fileUpload.loading,
        oldIcon: '',
        multilinks: newMultiLinks(),
    });

    const createMultiLink = async (field: string) => {
        const res = await createMultilink.mutate({ name: field });
        return res?.data?.createMultilink?.multilink?.id;
    };

    const createInitialMultiLinks = async () => {
        const params: UpdateEventMultilinkParams = { id: unref(eventId) };
        for (const f of EVENT_MULTILINK_FIELDS) {
            const multilink = state.event[f as keyof Multilink] as Multilink | undefined | null;
            if (!multilink || !multilink.id) {
                const id = await createMultiLink(f);
                const key = `${f}Id`;
                params[key] = id;
            }
        }
        if (Object.keys(params).length > 1) {
            const res = await updateEventMultilink.mutate(params);
            if (res?.data?.updateEvent?.event) {
                return res?.data?.updateEvent?.event;
            }
        }
        return {};
    };

    const setInitialLinks = () => {
        for (const f of EVENT_MULTILINK_FIELDS) {
            const multilink = state.event[f as keyof Multilink] as Multilink | undefined | null;
            if (multilink && multilink.id) {
                const formMultilink = toFormMultilink(multilink);
                formMultilink.links.nodes.push(emptyLink(formMultilink.id));
                state.multilinks[f as keyof EventMultiLinks] = formMultilink;
            }
        }
    };

    watch(
        loadedEvent,
        async (data) => {
            if (data) {
                state.event = Object.assign(newEvent(), data);
                if (state.event.id) {
                    // creation of initial multilinks if they are missing
                    await createInitialMultiLinks();
                    setInitialLinks();
                }
            }
        },
        { immediate: true },
    );

    const eventMultiLinks = computed(() => {
        const result: FormLink[] = [];
        for (const f of EVENT_MULTILINK_FIELDS) {
            const multilinks = state.multilinks[f as keyof EventMultiLinks];
            result.push(...(multilinks.links?.nodes ?? []));
        }
        return result;
    });

    const linksToCreate = computed(() =>
        eventMultiLinks.value.filter(
            (link) => link.status === 'new' && link.name.length > 0 && link.to.length > 0 && link.multilinkId,
        ),
    );
    const linksToDelete = computed(() => eventMultiLinks.value.filter((link) => link.id && link.status === 'deleted'));
    const linksToUpdate = computed(() => eventMultiLinks.value.filter((link) => link.id && link.status === 'prop'));

    function getDisabledExplanation(id: string) {
        if (state.activeCard.id !== '' && state.activeCard.status !== 'View') {
            const navItemRouteName = router.currentRoute.value.name;
            if (navItemRouteName && navItemRouteName !== id) {
                return ref(fluent.$t('tabs-disabled', { name: state.activeCard.label }));
            }
        }

        return ref('');
    }

    function isFieldDirty(fieldName: string) {
        if (EVENT_MULTILINK_FIELDS.includes(fieldName)) {
            const currentMultilink = state.multilinks[fieldName as keyof EventMultiLinks];
            const originalMultilink = state.event[fieldName as keyof EventMultiLinks] as Multilink;
            return isMultiLinkDirty(currentMultilink, originalMultilink);
        } else {
            const oldEvent = loadedEvent.value ?? {};
            const oldValue = oldEvent[fieldName as keyof GetEventQuery['event']];
            return state.event[fieldName] !== oldValue;
        }
    }

    function isFormDirty(cardId: string) {
        const fields = eventCardFieldsMapping.get(cardId);
        return fields?.some((f) => isFieldDirty(f));
    }

    function checkCardStatus(fieldName: string) {
        const cardId = getEventCardNameFromField(fieldName);
        if (isFormDirty(cardId)) {
            state.activeCard.status = 'Edit-Touched';
        } else {
            state.activeCard.status = 'Edit-Untouched';
        }
    }

    // FORM HANDLING

    type schemaType = z.infer<typeof EventSchema>;

    function handleSelectChange(formField: EmitSelectField) {
        const { item, fieldName } = formField;
        let value = '';
        if (item) value = item.id;
        const parse = EventSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.event[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.event[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleCheckboxInput(formField: EmitFormField) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.checked;
        const parse = EventSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.event[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.event[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleFormField(formField: EmitFormField) {
        const { event, fieldName } = formField;
        const target = event.target as HTMLInputElement;
        const value = target.value;
        const parse = EventSchema.shape[fieldName as keyof schemaType].safeParse(value);
        if (!parse.success) {
            state.errors[fieldName] = parse.error.format()._errors;
            state.event[fieldName] = value;
        } else {
            state.errors[fieldName] = [];
            state.event[fieldName] = parse.data;
        }
        checkCardStatus(fieldName);
    }

    function handleMultilink(formField: EmitMultilinkField) {
        processMultilink(formField, state.multilinks);
        checkCardStatus(formField.field);
    }

    function deleteMultilinkLink(formField: EmitDeleteLink) {
        const { link, fieldName } = formField;
        link.status = 'deleted';
        checkCardStatus(fieldName);
    }

    function checkForLinkErrors(links: FormLink[]) {
        return links.some(
            (link) => link.errors.name.length > 0 || link.errors.description.length > 0 || link.errors.to.length > 0,
        );
    }

    async function saveMultiLinks() {
        for (const link of linksToCreate.value) {
            await createLink.mutate({
                multiLinkId: link.multilinkId,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
        for (const link of linksToDelete.value) {
            await deleteLink.mutate({ id: link.id });
        }
        for (const link of linksToUpdate.value) {
            await updateLink.mutate({
                id: link.id,
                name: link.name.trim(),
                to: link.to.trim(),
                description: link.description.trim(),
            });
        }
    }

    async function onSaveForm(cardId: string) {
        const saveSchema = getEventSchemaFromCard(cardId) ?? EventSchema;
        const parse = saveSchema.safeParse(state.event);
        if (!parse.success) {
            const flattenedErrors = parse.error.flatten().fieldErrors;
            Object.assign(state.errors, flattenedErrors);
            return;
        }

        if (checkForLinkErrors(eventMultiLinks.value)) return;
        await saveMultiLinks();

        // trim all strings
        const data = parse.data;
        Object.keys(data).forEach((k) => (data[k] = typeof data[k] === 'string' ? data[k].trim() : data[k]));

        const res = await updateEvent.mutate({ id: unref(eventId), ...data });
        const { id, nameShort } = res?.data?.updateEvent?.event ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('update-success-notification', { type: 'event', name: nameShort }));
            // TODO the apollo cache seems to update its cache correctly but vue does not register
            // the change of event.icon.original. why?
            refetch();
            resetActiveCard();
        }
    }

    function resetActiveCard() {
        state.activeCard.id = '';
        state.activeCard.label = '';
        state.activeCard.status = 'View';
    }

    function onEnterEditMode(data: { id: string; label: string }) {
        state.activeCard.id = data.id;
        state.activeCard.label = data.label;
        state.activeCard.status = 'Edit-Untouched';
    }

    function onLeaveEditMode() {
        resetActiveCard();
    }

    function updateActiveCard(id: string, label: string, status: EditableCardStatus) {
        state.activeCard = { id, label, status };
    }

    function resetCardFields(cardId: string) {
        const fields = eventCardFieldsMapping.get(cardId);
        const oldEvent = loadedEvent.value ?? {};
        fields?.forEach((f) => {
            if (f === 'iconUpload') {
                fileUpload.abort();
                state.event.iconUpload = '';
                if (state.oldIcon) {
                    state.event.icon = { original: state.oldIcon };
                    state.oldIcon = '';
                }
            } else {
                state.event[f] = oldEvent[f as keyof GetEventQuery['event']];
            }
        });
        state.errors = newErrors();
    }

    function onDiscardChanges(cardId: string) {
        resetActiveCard();
        resetCardFields(cardId);
    }

    // FILE UPLOAD

    const iconUrl = computed(
        () => fileUpload.fileUrl.value ?? getImageUrl(state.event.icon?.original ?? state.event.series?.icon?.original),
    );

    const iconFromParent = computed(() => {
        if (state.event.series?.icon?.original && !state.event.icon?.original && !fileUpload.fileUrl.value) {
            return true;
        }
        return false;
    });

    async function uploadIcon(event: Event) {
        const file = await fileUpload.pickAndUpload(event);
        state.event.iconUpload = file ?? '';
        checkCardStatus('iconUpload');
    }

    async function deleteIcon() {
        fileUpload.abort();
        state.event.iconUpload = '-';
        if (state.event.icon?.original) {
            state.oldIcon = state.event.icon?.original;
            state.event.icon = undefined;
        }
        checkCardStatus('iconUpload');
    }

    const deleteEvent = useMutation(DeleteEventDocument);
    const onDeleteEvent = async () => {
        if (!confirm(fluent.$t('confirm-delete-event', { event: state.event.nameShort ?? '' }))) return;

        const res = await deleteEvent.mutate({ eventId: unref(eventId) });
        const { id, nameShort } = res?.data?.deleteEvent?.event ?? {};
        if (id && nameShort) {
            toast.success(fluent.$t('delete-success-notification', { type: 'event', name: nameShort }));
            router.replace({ name: 'events' });
        }
    };

    const updateEventStatus = useMutation(UpdateEventStatusDocument);

    async function updateStatus(status: EventStatus) {
        if (status === state.event.status) return;
        const eventId = state.event.id;
        const response = await updateEventStatus.mutate({ eventId, status });
        if (response?.errors) throw new Error('Could not update event series status');
        const newStatus = response?.data?.updateEvent?.event?.status;
        if (newStatus) state.event.status = newStatus as EventStatus;
    }

    return {
        ...toRefs(state),
        handleSelectChange,
        handleFormField,
        handleCheckboxInput,
        handleMultilink,
        deleteMultilinkLink,
        onSaveForm,
        onEnterEditMode,
        onLeaveEditMode,
        uploadIcon,
        deleteIcon,
        onDiscardChanges,
        onDeleteEvent,
        checkCardStatus,
        getDisabledExplanation,
        iconUrl,
        iconFromParent,
        updateActiveCard,
        updateStatus,
        updateStatusLoading: updateEventStatus.loading,
        updateStatusError: updateEventStatus.error,
    };
}
