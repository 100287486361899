<template>
    <div class="container">
        <TopBar v-if="zone" :title="zone.nameShort" :breadcrumbs="locationBreadcrumbs(zone)">
            <template #actions>
                <div class="flex gap-2">
                    <Button
                        size="Base"
                        variant="Danger-Strong"
                        :disabledExplanation="disabledExplanation"
                        @click="deleteZone"
                    >
                        <template #icon>
                            <DeleteForeverIcon :title="$t('location-delete')" />
                        </template>
                        {{ $t('delete') }}
                    </Button>
                    <DropDownSelector :options="actions" :disabled="!isAdmin" />
                </div>
            </template>
        </TopBar>

        <GQLLoading
            :query="getZoneQuery"
            skeleton-type="table-heading, list-item@3"
            :empty-error-msg="$t('empty-error', { type: 'zone', id: zoneId })"
            style="grid-column: 1; grid-row: 1 / span 2"
        >
            <LocationDetails
                v-if="zone"
                :location="zone"
                :location-types="zoneTypes"
                :to-edit="{ name: 'zone-edit', params: { zoneId } }"
                :can-edit="isAdmin"
            />
        </GQLLoading>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DropDownSelector from '@/components/DropDownSelector.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useZone } from '../zone';

const props = defineProps<{ zoneId: string }>();
const fluent = useFluent();
const isAdmin = auth.isAdmin();

const { zone, zoneTypes, getZoneQuery, deleteZone } = useZone(computed(() => props.zoneId));

const actions = [
    {
        title: fluent.$t('area'),
        description: fluent.$t('area-add-to-zone'),
        to: { name: 'area-add', zoneId: props.zoneId },
    },
];

const canDelete = computed(() => {
    const hasAllocations = zone.value?.areas.nodes.some((a) =>
        a.plots.nodes.some((p) => p.allocationPlots.nodes.length > 0),
    );
    return isAdmin && !hasAllocations;
});

const disabledExplanation = computed(() => {
    if (canDelete.value) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
