import MasterDetailLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import UserCreatePage from '@/user/pages/UserCreatePage.vue';
import UserOverviewPage from '@/user/pages/UserOverviewPage.vue';
import UserPage from '@/user/pages/UserPage.vue';
import UsersPage from '@/user/pages/UsersPage.vue';
import { RouteRecordRaw } from 'vue-router';
import UserUpdatePage from './pages/UserUpdatePage.vue';

export const userRoutes = <RouteRecordRaw[]>[
    {
        path: '/users',
        component: MasterDetailLayout,
        children: [
            {
                path: '',
                name: 'users',
                components: {
                    default: UsersPage,
                    detail: UserOverviewPage,
                },
            },
            {
                path: 'add',
                name: 'user-create',
                beforeEnter: requireAdmin,
                components: {
                    default: UsersPage,
                    detail: UserCreatePage,
                },
            },
            {
                path: ':userId',
                name: 'user-details',
                components: {
                    default: UsersPage,
                    detail: UserPage,
                },
                props: { detail: true },
            },
            {
                path: ':userId/edit',
                name: 'user-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: UsersPage,
                    detail: UserUpdatePage,
                },
                props: { detail: true },
            },
        ],
    },
];
