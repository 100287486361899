import { DateTime } from 'luxon';
import { AllocationPhaseUpdate } from '../Timeline.types';

/**
 * The database stores durations as half-open intervals (see README.md for details) which can lead to incorrectly displayed dates. See README.md for details.
 */
export function convertToDisplayedDateTime(input: DateTime): DateTime {
    return input.minus({ minutes: 1 });
}

export function convertToDatabaseDateTime(input: DateTime): DateTime {
    return input.plus({ minutes: 1 });
}

export function calculatePhaseUpdate(
    phases: AllocationPhaseUpdate[],
    allocation: { startDate: DateTime; endDate: DateTime },
): { startDateTime: DateTime; endDateTime: DateTime } {
    let startDayDelta = 0;
    let endDayDelta = 0;
    let durationInDays = 0;

    for (const phase of phases) {
        durationInDays = durationInDays + phase.cellSpanBefore + phase.cellSpan + phase.cellSpanAfter;

        if (phase.position === 'Before-Main' || phase.position === 'Main') {
            startDayDelta = startDayDelta + phase.cellSpanBefore;
        }

        if (phase.position === 'Main' || phase.position === 'After-Main') {
            endDayDelta = endDayDelta + phase.cellSpanAfter;
        }
    }

    return {
        startDateTime: allocation.startDate.minus({ days: startDayDelta }),
        endDateTime: allocation.endDate.plus({ days: endDayDelta }),
    };
}
