<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.5 11.333H4.667a3.213 3.213 0 0 1-2.359-.975A3.213 3.213 0 0 1 1.333 8c0-.922.325-1.708.975-2.358a3.213 3.213 0 0 1 2.359-.975H7.5v1H4.667c-.649 0-1.2.226-1.653.68a2.244 2.244 0 0 0-.68 1.65c0 .646.226 1.197.68 1.653a2.246 2.246 0 0 0 1.653.683H7.5v1ZM5.417 8.5v-1h5.166v1H5.417ZM8.5 11.333v-1h2.833c.648 0 1.2-.226 1.653-.68a2.24 2.24 0 0 0 .68-1.65c0-.646-.226-1.197-.68-1.653a2.245 2.245 0 0 0-1.653-.683H8.5v-1h2.833c.922 0 1.709.325 2.359.975.65.65.975 1.436.975 2.358 0 .922-.325 1.708-.975 2.358-.65.65-1.437.975-2.359.975H8.5Z"
        />
    </svg>
</template>

<script setup lang="ts">
type LinkIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<LinkIconProps>();
</script>
