import MasterDetailLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import CreateScenarioPage from '@/scenario/pages/CreateScenarioPage.vue';
import EditScenarioPage from '@/scenario/pages/EditScenarioPage.vue';
import ScenarioPage from '@/scenario/pages/ScenarioPage.vue';
import ScenariosPage from '@/scenario/pages/ScenariosPage.vue';
import { RouteRecordRaw, RouterView } from 'vue-router';

export const scenarioRoutes = <RouteRecordRaw[]>[
    {
        path: '/scenarios',
        component: MasterDetailLayout,
        children: [
            {
                path: '',
                name: 'scenarios',
                components: {
                    default: ScenariosPage,
                },
            },
            {
                path: ':scenarioId',
                components: {
                    default: ScenariosPage,
                    detail: RouterView,
                },
                children: [
                    {
                        path: '',
                        name: 'scenario-details',
                        component: ScenarioPage,
                        props: true,
                    },
                    {
                        path: 'edit',
                        name: 'edit-scenario',
                        beforeEnter: requireAdmin,
                        component: EditScenarioPage,
                        props: true,
                    },
                ],
            },
            {
                path: 'add',
                name: 'add-scenario',
                beforeEnter: requireAdmin,
                components: {
                    default: ScenariosPage,
                    detail: CreateScenarioPage,
                },
            },
        ],
    },
];
