import { ButtonSize, ButtonVariant } from './Button.types';

export function getVariantClasses(disabledExplanation: string | undefined, variant: ButtonVariant) {
    if (disabledExplanation) {
        return 'border border-gray-300 bg-gray-100 text-gray-500 cursor-not-allowed';
    }

    switch (variant) {
        case 'Transparent-Light':
            return `
                border border-gray-300 bg-transparent text-inherit
                hover:border-gray-800 hover:bg-gray-800 hover:text-gray-50
                active:border-gray-900 active:bg-gray-900 active:text-gray-50
            `;

        case 'Transparent':
            return `
                border border-gray-700 bg-transparent text-inherit
                hover:border-gray-800 hover:bg-gray-800 hover:text-gray-50
                active:border-gray-900 active:bg-gray-900 active:text-gray-50
            `;

        case 'Danger-Light':
            return `
                border border-red-700 bg-red-50 text-red-700
                hover:bg-red-700 hover:text-red-50
                active:border-red-900 active:bg-red-900 active:text-red-50
            `;

        case 'Danger-Strong':
            return `
                border border-red-700 bg-red-700 text-red-50
                hover:border-red-800 hover:bg-red-800 hover:text-red-50
                active:border-red-900 active:bg-red-900 active:text-red-50
            `;

        case 'Success-Light':
            return `
                border border-emerald-700 bg-emerald-50 text-emerald-700
                hover:bg-emerald-700 hover:text-emerald-50
                active:border-emerald-900 active:bg-emerald-900 active:text-emerald-50
            `;

        case 'Success-Strong':
            return `
                border border-emerald-700 bg-emerald-700 text-emerald-50
                hover:border-emerald-800 hover:bg-emerald-800 hover:text-emerald-50
                active:border-emerald-900 active:bg-emerald-900 active:text-emerald-50
            `;

        case 'Info-Light':
            return `
                border border-sky-700 bg-sky-50 text-sky-700
                hover:bg-sky-700 hover:text-sky-50
                active:border-sky-900 active:bg-sky-900 active:text-sky-50
            `;

        case 'Info-Strong':
            return `
                border border-sky-700 bg-sky-700 text-sky-50
                hover:border-sky-800 hover:bg-sky-800 hover:text-sky-50
                active:border-sky-900 active:bg-sky-900 active:text-sky-50
            `;

        case 'Neutral-Light':
            return `
                border border-gray-700 bg-gray-50 text-gray-700
                hover:bg-gray-700 hover:text-gray-50
                active:border-gray-900 active:bg-gray-900 active:text-gray-50
            `;

        case 'Neutral-Strong':
            return `
                border border-gray-700 bg-gray-700 text-gray-50
                hover:border-gray-800 hover:bg-gray-800 hover:text-gray-50
                active:border-gray-900 active:bg-gray-900 active:text-gray-50
            `;

        default:
            throw new Error(`Unknown button variant: ${variant}`);
    }
}

export function getFocusClasses(
    disabledExplanation: string | undefined,
    variant: ButtonVariant,
    hasDarkBackground: boolean | undefined,
) {
    if (disabledExplanation) {
        return 'focus-visible:outline-gray-400';
    }

    switch (variant) {
        case 'Transparent-Light':
        case 'Transparent':
        case 'Neutral-Light':
        case 'Neutral-Strong':
            if (hasDarkBackground) {
                return 'focus-visible:outline-gray-200';
            }
            return 'focus-visible:outline-gray-700';

        case 'Danger-Light':
        case 'Danger-Strong':
            if (hasDarkBackground) {
                return 'focus-visible:outline-red-200';
            }
            return 'focus-visible:outline-red-700';

        case 'Success-Light':
        case 'Success-Strong':
            if (hasDarkBackground) {
                return 'focus-visible:outline-emerald-200';
            }
            return 'focus-visible:outline-emerald-700';

        case 'Info-Light':
        case 'Info-Strong':
            if (hasDarkBackground) {
                return 'focus-visible:outline-sky-200';
            }
            return 'focus-visible:outline-sky-700';

        default:
            throw new Error(`Unknown button variant: ${variant}`);
    }
}

export function getSizeClasses(size: ButtonSize, hasIcon: boolean, hasText: boolean) {
    switch (size) {
        case 'Extra-Small':
            if (hasIcon) {
                // Has text?
                if (hasText) {
                    return 'text-xs py-[0.1875rem] pl-1.5 pr-2 leading-4';
                }

                return 'text-xs p-[0.1875rem] leading-4';
            } else {
                return 'text-xs py-[0.1875rem] px-2 leading-4';
            }

        case 'Small':
            if (hasIcon) {
                // Has text?
                if (hasText) {
                    return 'text-sm py-[0.3125rem] pl-1.5 pr-2 leading-5';
                }

                return 'text-sm p-[0.3125rem] leading-5';
            } else {
                return 'text-sm py-[0.3125rem] px-2 leading-5';
            }

        case 'Base':
            if (hasIcon) {
                // Has text?
                if (hasText) {
                    return 'text-base py-[0.4375rem] pl-1.5 pr-2 leading-6';
                }

                return 'text-base p-[0.5625rem] leading-6';
            } else {
                return 'text-base py-[0.4375rem] px-2 leading-6';
            }

        default:
            throw new Error(`Unknown button size: ${size}`);
    }
}

export function getIconVariantClasses(disabledExplanation: string | undefined, variant: ButtonVariant) {
    if (disabledExplanation) {
        return 'text-gray-500';
    }

    switch (variant) {
        case 'Transparent-Light':
        case 'Transparent':
            return 'text-inherit group-hover:text-gray-50';

        case 'Danger-Light':
            return 'group-active:text-red-50 text-red-700 group-hover:text-red-50';

        case 'Danger-Strong':
            return 'text-red-50 group-hover:text-red-50';

        case 'Success-Light':
            return 'group-active:text-emerald-50 text-emerald-700 group-hover:text-emerald-50';

        case 'Success-Strong':
            return 'text-emerald-50 group-hover:text-emerald-50';

        case 'Info-Light':
            return 'group-active:text-sky-50 text-sky-700 group-hover:text-sky-50';

        case 'Info-Strong':
            return 'text-sky-50 group-hover:text-sky-50';

        case 'Neutral-Light':
            return 'group-active:text-gray-50 text-gray-700 group-hover:text-gray-50';

        case 'Neutral-Strong':
            return 'text-gray-50 group-hover:text-gray-50';

        default:
            throw new Error(`Unknown button variant: ${variant}`);
    }
}
