<template>
    <GQLLoading
        :query="getQuery"
        skeleton-type="table-heading, list-item@3"
        cols="6"
        :empty-error-msg="$t('empty-error', { type: 'user', id: userId })"
    >
        <div v-if="user">
            <top-bar :title="user.name">
                <template #actions>
                    <div class="flex gap-2">
                        <Button
                            variant="Danger-Strong"
                            size="Base"
                            @click="deleteUser"
                            :disabledExplanation="deleteDisabledExplanation"
                        >
                            <template #icon>
                                <delete-forever-icon />
                            </template>
                            {{ $t('delete') }}
                        </Button>
                        <Button
                            variant="Info-Light"
                            size="Base"
                            @click="onEdit"
                            :disabledExplanation="editDisabledExplanation"
                        >
                            <template #icon>
                                <edit-icon />
                            </template>
                            {{ $t('edit') }}
                        </Button>
                    </div>
                </template>
            </top-bar>
            <div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 mx-8 mt-4 mb-8">
                <card :title="$t('informations')">
                    <description-list>
                        <description-list-item :label="$t('first-name')" :value="user.firstName">
                        </description-list-item>
                        <description-list-item :label="$t('last-name')" :value="user.lastName"> </description-list-item>
                        <description-list-item :label="$t('email')" :value="user.email"> </description-list-item>
                        <description-list-item :label="$t('is-admin')" :value="$t(user.isAdmin ? 'yes' : 'no')">
                        </description-list-item>
                    </description-list>
                </card>
                <card>
                    <div
                        class="bg-contain bg-center bg-no-repeat h-[200px] w-[400px]"
                        :style="`background-image: url(${iconUrl})`"
                    ></div>
                </card>
            </div>
        </div>
    </GQLLoading>
</template>

<script setup lang="ts">
import Button from '@/components/Button/Button.vue';
import Card from '@/components/Card.vue';
import DescriptionList from '@/components/DescriptionList.vue';
import DescriptionListItem from '@/components/DescriptionListItem.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import EditIcon from '@/components/Icon/EditIcon.vue';
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import router from '@/plugins/router';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useHeadSafe } from '@unhead/vue';
import { computed } from 'vue';
import { useUser } from '../user';

const props = defineProps<{ userId: string }>();
const userId = computed(() => props.userId);

const { getQuery, user, deleteUser, iconUrl, deleteDisabledExplanation, editDisabledExplanation } = useUser(userId);

const onEdit = () => {
    router.push({ name: 'user-edit', params: { userId: props.userId } });
};

useHeadSafe({ title: () => `${user.value.name} - ${$t('users')}` });
</script>
