import { PhaseForm } from '../Timeline.types';

export function calculateFormsForConfirmedAllocationParts(
    confirmedCellSpan: number,
    cellSpanBefore: number,
    cellSpanAfter: number,
    isLeftEndPosition: boolean,
    isRightEndPosition: boolean,
): { form: PhaseForm }[] {
    return [...Array(confirmedCellSpan)]
        .map((_, index) => {
            let isLeftEnd: boolean = false;
            let isRightEnd: boolean = false;

            // Very first position
            if (index === 0) {
                // Left side
                if (cellSpanBefore > 0) {
                    isLeftEnd = false;
                } else {
                    isLeftEnd = isLeftEndPosition;
                }
            }

            // Very last position (might be the same as the very first position)
            if (index === confirmedCellSpan - 1) {
                // Right side
                if (cellSpanAfter > 0) {
                    isRightEnd = false;
                } else {
                    isRightEnd = isRightEndPosition;
                }
            }

            // Middle positions
            if (index > 0 && index < confirmedCellSpan - 1) {
                isLeftEnd = false;
                isRightEnd = false;
            }

            return {
                isLeftEnd,
                isRightEnd,
            };
        })
        .map(({ isLeftEnd, isRightEnd }) => {
            if (isLeftEnd && isRightEnd) {
                return {
                    form: 'Single',
                };
            }

            if (isLeftEnd && !isRightEnd) {
                return {
                    form: 'Start',
                };
            }

            if (!isLeftEnd && isRightEnd) {
                return {
                    form: 'End',
                };
            }

            return {
                form: 'Middle',
            };
        });
}

export function calculateVariantsForConfirmedAllocationParts(
    forms: { form: PhaseForm }[],
    cellSpanBefore: number,
    cellSpanAfter: number,
    position: 'Before-Main' | 'Main' | 'After-Main',
): { form: PhaseForm; variant: 'Solid' | 'Deleted' }[] {
    if (
        (position === 'Before-Main' && cellSpanBefore < 0 && Math.abs(cellSpanBefore) > forms.length) ||
        (position === 'Main' &&
            cellSpanBefore + cellSpanAfter < 0 &&
            Math.abs(cellSpanBefore + cellSpanAfter) > forms.length) ||
        (position === 'After-Main' && cellSpanAfter < 0 && Math.abs(cellSpanAfter) > forms.length)
    ) {
        throw new Error("It's impossible to delete more cells than existing");
    }

    return forms.map(({ form }, index) => {
        const areLeftCellsDeleted = cellSpanBefore < 0;
        const areRightCellsDeleted = cellSpanAfter < 0;
        const isCurrentIndexLeftDeleted = index < Math.abs(cellSpanBefore);
        const isCurrentIndexRightDeleted = forms.length - index <= Math.abs(cellSpanAfter);

        if (position === 'Before-Main') {
            if (areLeftCellsDeleted && isCurrentIndexLeftDeleted) {
                return {
                    form,
                    variant: 'Deleted',
                };
            }
        }

        if (position === 'Main') {
            if (
                (areLeftCellsDeleted && isCurrentIndexLeftDeleted) ||
                (areRightCellsDeleted && isCurrentIndexRightDeleted)
            ) {
                return {
                    form,
                    variant: 'Deleted',
                };
            }
        }

        if (position === 'After-Main') {
            if (areRightCellsDeleted && isCurrentIndexRightDeleted) {
                return {
                    form,
                    variant: 'Deleted',
                };
            }
        }

        return {
            form,
            variant: 'Solid',
        };
    });
}

export function calculateInsertedPhases(
    cellSpan: number,
    isLeftEndPosition: boolean,
    isRightEndPosition: boolean,
): { form: PhaseForm }[] {
    if (cellSpan > 0) {
        return [...Array(cellSpan)].map((_, index) => {
            if (index === 0 && isLeftEndPosition) {
                return {
                    form: 'Start' as const,
                };
            }

            if (index === cellSpan - 1 && isRightEndPosition) {
                return {
                    form: 'End' as const,
                };
            }

            return {
                form: 'Middle' as const,
            };
        });
    }

    return [];
}
