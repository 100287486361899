<template>
    <BaseForm>
        <template #fields>
            <ComboboxField
                :value="selectedCycle"
                :label="$t('cycle')"
                :error-messages="errors.cycle"
                :items="CYCLE_ITEMS"
                @change="$emit('handleSelectChange', { item: $event, fieldName: 'cycle' })"
            />
            <TextAreaField
                :value="series.cycleChanges"
                :label="$t('cycle-changes')"
                :error-messages="errors.cycleChanges"
                @input="$emit('handleFormField', { event: $event, fieldName: 'cycleChanges' })"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import ComboboxField, { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import { CYCLE_ITEMS } from '@/event/forms/schemas';
import { EventSeriesDetails, EventSeriesDetailsErrors } from '@/event/types';
import { computed } from 'vue';

const props = defineProps<{ series: EventSeriesDetails; errors: EventSeriesDetailsErrors }>();
defineEmits<{
    (e: 'handleFormField', v: { event: Event; fieldName: string }): void;
    (e: 'handleSelectChange', v: { item: ComboboxItem | null; fieldName: string }): void;
}>();

const selectedCycle = computed(() => CYCLE_ITEMS.find((i) => i.id === props.series.cycle) ?? null);
</script>
