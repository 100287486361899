<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 13.333c-1.489 0-2.75-.516-3.783-1.55C3.183 10.75 2.667 9.49 2.667 8c0-1.489.516-2.75 1.55-3.783C5.25 3.183 6.51 2.667 8 2.667c.767 0 1.5.158 2.2.475.7.316 1.3.77 1.8 1.358V2.667h1.333v4.666H8.667V6h2.8a3.89 3.89 0 0 0-1.459-1.467A3.952 3.952 0 0 0 8 4c-1.111 0-2.055.389-2.833 1.167C4.389 5.944 4 6.889 4 8c0 1.111.389 2.056 1.167 2.833C5.945 11.611 6.889 12 8 12c.856 0 1.628-.245 2.317-.733a3.872 3.872 0 0 0 1.45-1.934h1.4a5.264 5.264 0 0 1-1.9 2.884A5.173 5.173 0 0 1 8 13.333Z"
        />
    </svg>
</template>

<script setup lang="ts">
type RefreshIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<RefreshIconProps>();
</script>
