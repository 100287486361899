import { DateTime } from 'luxon';
import { ZodTypeAny } from 'zod';
import { LocationType } from '../locationTypes';

export type DateOrInfinite = DateTime | 'infinity' | '-infinity';
export type TimeVariantAttributeValue<T> = {
    id?: string;
    value?: T;
    start: DateOrInfinite;
    end: DateOrInfinite;
};
export type TimeVariantAttribute<T> = TimeVariantAttributeValue<T>[];

export type FormFieldValueBase = string | number | boolean | string[] | undefined;
export type FormFieldValue = FormFieldValueBase | TimeVariantAttribute<FormFieldValueBase>;
export type FormValue = { [index: string]: FormFieldValue };

export type SnapshotAtTime = {
    time: DateOrInfinite;
    changes: FormField[];
    snapshot: any;
};

type BaseFormFieldValues = {
    title: string;
    dbName: string;
    isTimeVariant: boolean;
    timeVariantId?: string;
    group?: string;
    helper?: string;
    errorMessages?: string[];
    schema?: ZodTypeAny;
    valueLabel?: string;
    readonly?: boolean;
};

export type StringFormField = BaseFormFieldValues & {
    type: 'string';
    long: boolean;
    defaultValue: string;
    value?: string;
};

export type NumberFormField = BaseFormFieldValues & {
    type: 'number';
    defaultValue?: number;
    value?: number;
};

export type FloatFormField = BaseFormFieldValues & {
    type: 'float';
    unit: string;
    defaultValue?: number;
    value?: number;
};

export type CheckboxFormField = BaseFormFieldValues & {
    type: 'checkbox';
    defaultValue?: boolean;
    value?: boolean;
};

export type SelectFormField = BaseFormFieldValues & {
    type: 'select';
    items: readonly { id: string; name: string }[];
    defaultValue?: string;
    value?: string;
    gqlPathToTitle: string[];
};

export type FormField = StringFormField | NumberFormField | FloatFormField | CheckboxFormField | SelectFormField;

type SameRowLayout = string[];
export type FormGroupLayout = { sameRow?: SameRowLayout[] };

export type FormFieldGroup = {
    title: string;
    groups?: FormFieldSubGroup[];
    helper?: string;
    icon?: string;
    fields: FormField[];
    layout?: FormGroupLayout;
};

export type FormFieldSubGroup = { id: string; title: string };

export type Form = FormFieldGroup[];

export function compareDateOrInfinite(t1: DateOrInfinite, t2: DateOrInfinite) {
    if (t1 === t2) return 0;
    if (t1 === '-infinity' || t2 === 'infinity') return -1;
    if (t2 === '-infinity' || t1 === 'infinity') return 1;

    return t1.valueOf() - t2.valueOf();
}

export function dateOrInfiniteEqual(t1: DateOrInfinite, t2: DateOrInfinite) {
    return compareDateOrInfinite(t1, t2) === 0;
}

export type TimeVariantField = {
    nodes: TimeVariantValue[];
};

export function isTimeVariantField(field: any): field is TimeVariantField {
    return (field as TimeVariantField).nodes !== undefined;
}

export type TimeVariantValue = {
    id: string;
    start: string;
    end: string;
    value: string | boolean | number;
};

export type LocationDetailsResult = {
    __typename: LocationType;
    id: string;
    nameShort: string;
    nameLong: string;
    description: string;
    typeId?: string;
    nameExtra?: TimeVariantField;
    nameKeyMarketing?: TimeVariantField;
    nameKeyCad?: TimeVariantField;
    nameKeyEbms?: TimeVariantField;
    nameKeyCafm?: TimeVariantField;
    cadGrossSpaceMin?: TimeVariantField;
    cleaningGrossSpace?: TimeVariantField;
    sbvGrossSpace?: TimeVariantField;
    fkmGrossSpace?: TimeVariantField;
    cadLengthMin?: TimeVariantField;
    cadLengthMax?: TimeVariantField;
    cadWidthMin?: TimeVariantField;
    cadWidthMax?: TimeVariantField;
    cadHeightMin?: TimeVariantField;
    cadHeightMax?: TimeVariantField;
    capacityPax?: TimeVariantField;
    capacityCars?: TimeVariantField;
    capacityTrucks?: TimeVariantField;
    maxLoadFloor?: TimeVariantField;
    maxLoadCeiling?: TimeVariantField;
    tireLoadCar?: TimeVariantField;
    tireLoadTruck?: TimeVariantField;
    tireLoadForklift?: TimeVariantField;
    noVehicles?: TimeVariantField;
    daylight?: TimeVariantField;
    lightShutters?: TimeVariantField;
    phoneNumbers?: TimeVariantField;
    contactBooking?: TimeVariantField;
    clearanceConstruction?: TimeVariantField;
    keyIssuance?: TimeVariantField;
    keyIssuanceNotes?: TimeVariantField;
};

export type LocationSubType = {
    id: string;
    name: string;
};
