<template>
    <Transition name="content-form" mode="out-in">
        <slot></slot>
    </Transition>
</template>

<style>
/*
  Necessary CSS...
  Note: avoid using <style scoped> here since it
  does not apply to slot content.
*/

.content-form-enter-active,
.content-form-leave-active {
    transition: all 0.25s ease-out;
}

.content-form-enter-from {
    opacity: 0;
    transform: translateX(10px);
}

.content-form-leave-to {
    opacity: 0;
    transform: translateX(10px);
}
</style>
