<template>
    <TopBar :title="$t('event-overview')" />
    <PageContent size="md" class="m-4"></PageContent>
</template>

<script setup lang="ts">
import PageContent from '@/components/PageContent.vue';
import TopBar from '@/components/TopBar.vue';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';

const fluent = useFluent();
useHeadSafe({ title: () => fluent.$t('event-wiki') });
</script>
