import { $t } from '@/plugins/fluent';
import { defineStore } from 'pinia';
import { TimelineSection } from './TimelineView.types';

export type CheckboxStatus =
    | 'Checked'
    | 'Partially-Checked'
    | 'Not-Checked'
    | 'Disabled-Checked'
    | 'Disabled-Not-Checked'
    | 'No-Checkbox';

type LocationCheckbox = { checkboxStatus: CheckboxStatus; checkboxTooltip?: string };
export type LocationsCheckboxes = Map<string, LocationCheckbox>;
type SectionCheckbox = {
    checkboxStatus: CheckboxStatus;
    checkboxTooltip?: string;
    locationsCheckboxes: LocationsCheckboxes; // TODO Extend for location partitions ("Teilflächen")
};
export type SectionsCheckboxes = Map<string, SectionCheckbox>;

export const useTimelineStore = defineStore('Timeline', {
    state: () => {
        return {
            sections: [] as Readonly<TimelineSection[]>, // Should not be mutated here, but we need the data to calculate everything else
            sectionsCheckboxes: new Map() as SectionsCheckboxes,
            siteCheckboxStatus: 'No-Checkbox' as CheckboxStatus,
            _initialLocationId: null as string | null,
        };
    },

    getters: {
        siteCheckboxTooltip: (state) => getSiteCheckboxTooltip(state.siteCheckboxStatus),

        sectionsWithCheckboxes: (state) =>
            state.sections
                .map((section) => {
                    if (!section.hasLabel) {
                        return section;
                    }

                    const sectionCheckbox = state.sectionsCheckboxes.get(section.id);
                    if (sectionCheckbox) {
                        return {
                            ...section,
                            checkboxStatus: sectionCheckbox.checkboxStatus,
                            checkboxTooltip: sectionCheckbox.checkboxTooltip,

                            locations: section.locations.map((location) => {
                                const locationCheckbox = sectionCheckbox.locationsCheckboxes.get(location.id);

                                if (locationCheckbox) {
                                    return {
                                        ...location,
                                        checkboxStatus: locationCheckbox.checkboxStatus,
                                        checkboxTooltip: locationCheckbox.checkboxTooltip,
                                    };
                                }

                                return location;
                            }),
                        };
                    }

                    return {
                        ...section,
                        checkboxStatus: 'No-Checkbox' as const,
                        checkboxTooltip: undefined,

                        locations: section.locations.map((location) => {
                            return {
                                ...location,
                                checkboxStatus: 'No-Checkbox' as const,
                                checkboxTooltip: undefined,
                            };
                        }),
                    };
                })
                .filter(Boolean),

        checkedLocationIds: (state) => {
            const checkedLocationIds: string[] = [];

            [...state.sectionsCheckboxes.values()].forEach((section) => {
                for (const [locationId, location] of section.locationsCheckboxes) {
                    if (location.checkboxStatus === 'Checked' || location.checkboxStatus === 'Disabled-Checked') {
                        checkedLocationIds.push(locationId);
                    }
                }
            });

            return checkedLocationIds;
        },

        hasCheckboxes: (state) => {
            return state.siteCheckboxStatus !== 'No-Checkbox';
        },
    },

    actions: {
        showCheckboxes() {
            const newStatus: CheckboxStatus = 'Not-Checked';

            const sectionsCheckboxes: SectionsCheckboxes = new Map();
            for (const section of this.sections) {
                if (!section.hasLabel) {
                    continue;
                }

                const locationsCheckboxes: LocationsCheckboxes = new Map();
                for (const location of section.locations) {
                    locationsCheckboxes.set(location.id, {
                        checkboxStatus: newStatus,
                        checkboxTooltip: getLocationTooltip(newStatus),
                    });
                }

                sectionsCheckboxes.set(section.id, {
                    checkboxStatus: newStatus,
                    checkboxTooltip: getSectionTooltip(newStatus),
                    locationsCheckboxes,
                });
            }

            this.sectionsCheckboxes = sectionsCheckboxes;
            this.siteCheckboxStatus = aggregateCheckboxStatus(sectionsCheckboxes);
        },

        hideCheckboxes() {
            this._initialLocationId = null;

            const newStatus: CheckboxStatus = 'No-Checkbox';

            const sectionsCheckboxes: SectionsCheckboxes = new Map();
            for (const section of this.sections) {
                if (!section.hasLabel) {
                    continue;
                }

                const locationsCheckboxes: LocationsCheckboxes = new Map();
                for (const location of section.locations) {
                    locationsCheckboxes.set(location.id, {
                        checkboxStatus: newStatus,
                        checkboxTooltip: getLocationTooltip(newStatus),
                    });
                }

                sectionsCheckboxes.set(section.id, {
                    checkboxStatus: newStatus,
                    checkboxTooltip: getSectionTooltip(newStatus),
                    locationsCheckboxes,
                });
            }

            this.sectionsCheckboxes = sectionsCheckboxes;
            this.siteCheckboxStatus = aggregateCheckboxStatus(sectionsCheckboxes);
        },

        toggleSiteCheckbox() {
            const siteCheckboxStatus: CheckboxStatus = clickCheckbox(this.siteCheckboxStatus);

            const sectionsCheckboxes: SectionsCheckboxes = new Map();
            for (const [sectionId, sectionCheckbox] of this.sectionsCheckboxes) {
                const locationsCheckboxes: LocationsCheckboxes = new Map();

                for (const [locationId, locationCheckbox] of sectionCheckbox.locationsCheckboxes) {
                    locationsCheckboxes.set(locationId, {
                        checkboxStatus:
                            locationCheckbox.checkboxStatus === 'Disabled-Checked' ||
                            locationCheckbox.checkboxStatus === 'Disabled-Not-Checked'
                                ? locationCheckbox.checkboxStatus
                                : siteCheckboxStatus,
                        checkboxTooltip: getLocationTooltip(siteCheckboxStatus),
                    });
                }

                // The aggregated status might be different if locations have disabled checkboxes
                const aggregatedSectionCheckboxStatus = aggregateCheckboxStatus(locationsCheckboxes);

                sectionsCheckboxes.set(sectionId, {
                    checkboxStatus: aggregatedSectionCheckboxStatus,
                    checkboxTooltip: getSectionTooltip(aggregatedSectionCheckboxStatus),
                    locationsCheckboxes,
                });
            }

            this.sectionsCheckboxes = sectionsCheckboxes;
            this.siteCheckboxStatus = aggregateCheckboxStatus(sectionsCheckboxes);
        },

        toggleSectionCheckbox(toggledSectionId: string) {
            const sectionsCheckboxes: SectionsCheckboxes = new Map();

            for (const [sectionId, sectionCheckbox] of this.sectionsCheckboxes) {
                const sectionCheckboxStatus =
                    sectionId === toggledSectionId
                        ? clickCheckbox(sectionCheckbox.checkboxStatus)
                        : sectionCheckbox.checkboxStatus;

                const locationsCheckboxes: LocationsCheckboxes = new Map();
                for (const [locationId, locationCheckbox] of sectionCheckbox.locationsCheckboxes) {
                    locationsCheckboxes.set(locationId, {
                        checkboxStatus:
                            sectionId === toggledSectionId
                                ? locationCheckbox.checkboxStatus === 'Disabled-Checked' ||
                                  locationCheckbox.checkboxStatus === 'Disabled-Not-Checked'
                                    ? locationCheckbox.checkboxStatus
                                    : sectionCheckboxStatus
                                : locationCheckbox.checkboxStatus,
                        checkboxTooltip: getLocationTooltip(sectionCheckboxStatus),
                    });
                }

                // The aggregated status might be different if locations have disabled checkboxes
                const aggregatedSectionCheckboxStatus = aggregateCheckboxStatus(locationsCheckboxes);

                sectionsCheckboxes.set(sectionId, {
                    checkboxStatus: aggregatedSectionCheckboxStatus,
                    checkboxTooltip: getSectionTooltip(aggregatedSectionCheckboxStatus),
                    locationsCheckboxes,
                });
            }

            this.sectionsCheckboxes = sectionsCheckboxes;
            this.siteCheckboxStatus = aggregateCheckboxStatus(sectionsCheckboxes);
        },

        toggleLocationCheckbox(toggledLocationId: string, initialStatus?: CheckboxStatus) {
            if (!this._initialLocationId) {
                this._initialLocationId = toggledLocationId;
            }

            const sectionsCheckboxes: SectionsCheckboxes = new Map();

            for (const [sectionId, sectionCheckbox] of this.sectionsCheckboxes) {
                const locationsCheckboxes: LocationsCheckboxes = new Map();

                for (const [locationId, locationCheckbox] of sectionCheckbox.locationsCheckboxes) {
                    const locationCheckboxStatus =
                        locationId === toggledLocationId
                            ? initialStatus ?? clickCheckbox(locationCheckbox.checkboxStatus)
                            : locationCheckbox.checkboxStatus;

                    locationsCheckboxes.set(locationId, {
                        checkboxStatus: locationCheckboxStatus,
                        checkboxTooltip: getLocationTooltip(locationCheckboxStatus),
                    });
                }

                const sectionCheckboxStatus = aggregateCheckboxStatus(locationsCheckboxes);

                sectionsCheckboxes.set(sectionId, {
                    checkboxStatus: sectionCheckboxStatus,
                    checkboxTooltip: getSectionTooltip(sectionCheckboxStatus),
                    locationsCheckboxes,
                });
            }

            this.sectionsCheckboxes = sectionsCheckboxes;
            this.siteCheckboxStatus = aggregateCheckboxStatus(sectionsCheckboxes);
        },
    },
});

function clickCheckbox(previousStatus: CheckboxStatus): CheckboxStatus {
    switch (previousStatus) {
        case 'Checked':
            return 'Not-Checked';

        case 'Partially-Checked':
        case 'Not-Checked':
            return 'Checked';

        case 'Disabled-Checked':
        case 'Disabled-Not-Checked':
        case 'No-Checkbox':
            return previousStatus;
    }
}

function aggregateCheckboxStatus(items: Map<string, { checkboxStatus: CheckboxStatus }>): CheckboxStatus {
    const itemsArray = Array.from(items);

    if (itemsArray.every(([, item]) => item.checkboxStatus === 'No-Checkbox')) {
        return 'No-Checkbox';
    }

    if (
        itemsArray.every(([, item]) => item.checkboxStatus === 'Checked' || item.checkboxStatus === 'Disabled-Checked')
    ) {
        return 'Checked';
    }

    if (
        itemsArray.some(
            ([, item]) =>
                item.checkboxStatus === 'Checked' ||
                item.checkboxStatus === 'Disabled-Checked' ||
                item.checkboxStatus === 'Partially-Checked',
        )
    ) {
        return 'Partially-Checked';
    }

    if (
        itemsArray.every(
            ([, item]) => item.checkboxStatus === 'Not-Checked' || item.checkboxStatus === 'Disabled-Not-Checked',
        )
    ) {
        return 'Not-Checked';
    }

    throw new Error('Unexpected aggregated checkbox status');
}

function getSiteCheckboxTooltip(checkboxStatus: CheckboxStatus): string | undefined {
    switch (checkboxStatus) {
        case 'Checked':
            return $t('site-checkbox-click-to-unselect-all');

        case 'Partially-Checked':
        case 'Not-Checked':
            return $t('site-checkbox-click-to-select-all');

        case 'Disabled-Checked':
        case 'Disabled-Not-Checked':
            return $t('site-checkbox-no-selection-change-possible');
    }
}

function getSectionTooltip(checkboxStatus: CheckboxStatus): string | undefined {
    switch (checkboxStatus) {
        case 'Checked':
            return $t('section-checkbox-click-to-unselect-all');

        case 'Partially-Checked':
        case 'Not-Checked':
            return $t('section-checkbox-click-to-select-all');

        case 'Disabled-Checked':
        case 'Disabled-Not-Checked':
            return $t('section-checkbox-no-selection-change-possible');
    }
}

function getLocationTooltip(checkboxStatus: CheckboxStatus): string | undefined {
    switch (checkboxStatus) {
        case 'Checked':
            return $t('location-checkbox-click-to-unselect-all');

        case 'Partially-Checked':
        case 'Not-Checked':
            return $t('location-checkbox-click-to-select-all');

        case 'Disabled-Checked':
        case 'Disabled-Not-Checked':
            return $t('location-checkbox-no-selection-change-possible');
    }
}
