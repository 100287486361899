<template>
    <div class="py-2 px-4 items-start flex flex-col">
        <p class="text-lg text-gray-900 font-bold hyphens-auto">
            <TextLink :to="props.linkTarget">
                {{ props.linkText }}
            </TextLink>
        </p>
        <p class="text-sm text-gray-500 font-light">{{ props.subTitle }}</p>
    </div>
</template>

<script setup lang="ts">
import { RouteLocationRaw } from 'vue-router';
import TextLink from '../TextLink/TextLink.vue';

type CardRowLinkWithSubtitleProps = {
    linkText: string;
    linkTarget: RouteLocationRaw;
    subTitle: string;
};

const props = defineProps<CardRowLinkWithSubtitleProps>();
</script>
