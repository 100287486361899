<template>
    <div
        class="py-2 pl-4 pr-2 items-start grid gap-4"
        :class="{ 'border-b border-b-gray-200': props.hasBorderBottom }"
        :style="gridTemplateColumnsStyle"
    >
        <strong class="text-sm font-medium text-gray-500 flex flex-row items-center gap-1">
            {{ props.label }}
            <HelpButton v-if="props.helpText" :text="props.helpText" />
        </strong>
        <LinkFileAttachmentGroup :items="props.items" />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';
import LinkFileAttachmentGroup, {
    FileAttachmentType,
    LinkAttachmentType,
} from '../LinkFileAttachment/LinkFileAttachmentGroup.vue';

type CardRowAttachmentGroupProps = {
    label: string;
    labelWidthInRem?: number;
    helpText?: string;
    hasBorderBottom: boolean;
    items: (LinkAttachmentType | FileAttachmentType)[];
};

const props = defineProps<CardRowAttachmentGroupProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: ${props.labelWidthInRem ?? 15}rem 1fr;`;
});
</script>
