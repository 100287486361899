<template>
    <BaseForm @submit.prevent="onSubmit">
        <template #fields>
            <TextField
                :value="calendarEvent.name"
                :label="$t('name')"
                :error-messages="nameErrors"
                @input="nameChange"
            />
            <TextAreaField
                :value="calendarEvent.description"
                :label="$t('description')"
                :error-messages="descriptionErrors"
                @input="descriptionChange"
            />
            <DateTimeField
                :value="calendarEvent.start"
                :label="$t('start')"
                :error-messages="startErrors"
                @input="startChange"
            />
            <DateTimeField
                :value="calendarEvent.end"
                :label="$t('end')"
                :error-messages="endErrors"
                @input="endChange"
            />
        </template>
        <template #buttons>
            <BaseButton variant="outlined" exact @click="$router.back()">{{ $t('cancel') }}</BaseButton>
            <BaseButton submit color="primary" type="submit" :loading="updateCalendarEvent.loading.value">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard :mutation="updateCalendarEvent" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import DateTimeField from '@/components/Form/DateTimeField.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { UpdateCalendarEventDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { toHtmlDateTimeLocal } from '../../utils/date';
import { CalendarEvent, getDurationFromStartEnd, responseDurationToObject } from '../constraints';
import { CalendarEventEditSchema } from './schemas';

const props = defineProps<{ calendarEvent: CalendarEvent }>();
const router = useRouter();

const calendarEvent = ref({
    id: props.calendarEvent.id,
    name: props.calendarEvent.name,
    description: props.calendarEvent.description ?? '',
    start: toHtmlDateTimeLocal(props.calendarEvent.start),
    end: toHtmlDateTimeLocal(props.calendarEvent.end),
    duration: responseDurationToObject(props.calendarEvent.duration),
});

const nameErrors = ref<string[]>();
const descriptionErrors = ref<string[]>();
const startErrors = ref<string[]>();
const endErrors = ref<string[]>();

const nameChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    nameErrors.value = undefined;
    calendarEvent.value.name = value;
    const parse = CalendarEventEditSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.format()._errors;
    }
};

const descriptionChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendarEvent.value.description = value;
    const parse = CalendarEventEditSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.format()._errors;
    }
};

const startChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    startErrors.value = undefined;
    calendarEvent.value.start = value;
    const parse = CalendarEventEditSchema.shape.start.safeParse(value);
    if (!parse.success) {
        startErrors.value = parse.error.format()._errors;
    }
};

const endChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    endErrors.value = undefined;
    calendarEvent.value.end = value;
    const parse = CalendarEventEditSchema.shape.end.safeParse(value);
    if (!parse.success) {
        endErrors.value = parse.error.format()._errors;
    }
};

const updateCalendarEvent = useMutation(UpdateCalendarEventDocument);

const onSubmit = async () => {
    const duration = getDurationFromStartEnd(calendarEvent.value.start, calendarEvent.value.end);
    calendarEvent.value = {
        ...calendarEvent.value,
        duration,
    };
    const parse = CalendarEventEditSchema.safeParse(calendarEvent.value);
    if (!parse.success) {
        const flatError = parse.error.flatten().fieldErrors;
        nameErrors.value = flatError.name;
        descriptionErrors.value = flatError.description;
        startErrors.value = flatError.start;
        endErrors.value = flatError.end;
        return;
    }
    const res = await updateCalendarEvent.mutate(calendarEvent.value);
    const { id, name } = res?.data?.updateCalendarEvent?.calendarEvent ?? {};
    if (id && name) {
        router.push({ name: 'calendar-detail', params: { calendarId: props.calendarEvent.calendarId } });
    }
};
</script>
