<template>
    <TopBar :title="scenario.scenario.value?.name ?? ''" />
    <PageContent size="md">
        <GQLLoading
            class="p-4"
            :query="scenario.query"
            skeleton-type="table-heading, list-item@3"
            :empty-error-msg="$t('empty-error', { type: 'scenario', id: scenarioId })"
        >
            <div v-if="scenario.scenario.value" class="flex flex-col space-y-6">
                <ScenarioBaseDataCard
                    :to-edit="{ name: 'edit-scenario', params: { scenarioId: scenario.scenario.value.id } }"
                    :scenario="scenario.scenario.value"
                    :canEdit="scenario.isAdmin"
                />
                <Card>
                    <CardTitle :title="$t('event-variants')">
                        <template #action>
                            <ComboboxAdd
                                :disabled="!scenario.isAdmin"
                                :items="scenario.addableEvents.value"
                                item-text="name"
                                @select="(x: any) => scenario.addEvent(x)"
                            />
                        </template>
                    </CardTitle>
                    <CardContent>
                        <SimpleTable v-bind="scenario.table.value" :disabled="!scenario.isAdmin" />
                    </CardContent>
                </Card>
                <Card>
                    <CardTitle :title="$t('allocations')"> </CardTitle>
                    <CardContent> </CardContent>
                </Card>
            </div>
        </GQLLoading>
    </PageContent>
</template>

<script setup lang="ts">
import Card from '@/components/Card.vue';
import CardContent from '@/components/CardContent.vue';
import CardTitle from '@/components/CardTitle.vue';
import ComboboxAdd from '@/components/ComboboxAdd.vue';
import PageContent from '@/components/PageContent.vue';
import SimpleTable from '@/components/SimpleTable.vue';
import TopBar from '@/components/TopBar.vue';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';
import { computed, watchEffect } from 'vue';
import ScenarioBaseDataCard from '../components/ScenarioBaseDataCard.vue';
import { useScenario } from '../scenario';

const props = defineProps<{ scenarioId: string }>();
const fluent = useFluent();
const scenario = useScenario(computed(() => props.scenarioId));
watchEffect(() => {
    useHeadSafe({ title: () => `${scenario.scenario.value?.name ?? ''} - ${fluent.$t('scenarios')}` });
});
</script>

<fluent locale="de">
event-variants = Veranstaltungen im Szenario
event-label=Veranstaltung
variant-label=Variante
allocations-label=Belegungen
delete-label=Löschen
add-variant=Hinzufügen
add-variant-popup-title=Veranstaltungen hinzufügen
allocations=Belegungen
</fluent>
