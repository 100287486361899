<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8.667 15.333v-4L7.267 10 6.6 12.933 2 12l.267-1.333 3.2.666 1.066-5.4-1.2.467v2.267H4V5.533L6.633 4.4c.39-.167.675-.275.859-.325C7.675 4.025 7.844 4 8 4c.233 0 .45.061.65.183.2.123.361.284.483.484L9.8 5.733c.289.467.68.85 1.175 1.15.494.3 1.058.45 1.692.45v1.334a4.71 4.71 0 0 1-2.059-.459A4.887 4.887 0 0 1 9 7l-.4 2 1.4 1.333v5H8.667ZM9 3.667c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.942c0-.366.13-.68.391-.941C8.32 1.13 8.633 1 9 1s.68.13.942.392c.26.26.391.575.391.941 0 .367-.13.68-.391.942A1.284 1.284 0 0 1 9 3.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type DirectionsRunIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<DirectionsRunIconProps>();
</script>
