import { DateTime } from 'luxon';
import { nanoid } from 'nanoid';
import {
    AllocationAggregationForm,
    AllocationPart,
    Conflict,
    EnrichedAllocation,
    Location,
    LocationType,
    LocationWithAllDayProperties,
    LocationWithDays,
    PhaseForm,
    Section,
} from '../Timeline.types';
import { TimelineDay, TimelineLocation, TimelineRow, TimelineSection } from '../TimelineView.types';
import { getIcon, getIconTitle } from '../logic/icons';
import { calculateAggregatedAllocationForm, calculateAllocationsForDay } from './allocations';
import { extractRowsFromLocations } from './cells';
import { calculateConflictMarkerForDay, calculateConflictStatusForDay } from './conflicts';
import { insertInvisibleAllocations } from './continuingAllocationsLayout';

export function flattenLocations(locations: LocationWithAllDayProperties[], sectionId?: string): LocationWithDays[] {
    let flattenedLocations: LocationWithDays[] = [];

    for (let locationIndex = 0; locationIndex < locations.length; locationIndex++) {
        const location = locations[locationIndex];

        const hasNestedLocations = location.nestedLocations.length > 0;
        const isTemporaryLeaf = (hasNestedLocations && !location.isExpanded) || location.isActualLeaf;
        const showUnresolvedConflictsIcon = location.hasUnresolvedConflicts && isTemporaryLeaf;
        const showResolvedConflictsIcon =
            !location.hasUnresolvedConflicts && location.conflictsResolvedCount > 0 && isTemporaryLeaf;

        let status: 'has-conflicts-strong' | 'has-conflicts-light' | 'invalid-data' | 'ok' = 'ok' as const;

        if (isTemporaryLeaf && location.conflictsUnresolvedCount > 0) {
            status = 'has-conflicts-strong' as const;
        } else if (!isTemporaryLeaf && location.conflictsUnresolvedCount > 0) {
            status = 'has-conflicts-light' as const;
        } else if (location.areNestedLocationsMissing) {
            status = 'invalid-data' as const;
        }

        flattenedLocations = [
            ...flattenedLocations,
            {
                ...location,
                hasNestedLocations,
                isTemporaryLeaf,
                showUnresolvedConflictsIcon,
                showResolvedConflictsIcon,
                days: location.days.map((day) => {
                    if (!showUnresolvedConflictsIcon) {
                        return {
                            ...day,
                            conflictMarker: 'no-conflict',
                            hasCreateButton: location.hasCreateButtons,
                        };
                    }

                    return {
                        ...day,
                        hasCreateButton: location.hasCreateButtons,
                    };
                }),
                sectionId: sectionId ?? location.sectionId,
                status,
                backgroundClass:
                    isTemporaryLeaf && location.conflictsUnresolvedCount > 0
                        ? 'bg-red-600'
                        : !isTemporaryLeaf && location.conflictsUnresolvedCount > 0
                          ? 'bg-red-100'
                          : 'bg-gray-50',
                rowId: `row-${location.id}`,
            },
        ];

        if (location.isExpanded && location.nestedLocations) {
            flattenedLocations = [
                ...flattenedLocations,
                ...flattenLocations(location.nestedLocations, location.sectionId),
            ];
        }
    }

    return flattenedLocations;
}

function mapForm(aggregationForm: AllocationAggregationForm): PhaseForm {
    switch (aggregationForm) {
        case 'allocation-single':
            return 'Single';

        case 'allocation-start':
            return 'Start';

        case 'allocation-middle':
            return 'Middle';

        case 'allocation-end':
            return 'End';

        default:
            throw new Error(
                `Unknown aggregation form ${aggregationForm}, this is an implementation issue and should never happen`,
            );
    }
}

function countCellSpan(daysArrayHasAggregation: boolean[], startIndex: number): number {
    let count = 0;
    let index = startIndex;

    while (daysArrayHasAggregation[index]) {
        count++;
        index++;
    }

    return count;
}

export function calculateDayProperties(
    locations: Location[],
    allocations: EnrichedAllocation[],
    conflicts: Conflict[],
    startDateTime: DateTime,
    endDateTime: DateTime,
    durationInDays: number,
    hasTimelineCreateButtons: boolean,
): LocationWithAllDayProperties[] {
    const locationsWithDays = locations.map((location) => {
        const uuid = nanoid();

        const locationIcon = getIcon(location.typeCategory, location.type);
        const locationIconTitle = getIconTitle(location.typeCategory, location.type);

        const conflictsInLocation = conflicts.filter((conflict) =>
            isLocationOrNestedLocation(location, conflict.locationId),
        );
        const unresolvedConflicts = conflictsInLocation.filter((conflict) => conflict.status === 'unresolved');

        let daysArray: Pick<
            TimelineDay,
            'isWorkDay' | 'conflictMarker' | 'allocations' | 'startDateTime' | 'endDateTime'
        >[] = [];
        // Required as an intermediate step to calculate the actual form of the allocation aggregations
        let daysArrayHasAggregation: boolean[] = [];

        for (let day = 0; day < durationInDays; day++) {
            const dateTime = startDateTime.plus({ days: day });

            const conflictStatus = calculateConflictStatusForDay(conflictsInLocation, dateTime);
            const conflictMarker = calculateConflictMarkerForDay(conflictsInLocation, dateTime, conflictStatus);

            // Are there allocations in this location?
            const filteredAllocations = allocations.filter((allocation) =>
                isLocationOrNestedLocation(location, allocation.locationId),
            );
            const allocationsInLocationAndNestedLocations: AllocationPart[] = calculateAllocationsForDay(
                filteredAllocations,
                dateTime,
            );

            daysArray = [
                ...daysArray,
                {
                    isWorkDay: dateTime.weekday !== 6 && dateTime.weekday !== 7,
                    conflictMarker,
                    allocations: allocationsInLocationAndNestedLocations,
                    startDateTime: dateTime,
                    endDateTime: dateTime.plus({ days: 1 }),
                },
            ];

            const hasAggregation = !location.isLeafLocation && allocationsInLocationAndNestedLocations.length > 0;

            daysArrayHasAggregation = [...daysArrayHasAggregation, hasAggregation];
        }

        // Aggregate allocations
        for (let day = 0; day < durationInDays; day++) {
            if (daysArray[day].allocations.length > 0 && !location.isLeafLocation) {
                const isPreviousDayAggregation = daysArrayHasAggregation[day - 1] ?? false;
                const isNextDayAggregation = daysArrayHasAggregation[day + 1] ?? false;

                const cellSpanCount = countCellSpan(daysArrayHasAggregation, day);

                const aggregationForm = calculateAggregatedAllocationForm(
                    isPreviousDayAggregation,
                    daysArray[day].allocations,
                    isNextDayAggregation,
                );

                daysArray[day].allocations =
                    aggregationForm && aggregationForm !== 'no-allocation'
                        ? [
                              {
                                  id: `aggregation-${uuid}`,
                                  phaseType: 'Aggregation',
                                  allocationType: 'None',
                                  specialization: null,
                                  label: '',
                                  isLabelVisible: false,
                                  isPhaseEnd: false,
                                  form: mapForm(aggregationForm),
                                  isVisible: true,
                                  phases: [
                                      {
                                          id: nanoid(),
                                          cellSpan: cellSpanCount,
                                          type: 'Aggregation',
                                          specialization: null,
                                      },
                                  ],
                                  startDateTime,
                                  endDateTime,
                                  isEditable: false,
                                  linkTarget: '',
                                  allocationVariantId: 'aggregation',
                                  variant: 'Solid',
                              },
                          ]
                        : [];
            }
        }

        // Layout allocations
        daysArray = insertInvisibleAllocations(startDateTime, endDateTime, daysArray);

        const isInside = getIsInside(location.type);

        const isExpanded = false;

        return {
            ...location,
            isInside,
            sectionId: location.sectionId ?? 'no-section',
            icon: locationIcon,
            iconTitle: locationIconTitle,
            hasNestedLocations: location.nestedLocations.length > 0,
            nestedLocations: calculateDayProperties(
                location.nestedLocations,
                allocations,
                conflicts,
                startDateTime,
                endDateTime,
                durationInDays,
                hasTimelineCreateButtons,
            ),
            areNestedLocationsMissing: !location.isLeafLocation && location.nestedLocations.length === 0,
            isExpanded,
            isActualLeaf: location.isLeafLocation,
            conflictsResolvedCount: conflictsInLocation.length - unresolvedConflicts.length,
            conflictsUnresolvedCount: unresolvedConflicts.length,
            hasUnresolvedConflicts: unresolvedConflicts.length > 0,
            days: daysArray,
            hasCreateButtons: location.isLeafLocation && hasTimelineCreateButtons,
            checkboxStatus: 'No-Checkbox' as const,
        };
    });

    return locationsWithDays;
}

export function getIsInside(locationType: LocationType | undefined) {
    const locationTypesInside = [
        'Hallenausstellungsfläche',
        'Eingang',
        'Boulevard',
        'Passage',
        'Passage inkl. Durchfahrtstor',
        'Büro/Besprecher (ZBV intern)', // ZBV = Zur besonderen Verwendung
        'Büro/Besprecher (ZBV extern)',
        'Lagerraum (ZBV intern)',
        'Lagerraum (ZBV extern)',
        'Konferenzraum',
        'Service-Center',
    ];

    // TODO Extend this to outside areas, too. This only works as long as we have only inside areas
    if (!locationType) {
        return true;
    }

    return locationTypesInside.includes(locationType);
}

export function isLocationOrNestedLocation(location: Location, locationId: string): boolean {
    if (location.id === locationId) {
        return true;
    }

    if (location.nestedLocations.length > 0) {
        return location.nestedLocations.some((nestedLocation) =>
            isLocationOrNestedLocation(nestedLocation, locationId),
        );
    }

    return false;
}

export function groupLocationsBySection(locationsWithDays: LocationWithDays[], sections: Section[]): TimelineSection[] {
    if (sections.length === 0) {
        const rows = extractRowsFromLocations(locationsWithDays);

        return [
            {
                hasLabel: false,
                locations: locationsWithDays.map((location) => ({ ...location, isVisible: true })),
                rows,
            },
        ];
    }

    return sections.map((section) => {
        let rows: TimelineRow[] = [];
        let unresolvedConflictsCount = 0;
        let allocationCount = 0;

        const locations: TimelineLocation[] = locationsWithDays
            .filter((location) => location.sectionId === section.id)
            .map((location, locationIndex) => {
                unresolvedConflictsCount += location.conflictsUnresolvedCount;

                const row = {
                    days: location.days.map((day, dayIndex) => {
                        if (
                            day.allocations.some(
                                (allocationPart) => allocationPart.form === 'Start' || allocationPart.form === 'Single',
                            )
                        ) {
                            allocationCount = allocationCount + 1;
                        }

                        return {
                            ...day,
                            isWorkDay: day.isWorkDay,
                            conflictMarker: day.conflictMarker,
                            allocations: day.allocations,
                            rowId: location.rowId,
                            columnId: `column-${dayIndex}`,
                            isFirstRow: locationIndex === 0,
                            isFirstColumn: dayIndex === 0,
                        };
                    }),
                    locationId: location.id,
                    isLocationInside: location.isInside,
                };

                rows = [...rows, row];

                return {
                    ...location,
                    isVisible: section.isExpanded,
                };
            });

        return {
            hasLabel: true,
            id: section.id,
            status: unresolvedConflictsCount > 0 ? 'has-conflicts' : 'ok',
            unresolvedConflictsCount,
            label: section.label,
            isExpanded: section.isExpanded,
            icon: getIcon(section.typeCategory, section.type),
            iconTitle: getIconTitle(section.typeCategory, section.type),
            locations,
            rows: section.isExpanded ? rows : [],
            allocationCount,
            checkboxStatus: 'No-Checkbox',
        };
    });
}

function getLocationIds(locations: Location[]): string[] {
    return locations.flatMap((location) => {
        let locationIds: string[] = [];

        locationIds = [...locationIds, location.id];

        if (location.nestedLocations.length > 0) {
            locationIds = [...locationIds, ...getLocationIds(location.nestedLocations)];
        }

        return locationIds;
    });
}

function assertUniqueLocationIds(locations: Location[]) {
    const locationIds = getLocationIds(locations);
    const sortedLocationIds = locationIds.sort();

    let previousString: string | undefined;
    for (const currentString of sortedLocationIds) {
        if (currentString === previousString) {
            const uniqueLocationIdsCount = new Set(locationIds).size;
            const totalLocationIdsCount = locationIds.length;

            throw new Error(
                `Duplicate location ID found: "${currentString}" (${uniqueLocationIdsCount} unique/${totalLocationIdsCount} total location IDs)`,
            );
        }
        previousString = currentString;
    }
}

function assertLevelsAreCorrect(locations: Location[], startLevel: number = 0) {
    locations.forEach((location) => {
        if (location.level !== startLevel) {
            throw new Error(
                `Unexpected level ${location.level}, expected ${startLevel} on location with ID ${location.id}`,
            );
        }

        if (location.nestedLocations.length > 0) {
            assertLevelsAreCorrect(location.nestedLocations, startLevel + 1);
        }
    });
}

function getSectionIds(locations: Location[]): string[] {
    return locations.flatMap((location) => {
        let sectionIds: string[] = [];

        if (location.sectionId) {
            sectionIds = [...sectionIds, location.sectionId];
        }

        if (location.nestedLocations.length > 0) {
            sectionIds = [...sectionIds, ...getSectionIds(location.nestedLocations)];
        }

        return sectionIds;
    });
}

function assertSectionIdExists(locations: Location[], sections: Section[] | undefined) {
    if (sections) {
        const usedSectionIds = getSectionIds(locations);

        for (const sectionId of usedSectionIds) {
            if (!sections.find((section) => section.id === sectionId)) {
                throw new Error(
                    `Section ID "${sectionId}" was referenced in a location but there is no corresponding section definition`,
                );
            }
        }
    }
}

function assertChildrenHaveSectionId(locations: Location[], parentSectionId?: string | undefined) {
    locations.forEach((location) => {
        if (parentSectionId) {
            if (location.sectionId !== parentSectionId) {
                throw new Error(
                    `Section ID "${parentSectionId}" was expected on location with ID ${location.id} to be in sync with parent location`,
                );
            }

            if (location.nestedLocations.length > 0) {
                assertChildrenHaveSectionId(location.nestedLocations, parentSectionId);
            }
        } else if (location.sectionId) {
            if (location.nestedLocations.length > 0) {
                assertChildrenHaveSectionId(location.nestedLocations, location.sectionId);
            }
        }
    });
}

export function validateLocations(locations: Location[], sections: Section[] | undefined) {
    assertUniqueLocationIds(locations);
    assertLevelsAreCorrect(locations);
    assertSectionIdExists(locations, sections);
    assertChildrenHaveSectionId(locations);
}
