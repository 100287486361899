<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 14.667a6.45 6.45 0 0 1-2.583-.525 6.761 6.761 0 0 1-2.125-1.434 6.761 6.761 0 0 1-1.434-2.125A6.45 6.45 0 0 1 1.333 8c0-.922.175-1.786.525-2.592a6.789 6.789 0 0 1 1.434-2.116 6.762 6.762 0 0 1 2.125-1.434A6.45 6.45 0 0 1 8 1.333c.189 0 .347.064.475.192A.645.645 0 0 1 8.667 2a.645.645 0 0 1-.192.475.645.645 0 0 1-.475.192c-1.478 0-2.736.52-3.775 1.558C3.186 5.264 2.667 6.522 2.667 8c0 1.478.519 2.736 1.558 3.775C5.264 12.814 6.522 13.333 8 13.333c1.478 0 2.736-.52 3.775-1.558 1.039-1.039 1.558-2.297 1.558-3.775 0-.189.064-.347.192-.475A.645.645 0 0 1 14 7.333c.189 0 .347.064.475.192a.645.645 0 0 1 .192.475 6.45 6.45 0 0 1-.525 2.583 6.762 6.762 0 0 1-1.434 2.125 6.79 6.79 0 0 1-2.116 1.434c-.806.35-1.67.525-2.592.525Z"
        />
    </svg>
</template>

<script setup lang="ts">
type PendingIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PendingIconProps>();
</script>
