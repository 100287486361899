<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4 14.667V11.8a6.155 6.155 0 0 1-1.475-2.025A5.848 5.848 0 0 1 2 7.333c0-1.666.583-3.083 1.75-4.25C4.917 1.917 6.333 1.333 8 1.333c1.389 0 2.62.409 3.692 1.225a5.572 5.572 0 0 1 2.091 3.192l.917 3.633a.453.453 0 0 1-.083.425.482.482 0 0 1-.4.192h-1.55v2.333c0 .275-.098.51-.294.707a.963.963 0 0 1-.706.293H10v1.334H9v-2.334h2.667V9h1.9l-.75-3c-.267-1.078-.85-1.958-1.75-2.642A4.94 4.94 0 0 0 8 2.333c-1.389 0-2.57.48-3.542 1.442C3.486 4.736 3 5.909 3 7.292c0 .716.146 1.396.439 2.04a5.368 5.368 0 0 0 1.244 1.718l.317.3v3.317H4Zm4-4a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475A.645.645 0 0 0 8 9.333a.645.645 0 0 0-.475.192.645.645 0 0 0-.192.475c0 .189.064.347.192.475a.645.645 0 0 0 .475.192Zm-.5-2.134h1.017c0-.277.036-.502.108-.675.072-.172.22-.38.442-.625.2-.222.394-.447.583-.675.189-.227.283-.525.283-.891 0-.467-.18-.861-.541-1.184C9.03 4.161 8.583 4 8.05 4c-.444 0-.847.128-1.208.383a2.02 2.02 0 0 0-.759 1L7 5.767c.078-.245.214-.442.408-.592a1.03 1.03 0 0 1 .642-.225c.244 0 .447.067.608.2a.64.64 0 0 1 .242.517c0 .233-.07.441-.208.625-.14.183-.303.37-.492.558a3.143 3.143 0 0 0-.517.7c-.122.233-.183.561-.183.983Z"
        />
    </svg>
</template>

<script setup lang="ts">
type BrainIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<BrainIconProps>();
</script>
