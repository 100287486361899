<template>
    <div v-if="level">
        <TopBar :title="$t('room-add')" :breadcrumbs="locationBreadcrumbs(level)" transparent />
        <LocationCreateForm type="Room" :location-types="roomTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetCreateRoomParamsDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ levelId: string }>();

const query = useQuery(GetCreateRoomParamsDocument, { id: props.levelId });
const level = useResult(query.result, undefined, (x) => x.level);
const roomTypes = useResult(query.result, [], (x) => x.roomTypes.nodes);
const parent = { key: 'levelId', value: props.levelId };
</script>
