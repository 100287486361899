<template>
    <li role="tab" class="flex flex-row" :aria-controls="props.tabPanelId">
        <RouterLink v-slot="{ isExactActive, href, navigate }" :to="target" custom>
            <Tooltip :is-disabled="!isTabDisabled">
                <template #trigger>
                    <a
                        ref="linkRef"
                        :href="isTabDisabled ? undefined : href"
                        :tabindex="isExactActive ? undefined : -1"
                        :aria-selected="isExactActive"
                        :aria-current="isExactActive ? 'page' : undefined"
                        role="link"
                        :aria-disabled="isTabDisabled"
                        class="px-2 border-b-2 h-[3rem] leading-[3] focus-visible:outline focus-visible:-outline-offset-1 focus-visible:outline-2 focus-visible:outline-sky-700"
                        :class="[
                            isExactActive
                                ? 'text-sky-800 border-b-sky-800'
                                : isTabDisabled
                                  ? 'text-gray-400 border-b-transparent cursor-not-allowed'
                                  : 'text-gray-700 border-b-transparent hover:text-sky-800 hover:border-b-sky-800',
                        ]"
                        v-on="{ click: isTabDisabled ? undefined : navigate }"
                        @keyup="handleKeys"
                    >
                        <slot />
                    </a>
                </template>

                <template #text>
                    <div class="w-max">{{ props.disabledExplanation }}</div>
                </template>
            </Tooltip>
        </RouterLink>
    </li>
</template>

<script setup lang="ts">
import { useFocus } from '@vueuse/core';
import { computed, ref, watchEffect } from 'vue';
import { RouteLocationRaw, RouterLink, useRoute, useRouter } from 'vue-router';
import Tooltip from '../Tooltip/Tooltip.vue';

type TabNavigationItemProps = {
    target: RouteLocationRaw;
    routeName: string; // Important: This route name must be unique across the entire application
    isFocused: boolean;
    isManualFocusChange: boolean;
    tabPanelId: string;
    disabledExplanation?: string;
};

const props = defineProps<TabNavigationItemProps>();

const isTabDisabled = computed(() => Boolean(props.disabledExplanation));

const emit = defineEmits<{
    (e: 'focus-first-tab'): void;
    (e: 'focus-last-tab'): void;
    (e: 'focus-previous-tab'): void;
    (e: 'focus-next-tab'): void;
    (e: 'focus-tab', isManualFocusChange: boolean): void;
    (e: 'focus-tab-panel'): void;
}>();

const linkRef = ref();
const { focused } = useFocus(linkRef);

// Set initial focus depending on the initially opened tab
const route = useRoute();
// By resolving the target route name, we do not need to pass it via props anymore
const router = useRouter();
const targetRouteName = router.resolve(props.target).name;
const isTabActive = computed(() => targetRouteName === route.name);
watchEffect(() => {
    if (isTabActive.value) {
        emit('focus-tab', false);
    }
});

// Update focus when moving between tabs
watchEffect(() => {
    if (props.isFocused && props.isManualFocusChange) {
        focused.value = true;
    }
});

function handleKeys(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowLeft':
            emit('focus-previous-tab');
            event.preventDefault();
            break;

        case 'ArrowRight':
            emit('focus-next-tab');
            event.preventDefault();
            break;

        case 'PageUp':
            emit('focus-first-tab');
            event.preventDefault();
            break;

        case 'PageDown':
            emit('focus-last-tab');
            event.preventDefault();
            break;

        case 'Enter':
            emit('focus-tab-panel');
            // Don't prevent default, this is supposed to actually activate the link
            break;
    }
}
</script>
