<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path fill="currentColor" d="M10 11V8.5H6V11H1.333V5H6v2.5h4V5h4.667v6H10Z" />
    </svg>
</template>

<script setup lang="ts">
type PassageIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PassageIconProps>();
</script>
