<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 14v-1.333h1.333V3.333c0-.366.13-.68.392-.941A1.28 1.28 0 0 1 4.667 2h6.666c.367 0 .68.13.942.392.261.26.392.575.392.941v9.334H14V14H2Zm2.667-1.333h6.666V3.333H4.667v9.334Zm2-4a.645.645 0 0 0 .475-.192A.645.645 0 0 0 7.333 8a.645.645 0 0 0-.191-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192A.645.645 0 0 0 6 8c0 .189.064.347.192.475a.645.645 0 0 0 .475.192Z"
        />
    </svg>
</template>

<script setup lang="ts">
type DoorBackIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<DoorBackIconProps>();
</script>
