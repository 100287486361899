<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>

        <path
            fill="currentColor"
            d="M2.667 11.667v-1.334h6.666v1.334H2.667Zm0-2.667V7.667h10.666V9H2.667Zm0-2.667V5h6.666v1.333H2.667Zm0-2.666V2.333h10.666v1.334H2.667Zm0 9.333v1.3h6.666V13H2.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type GroupedEntriesIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<GroupedEntriesIconProps>();
</script>
