<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M5.317 11.833h5.366v-1H5.317v1Zm0-2.833h5.366V8H5.317v1Zm-1.65 5.667a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V2.333c0-.266.1-.5.3-.7.2-.2.433-.3.7-.3h6.016l3.65 3.65v8.684c0 .266-.1.5-.3.7-.2.2-.433.3-.7.3H3.667Zm5.516-9.234v-3.1H3.667v11.334h8.666V5.433h-3.15Z"
        />
    </svg>
</template>

<script setup lang="ts">
type FileIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<FileIconProps>();
</script>
