import { ZodSchema } from 'zod';
import {
    EventSeriesAllocationNeeds,
    EventSeriesAllocationRules,
    EventSeriesCycle,
    EventSeriesDayOrder,
    EventSeriesIdentifiersSchema,
    EventSeriesNotesAppointmentRules,
    EventSeriesOrganisation,
    EventSeriesPartnersSchema,
    EventSeriesTypesSchema,
} from './forms/schemas';

export const SERIES_MULTILINK_FIELDS = [
    'organizers',
    'executors',
    'sponsors',
    'extraDocuments',
    'extraSystems',
    'externalData',
    'internalData',
    'subeventsLocal',
    'subeventsOtherLocations',
    'fairAbroad',
    'fairAbroadCooperation',
    'competitionNational',
    'otherCompetitionNational',
    'competitionInternational',
    'otherCompetitionInternational',
    'favoredParallelsInternal',
    'favoredParallelsExternal',
    'otherLinksInternal',
    'otherLinksExternal',
];

const LINK_TAB_FIELDS = [
    'extraDocuments',
    'extraSystems',
    'externalData',
    'internalData',
    'subeventsLocal',
    'subeventsOtherLocations',
    'fairAbroad',
    'fairAbroadCooperation',
    'competitionNational',
    'otherCompetitionNational',
    'competitionInternational',
    'otherCompetitionInternational',
    'favoredParallelsInternal',
    'favoredParallelsExternal',
    'otherLinksInternal',
    'otherLinksExternal',
];

export const cardFieldsMapping = new Map([
    [
        'identifiers',
        ['nameShort', 'nameLong', 'subtitle', 'numberShort', 'foundationYear', 'notes', 'description', 'iconUpload'],
    ],
    ['typeDefinitions', ['category', 'type', 'isPublic', 'isTechnical']],
    [
        'eventPartners',
        ['partnersOverviewName', 'partnersOverviewLink', 'organizers', 'executors', 'sponsors', 'additionalPartners'],
    ],
    [
        'dayOrder',
        [
            'notesDayOrder',
            'notesSetup',
            'notesEarlyConstructionInternal',
            'notesConstruction',
            'notesExecution',
            'notesDismantling',
            'notesExtendedDismantlingExternal',
            'notesExtendedDismantlingInternal',
            'notesTechnicalDismantling',
            'notesDayOrderHistory',
        ],
    ],
    [
        'appointmentRules',
        [
            'notesAppointmentRules',
            'notesMonths',
            'notesHolidaysNrw',
            'notesHolidaysNational',
            'notesHolidaysInternational',
            'notesPublicHolidaysNrw',
            'notesPublicHolidaysNational',
            'notesPublicHolidaysInternational',
            'otherNotesAppointmentRules',
            'appointmentHistory',
        ],
    ],
    ['cycle', ['cycle', 'cycleChanges']],
    ['allocationRules', ['allocationRules', 'locationHistory']],
    [
        'allocationRulesLocationTypes',
        [
            'notesHallExhibitionAreas',
            'notesBoulevardSections',
            'notesPassages',
            'notesEntrances',
            'notesServiceCenters',
            'notesConferenceRooms',
            'notesOfficesInternal',
            'notesStorageInternal',
            'notesOfficesExternal',
            'notesStorageExternal',
            'notesAreas',
            'notesParking',
            'otherAllocationNeeds',
        ],
    ],
    ['orgcat', ['companyGroup', 'businessDomain', 'department', 'organisationalChanges']],
    ['links', LINK_TAB_FIELDS],
]);

export const getCardNameFromField = (fieldName: string) => {
    for (const [cardName, fields] of cardFieldsMapping) {
        if (fields.indexOf(fieldName) >= 0) return cardName;
    }
    return '';
};

export const cardSchemaMapping = new Map<string, ZodSchema>([
    ['identifiers', EventSeriesIdentifiersSchema],
    ['typeDefinitions', EventSeriesTypesSchema],
    ['eventPartners', EventSeriesPartnersSchema],
    ['dayOrder', EventSeriesDayOrder],
    ['appointmentRules', EventSeriesNotesAppointmentRules],
    ['cycle', EventSeriesCycle],
    ['allocationRules', EventSeriesAllocationRules],
    ['allocationRulesLocationTypes', EventSeriesAllocationNeeds],
    ['orgcat', EventSeriesOrganisation],
]);

export const getEventSeriesSchemaFromCard = (cardId: string) => {
    return cardSchemaMapping.get(cardId);
};
