import CalendarSidePanel from '@/constraints/components/CalendarSidePanel.vue';
import CalendarDetailPage from '@/constraints/pages/CalendarDetailPage.vue';
import CalendarOverviewPage from '@/constraints/pages/CalendarOverviewPage.vue';
import MasterDetailLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import { RouteRecordRaw, RouterView } from 'vue-router';
import CalendarCreatePage from './pages/CalendarCreatePage.vue';
import CalendarEditPage from './pages/CalendarEditPage.vue';
import CalendarEventCreatePage from './pages/CalendarEventCreatePage.vue';
import CalendarEventEditPage from './pages/CalendarEventEditPage.vue';

export const externalEventRoutes = <RouteRecordRaw[]>[
    {
        path: '/external-events',
        component: MasterDetailLayout,
        children: [
            {
                path: '',
                name: 'calendars',
                components: {
                    default: CalendarSidePanel,
                    detail: CalendarOverviewPage,
                },
            },
            {
                path: 'add',
                name: 'calendar-add',
                beforeEnter: requireAdmin,
                components: {
                    default: CalendarSidePanel,
                    detail: CalendarCreatePage,
                },
            },
            {
                path: ':calendarId',
                components: {
                    default: CalendarSidePanel,
                    detail: RouterView,
                },
                children: [
                    {
                        path: '',
                        name: 'calendar-detail',
                        component: CalendarDetailPage,
                        props: true,
                    },
                    {
                        path: 'edit',
                        name: 'calendar-edit',
                        beforeEnter: requireAdmin,
                        component: CalendarEditPage,
                        props: true,
                    },
                    {
                        path: 'event-add',
                        name: 'calendar-event-add',
                        beforeEnter: requireAdmin,
                        component: CalendarEventCreatePage,
                        props: true,
                    },
                    {
                        path: 'event/:calendarEventId/edit',
                        name: 'calendar-event-edit',
                        beforeEnter: requireAdmin,
                        component: CalendarEventEditPage,
                        props: true,
                    },
                ],
            },
        ],
    },
];
