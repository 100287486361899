<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3.333 14.667c-.366 0-.68-.13-.941-.392A1.284 1.284 0 0 1 2 13.333V4c0-.367.13-.68.392-.942.26-.26.575-.391.941-.391H4V1.333h1.333v1.334h5.334V1.333H12v1.334h.667c.366 0 .68.13.941.391.261.261.392.575.392.942v3.333h-1.333v-.666H3.333v6.666H8v1.334H3.333Zm0-9.334h9.334V4H3.333v1.333Zm6 9.334v-2.05l3.684-3.667c.1-.1.21-.172.333-.217.122-.044.244-.066.367-.066a1.003 1.003 0 0 1 .716.3l.617.616c.089.1.158.211.208.334.05.122.075.244.075.366 0 .123-.022.248-.066.375a.895.895 0 0 1-.217.342l-3.667 3.667h-2.05Zm1-1h.634l2.016-2.034-.3-.316-.316-.3-2.034 2.016v.634Zm2.35-2.35-.316-.3.616.616-.3-.316Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CalendarEditIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<CalendarEditIconProps>();
</script>
