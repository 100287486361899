import { z } from 'zod';
import { $t } from '../../plugins/fluent';
import { LinkSchema } from '../MultiLink/schema';

export const REQUIRED_STRING = z.string().min(1, $t('input-required'));
export const OPTIONAL_STRING = z.string().optional();
export const OPTIONAL_TEXT = z.string().max(250, $t('max-characters', { count: 250 }));
export const OPTIONAL_YEAR = z.coerce
    .number({ invalid_type_error: $t('expected-number') })
    .optional()
    .transform((val, ctx) => {
        if (val === undefined || val === 0) return undefined;
        if (val < 1000 || val > 2099) {
            ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: $t('number-between', { start: '1000', end: '2099' }),
            });
        }
        return val;
    });
export const YEAR = z.coerce.number({ invalid_type_error: $t('expected-number') }).transform((val, ctx) => {
    if (val === undefined || val === 0) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: $t('input-required'),
        });
        return undefined;
    }
    if (val < 1000 || val > 2099) {
        ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: $t('number-between', { start: '1000', end: '2099' }),
        });
    }
    return val;
});
export const NULL_BOOLEAN = z.boolean().nullable().optional();
export const MULTILINK = z.array(LinkSchema);
