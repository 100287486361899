import { $t } from '@/plugins/fluent';
import { ApolloError } from '@apollo/client/core';
import { UseMutationReturn } from '@vue/apollo-composable';
import { computed, ref, Ref } from 'vue';

export function useMutationError(
    mutation: UseMutationReturn<any, any>,
    formatError: (res: any) => string | undefined,
): Ref<string | undefined> {
    const domainError = ref<string | undefined>();
    mutation.onDone((x) => {
        let innerResult: any = Object.values(x.data)[0];
        domainError.value = formatError(innerResult);
    });

    return computed(() => {
        var apolloError = mutation.error.value;
        if (apolloError) return prettyPrintApolloError(apolloError);
        return domainError.value;
    });
}

export function prettyPrintApolloError(err: ApolloError) {
    if (err.networkError) return $t('network-error');
    return err.message;
}
