<template>
    <h2 :id="props.headlineId" class="text-base font-semibold leading-[1.875rem] flex flex-row items-center gap-2">
        <slot />

        <HelpButton v-if="props.helpText" :text="props.helpText" />
    </h2>
</template>

<script setup lang="ts">
import HelpButton from '../HelpButton/HelpButton.vue';

type CardHeadlineProps = {
    headlineId: string; // Implemented as prop so we can use this for site internal links
    helpText?: string;
};

const props = defineProps<CardHeadlineProps>();
</script>
