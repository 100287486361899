<template>
    <div v-if="project" class="space-y-4">
        <FlexibleCard headline-id="documents" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('documents')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('documents')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('documents', $t('project-series-documents'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectDocumentsSchema)"
                    @save="dataFunctions?.save(undefined, true)"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('annual-documents-help')">
                        {{ $t('project-documents') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectDocumentsForm v-if="showForm('documents')" class="p-5" />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="project.otherDocuments && project.otherDocuments.links?.nodes.length"
                            :label="$t('other-documents')"
                            :items="toCardRowAttachmentLink(project.otherDocuments.links.nodes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('other-documents')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="systems" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('systems')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('systems')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('systems', $t('project-series-systems'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectSystemsSchema)"
                    @save="dataFunctions?.save(undefined, true)"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('annual-systems-help')">
                        {{ $t('project-systems') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectSystemsForm v-if="showForm('systems')" class="p-5" />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="project.otherSystems && project.otherSystems.links?.nodes.length"
                            :label="$t('other-systems')"
                            :items="toCardRowAttachmentLink(project.otherSystems.links.nodes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('other-systems')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="otherLinks" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('otherLinks')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('otherLinks')"
                    @enter-edit-mode="
                        cardFunctions?.updateCard('otherLinks', $t('project-series-other-links'), 'Edit-Untouched')
                    "
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectOtherLinksSchema)"
                    @save="dataFunctions?.save(undefined, true)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('project-other-links') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectOtherLinksForm v-if="showForm('otherLinks')" class="p-5" />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="project.otherLinks && project.otherLinks.links?.nodes.length"
                            :label="$t('other-links')"
                            :items="toCardRowAttachmentLink(project.otherLinks.links.nodes)"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('other-links')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowAttachmentGroup from '@/components/Card/CardRowAttachmentGroup.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import { toCardRowAttachmentLink } from '@/components/MultiLink/multilink';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { $t } from '@/plugins/fluent';
import ProjectDocumentsForm from '@/project/forms/project/ProjectDocumentsForm.vue';
import ProjectOtherLinksForm from '@/project/forms/project/ProjectOtherLinksForm.vue';
import ProjectSystemsForm from '@/project/forms/project/ProjectSystemsForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectData } from '@/project/keys';
import { ProjectDocumentsSchema, ProjectOtherLinksSchema, ProjectSystemsSchema } from '@/project/schemas';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';

const data = inject(projectData);
const project = data?.project;

useHeadSafe({
    title: () =>
        `${$t('links')} - ${project?.value.nameShort} - ${project?.value.series.nameShort} - ${$t('project-wiki')}`,
});

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
