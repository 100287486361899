<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8.15 14v-1H13V3H8.15V2H13c.267 0 .5.1.7.3.2.2.3.433.3.7v10c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H8.15Zm-1.3-3.083-.717-.717 1.7-1.7H2v-1h5.8L6.1 5.8l.717-.717L9.75 8.017l-2.9 2.9Z"
        />
    </svg>
</template>

<script setup lang="ts">
type LoginIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<LoginIconProps>();
</script>
