import { FormLink, FormMultilink, LinkStatus, Multilink } from './types';

export function isMultiLinkDirty(multilink: FormMultilink, originalMultilink: Multilink) {
    const links = multilink.links?.nodes ?? [];
    const linksToCreate = links.filter((link) => link.status === 'new' && (link.name.length > 0 || link.to.length > 0));
    const linksToDelete = links.filter((link) => link.id && link.status === 'deleted');
    const linksToUpdate = links.filter((link) => link.id && link.status === 'prop');

    if (linksToCreate.length > 0 || linksToDelete.length > 0) return true;
    if (linksToUpdate.length > 0) {
        return linksToUpdate.some((link) => {
            const originalLink = originalMultilink.links?.nodes.find((l) => l.id === link.id);
            if (
                originalLink?.name !== link.name ||
                originalLink.to !== link.to ||
                originalLink.description !== link.description
            ) {
                return true;
            }
            return false;
        });
    }
    return false;
}

const getLinkVariant = (to: string) => {
    // TODO do we want to do something more intelligent here?
    if (to.startsWith('http')) {
        return 'Link-External' as const;
    }
    return 'Link-Internal' as const;
};

// TODO Can we use a more precise type than `any`?
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function toCardRowAttachmentLink(nodes: any) {
    if (!nodes) return [];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return nodes.map((node: any) => ({
        variant: getLinkVariant(node.to),
        label: node.name,
        linkTarget: node.to,
        helpButtonText: node.description,
    }));
}

export function toFormMultilink(multilink: Multilink) {
    return {
        id: multilink.id,
        name: multilink.name,
        links: {
            nodes:
                multilink.links?.nodes.map((n) => ({
                    id: n.id,
                    multilinkId: multilink.id,
                    name: n.name,
                    to: n.to,
                    status: 'prop' as LinkStatus,
                    description: n.description,
                    errors: { name: [], description: [], to: [] },
                })) ?? [],
        },
    };
}

export function emptyLink(multilinkId: string) {
    return {
        id: '',
        multilinkId: multilinkId,
        name: '',
        to: '',
        description: '',
        status: 'new' as LinkStatus,
        errors: {
            name: [],
            description: [],
            to: [],
        },
    };
}

export function emptyMultilink() {
    return { id: '', name: '', links: { nodes: [emptyLink('')] } };
}

export function checkForLinkErrors(links: FormLink[]) {
    return links.some(
        (link) => link.errors.name.length > 0 || link.errors.description.length > 0 || link.errors.to.length > 0,
    );
}
