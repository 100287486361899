<template>
    <section class="flex flex-row gap-2">
        <div class="px-1 flex flex-col items-center">
            <div
                class="flex place-items-center place-content-center bg-gray-200 text-gray-600 rounded-full w-6 h-6 shrink-0"
            >
                <StarEmptyIcon class="text-gray-600 w-3 h-3" />
            </div>

            <div class="border-l border-l-solid border-l-gray-300 h-full w-px mt-2 translate-x-[0.5px]" />
        </div>

        <div class="flex flex-col pb-2">
            <p class="text-sm text-gray-500 font-light leading-tight mb-0.5">
                <i18n path="user-changed-main-variant">
                    <template #name>
                        <span class="font-medium text-gray-700">{{ props.userName }}</span>
                    </template>

                    <template #newVariant>
                        <span class="font-medium text-gray-700">
                            <i18n path="new-main-variant">
                                <template #variantLink>
                                    <RouterLink
                                        class="underline focus-visible:rounded-[0.0625rem] hover:text-gray-950 focus-visible:outline-gray-700 focus-visible:outline focus-visible:outline-offset-4 focus-visible:outline-2 active:text-sky-600"
                                        :to="props.newMainVariantLinkTarget"
                                    >
                                        {{ props.newMainVariantName }}
                                    </RouterLink>
                                </template>
                            </i18n>
                        </span>
                    </template>

                    <template #oldVariant>
                        <RouterLink
                            class="underline focus-visible:rounded-[0.0625rem] hover:text-gray-950 focus-visible:outline-gray-700 focus-visible:outline focus-visible:outline-offset-4 focus-visible:outline-2 active:text-sky-600"
                            :to="props.previousMainVariantLinkTarget"
                        >
                            {{ props.previousMainVariantName }}
                        </RouterLink>
                    </template>
                </i18n>
            </p>

            <time :datetime="props.dateTime.toISODate()" class="text-xs font-light text-gray-500">
                {{ props.dateTime.toLocaleString() }}
            </time>
        </div>
    </section>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { RouteLocationRaw } from 'vue-router';
import StarEmptyIcon from '../Icon/StarEmptyIcon.vue';

type NewMainVariantProps = {
    userName: string;
    previousMainVariantName: string;
    previousMainVariantLinkTarget: RouteLocationRaw;
    newMainVariantName: string;
    newMainVariantLinkTarget: RouteLocationRaw;
    dateTime: DateTime;
};

const props = defineProps<NewMainVariantProps>();
</script>
