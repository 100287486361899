<template>
    <TopBar v-if="level" :title="level.nameShort" :breadcrumbs="locationBreadcrumbs(level)">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabledExplanation="disabledExplanation"
                    @click="deleteLevel"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
                <DropDownSelector :options="actions" :disabled="!isAdmin" />
            </div>
        </template>
    </TopBar>

    <GQLLoading
        :query="getLevelQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'level', id: levelId })"
    >
        <LocationDetails
            v-if="level"
            :location="level"
            :location-types="levelTypes"
            :to-edit="{ name: 'level-edit', params: { levelId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DropDownSelector from '@/components/DropDownSelector.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useLevel } from '../level';

const props = defineProps<{ levelId: string }>();
const fluent = useFluent();

const { level, levelTypes, getLevelQuery, deleteLevel } = useLevel(computed(() => props.levelId));

const isAdmin = auth.isAdmin();

const actions = [
    {
        title: fluent.$t('room'),
        description: fluent.$t('room-add-to-level'),
        to: { name: 'room-add', levelId: props.levelId },
    },
];

const canDelete = computed(() => {
    const hasAllocations = level.value?.rooms.nodes.some((r) => r.allocationRooms.nodes.length > 0);
    return isAdmin && !hasAllocations;
});

const disabledExplanation = computed(() => {
    if (canDelete.value) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
