<template>
    <Tooltip
        v-bind="$attrs"
        class="p-1 rounded hover:cursor-pointer border-2 focus-visible:outline-none"
        :class="[light100BackgroundColor, borderColorClass, hoverClasses, activeColorClass]"
    >
        <template #trigger="tooltipProps">
            <div
                v-if="!props.specialization"
                class="size-5 flex items-center justify-center"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            >
                -
            </div>

            <!-- Event icons inside -->
            <ClothesHangerIcon
                v-if="specializationIcon === 'ClothesHanger'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <DoorBackIcon
                v-if="specializationIcon === 'DoorBack'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <GroupIcon
                v-if="specializationIcon === 'Group'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <InfoIcon
                v-if="specializationIcon === 'Info'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <PackageIcon
                v-if="specializationIcon === 'Package'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <ParkingIcon
                v-if="specializationIcon === 'Parking'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <RestaurantIcon
                v-if="specializationIcon === 'Restaurant'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <TruckIcon
                v-if="specializationIcon === 'Truck'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <WalkingIcon
                v-if="specializationIcon === 'Walking'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />

            <!-- Event icons outside -->
            <CenterPointIcon
                v-if="specializationIcon === 'CenterPoint'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <DirectionsRunIcon
                v-if="specializationIcon === 'DirectionsRun'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <StoreFrontIcon
                v-if="specializationIcon === 'StoreFront'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />

            <!-- Project icons -->
            <EngineeringIcon
                v-if="specializationIcon === 'Engineering'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <ConstructionIcon
                v-if="specializationIcon === 'Construction'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
            <DangerousIcon
                v-if="specializationIcon === 'Dangerous'"
                class="size-5"
                :aria-labelledby="tooltipProps['aria-labelledby']"
            />
        </template>

        <template #text>
            <span v-if="!specializationIcon">{{ $t('specialization-type-no-icon') }}</span>

            <!-- Event icons inside -->
            <span v-if="specializationIcon === 'ClothesHanger'">{{ $t('specialization-type-cloakroom') }}</span>
            <span v-if="specializationIcon === 'DoorBack'">{{ $t('specialization-type-mobile-entrance') }}</span>
            <span v-if="specializationIcon === 'Group'">{{ $t('specialization-type-congress') }}</span>
            <span v-if="specializationIcon === 'Info'">{{ $t('specialization-type-service-center') }}</span>
            <span v-if="specializationIcon === 'Package'">{{ $t('specialization-type-storage') }}</span>
            <span v-if="specializationIcon === 'Parking'">{{ $t('specialization-type-parking') }}</span>
            <span v-if="specializationIcon === 'Restaurant'">{{ $t('specialization-type-catering') }}</span>
            <span v-if="specializationIcon === 'Truck'">{{ $t('specialization-type-logistics') }}</span>
            <span v-if="specializationIcon === 'Walking'">{{ $t('specialization-type-routing') }}</span>

            <!-- Event icons outside -->
            <span v-if="specializationIcon === 'CenterPoint'">
                {{ $t('specialization-type-service-assembly-point') }}
            </span>
            <span v-if="specializationIcon === 'DirectionsRun'">
                {{ $t('specialization-type-evacuation-corridor') }}
            </span>
            <span v-if="specializationIcon === 'StoreFront'">{{ $t('specialization-type-open-air-ground') }}</span>

            <!-- Project icons -->
            <span v-if="specializationIcon === 'Engineering'">{{ $t('specialization-type-building-activity') }}</span>
            <span v-if="specializationIcon === 'Construction'">{{ $t('specialization-type-maintenance') }}</span>
            <span v-if="specializationIcon === 'Dangerous'">{{ $t('specialization-type-forbidden-zone') }}</span>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import CenterPointIcon from '../../Icon/CenterPointIcon.vue';
import ClothesHangerIcon from '../../Icon/ClothesHangerIcon.vue';
import ConstructionIcon from '../../Icon/ConstructionIcon.vue';
import DangerousIcon from '../../Icon/DangerousIcon.vue';
import DirectionsRunIcon from '../../Icon/DirectionsRunIcon.vue';
import DoorBackIcon from '../../Icon/DoorBackIcon.vue';
import EngineeringIcon from '../../Icon/EngineeringIcon.vue';
import GroupIcon from '../../Icon/GroupIcon.vue';
import InfoIcon from '../../Icon/InfoIcon.vue';
import PackageIcon from '../../Icon/PackageIcon.vue';
import ParkingIcon from '../../Icon/ParkingIcon.vue';
import RestaurantIcon from '../../Icon/RestaurantIcon.vue';
import StoreFrontIcon from '../../Icon/StoreFrontIcon.vue';
import TruckIcon from '../../Icon/TruckIcon.vue';
import WalkingIcon from '../../Icon/WalkingIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';
import {
    getActiveBackgroundColorClassForType,
    getBorder700ColorClassForType,
    getHoverClasses700_50ForType,
    getLight100BackgroundColorClassForType,
} from '../allocation/shared';
import { mapSpecializationsTypesToIcons, SpecializationType } from '../allocation/SpecializationMappings';
import { PhaseType } from '../Timeline.types';

type ChangeTypePopupIconProps = {
    phaseType: PhaseType;
    specialization: SpecializationType | null;
    isActive: boolean;
};

const props = defineProps<ChangeTypePopupIconProps>();

const light100BackgroundColor = computed(() => {
    if (!props.specialization) {
        return 'bg-gray-200';
    }

    return getLight100BackgroundColorClassForType(props.phaseType);
});

const hoverClasses = computed(() => {
    if (!props.specialization) {
        return 'hover:bg-gray-700 hover:text-gray-50';
    }

    return getHoverClasses700_50ForType(props.phaseType);
});

const activeColorClass = computed(() => {
    return `${getActiveBackgroundColorClassForType(props.phaseType)} active:text-sky-50`;
});

const borderColorClass = computed(() => {
    if (!props.isActive) {
        return 'border-transparent';
    }

    if (!props.specialization) {
        return 'border-gray-900';
    }

    return getBorder700ColorClassForType(props.phaseType);
});

const specializationIcon = computed(() => mapSpecializationsTypesToIcons(props.specialization));
</script>
