import { DeleteLevelDocument, GetLevelForDetailsPageDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useLevel(levelId: Ref<string>) {
    const getLevelQuery = useQuery(
        GetLevelForDetailsPageDocument,
        computed(() => ({ id: unref(levelId) })),
    );
    const level = useResult(getLevelQuery.result, undefined, (data) => data.level);
    const levelTypes = computed(() => getLevelQuery.result.value?.levelTypes?.nodes ?? []);

    const levelToDelete = computed(() => ({
        __typename: level.value?.__typename ?? '',
        id: level.value?.id ?? '',
        nameShort: level.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteLevelDocument);
    const routeToParent = computed(() => ({
        name: 'building-details',
        params: { buildingId: level.value?.building.id },
    }));
    const deleteLevel = useDeleteLocation(levelToDelete, deleteMutation, routeToParent);

    return {
        level,
        levelTypes,
        getLevelQuery,
        deleteLevel,
    };
}
