<template></template>

<script setup lang="ts">
import { useFluent } from 'fluent-vue';
import { reactive, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

const router = useRouter();
const fluent = useFluent();
const form = ref();
const toast = useToast();

const variables = reactive({
    firstName: '',
    lastName: '',
    password: '',
    password2: '',
    email: '',
});

/* FIXME
const createUser = useMutation(CreateUserDocument, {
    refetchQueries: ['GetUsers'],
});


async function save() {
    if (!form.value.validate()) return;
    let res = await createUser.mutate(variables);

    if (res?.data?.createUser.__typename === 'CreateUserResult') {
        var { id, name } = res?.data?.createUser.user;

        toast.success(fluent.$t('create-sucess-notification', { name: name }), { onClick: () => goToUser(id) });

        goToUser(id);
    }
}

function goToUser(userId: string) {
    router.replace({
        name: 'user-details',
        params: { userId: userId },
    });
}

function formatError(res: CreateUserResponse): string | undefined {
    switch (res.__typename) {
        case 'UserAlreadyExistsError':
            return fluent.$t('error-already-exists', { name: res.name });
        default:
            return undefined;
    }
}
*/
</script>

<style scoped>
.align-with-textfields {
    margin-left: 32px;
}
</style>

<fluent locale="de">
title=Neuen Nutzer anlegen
first-name-input=
    .label = Vorname
    .placeholder = Max
last-name-input=
    .label = Nachname
    .placeholder = Mustermann
email-input=
    .label = Email
    .placeholder = abc@...
password-input=
    .label = Passwort
repeat-password-input=
    .label = Passwort wiederholen
create=Erstellen
cancel=Abbrechen
create-sucess-notification=Benutzer { $name } wurde angelegt
error-already-exists=Es gibt bereits einen Account ({ $name }) mit dieser Email Adresse
</fluent>
