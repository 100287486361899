<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 14c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.941c0-.367.13-.68.391-.942.262-.261.575-.392.942-.392s.68.13.942.392c.26.261.391.575.391.942 0 .366-.13.68-.391.941A1.284 1.284 0 0 1 8 14Zm-1.333-4V2h2.666v8H6.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type PriorityHighIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PriorityHighIconProps>();
</script>
