<!-- Uses tailwind ui button group: https://tailwindui.com/components/application-ui/elements/button-groups#component-cbd15ccb80090a5dfb2d647be03b6ee9 -->
<template>
    <Button size="Small" variant="Transparent-Light" type="button" @click="createJSONDownload">
        {{ $t('download-locations-copy') }}
        <template #icon><DownloadIcon :title="$t('download')"></DownloadIcon></template>
    </Button>
</template>

<script setup lang="ts">
import Button from '@/components/Button/Button.vue';
import DownloadIcon from '@/components/Icon/DownloadIcon.vue';
import { useLocationDownload } from '@/location/composables/useLocationDownload';

const { createJSONDownload } = useLocationDownload();
</script>
