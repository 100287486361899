<!-- Based on these implementations:
    - https://github.com/microsoft/sonder-ui/tree/master/src/components/select
    - https://www.w3.org/WAI/ARIA/apg/patterns/combobox/examples/combobox-select-only/
-->

<template>
    <div ref="wrapperRef">
        <label :id="labelId" class="sr-only">{{ props.label }}</label>
        <Tooltip :is-disabled="!hasTooltip">
            <template #trigger>
                <div
                    ref="currentValueRef"
                    class="inline-flex items-center rounded-md py-[0.4375rem] pr-1.5 pl-2.5 font-medium text-base ring-1 ring-inset focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 outline-none"
                    :class="[wrapperColorClasses, isDisabled ? 'cursor-not-allowed' : 'cursor-pointer']"
                    :aria-disabled="isDisabled"
                    :aria-activedescendant="idFocusedOption"
                    aria-autocomplete="none"
                    :aria-controls="listboxId"
                    :aria-expanded="isOpen"
                    aria-haspopup="listbox"
                    :aria-labelledby="labelId"
                    :role="isRetry ? 'button' : 'combobox'"
                    tabindex="0"
                    @mouseover="!isDisabled ? (isHovered = true) : null"
                    @mouseout="!isDisabled ? (isHovered = false) : null"
                    @focus="canBeOpened ? (isFocused = true) : null"
                    @focusout="canBeOpened ? (isFocused = false) : null"
                    @keydown="keydownTrigger"
                    @click="clickTrigger"
                >
                    <WarningIcon v-if="isRetry" class="text-red-700 h-5 w-5 mr-2" />

                    <div class="pr-2 border-r leading-[1.625rem]" :class="innerBorderColorClass">
                        {{ props.currentValue.label }}
                    </div>

                    <TriangleIcon
                        v-if="isDefault || isDisabled"
                        class="ml-1 h-5 w-5 rounded-sm transition-transform"
                        :class="[isOpen && isDefault ? '-rotate-90' : 'rotate-90']"
                    />
                    <PendingIcon v-if="isLoading" class="ml-1 h-5 w-5 rounded-sm animate-spin" />
                    <RefreshIcon v-if="isRetry" class="ml-1 h-5 w-5 rounded-sm" />
                </div>
            </template>

            <template #text>
                <div class="w-max">{{ props.currentValue.tooltip }}</div>
            </template>
        </Tooltip>

        <div
            :id="listboxId"
            ref="valuesPanelRef"
            class="bg-gray-50 space-y-0.5 shadow rounded z-20"
            :class="[{ 'py-0.5': isOpen }]"
            :style="floatingStyles"
            role="listbox"
            :aria-labelledby="labelId"
            tabindex="-1"
        >
            <div
                v-for="(option, index) in options"
                v-if="canBeOpened && isOpen"
                :id="`${optionId}-${index}`"
                :key="option.id"
                class="flex flex-row items-center pl-2.5 pr-3 py-1.5 focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:outline-sky-700 focus-visible:rounded outline-none"
                :class="[
                    { 'bg-sky-700 cursor-pointer': option.isFocused },
                    option.isFocused ? 'text-gray-50' : 'text-gray-700',
                ]"
                role="option"
                :aria-selected="option.isSelected"
                @mouseover="focusedIndex = index"
                @mouseout="focusedIndex = -1"
                @focus="focusedIndex = index"
                @focusout="focusedIndex = -1"
                @click="handleOptionClick(option.id)"
            >
                <CheckIcon v-if="option.isSelected" class="mr-2" />
                <div v-else class="w-4 h-4 mr-2" />

                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/vue';
import { onClickOutside } from '@vueuse/core';
import { nanoid } from 'nanoid';
import { computed, provide, ref, watchEffect } from 'vue';
import CheckIcon from '../Icon/CheckIcon.vue';
import PendingIcon from '../Icon/PendingIcon.vue';
import RefreshIcon from '../Icon/RefreshIcon.vue';
import TriangleIcon from '../Icon/TriangleIcon.vue';
import WarningIcon from '../Icon/WarningIcon.vue';
import Tooltip from '../Tooltip/Tooltip.vue';
import { triggerHideTooltipKey } from '../Tooltip/injectionKeys';

export type BadgeDropDownStatus = 'Default' | 'Disabled' | 'Loading' | 'Retry';
export type BadgeDropDownVariant = 'Green' | 'Yellow' | 'Red' | 'Gray' | 'Purple';

export type BadgeDropDownValue = {
    id: string;
    label: string;
    variant: BadgeDropDownVariant;
};

type BadgeDropDownProps = {
    label: string;
    currentValue: BadgeDropDownValue & { tooltip?: string };
    values: (BadgeDropDownValue & { isSelected: boolean })[];
    status: BadgeDropDownStatus;
};

const props = defineProps<BadgeDropDownProps>();

const emit = defineEmits<{
    (e: 'changeValue', value: string): void;
    (e: 'retry'): void;
}>();

const isDefault = computed(() => props.status === 'Default');
const isDisabled = computed(() => props.status === 'Disabled');
const isLoading = computed(() => props.status === 'Loading');
const isRetry = computed(() => props.status === 'Retry');
const hasTooltip = computed(() => Boolean(props.currentValue.tooltip));
const canBeOpened = computed(() => !isDisabled.value && !isRetry.value);

const options = ref<(BadgeDropDownValue & { isSelected: boolean; isFocused: boolean })[]>([]);
watchEffect(() => {
    options.value = props.values.map((value) => {
        return {
            ...value,
            isFocused: false,
        };
    });
});
const idFocusedOption = computed(() => {
    const index = options.value.findIndex((option) => option.isFocused);

    if (index === -1) {
        return undefined;
    }

    return `${optionId}-${index}`;
});

const focusedIndex = ref(-1);
watchEffect(() => {
    focusOption(focusedIndex.value);
});

function focusOption(focusIndex: number) {
    options.value = options.value.map((option, optionIndex) => {
        return {
            ...option,
            isFocused: focusIndex === optionIndex,
        };
    });
}

function focusFirstOption() {
    focusedIndex.value = 0;
}

function focusLastOption() {
    focusedIndex.value = options.value.length - 1;
}

function focusPreviousOption() {
    focusedIndex.value = Math.max(focusedIndex.value - 1, 0);
}

function focusNextOption() {
    focusedIndex.value = Math.min(focusedIndex.value + 1, options.value.length - 1);
}

function handleKeyDown(event: KeyboardEvent) {
    // When collapsed
    if (!isOpen.value) {
        switch (event.key) {
            case 'Enter':
            case ' ':
            case 'ArrowDown':
                isOpen.value = true;
                focusFirstOption();
                event.preventDefault();
                return;

            case 'ArrowUp':
                isOpen.value = true;
                focusLastOption();
                event.preventDefault();
                return;
        }
    }

    // When expanded
    if (isOpen.value) {
        switch (event.key) {
            case 'Enter':
            case ' ':
                emit('changeValue', options.value[focusedIndex.value].id);
                isOpen.value = false;
                event.preventDefault();
                return;

            case 'ArrowUp':
                focusPreviousOption();
                event.preventDefault();
                return;

            case 'ArrowDown':
                focusNextOption();
                event.preventDefault();
                return;

            case 'PageUp':
                focusFirstOption();
                event.preventDefault();
                return;

            case 'PageDown':
                focusLastOption();
                event.preventDefault();
                return;

            case 'Escape':
                focusOption(-1);
                isOpen.value = false;
                event.preventDefault();
                return;

            case 'Tab':
                isOpen.value = false;
                return;
        }

        return;
    }
}

function handleOptionClick(id: string) {
    emit('changeValue', id);
    isOpen.value = false;
}

const labelId = nanoid();
const listboxId = nanoid();
const optionId = nanoid();

const isOpen = ref(false);
const isHovered = ref(false);
const isFocused = ref(false);

const wrapperColorClasses = computed(() => {
    if (isDisabled.value) {
        return `
            bg-gray-100 text-gray-400 ring-gray-700/10
            focus-visible:outline focus-visible:outline-offset-1 focus-visible:outline-2 focus-visible:outline-gray-500
        `;
    }

    if (isRetry.value) {
        switch (props.currentValue.variant) {
            case 'Green':
                return `
                    bg-green-50 text-green-700 ring-red-700
                    outline outline-offset-0 outline-1 outline-red-700
                `;

            case 'Yellow':
                return `
                    bg-yellow-50 text-yellow-700 ring-red-700
                    outline outline-offset-0 outline-1 outline-red-700
                `;

            case 'Red':
                return `
                    bg-red-50 text-red-700 ring-red-700
                    outline outline-offset-0 outline-1 outline-red-700
                `;

            case 'Gray':
                return `
                    bg-gray-50 text-gray-700 ring-red-700
                    outline outline-offset-0 outline-1 outline-red-700
                `;

            case 'Purple':
                return `
                    bg-purple-50 text-purple-700 ring-red-700
                    outline outline-offset-0 outline-1 outline-red-700
                `;

            default:
                throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
        }
    }

    if (isFocused.value && isHovered.value) {
        switch (props.currentValue.variant) {
            case 'Green':
                return `
                    bg-green-50 text-green-700 ring-green-700/10
                    outline outline-offset-1 outline-2 outline-green-700
                `;

            case 'Yellow':
                return `
                    bg-yellow-50 text-yellow-700 ring-yellow-700/10
                    outline outline-offset-1 outline-2 outline-yellow-700
                `;

            case 'Red':
                return `
                    bg-red-50 text-red-700 ring-red-700/10
                    outline outline-offset-1 outline-2 outline-red-700
                `;

            case 'Gray':
                return `
                    bg-gray-50 text-gray-700 ring-gray-700/10
                    outline outline-offset-1 outline-2 outline-gray-700
                `;

            case 'Purple':
                return `
                    bg-purple-50 text-purple-700 ring-purple-700/10
                    outline outline-offset-1 outline-2 outline-purple-700
                `;

            default:
                throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
        }
    }

    if (isFocused.value) {
        switch (props.currentValue.variant) {
            case 'Green':
                return `
                    bg-green-50 text-green-700 ring-green-700/10
                    outline outline-offset-1 outline-2 outline-green-700
                `;

            case 'Yellow':
                return `
                    bg-yellow-50 text-yellow-700 ring-yellow-700/10
                    outline outline-offset-1 outline-2 outline-yellow-700
                `;

            case 'Red':
                return `
                    bg-red-50 text-red-700 ring-red-700/10
                    outline outline-offset-1 outline-2 outline-red-700
                `;

            case 'Gray':
                return `
                    bg-gray-50 text-gray-700 ring-gray-700/10
                    outline outline-offset-1 outline-2 outline-gray-700
                `;

            case 'Purple':
                return `
                    bg-purple-50 text-purple-700 ring-purple-700/10
                    outline outline-offset-1 outline-2 outline-purple-700
                `;

            default:
                throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
        }
    }

    if (isHovered.value) {
        switch (props.currentValue.variant) {
            case 'Green':
                return `
                    bg-green-50 text-green-700 ring-green-700
                    focus-visible:outline-green-700
                `;

            case 'Yellow':
                return `
                    bg-yellow-50 text-yellow-700 ring-yellow-700
                    focus-visible:outline-yellow-700
                `;

            case 'Red':
                return `
                    bg-red-50 text-red-700 ring-red-700
                    focus-visible:outline-red-700
                `;

            case 'Gray':
                return `
                    bg-gray-50 text-gray-700 ring-gray-700
                    focus-visible:outline-gray-700
                `;

            case 'Purple':
                return `
                    bg-gray-50 text-purple-700 ring-purple-700
                    focus-visible:outline-purple-700
                `;

            default:
                throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
        }
    }

    switch (props.currentValue.variant) {
        case 'Green':
            return `
                    bg-green-50 text-green-700 ring-green-700/10
                    focus-visible:outline-green-700
                `;

        case 'Yellow':
            return `
                    bg-yellow-50 text-yellow-700 ring-yellow-700/10
                    focus-visible:outline-yellow-700
                `;

        case 'Red':
            return `
                    bg-red-50 text-red-700 ring-red-700/10
                    focus-visible:outline-red-700
                `;

        case 'Gray':
            return `
                    bg-gray-50 text-gray-700 ring-gray-700/10
                    focus-visible:outline-gray-700
                `;

        case 'Purple':
            return `
                    bg-purple-50 text-purple-700 ring-purple-700/10
                    focus-visible:outline-purple-700
                `;

        default:
            throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
    }
});

const innerBorderColorClass = computed(() => {
    switch (props.currentValue.variant) {
        case 'Green':
            return 'border-r-green-600/10';

        case 'Yellow':
            return 'border-r-yellow-600/10';

        case 'Red':
            return 'border-r-red-600/10';

        case 'Gray':
            return 'border-r-gray-600/10';

        case 'Purple':
            return 'border-r-purple-600/10';

        default:
            throw new Error(`Unknown BadgeDropDown variant: ${props.currentValue.variant}`);
    }
});

const currentValueRef = ref(null);
const valuesPanelRef = ref(null);
const wrapperRef = ref(null);
const { floatingStyles } = useFloating(currentValueRef, valuesPanelRef, {
    open: isOpen.value,
    placement: 'bottom-end',
    whileElementsMounted: autoUpdate,
    middleware: [
        offset(6), // number = Shorthand for main-axis
        flip({
            fallbackAxisSideDirection: 'start', // Which side to try first if neither the preferred option nor the fallback option fits
            crossAxis: true, // Flip to opposite axis when possible
        }),
        shift({ padding: 4 }), // padding = Minimal distance from tooltip to viewport edge
    ],
});

onClickOutside(wrapperRef, () => (isOpen.value = false));

// This is used to force close the Tooltip after clicking the button
const triggerHideTooltipKeyCounter = ref(0);
provide(triggerHideTooltipKey, triggerHideTooltipKeyCounter);

function triggerHideTooltip() {
    triggerHideTooltipKeyCounter.value = triggerHideTooltipKeyCounter.value + 1;
}

function keydownTrigger(event: KeyboardEvent) {
    if (canBeOpened.value) {
        handleKeyDown(event);
    } else if (event.key === ' ' || event.key === 'Enter') {
        emit('retry');
    }
}

function clickTrigger() {
    if (canBeOpened.value) {
        triggerHideTooltip();
        isOpen.value = !isOpen.value;
    }

    if (isRetry.value) {
        emit('retry');
    }
}
</script>
