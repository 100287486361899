import { getAbsoluteURI } from '@/utils/url';
import { useFluent } from 'fluent-vue';
import { RouteLocationRaw, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

export function useReportLink() {
    const router = useRouter();
    const toast = useToast();
    const fluent = useFluent();

    function copyLink(to: RouteLocationRaw) {
        const reportLink = router.resolve(to);
        const url = getAbsoluteURI(reportLink.fullPath);
        navigator.clipboard.writeText(url);
        toast.success(fluent.$t('copy-success-notification'));
    }

    return {
        copyLink,
    };
}
