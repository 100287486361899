import LocationsPage from '@/location/pages/LocationsPage.vue';
import PlotEditPage from '@/plot/pages/PlotEditPage.vue';
import PlotPage from '@/plot/pages/PlotPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';

export const plotRoutes = [
    {
        path: 'plot/:plotId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'plot-details',
                components: {
                    default: LocationsPage,
                    detail: PlotPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'plot-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: PlotEditPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
