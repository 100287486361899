import { z } from 'zod';
import { $t } from '../../plugins/fluent';

export const scenarioCreateSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
});

export const scenarioUpdateSchema = z.object({
    name: z.string().trim().min(1, $t('name-required')),
    description: z.string().optional(),
});
