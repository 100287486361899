import { LocationWithDays } from '../Timeline.types';
import { TimelineRow } from '../TimelineView.types';

export function extractRowsFromLocations(locations: LocationWithDays[]): TimelineRow[] {
    return locations.map((location, locationIndex) => {
        return {
            days: location.days.map((day, dayIndex) => {
                return {
                    ...day,
                    rowId: `row-${location.id}`,
                    columnId: `column-${dayIndex}`,
                    isFirstRow: locationIndex === 0,
                    isFirstColumn: dayIndex === 0,
                };
            }),
            locationId: location.id,
            isLocationInside: location.isInside,
        };
    });
}
