<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.333 15.333V12.55l-.733.717-.933-.934L8 10l2.333 2.333-.933.934-.733-.717v2.783H7.333Zm-3.666-5L2.733 9.4l.717-.733H.667V7.333H3.45L2.733 6.6l.934-.933L6 8l-2.333 2.333Zm8.666 0L10 8l2.333-2.333.934.933-.717.733h2.783v1.334H12.55l.717.733-.934.933ZM8 9a.964.964 0 0 1-.708-.292A.964.964 0 0 1 7 8a.96.96 0 0 1 .292-.708A.964.964 0 0 1 8 7a.96.96 0 0 1 .708.292A.96.96 0 0 1 9 8a.964.964 0 0 1-.292.708A.964.964 0 0 1 8 9Zm0-3L5.667 3.667l.933-.934.733.717V.667h1.334V3.45l.733-.717.933.934L8 6Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CenterPointIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<CenterPointIconProps>();
</script>
