import { ref, toValue, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

export function useChangeVariantsRouteQueryParams() {
    const router = useRouter();
    const route = useRoute();

    const routeHighlight = route.query.highlight?.toString();
    const highlightRef = ref(routeHighlight);
    const routeVisible = route.query.visible?.toString();
    const visibleRef = ref(routeVisible);

    function setHighlightParam(value: string | undefined) {
        highlightRef.value = value;
    }

    function setVisibleParam(value: string | undefined) {
        visibleRef.value = value;
    }

    function pushNewRoute() {
        const highlightValue = toValue(highlightRef);
        const visibleValue = toValue(visibleRef);

        if (highlightValue && visibleValue) {
            router.push({ query: { visible: visibleValue, highlight: highlightValue } });
        }
        if (!highlightValue && visibleValue) {
            router.push({ query: { visible: visibleValue } });
        }
        if (highlightValue && !visibleValue) {
            router.push({ query: { highlight: highlightValue } });
        }
        if (!highlightValue && !visibleValue) {
            router.push({ query: {} });
        }
    }

    watchEffect(pushNewRoute);

    return {
        setHighlightParam,
        setVisibleParam,
    };
}
