<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4 14V2h4.667c1.11 0 2.055.389 2.833 1.167.778.777 1.167 1.722 1.167 2.833 0 1.111-.39 2.056-1.167 2.833C10.722 9.611 9.778 10 8.667 10h-2v4H4Zm2.667-6.667H8.8c.367 0 .68-.13.942-.391.26-.261.391-.575.391-.942s-.13-.68-.391-.942a1.284 1.284 0 0 0-.942-.391H6.667v2.666Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ParkingIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ParkingIconProps>();
</script>
