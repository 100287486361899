<template>
    <list-item :to="to" :title="scenario.name" :subtitle="eventNames"> </list-item>
</template>

<script setup lang="ts">
import ListItem from '@/components/ListItem.vue';
import { distinctBy } from '@/utils/distinct';
import { computed } from 'vue';

type Scenario = {
    name: string;
    scenarioEvents: {
        nodes: Array<{
            event: {
                nameShort: string;
            };
            eventVariant: {
                name: string;
                isCurrent: boolean;
            };
        }>;
    };
};
const props = defineProps<{
    scenario: Scenario;
    to: Object;
}>();

const eventNames = computed(() =>
    distinctBy(props.scenario.scenarioEvents.nodes, (x) => x.event.nameShort)
        .map((x) => x.event.nameShort)
        .join(', '),
);
</script>
