<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 14.667a6.492 6.492 0 0 1-2.6-.525 6.732 6.732 0 0 1-2.117-1.425A6.733 6.733 0 0 1 1.858 10.6 6.492 6.492 0 0 1 1.333 8c0-1.3.345-2.492 1.034-3.575a6.57 6.57 0 0 1 2.8-2.458A5.714 5.714 0 0 0 5.317 3a5.606 5.606 0 0 0-2.184 2.092A5.578 5.578 0 0 0 2.333 8c0 1.578.55 2.917 1.65 4.017 1.1 1.1 2.44 1.65 4.017 1.65 1.578 0 2.917-.55 4.017-1.65 1.1-1.1 1.65-2.44 1.65-4.017a5.565 5.565 0 0 0-.8-2.917A5.594 5.594 0 0 0 10.667 3c.044-.2.08-.38.108-.542.028-.16.047-.325.058-.491a6.57 6.57 0 0 1 2.8 2.458A6.529 6.529 0 0 1 14.667 8c0 .922-.175 1.789-.525 2.6a6.733 6.733 0 0 1-1.425 2.117c-.6.6-1.306 1.075-2.117 1.425a6.491 6.491 0 0 1-2.6.525ZM8 12c-1.111 0-2.056-.389-2.833-1.167C4.389 10.056 4 9.111 4 8a3.958 3.958 0 0 1 1.783-3.333c.045.122.092.264.142.425.05.16.114.352.192.575-.356.289-.63.633-.825 1.033C5.097 7.1 5 7.533 5 8c0 .833.292 1.542.875 2.125A2.893 2.893 0 0 0 8 11c.833 0 1.542-.292 2.125-.875A2.893 2.893 0 0 0 11 8c0-.467-.097-.9-.292-1.3-.194-.4-.47-.744-.825-1.033l.175-.559c.05-.16.098-.308.142-.441a4.014 4.014 0 0 1 1.325 1.441C11.842 6.692 12 7.322 12 8c0 1.111-.389 2.056-1.167 2.833C10.056 11.611 9.111 12 8 12Zm-.533-6.5c-.411-1.233-.675-2.08-.792-2.542A5.283 5.283 0 0 1 6.5 1.667c0-.423.144-.778.433-1.067A1.45 1.45 0 0 1 8 .167c.422 0 .778.144 1.067.433.289.289.433.644.433 1.067 0 .4-.058.83-.175 1.291-.117.461-.38 1.309-.792 2.542H7.467ZM8 9.167c-.322 0-.597-.114-.825-.342A1.124 1.124 0 0 1 6.833 8c0-.322.114-.597.342-.825.228-.228.503-.342.825-.342.322 0 .597.114.825.342.228.228.342.503.342.825 0 .322-.114.597-.342.825A1.124 1.124 0 0 1 8 9.167Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CrisisAlertIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<CrisisAlertIconProps>();
</script>
