<template>
    <div>
        <BaseForm>
            <template #fields>
                <Toggle
                    :value="series.singleEvent"
                    :label="$t('limit-to-single-event')"
                    :help-button-text="$t('limit-to-single-event-help')"
                    @change="handleToggleField({ value: $event, fieldName: 'singleEvent' })"
                ></Toggle>
                <FieldGroup :title="$t('identifiers')">
                    <TextField
                        :value="series.nameShort"
                        :label="$t('name-short')"
                        :errorMessages="errors.nameShort"
                        @input="handleFormField({ event: $event, fieldName: 'nameShort' })"
                    />
                    <TextField
                        :value="series.nameLong"
                        :label="$t('name-long')"
                        :errorMessages="errors.nameLong"
                        @input="handleFormField({ event: $event, fieldName: 'nameLong' })"
                    />
                    <TextAreaField
                        :value="series.subtitle"
                        :label="$t('subtitle')"
                        :errorMessages="errors.subtitle"
                        @input="handleFormField({ event: $event, fieldName: 'subtitle' })"
                    />
                </FieldGroup>
            </template>
        </BaseForm>
        <div class="bg-white sticky bottom-0 p-3 shadow-[0px_-3px_3px_-3px_#ccc] flex justify-end gap-3">
            <BaseButton variant="outlined" @click="$router.back">{{ $t('cancel') }}</BaseButton>
            <BaseButton @click="onSave">{{ $t('save') }}</BaseButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import FieldGroup from '@/components/Form/FieldGroup.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import Toggle from '@/components/Form/Toggle.vue';
import { useEventSeriesForm } from '@/event/composables/forms';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const { series, errors, handleFormField, handleToggleField, onSaveCreateForm } = useEventSeriesForm(ref(''));

const router = useRouter();

async function onSave() {
    const seriesId = await onSaveCreateForm();
    if (seriesId) {
        router.push({
            name: 'event-series-overview',
            params: { seriesId },
        });
    }
}
</script>
