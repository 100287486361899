<template>
    <div class="-mx-4 p-4 bg-gray-50">
        <div v-if="!timelineLoading && mappedCurrentVariant" class="flex justify-end mb-4">
            <VariantSelectionDropdown
                :drop-down-status="dropDownStatus"
                :variants-ref="variantsRef"
                :visible-variants="visibleVariants"
                :default-active-variants="defaultActiveVariants"
                :selection-panel-variants="selectionPanelVariants"
                :highlighted-variant="highlightedVariant"
                :highlighted-variant-id="highlightedVariantId"
                :mapped-current-variant="mappedCurrentVariant"
                :allocation-type="allocationType"
                :object-id="eventId"
                :set-variants-ref="setVariantsRef"
                :set-visible-variants="setVisibleVariants"
                :set-highlighted-variant-id="setHighlightedVariantId"
                :on-delete-filter="onDeleteFilter"
            ></VariantSelectionDropdown>
        </div>

        <Message v-if="timelineLoading" status="info">
            {{ $t('loading-timeline-data') }}
        </Message>

        <Message v-if="!timelineLoading && timelineError" status="error" :headline="$t('error-fetching-timeline-data')">
            {{ timelineError }}
        </Message>

        <Timeline
            v-if="!timelineLoading && editableVariant && site"
            :site-name="site.nameShort"
            :site-link-target="routeForLocation('Site', site.id)"
            :start-date-time="timelineStartDateTime"
            :end-date-time="timelineEndDateTime"
            previous-month-button="Visible"
            next-month-button="Visible"
            :locations="locations"
            :allocations="filteredAllocations"
            :external-events="externalEvents"
            :sections="sections"
            :allocation-variant-id="editableVariant.id"
            :current-allocation-variant-label="editableVariantLabel"
            :has-create-buttons="Boolean(highlightedVariant)"
            @toggle-section-expanded="toggleSectionExpanded"
            @create-allocation="onCreateAllocation"
            @delete-allocation="onDeleteAllocation"
            @update-phases="onUpdatePhases"
            @expand-to-previous-month="onExpandToPreviousMonth"
            @expand-to-next-month="onExpandToNextMonth"
        >
            <template #site-icon>
                <TerrainIcon class="w-5 h-5" :title="$t('site-icon-alt')" />
            </template>
        </Timeline>
    </div>
</template>

<script setup lang="ts">
import { useCreateAllocation } from '@/allocation/composables/useCreateAllocation';
import { useDeleteAllocation } from '@/allocation/composables/useDeleteAllocation';
import { useUpdateAllocations } from '@/allocation/composables/useUpdateAllocations';
import { useVariantSelectionPanel } from '@/components/AllocationVariantSelectionPanel/composables/useVariantSelectionPanel';
import TerrainIcon from '@/components/Icon/TerrainIcon.vue';
import Message from '@/components/Message/Message.vue';
import { ExternalEvent } from '@/components/Timeline/Timeline.types';
import Timeline from '@/components/Timeline/Timeline.vue';
import { useSections } from '@/components/Timeline/composables/useSections';
import { useTimelineLocations, useTimelineRouteParams, useTimelineStartAndEnd } from '@/components/Timeline/timeline';
import { useEventAllocations } from '@/event/composables/useEventAllocations';
import { useEventTimelineData } from '@/event/composables/useEventTimelineData';
import { GetEventVariantForTimelineDocument } from '@/generated/graphql';
import { routeForLocation } from '@/location/location';
import { $t } from '@/plugins/fluent';
import VariantSelectionDropdown from '@/variant/components/VariantSelectionDropdown.vue';
import { DateTime } from 'luxon';
import { computed } from 'vue';

const props = defineProps<{ eventId: string }>();
const eventId = computed(() => props.eventId);
const allocationType = 'Event';

const {
    variantsRef,
    setVariantsRef,
    loading: timelineLoading,
    error: timelineError,
    onResult: onTimelineResult,
    site,
    event,
    rooms,
    areas,
    usages,
} = useEventTimelineData(eventId);

const {
    dropDownStatus,
    visibleVariants,
    defaultActiveVariants,
    editableVariant,
    editableVariantLabel,
    highlightedVariantId,
    highlightedVariant,
    mappedCurrentVariant,
    selectionPanelVariants,
    setVisibleVariants,
    setHighlightedVariantId,
    onDeleteFilter,
} = useVariantSelectionPanel(variantsRef, allocationType, eventId);

const { visibleIdsFromRoute, highlightedIdFromRoute } = useTimelineRouteParams(variantsRef);

const executionYear = computed(() => event.value?.executionYear ?? DateTime.now().year);

const { timelineStartDateTime, timelineEndDateTime, onExpandToPreviousMonth, onExpandToNextMonth } =
    useTimelineStartAndEnd(variantsRef, executionYear);

onTimelineResult(() => {
    if (visibleIdsFromRoute.value.length > 0) {
        setVisibleVariants(visibleIdsFromRoute.value);
    } else {
        setVisibleVariants(defaultActiveVariants.value.map((v) => v.id));
    }
    setHighlightedVariantId(highlightedIdFromRoute.value);
});

const { locations } = useTimelineLocations(rooms, areas);

const label = computed(() => event.value?.nameShort ?? '');

const { filteredAllocations } = useEventAllocations(
    variantsRef,
    visibleVariants,
    allocationType,
    label,
    highlightedVariantId,
    eventId,
);

const externalEvents: ExternalEvent[] = [];

const { sections, toggleSectionExpanded } = useSections();
const { onCreateAllocation } = useCreateAllocation(
    variantsRef,
    setVariantsRef,
    editableVariant,
    usages,
    allocationType,
);
const { onDeleteAllocation } = useDeleteAllocation(variantsRef, setVariantsRef, allocationType);
const { onUpdatePhases } = useUpdateAllocations(
    variantsRef,
    setVariantsRef,
    usages,
    GetEventVariantForTimelineDocument,
);
</script>
