<template>
    <div v-if="series" class="space-y-4">
        <FlexibleCard headline-id="documents" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('documents')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('documents')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'documents', label: $t('series-annual-documents') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'documents')"
                    @save="$emit('save', 'documents')"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('annual-documents-help')">
                        {{ $t('series-annual-documents') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <DocumentsForm
                        v-if="showForm('documents')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="extraDocuments.length"
                            :label="$t('extra-documents')"
                            :items="extraDocuments"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('extra-documents')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="systems" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('systems')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('systems')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'systems', label: $t('series-annual-systems') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'systems')"
                    @save="$emit('save', 'systems')"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('annual-systems-help')">
                        {{ $t('series-annual-systems') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <SystemsForm
                        v-if="showForm('systems')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="extraSystems.length"
                            :label="$t('extra-systems')"
                            :items="extraSystems"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('extra-systems')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="data" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('data')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('data')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'data', label: $t('series-annual-data') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'data')"
                    @save="$emit('save', 'data')"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('annual-data-help')">
                        {{ $t('series-annual-data') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <DataForm
                        v-if="showForm('data')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="externalData.length"
                            :label="$t('external-data')"
                            :items="externalData"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('external-data')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="internalData.length"
                            :label="$t('internal-data')"
                            :items="internalData"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('internal-data')" text="-" :has-border-bottom="false" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="subevents" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('subevents')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('subevents')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'subevents', label: $t('series-subevents') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'subevents')"
                    @save="$emit('save', 'subevents')"
                >
                    <CardHeadline :headline-id="headlineId" :help-text="$t('series-subevents-help')">
                        {{ $t('series-subevents') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <SubEventsForm
                        v-if="showForm('subevents')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="subeventsLocal.length"
                            :label="$t('subevents-local')"
                            :items="subeventsLocal"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('subevents-local')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="subeventsOtherLocations.length"
                            :label="$t('subevents-other-locations')"
                            :items="subeventsOtherLocations"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            v-else
                            :label="$t('subevents-other-locations')"
                            text="-"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="fair" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('fair')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('fair')"
                    @enter-edit-mode="$emit('enterEditMode', { id: 'fair', label: $t('series-annual-fair-abroad') })"
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'fair')"
                    @save="$emit('save', 'fair')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('series-annual-fair-abroad') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <FairAbroadForm
                        v-if="showForm('fair')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="fairAbroad.length"
                            :label="$t('fair-abroad')"
                            :items="fairAbroad"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('fair-abroad')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="fairAbroadCooperation.length"
                            :label="$t('fair-abroad-cooperation')"
                            :items="fairAbroadCooperation"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            v-else
                            :label="$t('fair-abroad-cooperation')"
                            text="-"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="competition" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('competition')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('competition')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'competition', label: $t('series-annual-competition') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'competition')"
                    @save="$emit('save', 'competition')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('series-annual-competition') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <CompetitionForm
                        v-if="showForm('competition')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="competitionNational.length"
                            :label="$t('competition-national')"
                            :items="competitionNational"
                            :has-border-bottom="true"
                            :help-text="$t('competition-national-help')"
                        />
                        <CardRowText
                            v-else
                            :label="$t('competition-national')"
                            text="-"
                            :has-border-bottom="true"
                            :help-text="$t('competition-national-help')"
                        />
                        <CardRowAttachmentGroup
                            v-if="otherCompetitionNational.length"
                            :label="$t('other-competition-national')"
                            :items="otherCompetitionNational"
                            :has-border-bottom="true"
                            :help-text="$t('other-competition-national-help')"
                        />
                        <CardRowText
                            v-else
                            :label="$t('other-competition-national')"
                            text="-"
                            :has-border-bottom="true"
                            :help-text="$t('other-competition-national-help')"
                        />
                        <CardRowAttachmentGroup
                            v-if="competitionInternational.length"
                            :label="$t('competition-international')"
                            :items="competitionInternational"
                            :has-border-bottom="true"
                            :help-text="$t('competition-international-help')"
                        />
                        <CardRowText
                            v-else
                            :label="$t('competition-international')"
                            text="-"
                            :has-border-bottom="true"
                            :help-text="$t('competition-international-help')"
                        />
                        <CardRowAttachmentGroup
                            v-if="otherCompetitionInternational.length"
                            :label="$t('other-competition-international')"
                            :items="otherCompetitionInternational"
                            :has-border-bottom="true"
                            :help-text="$t('other-competition-international-help')"
                        />
                        <CardRowText
                            v-else
                            :label="$t('other-competition-international')"
                            text="-"
                            :help-text="$t('other-competition-international-help')"
                            :has-border-bottom="false"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="parallels" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('parallels')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('parallels')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'parallels', label: $t('series-annual-favored-parallels') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'parallels')"
                    @save="$emit('save', 'parallels')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('series-annual-favored-parallels') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ParallelsForm
                        v-if="showForm('parallels')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="favoredParallelsInternal.length"
                            :label="$t('favored-parallels-internal')"
                            :items="favoredParallelsInternal"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            v-else
                            :label="$t('favored-parallels-internal')"
                            text="-"
                            :has-border-bottom="true"
                        />
                        <CardRowAttachmentGroup
                            v-if="favoredParallelsExternal.length"
                            :label="$t('favored-parallels-external')"
                            :items="favoredParallelsExternal"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            v-else
                            :label="$t('favored-parallels-external')"
                            text="-"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="otherLinks" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('otherLinks')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('otherLinks')"
                    @enter-edit-mode="
                        $emit('enterEditMode', { id: 'otherLinks', label: $t('series-annual-other-links') })
                    "
                    @leave-edit-mode="$emit('leaveEditMode')"
                    @discard-changes="$emit('discardChanges', 'otherLinks')"
                    @save="$emit('save', 'otherLinks')"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('series-annual-other-links') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <OtherLinksForm
                        v-if="showForm('otherLinks')"
                        class="p-5"
                        :multilinks="multilinks"
                        @handle-multilink="$emit('handleMultilink', $event)"
                        @delete-link="$emit('deleteLink', $event)"
                    />
                    <div v-else>
                        <CardRowAttachmentGroup
                            v-if="otherLinksInternal.length"
                            :label="$t('other-links-internal')"
                            :items="otherLinksInternal"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('other-links-internal')" text="-" :has-border-bottom="true" />
                        <CardRowAttachmentGroup
                            v-if="otherLinksExternal.length"
                            :label="$t('other-links-external')"
                            :items="otherLinksExternal"
                            :has-border-bottom="true"
                        />
                        <CardRowText v-else :label="$t('other-links-external')" text="-" :has-border-bottom="true" />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowAttachmentGroup from '@/components/Card/CardRowAttachmentGroup.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import { toCardRowAttachmentLink } from '@/components/MultiLink/multilink';
import { FormLink } from '@/components/MultiLink/types';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import CompetitionForm from '@/event/forms/series/CompetitionForm.vue';
import DataForm from '@/event/forms/series/DataForm.vue';
import DocumentsForm from '@/event/forms/series/DocumentsForm.vue';
import FairAbroadForm from '@/event/forms/series/FairAbroadForm.vue';
import OtherLinksForm from '@/event/forms/series/OtherLinksForm.vue';
import ParallelsForm from '@/event/forms/series/ParallelsForm.vue';
import SubEventsForm from '@/event/forms/series/SubEventsForm.vue';
import SystemsForm from '@/event/forms/series/SystemsForm.vue';

import { EventSeriesDetails, EventSeriesMultiLinks } from '@/event/types';
import { computed } from 'vue';

const props = defineProps<{
    series: EventSeriesDetails;
    multilinks: EventSeriesMultiLinks;
    activeCard: ActiveCard;
}>();

defineEmits<{
    (e: 'enterEditMode', v: { id: string; label: string }): void;
    (e: 'leaveEditMode'): void;
    (e: 'discardChanges', v: string): void;
    (e: 'save', v: string): void;
    (e: 'handleMultilink', v: { input: { event: Event; link: FormLink; fieldName: string }; field: string }): void;
    (e: 'deleteLink', v: { link: FormLink; fieldName: string }): void;
}>();

const activeCard = computed(() => props.activeCard);

const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard);

const extraDocuments = computed(() => toCardRowAttachmentLink(props.series.extraDocuments?.links?.nodes));
const extraSystems = computed(() => toCardRowAttachmentLink(props.series.extraSystems?.links?.nodes));
const internalData = computed(() => toCardRowAttachmentLink(props.series.internalData?.links?.nodes));
const externalData = computed(() => toCardRowAttachmentLink(props.series.externalData?.links?.nodes));
const subeventsLocal = computed(() => toCardRowAttachmentLink(props.series.subeventsLocal?.links?.nodes));
const subeventsOtherLocations = computed(() =>
    toCardRowAttachmentLink(props.series.subeventsOtherLocations?.links?.nodes),
);
const fairAbroad = computed(() => toCardRowAttachmentLink(props.series.fairAbroad?.links?.nodes));
const fairAbroadCooperation = computed(() => toCardRowAttachmentLink(props.series.fairAbroadCooperation?.links?.nodes));
const competitionNational = computed(() => toCardRowAttachmentLink(props.series.competitionNational?.links?.nodes));
const otherCompetitionNational = computed(() =>
    toCardRowAttachmentLink(props.series.otherCompetitionNational?.links?.nodes),
);
const competitionInternational = computed(() =>
    toCardRowAttachmentLink(props.series.competitionInternational?.links?.nodes),
);
const otherCompetitionInternational = computed(() =>
    toCardRowAttachmentLink(props.series.otherCompetitionInternational?.links?.nodes),
);
const favoredParallelsInternal = computed(() =>
    toCardRowAttachmentLink(props.series.favoredParallelsInternal?.links?.nodes),
);
const favoredParallelsExternal = computed(() =>
    toCardRowAttachmentLink(props.series.favoredParallelsExternal?.links?.nodes),
);
const otherLinksInternal = computed(() => toCardRowAttachmentLink(props.series.otherLinksInternal?.links?.nodes));
const otherLinksExternal = computed(() => toCardRowAttachmentLink(props.series.otherLinksExternal?.links?.nodes));
</script>
