export type SpecializationType =
    | EventSpecializationTypeInside
    | EventSpecializationTypeOutside
    | ProjectSpecializationType;

export type EventSpecializationTypeInside =
    | 'Parking'
    | 'Catering'
    | 'Storage'
    | 'Mobile-Entrance'
    | 'Congress'
    | 'Logistics'
    | 'Cloakroom'
    | 'Service-Center'
    | 'Routing';
export type EventSpecializationTypeOutside = 'Service-Assembly-Point' | 'Open-Air-Ground' | 'Evacuation-Corridor';
export type ProjectSpecializationType = 'Building-Activity' | 'Maintenance' | 'Forbidden-Zone';

export type SpecializationIcon =
    | EventSpecializationIconInside
    | EventSpecializationIconOutside
    | ProjectSpecializationIcon;

export type EventSpecializationIconInside =
    | 'ClothesHanger'
    | 'DoorBack'
    | 'Group'
    | 'Info'
    | 'Package'
    | 'Parking'
    | 'Restaurant'
    | 'Truck'
    | 'Walking';
export type EventSpecializationIconOutside = 'CenterPoint' | 'DirectionsRun' | 'StoreFront';
export type ProjectSpecializationIcon = 'Engineering' | 'Construction' | 'Dangerous';

const specializationMappingsEventInside: Record<EventSpecializationTypeInside, EventSpecializationIconInside> = {
    Parking: 'Parking',
    Catering: 'Restaurant',
    Storage: 'Package',
    'Mobile-Entrance': 'DoorBack',
    Congress: 'Group',
    Logistics: 'Truck',
    Cloakroom: 'ClothesHanger',
    'Service-Center': 'Info',
    Routing: 'Walking',
};

const specializationMappingsEventOutside: Record<EventSpecializationTypeOutside, EventSpecializationIconOutside> = {
    'Service-Assembly-Point': 'CenterPoint',
    'Open-Air-Ground': 'StoreFront',
    'Evacuation-Corridor': 'DirectionsRun',
};

const specializationMappingsProject: Record<ProjectSpecializationType, ProjectSpecializationIcon> = {
    'Building-Activity': 'Engineering',
    Maintenance: 'Construction',
    'Forbidden-Zone': 'Dangerous',
};

export function mapStringToSpecializationType(specialization: string): SpecializationType | null {
    if (specialization === '') {
        return null;
    }

    if (Object.keys(specializationMappingsEventInside).includes(specialization)) {
        return specialization as EventSpecializationTypeInside;
    }

    if (Object.keys(specializationMappingsEventOutside).includes(specialization)) {
        return specialization as EventSpecializationTypeOutside;
    }

    if (Object.keys(specializationMappingsProject).includes(specialization)) {
        return specialization as ProjectSpecializationType;
    }

    throw new Error(`Unexpected specialization type: ${specialization}`);
}

export function mapSpecializationsTypesToIcons(
    specializationType: SpecializationType | null,
): SpecializationIcon | null {
    if (specializationType === null) {
        return null;
    }

    if (Object.keys(specializationMappingsEventInside).includes(specializationType)) {
        return specializationMappingsEventInside[specializationType as EventSpecializationTypeInside];
    }

    if (Object.keys(specializationMappingsEventOutside).includes(specializationType)) {
        return specializationMappingsEventOutside[specializationType as EventSpecializationTypeOutside];
    }

    if (Object.keys(specializationMappingsProject).includes(specializationType)) {
        return specializationMappingsProject[specializationType as ProjectSpecializationType];
    }

    throw new Error(`Unexpected specialization type: ${specializationType}`);
}
