<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M9.95 12.333c-.456 0-.839-.155-1.15-.466a1.563 1.563 0 0 1-.467-1.15c0-.456.156-.84.467-1.15A1.563 1.563 0 0 1 9.95 9.1c.456 0 .839.156 1.15.467.311.31.467.694.467 1.15 0 .455-.156.838-.467 1.15a1.563 1.563 0 0 1-1.15.466ZM3 14.667a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3.333c0-.266.1-.5.3-.7.2-.2.433-.3.7-.3h1.083v-1h1.084v1h5.666v-1h1.084v1H13c.267 0 .5.1.7.3.2.2.3.434.3.7v10.334c0 .266-.1.5-.3.7-.2.2-.433.3-.7.3H3Zm0-1h10V6.5H3v7.167ZM3 5.5h10V3.333H3V5.5Zm0 0V3.333 5.5Z"
        />
    </svg>
</template>

<script setup lang="ts">
type EventIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<EventIconProps>();
</script>
