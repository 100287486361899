import { DateTime, QuarterNumbers } from 'luxon';
import { computed, ComputedRef, ref } from 'vue';

type UseTimelineRangeReturn = {
    startDateTime: ComputedRef<DateTime>;
    endDateTime: ComputedRef<DateTime>;
    isTimeSpanModified: ComputedRef<boolean>;
    quarter: ComputedRef<QuarterNumbers>;
    year: ComputedRef<number>;
    onChangeQuarter: (newQuarter: QuarterNumbers) => void;
    onChangeYear: (newYear: number) => void;
    onClickPreviousQuarter: () => void;
    onClickNextQuarter: () => void;
    onExpandToPreviousMonth: () => void;
    onExpandToNextMonth: () => void;
};

export function useTimelineRange(): UseTimelineRangeReturn {
    const now = DateTime.now();

    // DateTimes selected in the TimelineIntervalChanger
    const selectedStartDateTime = ref(now.startOf('quarter').startOf('day'));

    // Timeline allows to expand the displayed time range by quarters
    const monthsBefore = ref(0); // Quarters before selected start DateTime
    const monthsAfter = ref(0); // Quarters after selected end DateTime

    function onChangeQuarter(newQuarter: QuarterNumbers) {
        const quarterDifference = newQuarter - quarter.value;
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ quarters: quarterDifference });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onChangeYear(newYear: number) {
        const yearDifference = newYear - year.value;
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ years: yearDifference });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onClickPreviousQuarter() {
        selectedStartDateTime.value = selectedStartDateTime.value.minus({ quarters: 1 });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onClickNextQuarter() {
        selectedStartDateTime.value = selectedStartDateTime.value.plus({ quarters: 1 });
        monthsBefore.value = 0;
        monthsAfter.value = 0;
    }

    function onExpandToPreviousMonth() {
        monthsBefore.value = monthsBefore.value + 1;
    }

    function onExpandToNextMonth() {
        monthsAfter.value = monthsAfter.value + 1;
    }

    // DateTimes the Timeline actually shows
    const displayedStartDateTime = computed(() => {
        return selectedStartDateTime.value.startOf('quarter').startOf('day').minus({ months: monthsBefore.value });
    });
    const displayedEndDateTime = computed(() => {
        return selectedStartDateTime.value.endOf('quarter').startOf('day').plus({ months: monthsAfter.value });
    });
    const isTimeSpanModified = computed(() => {
        return monthsBefore.value !== 0 || monthsAfter.value !== 0;
    });
    const quarter = computed(() => {
        return selectedStartDateTime.value.quarter;
    });
    const year = computed(() => {
        return selectedStartDateTime.value.year;
    });

    return {
        startDateTime: displayedStartDateTime,
        endDateTime: displayedEndDateTime,
        isTimeSpanModified: isTimeSpanModified,
        quarter,
        year,
        onChangeQuarter,
        onChangeYear,
        onClickPreviousQuarter,
        onClickNextQuarter,
        onExpandToPreviousMonth,
        onExpandToNextMonth,
    };
}
