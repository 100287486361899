<template>
    <div v-if="site">
        <TopBar :title="site.nameShort" transparent />
        <LocationUpdateForm :location="site" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetSiteForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ siteId: string }>();

const query = useQuery(GetSiteForEditDocument, { id: props.siteId });
const site = useResult(query.result, undefined, (x) => x.site);
</script>
