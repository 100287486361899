import { $t } from '@/plugins/fluent';
import { DateTime, MonthNumbers } from 'luxon';
import { Conflict } from '../Timeline.types';
import { TimelineHeaderCalendarWeek, TimelineHeaderDay, TimelineHeaderMonth } from '../TimelineView.types';
import { calculateConflictStatusForDay } from './conflicts';

export function calculateHeaderDays(
    conflicts: Conflict[],
    startDateTime: DateTime,
    durationInDays: number,
): TimelineHeaderDay[] {
    let daysArray: TimelineHeaderDay[] = [];

    for (let dayIndex = 0; dayIndex < durationInDays; dayIndex++) {
        const dateTime = startDateTime.plus({ days: dayIndex });

        const conflictStatus = calculateConflictStatusForDay(conflicts, dateTime);

        daysArray = [
            ...daysArray,
            {
                number: dateTime.day,
                abbreviation: $t('day-abbreviated', { dayNumber: dateTime.weekday }),
                fullName: $t('day-full-name', { dayNumber: dateTime.weekday }),
                isWorkDay: dateTime.weekday !== 6 && dateTime.weekday !== 7,
                conflictStatus,
                formattedDate: dateTime.toLocaleString({
                    ...DateTime.DATE_SHORT,
                    day: '2-digit',
                    month: '2-digit',
                }),
                columnId: `column-${dayIndex}`,
                isFirstColumn: dayIndex === 0,
            },
        ];
    }

    return daysArray;
}

export function calculateHeaderCalendarWeeks(
    startDateTime: DateTime,
    durationInDays: number,
): TimelineHeaderCalendarWeek[] {
    let calendarWeeksArray: TimelineHeaderCalendarWeek[] = [];
    let currentCalendarWeek = -1;

    for (let day = 0; day < durationInDays; day++) {
        const dateTime = startDateTime.plus({ days: day });

        if (currentCalendarWeek !== dateTime.weekNumber) {
            currentCalendarWeek = dateTime.weekNumber;

            calendarWeeksArray = [
                ...calendarWeeksArray,
                {
                    daysCount: 1,
                    label: $t('calendar-week', { calendarWeek: currentCalendarWeek }),
                    startColumnIndex: day + 1,
                },
            ];
        } else {
            calendarWeeksArray[calendarWeeksArray.length - 1].daysCount += 1;
        }
    }

    return calendarWeeksArray;
}

export function calculateHeaderMonths(startDateTime: DateTime, durationInDays: number): TimelineHeaderMonth[] {
    let monthArray: TimelineHeaderMonth[] = [];
    let currentMonth: MonthNumbers | -1 = -1;

    for (let day = 0; day < durationInDays; day++) {
        const dateTime = startDateTime.plus({ days: day });

        if (currentMonth !== dateTime.month) {
            currentMonth = dateTime.month;

            monthArray = [
                ...monthArray,
                {
                    daysCount: 1,
                    label: $t('month-with-year', { month: dateTime.month, yearString: String(dateTime.year) }),
                    startColumnIndex: day + 1,
                },
            ];
        } else {
            monthArray[monthArray.length - 1].daysCount += 1;
        }
    }

    return monthArray;
}
