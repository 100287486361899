<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M8.002 11.09c.86 0 1.589-.3 2.189-.901.6-.602.9-1.332.9-2.191 0-.86-.3-1.589-.902-2.189-.602-.6-1.332-.9-2.191-.9-.86 0-1.589.3-2.189.902-.6.602-.9 1.332-.9 2.191 0 .86.3 1.589.902 2.189.602.6 1.332.9 2.191.9Zm-.006-1.054a1.954 1.954 0 0 1-1.441-.595 1.975 1.975 0 0 1-.591-1.445c0-.567.198-1.048.595-1.441a1.974 1.974 0 0 1 1.445-.591 1.95 1.95 0 0 1 1.441.595c.394.397.591.878.591 1.445a1.95 1.95 0 0 1-.595 1.441 1.974 1.974 0 0 1-1.445.591ZM8 13.455c-1.77 0-3.37-.504-4.8-1.51A9.194 9.194 0 0 1 0 8a9.193 9.193 0 0 1 3.2-3.945c1.43-1.006 3.03-1.51 4.8-1.51s3.37.504 4.8 1.51A9.193 9.193 0 0 1 16 8a9.194 9.194 0 0 1-3.2 3.945c-1.43 1.006-3.03 1.51-4.8 1.51Zm-.003-1.091a7.316 7.316 0 0 0 4.049-1.191A7.727 7.727 0 0 0 14.855 8a7.735 7.735 0 0 0-2.806-3.173 7.302 7.302 0 0 0-4.046-1.19 7.317 7.317 0 0 0-4.048 1.19A7.858 7.858 0 0 0 1.127 8a7.865 7.865 0 0 0 2.824 3.173 7.301 7.301 0 0 0 4.046 1.19Z"
        />
    </svg>
</template>

<script setup lang="ts">
type VisibilityIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<VisibilityIconProps>();
</script>
