<template>
    <div class="flex flex-col">
        <Tooltip>
            <template #trigger>
                <button
                    class="rounded-t focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-500"
                    :class="[interactiveStyles]"
                    type="button"
                    @click="$emit('changeTab')"
                >
                    <PhaseMinusPlusIcon v-if="icon === 'Edit-Phases'" class="size-4" />
                    <MoveLeftRightIcon v-if="icon === 'Move-Date'" class="size-4" />
                    <ClockIcon v-if="icon === 'Change-Time'" class="size-4" />
                </button>
            </template>

            <template #text>
                <div v-if="icon === 'Edit-Phases'" class="w-max">{{ $t('edit-phases') }}</div>
                <div v-if="icon === 'Move-Date'" class="w-max">{{ $t('move-start-or-date') }}</div>
                <div v-if="icon === 'Change-Time'" class="w-max">{{ $t('change-start-or-end-time') }}</div>
            </template>
        </Tooltip>

        <div v-if="status === 'Active'" class="rounded-t bg-gray-50 w-full">
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import ClockIcon from '../../Icon/ClockIcon.vue';
import MoveLeftRightIcon from '../../Icon/MoveLeftRightIcon.vue';
import PhaseMinusPlusIcon from '../../Icon/PhaseMinusPlusIcon.vue';
import Tooltip from '../../Tooltip/Tooltip.vue';

type AllocationEditTabNavigationItemProps = {
    icon: 'Edit-Phases' | 'Move-Date' | 'Change-Time';
    status: 'Active' | 'Disabled' | 'Default';
};

const props = defineProps<AllocationEditTabNavigationItemProps>();

const interactiveStyles = computed(() => {
    switch (props.status) {
        case 'Active':
            return 'text-gray-600 bg-gray-50 p-1.5 mt-1.5 inverted-radius inverted-radius-active';

        case 'Disabled':
            return `
                text-gray-200 bg-gray-500 px-1.5 py-[0.3125rem] mt-2 inverted-radius
                hover:text-gray-600 hover:bg-gray-50 hover:p-1.5 hover:mt-1.5
                focus-visible:text-gray-600 focus-visible:bg-gray-50 focus-visible:p-1.5 focus-visible:mt-1.5
            `;

        case 'Default':
        default:
            return `
                text-gray-200 bg-gray-500 px-1.5 py-[0.3125rem] mt-2 inverted-radius
                hover:text-gray-600 hover:bg-gray-50 hover:p-1.5 hover:mt-1.5
                focus-visible:text-gray-600 focus-visible:bg-gray-50 focus-visible:p-1.5 focus-visible:mt-1.5
            `;
    }
});

defineEmits<{
    (e: 'changeTab'): void;
}>();
</script>

<style scoped>
/* Based on: https://codepen.io/t_afif/pen/zYJVLRQ */
.inverted-radius,
.inverted-radius {
    position: relative;
}

.inverted-radius::before,
.inverted-radius::after {
    position: absolute;
    bottom: 0;
    width: 0.25rem;
    height: 0.25rem;
    content: '';
}

.inverted-radius::before {
    left: -0.25rem;
}

.inverted-radius::after {
    right: -0.25rem;
}

.inverted-radius-active::before,
.inverted-radius:hover::before,
.inverted-radius:focus-visible::before {
    background: radial-gradient(102% 102% at top left, transparent 98%, theme('colors.gray.50'));
}

.inverted-radius-active::after,
.inverted-radius:hover::after,
.inverted-radius:focus-visible::after {
    background: radial-gradient(102% 102% at top right, transparent 98%, theme('colors.gray.50'));
}
</style>
