import { InjectionKey, Ref } from 'vue';

// This follows the recommendation for typing Provide/Inject: https://vuejs.org/guide/typescript/composition-api.html#typing-provide-inject
export const timelineOptionSwitchKey = Symbol() as InjectionKey<{
    activeIndex: Ref<number>;
    name: Ref<string>;
    onChange: (index: number) => void;
    onMoveFocusLeft: () => void;
    onMoveFocusRight: () => void;
}>;
