<template>
    <div class="grid items-center justify-items-start" :style="gridTemplateColumnsStyle">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type CardRowColumnsProps = {
    columnsCount: number;
};

const props = defineProps<CardRowColumnsProps>();

const gridTemplateColumnsStyle = computed(() => {
    return `grid-template-columns: repeat(${props.columnsCount}, 1fr);`;
});
</script>
