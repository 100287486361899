<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>

        <path
            fill="currentColor"
            d="M14.017 7.367v5.3c0 .366-.131.68-.392.941a1.284 1.284 0 0 1-.942.392H3.35c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.941v-5.3a2.295 2.295 0 0 1-.592-.9c-.139-.367-.142-.767-.008-1.2L2.117 3c.088-.289.247-.528.475-.717A1.2 1.2 0 0 1 3.383 2h9.267c.3 0 .561.092.783.275.223.183.384.425.484.725l.7 2.267c.133.433.13.827-.009 1.183a2.635 2.635 0 0 1-.591.917Zm-4.534-.7c.3 0 .528-.103.684-.309a.91.91 0 0 0 .183-.691l-.367-2.334h-1.3V5.8c0 .233.078.436.234.608a.736.736 0 0 0 .566.259Zm-3 0a.817.817 0 0 0 .625-.259.861.861 0 0 0 .242-.608V3.333h-1.3l-.367 2.334c-.044.266.014.5.175.7.161.2.37.3.625.3Zm-2.966 0c.2 0 .375-.073.525-.217a.932.932 0 0 0 .275-.55l.366-2.567h-1.3l-.666 2.234c-.067.222-.031.46.108.716.139.256.37.384.692.384Zm9 0c.322 0 .555-.128.7-.384.144-.255.177-.494.1-.716l-.7-2.234H11.35l.367 2.567a.931.931 0 0 0 .275.55c.15.144.325.217.525.217Zm-9.167 6h9.333v-4.7a.402.402 0 0 1-.108.033h-.058c-.3 0-.564-.05-.792-.15a2.25 2.25 0 0 1-.675-.483 2.2 2.2 0 0 1-.683.466A2.026 2.026 0 0 1 9.55 8a2.127 2.127 0 0 1-1.533-.633c-.19.2-.409.355-.659.466-.25.111-.52.167-.808.167-.322 0-.614-.056-.875-.167a2.172 2.172 0 0 1-.692-.466 2.191 2.191 0 0 1-.691.491A2.007 2.007 0 0 1 3.517 8h-.075a.169.169 0 0 1-.092-.033v4.7Z"
        />
    </svg>
</template>

<script setup lang="ts">
type StoreFrontIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<StoreFrontIconProps>();
</script>
