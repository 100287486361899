<template>
    <InlineFieldGroup class="items-start">
        <TextField
            class="w-1/3"
            :value="link.name"
            :label="$t('label')"
            :disabled="Boolean(disabledExplanation)"
            :error-messages="link.errors.name"
            @input="$emit('input', { event: $event, link, fieldName: 'name' })"
        />
        <TextField
            class="w-1/3"
            :value="link.to"
            :label="$t('target')"
            :disabled="Boolean(disabledExplanation)"
            :error-messages="link.errors.to"
            @input="$emit('input', { event: $event, link, fieldName: 'to' })"
        />
        <TextAreaField
            class="grow"
            :value="link.description ?? ''"
            :label="$t('explanation')"
            :disabled="Boolean(disabledExplanation)"
            :rows="3"
            :error-messages="link.errors.description"
            @input="$emit('input', { event: $event, link, fieldName: 'description' })"
        />
        <Button
            class="mt-6"
            variant="Danger-Light"
            size="Base"
            :disabled-explanation="getLinkDeleteDisabledExplanation(link)"
            :icon-description="$t('delete')"
            @click="$emit('delete', link)"
        >
            <template #icon>
                <DeleteForeverIcon class="w-5 h-5" />
            </template>
        </Button>
    </InlineFieldGroup>
</template>
<script setup lang="ts">
import { useFluent } from 'fluent-vue';
import Button from '../Button/Button.vue';
import InlineFieldGroup from '../Form/InlineFieldGroup.vue';
import TextAreaField from '../Form/TextAreaField.vue';
import TextField from '../Form/TextField.vue';
import DeleteForeverIcon from '../Icon/DeleteForeverIcon.vue';
import { FormLink } from './types';

export type LinkProps = {
    link: FormLink;
    disabledExplanation?: string;
};
const props = defineProps<LinkProps>();
const fluent = useFluent();

defineEmits<{
    (e: 'input', v: { event: Event; link: FormLink; fieldName: string }): void;
    (e: 'delete', v: FormLink): void;
}>();

const getLinkDeleteDisabledExplanation = (link: FormLink) => {
    if (props.disabledExplanation) return props.disabledExplanation;
    if (link.name.length === 0 && link.to.length === 0 && link.description?.length === 0 && link.status !== 'prop') {
        return fluent.$t('cant-delete-empty-lines');
    }
    return undefined;
};
</script>
