<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.333 14V4.55L6.267 5.6l-.934-.933L8 2l2.667 2.667-.934.933-1.066-1.05V6c0 .711.147 1.364.441 1.958a6.94 6.94 0 0 0 1.067 1.584c.417.46.853.858 1.308 1.191.456.334.85.595 1.184.784l-.967.966a12.446 12.446 0 0 1-1.717-1.258A8.982 8.982 0 0 1 8.667 9.8V14H7.333Z"
        />
    </svg>
</template>

<script setup lang="ts">
type RampIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<RampIconProps>();
</script>
