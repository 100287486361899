<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(title || props['aria-labelledby'])"
        :tabindex="props['aria-labelledby'] ? 0 : undefined"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>

        <path fill="currentColor" d="M11 12V4h1.333v8H11Zm-7.333 0V4l6 4-6 4ZM5 9.5 7.267 8 5 6.5v3Z" />
    </svg>
</template>

<script setup lang="ts">
type SkipIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<SkipIconProps>();
</script>
