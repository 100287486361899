<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.55 6.583h3.8v-1h-3.8v1Zm0 3.834h3.8v-1h-3.8v1ZM5.8 6.95a.835.835 0 0 0 .612-.255.835.835 0 0 0 .255-.612.835.835 0 0 0-.255-.612.835.835 0 0 0-.612-.254.835.835 0 0 0-.612.254.835.835 0 0 0-.255.612c0 .239.085.443.255.612.17.17.374.255.612.255Zm0 3.833a.835.835 0 0 0 .612-.254.835.835 0 0 0 .255-.612.835.835 0 0 0-.255-.612.835.835 0 0 0-.612-.255.835.835 0 0 0-.612.255.835.835 0 0 0-.255.612c0 .238.085.442.255.612.17.17.374.254.612.254ZM3 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h10c.267 0 .5.1.7.3.2.2.3.433.3.7v10c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H3Zm0-1h10V3H3v10Z"
        />
    </svg>
</template>

<script setup lang="ts">
type BallotIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<BallotIconProps>();
</script>
