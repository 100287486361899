import {
    NULL_BOOLEAN,
    OPTIONAL_STRING,
    OPTIONAL_TEXT,
    OPTIONAL_YEAR,
    REQUIRED_STRING,
    YEAR,
} from '@/components/Form/schema';
import { z } from 'zod';

export const CATEGORY_ITEMS = [
    { id: '1', name: 'Messen und Ausstellungen' },
    { id: '2', name: 'Tagungen und Kongresse' },
    { id: '3', name: 'Corporate Events' },
    { id: '4', name: 'Gesellschaftliche Veranstaltungen' },
    { id: '5', name: 'Sonderveranstaltungen' },
    { id: '6', name: 'CV Veranstaltungen' },
    { id: '7', name: 'Koelnmesse konzerninterne Veranstaltungen' },
];

export const TYPE_ITEMS = [
    { id: '1', name: 'Eigenveranstaltung' },
    { id: '2', name: 'Gastmesse' },
    { id: '3', name: 'Ausstellung' },
    { id: '4', name: 'Hausmesse (Closed Shop)' },
    { id: '5', name: 'Kongressmesse' },
    { id: '6', name: 'Märkte und Börsen' },
    { id: '7', name: 'Kongress' },
    { id: '8', name: 'Haupt-, Mitglieder-, Gesellschafterversammlung' },
    { id: '9', name: 'Parteitage und politische Zusammenkünfte' },
    { id: '10', name: 'Tagung, Symposium, Seminar, Konferenz' },
    { id: '11', name: 'Gremiensitzungen' },
    { id: '12', name: 'Prüfungen, Klausuren' },
    { id: '13', name: 'Pressekonferenz' },
    { id: '14', name: 'Showproduktion' },
    { id: '15', name: 'Produktpräsentation & Road Show' },
    { id: '16', name: 'Sportveranstaltungen' },
    { id: '17', name: 'Abiturfeier' },
    { id: '18', name: 'Karnevalsveranstaltung' },
    { id: '19', name: 'Award/Preisverleihung' },
    { id: '20', name: 'Comedyveranstaltung' },
    { id: '21', name: 'Weihnachtsfeier' },
    { id: '22', name: 'Hochzeit' },
    { id: '23', name: 'Bankettveranstaltung' },
    { id: '24', name: 'Geburtstag/Trauerfeier/Jubiläum' },
    { id: '25', name: 'Konzerte' },
    { id: '26', name: 'Film- und Fotoaufnahmen' },
    { id: '27', name: 'Parkplatzvermietung' },
    { id: '28', name: 'Briefwahlzentrum' },
    { id: '29', name: 'Zwischenlager' },
    { id: '30', name: 'Evakuierungen' },
    { id: '31', name: 'Impfzentrum' },
    { id: '32', name: 'Jurysitzung' },
    { id: '33', name: 'Verbandstreffen/Tagungen' },
    { id: '43', name: 'Km-Rahmenveranstaltung' },
    { id: '35', name: 'Kc Rahmenveranstaltung' },
    { id: '36', name: 'Fachbeiratssitzung' },
    { id: '37', name: '(Fach-)Pressekonferenzen' },
    { id: '38', name: 'Gremiensitzungen' },
    { id: '39', name: 'Pressekonferenzen' },
    { id: '40', name: 'Betriebsversammlung' },
    { id: '41', name: 'Belegschaftsversammlung' },
    { id: '42', name: 'Mitarbeiterveranstaltung' },
];

type Mapping = {
    [key: string]: string[];
};

export const CAT_TYPE_MAPPING: Mapping = {
    '1': ['1', '2', '3', '4', '5', '6'],
    '2': ['7', '8', '9', '10', '11', '12'],
    '3': ['13', '14', '15', '16'],
    '4': ['17', '18', '19', '20', '21', '22', '23', '24', '25'],
    '5': ['26', '27', '28', '29', '30', '31'],
    '6': ['32', '33', '34', '35', '36', '37'],
    '7': ['38', '39', '40', '41', '42'],
};

export const CYCLE_ITEMS = [
    { id: '1', name: '1/2 (halbjährlich)' },
    { id: '2', name: '1 (jährlich)' },
    { id: '3', name: '2G (zweijährlich gerade Jahre)' },
    { id: '4', name: '2U (zweijährlich ungerade Jahre)' },
    { id: '5', name: '3 (dreijährlich)' },
    { id: '6', name: '4G (vierjährlich gerade Jahre)' },
    { id: '7', name: '4U (vierjährlich ungerade Jahre)' },
    { id: '8', name: 'unregelmäßig (Wanderveranstaltung)' },
];

export const EventSeriesSchema = z.object({
    singleEvent: z.boolean(),
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
    subtitle: OPTIONAL_TEXT,
    status: REQUIRED_STRING,
    numberShort: OPTIONAL_STRING,
    foundationYear: OPTIONAL_YEAR, // z.number().int().max(2099).optional()
    notes: OPTIONAL_TEXT,
    description: OPTIONAL_TEXT,
    category: OPTIONAL_STRING, // z.enum(CATEGORY_CHOICES)
    type: OPTIONAL_STRING, // z.enum(TYPE_CHOICES)
    isTechnical: NULL_BOOLEAN,
    isPublic: NULL_BOOLEAN,
    partnersOverviewName: OPTIONAL_STRING,
    partnersOverviewLink: OPTIONAL_STRING,
    additionalPartners: OPTIONAL_STRING,
    companyGroup: OPTIONAL_STRING,
    businessDomain: OPTIONAL_STRING,
    department: OPTIONAL_STRING,
    organisationalChanges: OPTIONAL_STRING,
    notesDayOrder: OPTIONAL_STRING,
    notesSetup: OPTIONAL_STRING,
    notesEarlyConstructionInternal: OPTIONAL_STRING,
    notesEarlyConstructionExternal: OPTIONAL_STRING,
    notesConstruction: OPTIONAL_STRING,
    notesExecution: OPTIONAL_STRING,
    notesDismantling: OPTIONAL_STRING,
    notesExtendedDismantlingExternal: OPTIONAL_STRING,
    notesExtendedDismantlingInternal: OPTIONAL_STRING,
    notesTechnicalDismantling: OPTIONAL_STRING,
    notesDayOrderHistory: OPTIONAL_STRING,
    notesAppointmentRules: OPTIONAL_STRING,
    notesMonths: OPTIONAL_STRING,
    notesHolidaysNrw: OPTIONAL_STRING,
    notesHolidaysNational: OPTIONAL_STRING,
    notesHolidaysInternational: OPTIONAL_STRING,
    notesPublicHolidaysNrw: OPTIONAL_STRING,
    notesPublicHolidaysNational: OPTIONAL_STRING,
    notesPublicHolidaysInternational: OPTIONAL_STRING,
    otherNotesAppointmentRules: OPTIONAL_STRING,
    appointmentHistory: OPTIONAL_STRING,
    cycle: OPTIONAL_STRING, // z.enum(CYCLE_CHOICES)
    cycleChanges: OPTIONAL_STRING,
    allocationRules: OPTIONAL_STRING,
    locationHistory: OPTIONAL_STRING,
    notesHallExhibitionAreas: OPTIONAL_STRING,
    notesBoulevardSections: OPTIONAL_STRING,
    notesPassages: OPTIONAL_STRING,
    notesEntrances: OPTIONAL_STRING,
    notesServiceCenters: OPTIONAL_STRING,
    notesConferenceRooms: OPTIONAL_STRING,
    notesOfficesInternal: OPTIONAL_STRING,
    notesStorageInternal: OPTIONAL_STRING,
    notesOfficesExternal: OPTIONAL_STRING,
    notesStorageExternal: OPTIONAL_STRING,
    notesAreas: OPTIONAL_STRING,
    notesParking: OPTIONAL_STRING,
    otherAllocationNeeds: OPTIONAL_STRING,
    iconUpload: OPTIONAL_STRING,
});

export const EventSeriesCreateSchema = EventSeriesSchema.pick({
    singleEvent: true,
    nameShort: true,
    nameLong: true,
    subtitle: true,
});

export const EventSeriesIdentifiersSchema = EventSeriesSchema.pick({
    nameShort: true,
    nameLong: true,
    subtitle: true,
    numberShort: true,
    foundationYear: true,
    notes: true,
    description: true,
    iconUpload: true,
});

export const EventSeriesTypesSchema = EventSeriesSchema.pick({
    category: true,
    type: true,
    isTechnical: true,
    isPublic: true,
});

export const EventSeriesPartnersSchema = EventSeriesSchema.pick({
    partnersOverviewName: true,
    partnersOverviewLink: true,
    additionalPartners: true,
});

export const EventSeriesOrganisation = EventSeriesSchema.pick({
    companyGroup: true,
    businessDomain: true,
    department: true,
    organisationalChanges: true,
});

export const EventSeriesDayOrder = EventSeriesSchema.pick({
    notesDayOrder: true,
    notesSetup: true,
    notesEarlyConstructionInternal: true,
    notesEarlyConstructionExternal: true,
    notesConstruction: true,
    notesExecution: true,
    notesDismantling: true,
    notesExtendedDismantlingExternal: true,
    notesExtendedDismantlingInternal: true,
    notesTechnicalDismantling: true,
    notesDayOrderHistory: true,
});

export const EventSeriesNotesAppointmentRules = EventSeriesSchema.pick({
    notesAppointmentRules: true,
    notesMonths: true,
    notesHolidaysNrw: true,
    notesHolidaysNational: true,
    notesHolidaysInternational: true,
    notesPublicHolidaysNrw: true,
    notesPublicHolidaysNational: true,
    notesPublicHolidaysInternational: true,
    otherNotesAppointmentRules: true,
    appointmentHistory: true,
});

export const EventSeriesCycle = EventSeriesSchema.pick({
    cycle: true,
    cycleChanges: true,
});

export const EventSeriesAllocationRules = EventSeriesSchema.pick({
    allocationRules: true,
    locationHistory: true,
});

export const EventSeriesAllocationNeeds = EventSeriesSchema.pick({
    notesHallExhibitionAreas: true,
    notesBoulevardSections: true,
    notesPassages: true,
    notesEntrances: true,
    notesServiceCenters: true,
    notesConferenceRooms: true,
    notesOfficesInternal: true,
    notesStorageInternal: true,
    notesOfficesExternal: true,
    notesStorageExternal: true,
    notesAreas: true,
    notesParking: true,
    otherAllocationNeeds: true,
});

export const EventSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: OPTIONAL_STRING,
    subtitle: OPTIONAL_STRING,
    executionYear: YEAR,
    description: OPTIONAL_STRING,
    annualSpecifics: OPTIONAL_STRING,
    companyGroup: OPTIONAL_STRING,
    businessDomain: OPTIONAL_STRING,
    department: OPTIONAL_STRING,
    responsible: OPTIONAL_STRING,
    assistant: OPTIONAL_STRING,
    teamChanges: OPTIONAL_STRING,
    status: REQUIRED_STRING,
    iconUpload: OPTIONAL_STRING,
});

export const EventCreateSchema = z.object({
    nameShort: REQUIRED_STRING,
    nameLong: REQUIRED_STRING,
    executionYear: YEAR,
    status: REQUIRED_STRING,
});

export const EventBaseDataSchema = EventSchema.pick({
    nameShort: true,
    nameLong: true,
    subtitle: true,
    status: true,
    executionYear: true,
    description: true,
    annualSpecifics: true,
    iconUpload: true,
});

export const EventOrganisationSchema = EventSchema.pick({
    companyGroup: true,
    businessDomain: true,
    department: true,
});

export const EventTeamStructureSchema = EventSchema.pick({
    responsible: true,
    assistant: true,
    teamChanges: true,
});
