<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="m10.71 9.48-.8-.8c.314-.862.15-1.577-.492-2.146-.642-.57-1.34-.716-2.09-.437l-.8-.8a2.18 2.18 0 0 1 .69-.29c.255-.061.515-.092.782-.092.86 0 1.59.3 2.19.9.6.6.9 1.33.9 2.191 0 .267-.032.53-.1.791-.066.26-.16.488-.28.682Zm2.345 2.345-.728-.728a8.53 8.53 0 0 0 1.555-1.463c.442-.54.767-1.082.973-1.628-.607-1.345-1.516-2.409-2.728-3.19a7.14 7.14 0 0 0-3.945-1.173c-.51 0-1.03.048-1.564.145-.533.097-.951.212-1.254.346l-.837-.855c.425-.194.967-.364 1.628-.509a8.99 8.99 0 0 1 1.936-.218c1.733 0 3.318.494 4.755 1.482 1.436.988 2.487 2.312 3.154 3.972a8.982 8.982 0 0 1-1.218 2.128 8.778 8.778 0 0 1-1.727 1.69Zm1.054 4.109-3.054-3c-.425.17-.903.3-1.437.39A9.614 9.614 0 0 1 8 13.462c-1.77 0-3.376-.494-4.818-1.482C1.739 10.991.679 9.667 0 8.006a8.25 8.25 0 0 1 1.01-1.845c.43-.6.954-1.173 1.572-1.718L.29 2.152l.764-.782 13.763 13.764-.709.8ZM3.327 5.206a6.691 6.691 0 0 0-1.3 1.291c-.418.534-.718 1.037-.9 1.51a7.282 7.282 0 0 0 2.791 3.19c1.243.782 2.652 1.173 4.227 1.173.4 0 .794-.024 1.182-.073.388-.048.68-.12.873-.218l-1.164-1.164a1.9 1.9 0 0 1-.49.137c-.194.03-.376.045-.546.045a3.006 3.006 0 0 1-2.182-.89c-.606-.595-.909-1.328-.909-2.2 0-.182.015-.364.046-.546a2.18 2.18 0 0 1 .136-.491L3.327 5.206Z"
        />
    </svg>
</template>

<script setup lang="ts">
type VisibilityOffIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<VisibilityOffIconProps>();
</script>
