<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4.9 11.967 1.167 8.233l.716-.716L4.9 10.533l.717.717-.717.717Zm2.833 0L4 8.233l.717-.716 3.016 3.016 6.4-6.4.717.717-7.117 7.117Zm0-2.834-.716-.716L11.3 4.133l.717.717-4.284 4.283Z"
        />
    </svg>
</template>

<script setup lang="ts">
type AllDoneIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<AllDoneIconProps>();
</script>
