import { iconForType, routeForLocation } from './location';

type Site = {
    __typename?: 'Site';
    id: string;
    nameShort: string;
};

type Building = {
    __typename?: 'Building';
    id: string;
    nameShort: string;
    site: Site;
};

type Level = {
    __typename?: 'Level';
    id: string;
    nameShort: string;
    building: Building;
};

type Room = {
    __typename?: 'Room';
    id: string;
    nameShort: string;
    level: Level;
};

type Zone = {
    __typename?: 'Zone';
    id: string;
    nameShort: string;
    site: Site;
    level?: Level;
};

type Area = {
    __typename?: 'Area';
    id: string;
    nameShort: string;
    zone: Zone;
};

type Plot = {
    __typename?: 'Plot';
    id: string;
    nameShort: string;
    area: Area;
};

type RoomPartition = {
    __typename?: 'RoomPartition';
    id: string;
    nameShort: string;
    room: Room;
};

type Location = Site | Building | Level | Room | Zone | Area | Plot | RoomPartition;

export function locationBreadcrumbs(location: Location) {
    const type = location.__typename;

    const plot = type === 'Plot' ? location : undefined;
    const area = plot?.area ?? (type === 'Area' ? location : undefined);
    const zone = area?.zone ?? (type === 'Zone' ? location : undefined);

    const roomPartition = type === 'RoomPartition' ? location : undefined;
    const room = roomPartition?.room ?? (type === 'Room' ? location : undefined);
    const level = zone?.level ?? room?.level ?? (type === 'Level' ? location : undefined);
    const building = level?.building ?? (type === 'Building' ? location : undefined);
    const site = building?.site ?? zone?.site ?? (type === 'Site' ? location : undefined);

    const res = [];
    if (site) {
        res.push({
            title: site.nameShort,
            to: routeForLocation('Site', site.id),
            icon: iconForType('Site'),
        });
    }
    if (building) {
        res.push({
            title: building.nameShort,
            to: routeForLocation('Building', building.id),
            icon: iconForType('Building'),
        });
    }

    if (level) {
        res.push({
            title: level.nameShort,
            to: routeForLocation('Level', level.id),
            icon: iconForType('Level'),
        });
    }

    if (room) {
        res.push({
            title: room.nameShort,
            to: routeForLocation('Room', room.id),
            icon: iconForType('Room'),
        });
    }

    if (zone) {
        res.push({
            title: zone.nameShort,
            to: routeForLocation('Zone', zone.id),
            icon: iconForType('Zone'),
        });
    }

    if (area) {
        res.push({
            title: area.nameShort,
            to: routeForLocation('Area', area.id),
            icon: iconForType('Area'),
        });
    }

    if (plot) {
        res.push({
            title: plot.nameShort,
            to: routeForLocation('Plot', plot.id),
            icon: iconForType('Plot'),
        });
    }

    if (roomPartition) {
        res.push({
            title: roomPartition.nameShort,
            to: routeForLocation('RoomPartition', roomPartition.id),
            icon: iconForType('RoomPartition'),
        });
    }

    return res;
}
