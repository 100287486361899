<template>
    <div>
        <div class="p-4">
            <BaseForm>
                <template #fields>
                    <div v-for="group in locationForm" class="border-gray-900/10 pb-8 w-[580px]">
                        <h2 class="text-base font-semibold leading-7 text-gray-900">
                            <span>{{ group.title }}</span>
                        </h2>
                        <div class="mt-5 space-y-5">
                            <template v-for="field in group.fields" :key="field.dbName">
                                <div class="grid gap-4">
                                    <StringField
                                        v-if="field.type === 'string'"
                                        :field="field"
                                        @valueChanged="updateFormField"
                                    />
                                    <SelectField
                                        v-if="field.type === 'select'"
                                        :field="field"
                                        :items="field.items"
                                        @valueChanged="updateFormField"
                                    />
                                    <NumberField
                                        v-if="field.type === 'number' || field.type === 'float'"
                                        :field="field"
                                        :unit="'unit' in field ? field.unit : undefined"
                                        :float="field.type === 'float'"
                                        @valueChanged="updateFormField"
                                    />
                                    <CheckboxField
                                        v-if="field.type === 'checkbox'"
                                        :field="field"
                                        @valueChanged="updateFormField"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </template>
            </BaseForm>
        </div>
        <div class="bg-white sticky bottom-0 p-3 shadow-[0px_-3px_3px_-3px_#ccc] flex justify-end gap-3">
            <BaseButton variant="outlined" @click="$router.back">{{ $t('cancel') }}</BaseButton>
            <BaseButton @click="onSave">{{ $t('save') }}</BaseButton>
        </div>
    </div>
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import { useApolloClient } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import gql from 'graphql-tag';
import { computed, reactive } from 'vue';
import {
    QueryKeyValue,
    getContactBookingChoices,
    getFormField,
    getKeyIssuanceChoices,
    lowerCaseFirstLetter,
    useCreateLocation,
} from '../location';
import { formForLocationType } from '../locationAttributes';
import { LocationType } from '../locationTypes';
import CheckboxField from './CheckboxField.vue';
import NumberField from './NumberField.vue';
import SelectField from './SelectField.vue';
import StringField from './StringField.vue';
import { FormField, FormFieldGroup, LocationSubType } from './types';

const props = defineProps<{ type: LocationType; locationTypes: readonly LocationSubType[]; parent?: QueryKeyValue }>();
const fluent = useFluent();

const typename = computed(() => props.type);

const locationForm = reactive(
    formForLocationType(props.type, undefined, {
        types: props.locationTypes?.slice().sort((a, b) => a.name.localeCompare(b.name)),
        contactBookingChoices: getContactBookingChoices(),
        keyIssuanceChoices: getKeyIssuanceChoices(),
    }),
);

const updateFormField = (id: string, value: any, valueLabel?: string) => {
    const field = getFormField(locationForm, id);
    if (field) {
        field.errorMessages = undefined;
        const res = field.schema?.safeParse(value);
        if (!res?.success) {
            field.errorMessages = res?.error.format()._errors;
            field.value = value;
        } else {
            field.value = res.data;
        }
        field.valueLabel = valueLabel;
    }
};

const { save } = useCreateLocation(locationForm, props.type, props.parent);

const client = useApolloClient().client;

const onSave = async () => {
    locationForm.forEach((g) => {
        g.fields.forEach((f) => {
            const res = f.schema?.safeParse(f.value);
            if (!res?.success) {
                f.errorMessages = res?.error.format()._errors;
            }
        });
    });

    const formErrors = locationForm.some((g: FormFieldGroup) =>
        g.fields.some((f: FormField) => f.errorMessages && f.errorMessages.length),
    );

    if (formErrors) return;

    // If the nameShort Field exists and was changed check if it already exists and set a field error
    const nameShortField = getFormField(locationForm, 'nameShort');
    if (nameShortField) {
        let newNameShort = nameShortField.value;
        if (newNameShort != '') {
            const query = `query CheckNameShort($nameShort: String!) { ${lowerCaseFirstLetter(
                typename.value,
            )}ByNameShort(nameShort: $nameShort) { id nameShort }}`;
            const nameShortQuery = await client.query({
                query: gql(query),
                variables: { nameShort: nameShortField.value },
            });
            if (nameShortQuery.data[`${lowerCaseFirstLetter(typename.value)}ByNameShort`] != null) {
                nameShortField.errorMessages = [fluent.$t('nameshort-already-taken')];
                return;
            }
        }
    }

    await save();
};
</script>
