<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="m4.7 14.333-2.7-2.7 2.7-2.7.7.7-1.5 1.5h4.767v1H3.9l1.5 1.5-.7.7Zm6.6-6.266-.7-.7 1.5-1.5H7.333v-1H12.1l-1.5-1.5.7-.7 2.7 2.7-2.7 2.7Z"
        />
    </svg>
</template>

<script setup lang="ts">
type MoveLeftRightIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<MoveLeftRightIconProps>();
</script>
