<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M1.333 12V4h13.334v8h-1v-1.667H10.5V12h-1V5H2.333v7h-1ZM10.5 6.667h3.167V5H10.5v1.667Zm0 2.666h3.167V7.667H10.5v1.666Z"
        />
    </svg>
</template>

<script setup lang="ts">
type OfficeIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<OfficeIconProps>();
</script>
