<template>
    <TopBar v-if="partition" :title="partition.nameShort" :breadcrumbs="locationBreadcrumbs(partition)">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabledExplanation="disabledExplanation"
                    @click="deleteRoomPartition"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
            </div>
        </template>
    </TopBar>
    <GQLLoading
        :query="getRoomPartitionQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'roompartition', id: partitionId })"
    >
        <LocationDetails
            v-if="partition"
            :location="partition"
            :location-types="partitionTypes"
            :to-edit="{ name: 'room-partition-edit', params: { partitionId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useRoomPartition } from '../room_partition';

const props = defineProps<{ partitionId: string }>();
const fluent = useFluent();

const { getRoomPartitionQuery, partition, partitionTypes, deleteRoomPartition } = useRoomPartition(
    computed(() => props.partitionId),
);

const isAdmin = auth.isAdmin();

const disabledExplanation = computed(() => {
    if (isAdmin) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
