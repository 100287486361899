<template>
    <div class="h-full w-full fixed bg">
        <div class="h-1/6" />
        <DigikapLogo class="logo text-white" />
        <div class="mt-4 sm:mx-auto sm:w-full sm:max-w-md w-full">
            <div class="bg-white py-3 px-4 drop-shadow-lg sm:rounded-lg sm:px-10">
                <LoginForm
                    @login="login"
                    @reset-password="resetPassword"
                    :error="errorMsg"
                    :loading="gqlLogin.loading.value"
                >
                    <template #error>
                        <ErrorCard :mutation="gqlLogin" :error="errorMsg" :format-mutation-error="formatError" />
                    </template>
                </LoginForm>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import auth, { LoginCredentials } from '@/auth/auth';
import { LoginDocument, LoginResponse } from '@/generated/graphql';
import DigikapLogo from '@/utils/components/DigikapLogo.vue';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import LoginForm from '../components/LoginForm.vue';

const props = defineProps<{ redirect?: string; initialError?: string }>();
const didShowInitialError = ref(false);
const router = useRouter();
const fluent = useFluent();

const gqlLogin = useMutation(LoginDocument);
const errorMsg = computed(() => (didShowInitialError.value ? undefined : props.initialError));

async function login(credentials: LoginCredentials): Promise<void> {
    didShowInitialError.value = true;

    if (auth.isLoggedIn()) {
        auth.logout();
    }

    const res = await gqlLogin.mutate(credentials);
    if (res?.data?.login?.__typename === 'Session') {
        auth.storeSession(res.data.login);
        router.push(props.redirect ?? '/');
    }
}

function resetPassword(): void {
    router.push('/resetpassword');
}

function formatError(res: LoginResponse): string | undefined {
    switch (res.__typename) {
        case 'InvalidCredentials':
            return fluent.$t('login-error-invalid-credentials');
        default:
            return undefined;
    }
}
</script>

<style scoped>
.bg {
    background: linear-gradient(theme('colors.primary.500') 40%, white 40%);
}
.logo {
    text-align: center;
    display: block;
}
.form {
    margin: 20px auto;
    width: 500px;
    padding: 24px;
}
</style>

<fluent locale="de">
login-error-invalid-credentials=Email oder Passwort ungültig
</fluent>
