<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 13.833 2 9.167l.833-.617L8 12.567l5.167-4.017.833.617-6 4.666ZM8 11.3 2 6.633l6-4.666 6 4.666L8 11.3Z"
        />
    </svg>
</template>

<script setup lang="ts">
type LayersIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<LayersIconProps>();
</script>
