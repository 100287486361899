<template>
    <div v-if="zone">
        <TopBar :title="zone.nameShort" :breadcrumbs="locationBreadcrumbs(zone)" transparent />
        <LocationUpdateForm :location="zone" :location-types="zoneTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetZoneForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ zoneId: string }>();
const query = useQuery(GetZoneForEditDocument, () => ({ id: props.zoneId }));
const zone = useResult(query.result, undefined, (x) => x.zone);
const zoneTypes = useResult(query.result, [], (x) => x.zoneTypes.nodes);
</script>
