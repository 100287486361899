<template>
    <div v-if="building">
        <TopBar :title="$t('level-add')" :breadcrumbs="locationBreadcrumbs(building)" transparent />
        <LocationCreateForm type="Level" :location-types="levelTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetCreateLevelParamsDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ buildingId: string }>();

// FIXME the props seem weird because this should be a level form, but we use the building result?????

const query = useQuery(GetCreateLevelParamsDocument, { buildingId: props.buildingId });
const building = useResult(query.result, undefined, (x) => x.building);
const levelTypes = useResult(query.result, [], (x) => x.levelTypes.nodes);
const parent = { key: 'buildingId', value: props.buildingId };
</script>
