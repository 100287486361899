<template>
    <div v-if="project" class="space-y-4">
        <FlexibleCard headline-id="appointment" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('appointment')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('appointment')"
                    @enter-edit-mode="cardFunctions?.updateCard('appointment', $t('appointment'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectAppointmentSchema)"
                    @save="dataFunctions?.save(ProjectAppointmentSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('appointment') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectAppointmentForm v-if="showForm('appointment')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('project-notes-dayorder')"
                            :text="valueToString(project.notesDayOrder)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('project-notes-appointment')"
                            :text="valueToString(project.notesAppointment)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>

        <FlexibleCard headline-id="location" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderEditable
                    :status="getCardStatus('location')"
                    :edit-button-disabled-explanation="editButtonDisabledMessage('location')"
                    @enter-edit-mode="cardFunctions?.updateCard('location', $t('location'), 'Edit-Untouched')"
                    @leave-edit-mode="cardFunctions?.resetCard()"
                    @discard-changes="cardFunctions?.discardChanges(ProjectLocationSchema)"
                    @save="dataFunctions?.save(ProjectLocationSchema)"
                >
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('location') }}
                    </CardHeadline>
                </CardHeaderEditable>
            </template>

            <template #body>
                <ContentFormTransition>
                    <ProjectLocationForm v-if="showForm('location')" class="p-5" />
                    <div v-else>
                        <CardRowText
                            :label="$t('location-requirements')"
                            :text="valueToString(project.locationRequirements)"
                            :has-border-bottom="true"
                        />
                        <CardRowText
                            :label="$t('notes-setup')"
                            :text="valueToString(project.allocationHints)"
                            :has-border-bottom="true"
                        />
                    </div>
                </ContentFormTransition>
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { emptyActiveCard } from '@/components/Card/editableCard';
import ContentFormTransition from '@/event/components/ContentFormTransition.vue';
import { useCard } from '@/event/composables/cards';
import { valueToString } from '@/event/event';
import { $t } from '@/plugins/fluent';
import ProjectAppointmentForm from '@/project/forms/project/ProjectAppointmentForm.vue';
import ProjectLocationForm from '@/project/forms/project/ProjectLocationForm.vue';
import { activeCardData, activeCardFunctions, formFunctions, projectData } from '@/project/keys';
import { useHeadSafe } from '@unhead/vue';
import { inject, ref } from 'vue';
import { ProjectAppointmentSchema, ProjectLocationSchema } from '../../schemas';

const data = inject(projectData);
const project = data?.project;

useHeadSafe({
    title: () =>
        `${$t('appointment-and-location')} - ${project?.value.nameShort} - ${project?.value.series.nameShort} - ${$t(
            'project-wiki',
        )}`,
});

const dataFunctions = inject(formFunctions);
const cardFunctions = inject(activeCardFunctions);
const activeCard = inject(activeCardData);
const { getCardStatus, showForm, editButtonDisabledMessage } = useCard(activeCard ?? ref(emptyActiveCard()));
</script>
