import { reactive } from 'vue';
import { ActiveCard } from './types';

export function emptyActiveCard() {
    return reactive<ActiveCard>({
        id: '',
        label: '',
        status: 'View',
    });
}
