<template>
    <BaseForm v-if="project && errors">
        <template #fields>
            <TextField
                :value="project.category"
                :label="$t('project-category')"
                :errorMessages="errors.category"
                @input="onInput($event, 'category')"
            />
            <TextField
                :value="project.type"
                :label="$t('project-type')"
                :errorMessages="errors.type"
                @input="onInput($event, 'type')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import { formFunctions, projectData } from '@/project/keys';
import { ProjectTypeDefSchema, ProjectTypeDefSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectData);
const project = data?.project;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectTypeDefSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectTypeDefSchemaType;
    const schema = ProjectTypeDefSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
