import { GetUploadUrlDocument, GetUploadUrlMutation } from '@/generated/graphql';
import { MutateFunction, useMutation } from '@vue/apollo-composable';
import { Ref, computed, reactive } from 'vue';

async function pickFile(options: { accept: string; event: Event }): Promise<File | undefined> {
    const input = options.event.target as HTMLInputElement;
    let res = new Promise<File | undefined>((resolve) => {
        input.onchange = () => {
            if (input.files) {
                resolve(input.files[0]);
            }
            resolve(undefined);
        };
        input.onabort = () => {
            resolve(undefined);
        };
    });
    return res;
}

export async function uploadFile(
    file: File,
    mutation: MutateFunction<GetUploadUrlMutation, any>,
    abortController: AbortController,
): Promise<string | undefined> {
    const res = await mutation();
    if (!res?.data) return;

    let { url, upload_id } = res.data.createUploadUrl;
    await fetch(url, { method: 'PUT', body: file, signal: abortController.signal });

    return upload_id;
}

interface State {
    current: {
        loading: boolean;
        file: File | undefined;
        abortController: AbortController | undefined;
    };
}

function newCurrentState() {
    return {
        loading: false,
        file: undefined,
        abortController: undefined,
    };
}

export function useFileUpload(accept: string = ''): {
    loading: Ref<boolean>;
    file: Ref<File | undefined>;
    fileUrl: Ref<string | undefined>;
    pickAndUpload: (event: Event) => Promise<string | undefined>;
    abort: () => void;
} {
    const state = reactive<State>({
        current: newCurrentState(),
    });
    const loading = computed(() => state.current.loading);
    const file = computed(() => state.current.file);
    const fileUrl = computed(() => (file.value ? URL.createObjectURL(file.value) : undefined));
    const mutation = useMutation(GetUploadUrlDocument);

    return {
        loading,
        file,
        fileUrl,
        pickAndUpload: async (event: Event) => {
            const currentState = state.current;
            currentState.abortController = new AbortController();
            try {
                const pickedFile = await pickFile({ accept, event });
                if (!pickedFile) return;

                currentState.loading = true;
                currentState.file = pickedFile;
                var res = await uploadFile(pickedFile, mutation.mutate, currentState.abortController);
                if (!res) {
                    currentState.file = undefined;
                }

                return res;
            } catch (e: any) {
                if (e.name !== 'AbortError') {
                    throw e;
                }
            } finally {
                currentState.loading = false;
            }
        },
        abort: () => {
            state.current.abortController?.abort();
            state.current = newCurrentState();
        },
    };
}
