import { GetAllocationUsageIdByNameDocument, IntervalInput } from '@/generated/graphql';
import apolloClient from '@/plugins/apollo';
import { DateTime, Duration } from 'luxon';
import {
    Allocation,
    AllocationAggregationForm,
    AllocationPart,
    AllocationPhase,
    AllocationPhaseType,
    EnrichedAllocation,
    PhaseType,
    PhasesPerDay,
} from '../Timeline.types';
import { TimelineSection } from '../TimelineView.types';
import { convertToDatabaseDateTime } from './durations';

export function enrichAllocations(allocations: Allocation[]): EnrichedAllocation[] {
    return allocations.map((allocation) => {
        const phasesPerDay = getPhasesPerDay(allocation.phases);

        return {
            ...allocation,
            phasesPerDay,
        };
    });
}

export function calculateAggregatedAllocationForm(
    isPreviousDayAggregation: boolean,
    allocations: AllocationPart[],
    isNextDayAggregation: boolean,
): AllocationAggregationForm {
    if (allocations.length > 0) {
        if (!isPreviousDayAggregation && !isNextDayAggregation) {
            return 'allocation-single';
        } else if (!isPreviousDayAggregation && isNextDayAggregation) {
            return 'allocation-start';
        } else if (isPreviousDayAggregation && !isNextDayAggregation) {
            return 'allocation-end';
        } else if (isPreviousDayAggregation && isNextDayAggregation) {
            return 'allocation-middle';
        }
    }

    return 'no-allocation';
}

export function calculateAllocationsForDay(allocations: EnrichedAllocation[], dateTime: DateTime): AllocationPart[] {
    const allocationsPerDay: AllocationPart[] = allocations
        .map((allocation: EnrichedAllocation): AllocationPart | null => {
            const diffStart = Math.ceil(dateTime.diff(allocation.startDateTime, 'days').days);
            const diffEnd = Math.ceil(dateTime.diff(allocation.endDateTime, 'days').days);

            if (diffStart < 0 || !allocation.phasesPerDay[diffStart]) {
                return null;
            }

            const phaseType = allocation.phasesPerDay[diffStart].type;
            const specialization = allocation.phasesPerDay[diffStart].specialization;
            const isPhaseEnd = allocation.phasesPerDay[diffStart].isPhaseEnd;

            if (diffStart === 0 && diffEnd === 0) {
                return {
                    id: allocation.id,
                    phaseType,
                    allocationType: allocation.allocationType,
                    specialization,
                    label: allocation.label,
                    isLabelVisible: false,
                    isPhaseEnd,
                    form: 'Single' as const,
                    isVisible: true,
                    startDateTime: allocation.startDateTime,
                    endDateTime: allocation.endDateTime,
                    phases: allocation.phases,
                    isEditable: allocation.isEditable,
                    linkTarget: allocation.linkTarget,
                    allocationVariantId: allocation.allocationVariantId,
                    variant: allocation.variant,
                    allocationVariantLabel: allocation.allocationVariantLabel,
                };
            } else if (diffStart === 0 && diffEnd < 0) {
                return {
                    id: allocation.id,
                    phaseType,
                    allocationType: allocation.allocationType,
                    specialization,
                    label: allocation.label,
                    isLabelVisible: false,
                    isPhaseEnd,
                    form: 'Start' as const,
                    isVisible: true,
                    startDateTime: allocation.startDateTime,
                    endDateTime: allocation.endDateTime,
                    phases: allocation.phases,
                    isEditable: allocation.isEditable,
                    linkTarget: allocation.linkTarget,
                    allocationVariantId: allocation.allocationVariantId,
                    variant: allocation.variant,
                    allocationVariantLabel: allocation.allocationVariantLabel,
                };
            } else if (diffStart > 0 && diffEnd < 0) {
                return {
                    id: allocation.id,
                    phaseType,
                    allocationType: allocation.allocationType,
                    specialization,
                    label: allocation.label,
                    isLabelVisible: false,
                    isPhaseEnd,
                    form: 'Middle' as const,
                    isVisible: true,
                    startDateTime: allocation.startDateTime,
                    endDateTime: allocation.endDateTime,
                    phases: allocation.phases,
                    isEditable: allocation.isEditable,
                    linkTarget: allocation.linkTarget,
                    allocationVariantId: allocation.allocationVariantId,
                    variant: allocation.variant,
                    allocationVariantLabel: allocation.allocationVariantLabel,
                };
            } else if (diffEnd === 0) {
                return {
                    id: allocation.id,
                    phaseType,
                    allocationType: allocation.allocationType,
                    specialization,
                    label: allocation.label,
                    isLabelVisible: false,
                    isPhaseEnd,
                    form: 'End' as const,
                    isVisible: true,
                    startDateTime: allocation.startDateTime,
                    endDateTime: allocation.endDateTime,
                    phases: allocation.phases,
                    isEditable: allocation.isEditable,
                    linkTarget: allocation.linkTarget,
                    allocationVariantId: allocation.allocationVariantId,
                    variant: allocation.variant,
                    allocationVariantLabel: allocation.allocationVariantLabel,
                };
            }

            return null;
        })
        .filter((allocation): allocation is AllocationPart => allocation !== null);

    return allocationsPerDay;
}

export function mapToPhaseTypes(usageName: string): AllocationPhaseType {
    const executionPhases = [
        'Veranstaltung',
        'Parken',
        'Gastro',
        'Lager',
        'Mobiler Eingang',
        'Kongress',
        'Logistik',
        'Service-/Sammelplatz',
        'Freigelände',
        'Baumaßnahme',
        'Wartung/Instandhaltung',
        'Sperrfläche',
        'Optional',
    ];

    if (executionPhases.includes(usageName)) {
        return 'Durchführung';
    }

    // TODO These should not be repeated here, instead we should derive the types from these values
    const allocationPhases = [
        'Rüstzeit',
        'Vorgezogener interner Aufbau',
        'Vorgezogener externer Aufbau',
        'Regulärer Aufbau',
        'Durchführung',
        'Regulärer Abbau',
        'Verlängerter externer Abbau',
        'Verlängerter interner Abbau',
        'Technischer Abbau',
    ];

    if (allocationPhases.includes(usageName)) {
        return usageName as AllocationPhaseType;
    }

    throw new Error(`Unknown usage name/phase type: ${usageName}`);
}

export async function mapTypeToUsageId(phaseType: PhaseType): Promise<string> {
    const result = await apolloClient.query({
        query: GetAllocationUsageIdByNameDocument,
        variables: { name: phaseType },
    });
    if (result.data.allocationUsageByName?.id) return result.data.allocationUsageByName?.id;
    throw new Error(`Unknown name/phase type: ${phaseType}`);
}

function getPhasesPerDay(phases: AllocationPhase[]): PhasesPerDay[] {
    return phases.reduce<PhasesPerDay[]>((phases, phase) => {
        if (phase.cellSpan <= 0) {
            throw new Error(`Unexpected cell span: ${phase.cellSpan}`);
        }

        if (!Number.isInteger(phase.cellSpan)) {
            throw new Error(`Expected cell span to be an integer: ${phase.cellSpan}`);
        }

        return [
            ...phases,
            ...Array(phase.cellSpan - 1).fill({
                type: phase.type,
                isPhaseEnd: false,
                specialization: phase.specialization,
            }),
            { type: phase.type, isPhaseEnd: true, specialization: phase.specialization },
        ];
    }, []);
}

export function validateAllocationDates(allocations: Allocation[]): void {
    allocations.forEach((allocation) => {
        const phasesPerDay = getPhasesPerDay(allocation.phases);

        if (phasesPerDay.length === 0) {
            throw new Error(`Allocation ${allocation.id} has no phases`);
        }

        const calculatedEndDateTime = allocation.startDateTime.plus({ days: phasesPerDay.length - 1 });

        if (!calculatedEndDateTime.hasSame(allocation.endDateTime, 'day')) {
            const startIso = allocation.startDateTime.toISO();
            const daysCount = phasesPerDay.length - 1;
            const calculatedEndIso = calculatedEndDateTime.toISO();
            const databaseEndIso = convertToDatabaseDateTime(allocation.endDateTime).toISO();

            throw new Error(
                `Expected start date: '${startIso}' + '${daysCount}' days to be end date: '${calculatedEndIso}', got: ${databaseEndIso} (allocation ID: ${allocation.id}, location ID: ${allocation.locationId})`,
            );
        }
    });
}

export function validateAllocationsAreUnique(allocations: Allocation[]): void {
    const countedAllocationIds = allocations
        .map((allocation) => ({
            id: allocation.id,
            count: 1,
        }))
        .reduce<Record<string, number>>((result, allocation) => {
            result[allocation.id] = (result[allocation.id] ?? 0) + allocation.count;

            return result;
        }, {});

    const duplicates = Object.keys(countedAllocationIds).filter((allocation) => countedAllocationIds[allocation] > 1);

    if (duplicates.length > 0) {
        throw new Error(`Found allocation ID duplicates: ${duplicates.join(', ')}`);
    }
}

export function countAllocations(sections: TimelineSection[]): number {
    let maxValue = 0;

    sections.forEach((section) => {
        section.rows.forEach((row) => {
            row.days.forEach((day) => {
                if (day.allocations.length > maxValue) {
                    maxValue = day.allocations.length;
                }
            });
        });
    });

    return maxValue;
}

export function getDurationInDays(interval: IntervalInput) {
    const durationInDays = Duration.fromObject({
        seconds: interval.seconds ?? undefined,
        minutes: interval.minutes ?? undefined,
        hours: interval.hours ?? undefined,
        days: interval.days ?? undefined,
        months: interval.months ?? undefined,
        years: interval.years ?? undefined,
    }).as('days');

    return durationInDays;
}
