<template>
    <button
        class="flex flex-row items-center gap-1.5 px-2 rounded h-[2.375rem] text-gray-700 hover:bg-gray-600 hover:text-gray-50 group focus:outline-none active:bg-gray-900 active:text-gray-50"
        :class="{ 'bg-gray-50 shadow': isActive }"
        type="button"
        :tabindex="isActive ? 0 : -1"
        :aria-checked="isActive"
        role="radio"
        @click="onChange(props.index)"
        @keydown="handleKeyDown"
    >
        <component :is="props.icon" class="group-hover:text-gray-50 group-active:text-gray-50 text-gray-500" />
        <span class="font-semibold leading-tight">{{ props.label }}</span>
    </button>
</template>

<script setup lang="ts">
import type { Component } from 'vue';
import { computed, inject } from 'vue';
import { timelineOptionSwitchKey } from './injectionKeys';

type TimelineOptionSwitchEntryProps = {
    index: number;
    label: string;
    icon: Component;
};

const props = defineProps<TimelineOptionSwitchEntryProps>();

const injectedValues = inject(timelineOptionSwitchKey);
if (!injectedValues) {
    throw new Error('TimelineOptionSwitchEntry must be used within a TimelineOptionSwitch component');
}

const { activeIndex, onChange, onMoveFocusLeft, onMoveFocusRight } = injectedValues;
const isActive = computed(() => activeIndex.value === props.index);

function handleKeyDown(event: KeyboardEvent) {
    switch (event.key) {
        case 'ArrowLeft':
            onMoveFocusLeft();
            event.preventDefault();
            break;

        case 'ArrowRight':
            onMoveFocusRight();
            event.preventDefault();
            break;
    }
}
</script>
