<template>
    <BaseForm v-if="project && errors">
        <template #fields>
            <TextField
                :value="project.companyGroup"
                :label="$t('company-group')"
                :errorMessages="errors.companyGroup"
                @input="onInput($event, 'companyGroup')"
            />
            <TextField
                :value="project.businessDomain"
                :label="$t('business-domain')"
                :errorMessages="errors.businessDomain"
                @input="onInput($event, 'businessDomain')"
            />
            <TextField
                :value="project.department"
                :label="$t('department')"
                :errorMessages="errors.department"
                @input="onInput($event, 'department')"
            />
            <TextAreaField
                :value="project.organisationalChanges"
                :label="$t('organisational-changes')"
                :errorMessages="errors.organisationalChanges"
                @input="onInput($event, 'organisationalChanges')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { formFunctions, projectData } from '@/project/keys';
import { ProjectOrgSchema, ProjectOrgSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectData);
const project = data?.project;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectOrgSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectOrgSchemaType;
    const schema = ProjectOrgSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
