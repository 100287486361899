<template>
    <TopBar :title="$t('create-object', { type: 'calendar' })" />
    <div class="p-5">
        <CalendarCreateForm></CalendarCreateForm>
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import CalendarCreateForm from '../forms/CalendarCreateForm.vue';

useHeadSafe({ title: () => `${$t('create-object', { type: 'calendar' })} - ${$t('external-events')}` });
</script>
