import { PhaseType } from '../Timeline.types';

// The Tailwind compiler requires these string literals to be available as a whole

export function getLightBackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'bg-green-50';

        case 'Aggregation':
            return 'bg-gray-50';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'bg-amber-50';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'bg-orange-50';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'bg-orange-50';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'bg-sky-50';

        case 'Durchführung':
            return 'bg-sky-50';

        case 'Empty':
            return 'bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'bg-rose-50';

        case 'Projekt':
            return 'bg-rose-50';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getLight100BackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'bg-green-100';

        case 'Aggregation':
            return 'bg-gray-100';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'bg-amber-100';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'bg-orange-100';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'bg-orange-100';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'bg-sky-100';

        case 'Durchführung':
            return 'bg-sky-100';

        case 'Empty':
            return 'bg-gray-100';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'bg-rose-100';

        case 'Projekt':
            return 'bg-rose-100';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getHoverClasses700_50ForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'hover:bg-green-700 hover:text-green-50';

        case 'Aggregation':
            return 'hover:bg-gray-700 hover:text-gray-50';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'hover:bg-amber-700 hover:text-amber-50';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'hover:bg-orange-700 hover:text-orange-50';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'hover:bg-orange-700 hover:text-orange-50';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'hover:bg-sky-700 hover:text-sky-50';

        case 'Durchführung':
            return 'hover:bg-sky-700 hover:text-sky-50';

        case 'Empty':
            return 'hover:bg-gray-700 hover:text-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'hover:bg-rose-800 hover:text-rose-50';

        case 'Projekt':
            return 'hover:bg-rose-800 hover:text-rose-50';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getBorder700ColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'border-green-700';

        case 'Aggregation':
            return 'border-gray-700';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'border-amber-700';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'border-orange-700';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'border-orange-700';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'border-sky-700';

        case 'Durchführung':
            return 'border-sky-700';

        case 'Empty':
            return 'border-gray-700';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'border-rose-800';

        case 'Projekt':
            return 'border-rose-800';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getStrongBackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'bg-green-300';

        case 'Aggregation':
            return 'bg-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'bg-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'bg-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'bg-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'bg-sky-300';

        case 'Durchführung':
            return 'bg-sky-800';

        case 'Empty':
            return 'bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'bg-rose-300';

        case 'Projekt':
            return 'bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getFillColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'fill-green-300';

        case 'Aggregation':
            return 'fill-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'fill-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'fill-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'fill-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'fill-sky-300';

        case 'Durchführung':
            return 'fill-sky-800';

        case 'Empty':
            return 'fill-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'fill-rose-300';

        case 'Projekt':
            return 'fill-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

// The Tailwind compiler requires these string literals to be available as a whole
export function getStrongBackgroundColorAfterClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'after:bg-green-300';

        case 'Aggregation':
            return 'after:bg-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'after:bg-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'after:bg-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'after:bg-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'after:bg-sky-300';

        case 'Durchführung':
            return 'after:bg-sky-800';

        case 'Empty':
            return 'after:bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'after:bg-rose-300';

        case 'Projekt':
            return 'after:bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getStrongBackgroundColorBeforeClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'before:bg-green-300';

        case 'Aggregation':
            return 'before:bg-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'before:bg-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'before:bg-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'before:bg-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'before:bg-sky-300';

        case 'Durchführung':
            return 'before:bg-sky-800';

        case 'Empty':
            return 'before:bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'before:bg-rose-300';

        case 'Projekt':
            return 'before:bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getSemiTransparentStrongBackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'bg-green-300/50';

        case 'Aggregation':
            return 'bg-gray-600/50';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'bg-amber-300/50';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'bg-orange-200/50';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'bg-orange-300/50';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'bg-sky-300/50';

        case 'Durchführung':
            return 'bg-sky-800/50';

        case 'Empty':
            return 'bg-gray-50/50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'bg-rose-300';

        case 'Projekt':
            return 'bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getSemiTransparentStrongBackgroundColorAfterClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'after:bg-green-300/50';

        case 'Aggregation':
            return 'after:bg-gray-600/50';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'after:bg-amber-300/50';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'after:bg-orange-200/50';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'after:bg-orange-300/50';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'after:bg-sky-300/50';

        case 'Durchführung':
            return 'after:bg-sky-800/50';

        case 'Empty':
            return 'after:bg-gray-50/50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'after:bg-rose-300';

        case 'Projekt':
            return 'after:bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getHoverStrongBackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'hover:bg-green-300';

        case 'Aggregation':
            return 'hover:bg-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'hover:bg-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'hover:bg-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'hover:bg-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'hover:bg-sky-300';

        case 'Durchführung':
            return 'hover:bg-sky-800';

        case 'Empty':
            return 'hover:bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'hover:bg-rose-300';

        case 'Projekt':
            return 'hover:bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getHoverStrongTextColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
        case 'Aggregation':
        case 'Rüstzeit':
        case 'Technischer Abbau':
        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
        case 'Durchführung':
        case 'Empty':
            return '';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'hover:text-gray-700';

        case 'Projekt':
            return 'hover:text-rose-50';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getActiveBackgroundColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'active:bg-green-500';

        case 'Aggregation':
            return 'active:bg-gray-800';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'active:bg-amber-500';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'active:bg-orange-400';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'active:bg-orange-500';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'active:bg-sky-500';

        case 'Durchführung':
            return 'active:bg-sky-950';

        case 'Empty':
            return 'active:bg-gray-50';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'active:bg-rose-500';

        case 'Projekt':
            return 'active:bg-rose-700';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getBorderColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'border-green-300';

        case 'Aggregation':
            return 'border-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'border-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'border-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'border-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'border-sky-300';

        case 'Durchführung':
            return 'border-sky-800';

        case 'Empty':
            return 'border-gray-300';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'border-rose-300';

        case 'Projekt':
            return 'border-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getActiveBorderColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'active:border-green-900';

        case 'Aggregation':
            return 'active:border-gray-900';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'active:border-amber-900';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'active:border-orange-900';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'active:border-orange-900';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'active:border-sky-900';

        case 'Durchführung':
            return 'active:border-sky-900';

        case 'Empty':
            return 'active:border-gray-900';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'active:border-rose-900';

        case 'Projekt':
            return 'active:border-rose-900';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getBorderRightColorClassForType(isPhaseEnd: boolean, type: PhaseType): string {
    if (isPhaseEnd) {
        return '';
    }

    switch (type) {
        case 'ExternalEvent':
            return 'after:bg-green-300';

        case 'Aggregation':
            return 'after:bg-gray-600';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'after:bg-amber-300';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'after:bg-orange-200';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'after:bg-orange-300';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'after:bg-sky-300';

        case 'Durchführung':
            return 'after:bg-sky-800';

        case 'Empty':
            return 'after:bg-gray-300';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'after:bg-rose-300';

        case 'Projekt':
            return 'after:bg-rose-500';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getTextColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
        case 'Rüstzeit':
        case 'Technischer Abbau':
        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'text-gray-900';

        case 'Durchführung':
        case 'Projekt':
        case 'Aggregation':
        case 'Empty':
            return 'text-gray-50';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}

export function getFocusWithinRingColorClassForType(type: PhaseType): string {
    switch (type) {
        case 'ExternalEvent':
            return 'has-[:focus-visible]:ring-green-700';

        case 'Aggregation':
            return 'has-[:focus-visible]:ring-gray-700';

        case 'Rüstzeit':
        case 'Technischer Abbau':
            return 'has-[:focus-visible]:ring-amber-700';

        case 'Vorgezogener interner Aufbau':
        case 'Verlängerter interner Abbau':
            return 'has-[:focus-visible]:ring-orange-700';

        case 'Vorgezogener externer Aufbau':
        case 'Verlängerter externer Abbau':
            return 'has-[:focus-visible]:ring-orange-700';

        case 'Regulärer Aufbau':
        case 'Regulärer Abbau':
            return 'has-[:focus-visible]:ring-sky-700';

        case 'Durchführung':
            return 'has-[:focus-visible]:ring-sky-700';

        case 'Empty':
            return 'has-[:focus-visible]:ring-gray-700';

        case 'Aufbau Projekt':
        case 'Abbau Projekt':
            return 'has-[:focus-visible]:ring-rose-800';

        case 'Projekt':
            return 'has-[:focus-visible]:ring-rose-800';

        default:
            throw new Error(`Unknown phase type: ${type}`);
    }
}
