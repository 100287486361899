import { DateTime } from 'luxon';
import { Conflict, ConflictMarker, ConflictStatus, EnrichedAllocation } from '../Timeline.types';

export function calculateConflicts(
    allocations: EnrichedAllocation[],
    startDateTime: DateTime,
    durationInDays: number,
): Conflict[] {
    let allocationConflicts: Conflict[] = [];

    for (let day = 0; day < durationInDays; day++) {
        const dateTime = startDateTime.plus({ days: day });

        const allocationsOnThisDay = allocations.filter((allocation) => {
            const diffStart = dateTime.diff(allocation.startDateTime, 'days').days;
            const diffEnd = dateTime.diff(allocation.endDateTime, 'days').days;

            return diffStart >= 0 && diffEnd <= 0;
        });

        const allocationsGroupedByLocation = allocationsOnThisDay.reduce<Record<string, EnrichedAllocation[]>>(
            (groupedAllocations, allocation) => {
                if (allocation.locationId in groupedAllocations) {
                    groupedAllocations[allocation.locationId] = [
                        ...groupedAllocations[allocation.locationId],
                        allocation,
                    ];
                } else {
                    groupedAllocations[allocation.locationId] = [allocation];
                }

                return groupedAllocations;
            },
            {},
        );

        const conflicts = Object.entries(allocationsGroupedByLocation)
            .map<Conflict | null>(([locationId, allocations]) => {
                if (allocations.length > 1) {
                    return {
                        type: 'allocation-conflict' as const,
                        date: dateTime.toJSDate(),
                        status: 'unresolved' as const,
                        locationId: locationId,
                    };
                }

                return null;
            })
            .filter((conflict): conflict is Conflict => Boolean(conflict));

        allocationConflicts = [...allocationConflicts, ...conflicts];
    }

    return allocationConflicts;
}

export function calculateConflictStatusForDay(conflictsInLocation: Conflict[], dateTime: DateTime): ConflictStatus {
    const conflictsOnThisDay = conflictsInLocation.filter(
        (conflict) => DateTime.fromJSDate(conflict.date).diff(dateTime, 'days').days === 0,
    );
    let conflictStatus: ConflictStatus = 'no-conflict' as const;

    if (conflictsOnThisDay.length > 0) {
        const containsUnresolvedConflicts = conflictsOnThisDay.some((conflict) => conflict.status === 'unresolved');

        conflictStatus = containsUnresolvedConflicts
            ? ('unresolved-conflict' as const)
            : ('resolved-conflict' as const);
    }

    return conflictStatus;
}

let conflictMarkerPreviousDay: ConflictMarker = 'no-conflict' as const;

export function calculateConflictMarkerForDay(
    conflictsInLocation: Conflict[],
    dateTime: DateTime,
    conflictStatus: ConflictStatus,
): ConflictMarker {
    let conflictMarker: ConflictMarker = 'no-conflict' as const;
    let conflictStatusNextDay: ConflictStatus = 'no-conflict' as const;

    const conflictsInLocationNextDay = conflictsInLocation.filter(
        (conflict) => DateTime.fromJSDate(conflict.date).diff(dateTime, 'days').days === 1,
    );

    if (conflictsInLocationNextDay.length > 0) {
        const containsUnresolvedConflictsNextDay = conflictsInLocationNextDay.some(
            (conflict) => conflict.status === 'unresolved',
        );

        conflictStatusNextDay = containsUnresolvedConflictsNextDay
            ? ('unresolved-conflict' as const)
            : ('resolved-conflict' as const);
    }

    if (conflictMarkerPreviousDay === 'no-conflict') {
        if (conflictStatus === 'unresolved-conflict') {
            // Start of new conflict --> Check when it ends
            if (conflictStatusNextDay === 'unresolved-conflict') {
                // Conflict continues
                conflictMarker = 'conflict-start';
                conflictMarkerPreviousDay = 'conflict-start';
            } else {
                // Single day conflict
                conflictMarker = 'conflict-single';
                conflictMarkerPreviousDay = 'conflict-single';
            }
        } else {
            // No conflict
            conflictMarker = 'no-conflict';
            conflictMarkerPreviousDay = 'no-conflict';
        }
    } else if (conflictMarkerPreviousDay === 'conflict-start' || conflictMarkerPreviousDay === 'conflict-middle') {
        // Within a conflict --> Check when it ends
        if (conflictStatusNextDay === 'unresolved-conflict') {
            // Conflict continues
            conflictMarker = 'conflict-middle';
            conflictMarkerPreviousDay = 'conflict-middle';
        } else {
            // Conflict ends
            conflictMarker = 'conflict-end';
            conflictMarkerPreviousDay = 'conflict-end';
        }
    } else if (conflictMarkerPreviousDay === 'conflict-end' || conflictMarkerPreviousDay === 'conflict-single') {
        // If the algorithm isn't wrong, this necessarily means that there is no conflict on this day
        conflictMarker = 'no-conflict';
        conflictMarkerPreviousDay = 'no-conflict';
    }

    return conflictMarker;
}
