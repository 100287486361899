<template>
    <div>
        <div :class="sizeClass">
            <slot></slot>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type Size = 'sm' | 'md' | 'lg';
const props = withDefaults(defineProps<{ size?: Size }>(), { size: 'lg' });

const sizeClass = computed(() => {
    switch (props.size) {
        case 'sm':
            return 'max-w-3xl';
        case 'md':
            return 'max-w-5xl';
        case 'lg':
            return 'max-w-7xl';
    }
});
</script>
