import { useElementBounding } from '@vueuse/core';
import { ref, Ref, watchEffect } from 'vue';

export function useGlobalTooltipPosition(elementRef: Ref<null>) {
    const {
        top: topBoundingBox,
        left: leftBoundingBox,
        width: widthBoundingBox,
        height: heightBoundingBox,
        update,
    } = useElementBounding(elementRef);

    const top = ref(0);
    const left = ref(0);

    watchEffect(() => {
        top.value = topBoundingBox.value - heightBoundingBox.value / 2;
        left.value = leftBoundingBox.value + widthBoundingBox.value / 2;
    });

    return {
        top,
        left,
        forceUpdate: update,
    };
}
