<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 12.333V11h1.333v1.333H2ZM2 10V8.667h1.333V10H2Zm0-2.333V6.333h1.333v1.334H2Zm2.333 7v-1.334h1.334v1.334H4.333ZM6 12c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.941v-8c0-.367.13-.68.391-.942.261-.261.575-.392.942-.392h6c.367 0 .68.13.942.392.26.261.391.575.391.942v8c0 .366-.13.68-.391.941A1.284 1.284 0 0 1 12 12H6Zm0-1.333h6v-8H6v8Zm.667 4v-1.334H8v1.334H6.667Zm-3.334 0c-.366 0-.68-.13-.941-.392A1.284 1.284 0 0 1 2 13.333h1.333v1.334Zm5.667 0v-1.334h1.333c0 .367-.13.68-.391.942a1.284 1.284 0 0 1-.942.392ZM2 5.333c0-.366.13-.68.392-.941.26-.261.575-.392.941-.392v1.333H2Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CopyAllIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<CopyAllIconProps>();
</script>
