<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 14v-1h1.533V2h6.5v.75h2.45V13H14v1h-2.517V3.75h-1.45V14H2Zm5.517-5.333a.645.645 0 0 0 .475-.192A.645.645 0 0 0 8.183 8a.645.645 0 0 0-.191-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192A.645.645 0 0 0 6.85 8c0 .189.064.347.192.475a.645.645 0 0 0 .475.192Z"
        />
    </svg>
</template>

<script setup lang="ts">
type RoomIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<RoomIconProps>();
</script>
