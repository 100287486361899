<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M5.833 14.667v-.7l1.334-1V8.783L1.333 10.5v-.967L7.167 6.1V2.167c0-.234.08-.43.241-.592A.805.805 0 0 1 8 1.333c.233 0 .43.08.592.242a.805.805 0 0 1 .241.592V6.1l5.834 3.433v.967L8.833 8.783v4.184l1.334 1v.7L8 14.05l-2.167.617Z"
        />
    </svg>
</template>

<script setup lang="ts">
type AirplaneIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<AirplaneIconProps>();
</script>
