<template>
    <img class="h-10 w-10 rounded-full" v-if="user.icon" :src="getImageUrl(user.icon.original)" />
    <div class="h-10 w-10 rounded-full text-white font-semibold text-center leading-10" :style="classes" v-else>
        {{ initials }}
    </div>
</template>

<script setup lang="ts">
import hashString from '@/utils/hash';
import { getImageUrl } from '@/utils/s3';
import { computed } from 'vue';
import { User } from '../user';

interface Props {
    user: User;
    size?: number;
}

const props = withDefaults(defineProps<Props>(), {
    size: 38,
});

const initials = computed(() => `${props.user.firstName.charAt(0)}${props.user.lastName.charAt(0)}`);
const classes = computed(() => {
    const name = `${props.user.firstName} ${props.user.lastName}`;
    const color = colors[hashString(name) % colors.length];
    return { 'background-color': color };
});

// 500 variants of all tailwind colors https://tailwindcss.com/docs/customizing-colors
const colors = [
    '#64748b',
    '#6b7280',
    '#71717a',
    '#737373',
    '#78716c',
    '#ef4444',
    '#f97316',
    '#f59e0b',
    '#eab308',
    '#84cc16',
    '#22c55e',
    '#10b981',
    '#14b8a6',
    '#06b6d4',
    '#0ea5e9',
    '#3b82f6',
    '#6366f1',
    '#8b5cf6',
    '#a855f7',
    '#d946ef',
    '#ec4899',
    '#f43f5e',
];
</script>
