<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M6.017 11.017 8 9l2 2.017.783-.8L8.8 8.2l1.983-2.017-.783-.8L8 7.4 6.017 5.383l-.8.8 2 2.017-2 2.017.8.8ZM4.35 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3.5h-.683v-1H5.8V2h4.4v.5h3.133v1h-.683V13c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3h-7.3Zm7.3-10.5h-7.3V13h7.3V3.5Z"
        />
    </svg>
</template>

<script setup lang="ts">
type DeleteForeverIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<DeleteForeverIconProps>();
</script>
