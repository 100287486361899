<template>
    <Tooltip :is-disabled="!props.iconDescription">
        <template #trigger="tooltipProps">
            <RouterLink
                :id="linkId"
                ref="linkRef"
                :to="props.to"
                :target="props.openInNewTab ? '_blank' : undefined"
                :aria-labelledby="
                    tooltipProps['aria-labelledby'] ? `${tooltipProps['aria-labelledby']} ${linkId}` : undefined
                "
                class="rounded flex flex-row items-center focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 w-max gap-1"
                :class="[variantClasses, focusClasses, sizeClasses, { group: slots.icon }]"
                @click="handleClick"
            >
                <div v-if="slots.icon" class="flex items-center" :class="[iconVariantClasses]">
                    <slot name="icon" />
                </div>
                <slot />
            </RouterLink>
        </template>

        <template #text>
            <div class="w-max">{{ props.iconDescription }}</div>
        </template>
    </Tooltip>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed, ref, useSlots } from 'vue';
import { RouteLocationRaw, RouterLink } from 'vue-router';
import Tooltip from '../Tooltip/Tooltip.vue';
import { getFocusClasses, getIconVariantClasses, getSizeClasses, getVariantClasses } from './Button.styles';
import { ButtonSize, ButtonVariant } from './Button.types';

type LinkButtonProps = {
    size: ButtonSize;
    variant: ButtonVariant;
    iconDescription?: string;
    hasDarkBackground?: boolean;
    openInNewTab?: boolean;
    to: RouteLocationRaw;
};

type LinkButtonEmits = {
    (e: 'click', ev: MouseEvent): void;
};

const props = defineProps<LinkButtonProps>();
const emit = defineEmits<LinkButtonEmits>();
const slots = useSlots();
const linkId = nanoid();

const variantClasses = computed(() => getVariantClasses(undefined, props.variant));
const focusClasses = computed(() => getFocusClasses(undefined, props.variant, props.hasDarkBackground));
const sizeClasses = computed(() => getSizeClasses(props.size, Boolean(slots.icon), Boolean(slots.default)));
const iconVariantClasses = computed(() => getIconVariantClasses(undefined, props.variant));

function handleClick(event: MouseEvent) {
    emit('click', event);
}

const linkRef = ref();
function focus() {
    linkRef.value.focus();
}

defineExpose({
    focus,
});
</script>
