import { _requireAuth } from '@/plugins/router';
import { RouteRecordRaw } from 'vue-router';
import SpacesPage from './pages/SpacesPage.vue';

export const reportRoutes = <RouteRecordRaw[]>[
    {
        path: '/report/spaces',
        beforeEnter: _requireAuth,
        name: 'spaces',
        component: SpacesPage,
    },
];
