<template>
    <TopBar v-if="room" :title="room.nameShort" :breadcrumbs="locationBreadcrumbs(room)" @delete="deleteRoom">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabledExplanation="disabledExplanation"
                    @click="deleteRoom"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
                <DropDownSelector :options="dropDownOptions" :disabled="!isAdmin" />
            </div>
        </template>
    </TopBar>
    <GQLLoading
        :query="getRoomQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'room', id: roomId })"
    >
        <LocationDetails
            v-if="room"
            :location="room"
            :location-types="roomTypes"
            :to-edit="{ name: 'room-edit', params: { roomId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DropDownSelector from '@/components/DropDownSelector.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useRoom } from '../room';

const props = defineProps<{ roomId: string }>();
const fluent = useFluent();

const { room, roomTypes, getRoomQuery, deleteRoom } = useRoom(computed(() => props.roomId));

const isAdmin = auth.isAdmin();

const dropDownOptions = [
    {
        title: fluent.$t('room-partition'),
        description: fluent.$t('room-partition-add-to-room'),
        to: { name: 'room-partition-add', roomId: props.roomId },
    },
];

const canDelete = computed(() => {
    const allocationsCount = room.value?.allocationRooms?.nodes.length ?? 0;
    return isAdmin && allocationsCount == 0;
});

const disabledExplanation = computed(() => {
    if (canDelete.value) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
