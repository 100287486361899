import { mapAllocationForTimeline } from '@/allocation/allocation';
import { getUnstyledAllocationVariantLabel } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import { Allocation, AllocationType } from '@/components/Timeline/Timeline.types';
import { TimelineProjectVariantFragment } from '@/generated/graphql';
import { computed, Ref, toValue } from 'vue';
import { getEventMainRoute } from '../event';

export function useEventAllocations(
    variantsRef: Ref<TimelineProjectVariantFragment[]>,
    visibleVariants: Ref<string[]>,
    allocationType: AllocationType,
    label: Ref<string>,
    highlightedVariantId: Ref<string>,
    eventId: Ref<string>,
) {
    const filteredAllocations = computed(() => {
        const allocations: Allocation[] = [];
        const filteredVariants = variantsRef.value.filter((v) => visibleVariants.value.includes(v.id)) ?? [];
        const linkTarget = getEventMainRoute(toValue(eventId));

        for (const variant of filteredVariants) {
            const variantLabel = getUnstyledAllocationVariantLabel(variant.index, variant.name);
            for (const allocation of variant.rooms.nodes) {
                allocations.push(
                    mapAllocationForTimeline(
                        allocation,
                        variant.isCurrent,
                        variantLabel,
                        allocationType,
                        toValue(label),
                        toValue(highlightedVariantId),
                        linkTarget,
                    ),
                );
            }
            for (const allocation of variant.areas.nodes) {
                allocations.push(
                    mapAllocationForTimeline(
                        allocation,
                        variant.isCurrent,
                        variantLabel,
                        allocationType,
                        toValue(label),
                        toValue(highlightedVariantId),
                        linkTarget,
                    ),
                );
            }
        }

        return allocations;
    });
    return {
        filteredAllocations,
    };
}
