<template>
    <BaseForm v-if="series && errors && multilinks">
        <template #fields>
            <TextField
                :value="series.nameShort"
                :label="$t('name-short')"
                :errorMessages="errors.nameShort"
                @input="onInput($event, 'nameShort')"
            />
            <TextField
                :value="series.nameLong"
                :label="$t('name-long')"
                :errorMessages="errors.nameLong"
                @input="onInput($event, 'nameLong')"
            />
            <TextAreaField
                :value="series.notes"
                :label="$t('notes')"
                :errorMessages="errors.notes"
                @input="onInput($event, 'notes')"
            />
            <TextAreaField
                :value="series.description"
                :label="$t('description')"
                :errorMessages="errors.description"
                @input="onInput($event, 'description')"
            />
            <MultiLink
                v-if="multilinks.websites"
                :multiLink="multilinks.websites"
                :label="$t('project-websites')"
                @input="onMultilinkInput($event.event, $event.link, $event.fieldName, 'websites')"
                @delete="onDelete($event)"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import MultiLink from '@/components/MultiLink/MultiLink.vue';
import { FormLink } from '@/components/MultiLink/types';
import { formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesIdentifiersSchema, ProjectSeriesIdentifiersSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;
const multilinks = data?.multilinks;

const functions = inject(formFunctions);

const schemaFields = ProjectSeriesIdentifiersSchema.keyof().options;
// FIXME multilinks should be part of the schema
const formFields = [...schemaFields, 'websites'];

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectSeriesIdentifiersSchemaType;
    const schema = ProjectSeriesIdentifiersSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}

function onMultilinkInput(event: Event, link: FormLink, property: string, fieldName: string) {
    functions?.handleMultilink(event, link, property, fieldName);
    functions?.isFormTouched(formFields);
}

function onDelete(link: FormLink) {
    functions?.deleteLink(link);
    functions?.isFormTouched(formFields);
}
</script>
