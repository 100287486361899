import { BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { EventBaseDataSchema, EventOrganisationSchema, EventTeamStructureSchema } from '@/event/forms/schemas';
import { GetEventsQuery } from '@/generated/graphql';
import { $t } from '@/plugins/fluent';
import router from '@/plugins/router';
import { NodesType } from '@/utils/graphql';
import { useFluent } from 'fluent-vue';
import { z } from 'zod';
import {
    eventSeriesStates,
    EventSeriesStatus,
    eventStates,
    EventStatus,
    eventVariantStates,
    EventVariantStatus,
} from './types';

export type EventVariant = {
    id: string;
    name: string;
};

export type SelectableEventVariant = {
    id: string;
    name: string;
    isCurrent: boolean;
    start: string;
    end: string;
    status?: string;
};

export type Event = {
    id: string;
    name: string;
    description?: string;
    mainVariant: EventVariant;
};

export type EventSeries = {
    id: string;
    nameShort: string;
    events?: Event[];
};

export type EventUpdate = {
    id: string;
    name?: string;
    description?: string;
    iconUpload?: string;
    iconId?: string;
};

export type EventVariantDetails = EventVariant & {
    isCurrent: boolean;
    start: string;
    end: string;
    version: number;
    isArchived: boolean;
};

export function iconForVariant({ isCurrent, isArchived }: { isCurrent: boolean; isArchived: boolean }) {
    if (isCurrent) return 'star';
    if (isArchived) return 'inventory_2';
    return 'star_border';
}

type Variant = { name: string; version: number };
export function nameForVariant({ name, version }: Variant) {
    const realName = name === '' ? $t('event-first-variant-name') : name;
    return $t('event-variant-name', { name: realName, version: version + 1 });
}

export function fullNameForVariant(eventName: string, { name, version }: Variant) {
    return `${eventName}: ${nameForVariant({ name, version })}`;
}

type QueryEventType = NodesType<GetEventsQuery['events']>;
type QueryVariantType = NodesType<QueryEventType['mainVariant']>;

function toVariantItem(variant: QueryVariantType) {
    return {
        id: variant.id,
        name: variant.name,
    };
}

export function toEventItem(event: QueryEventType) {
    return {
        id: event.id,
        name: event.nameShort,
        mainVariant: toVariantItem(event.mainVariant.nodes[0]),
    };
}

export function toSeriesItem(series: NodesType<GetEventsQuery['eventSerieses']>, events: QueryEventType[] | undefined) {
    let seriesEvents: QueryEventType[] = [];
    if (events) {
        seriesEvents = events.filter((e: QueryEventType) => e.series?.id === series.id);
    }
    return {
        id: series.id,
        nameShort: series.nameShort,
        singleEvent: series.singleEvent,
        events: seriesEvents.map((e) => toEventItem(e)),
    };
}

export function getEventMainRoute(eventId: string) {
    return { name: 'event-masterdata-tab', params: { eventId } };
}

export function goToEvent(eventId: string) {
    router.push(getEventMainRoute(eventId));
}

export function goToCreateEvent(seriesId?: string) {
    router.push({ name: 'add-event', query: { seriesId } });
}

export function goToCreateSeries() {
    router.push({ name: 'add-event-series' });
}

export function goToSeries(series: EventSeries) {
    if (series.id !== '') {
        router.push({
            name: 'event-series-overview',
            params: {
                seriesId: series.id,
            },
        });
    } else {
        router.push({ name: 'events' });
    }
}

export function getEventVariantRoute(eventId: string, variantIndex: number) {
    return { name: 'event-variants-tab', params: { eventId }, hash: `#v${variantIndex}` };
}

const EMPTY_VALUE_STRING = '-';

export function valueToString(value: string | null | undefined) {
    if (value === undefined || value === null || value === '') return EMPTY_VALUE_STRING;
    return value;
}

export function idToName(id: string, items: { id: string; name: string }[]) {
    if (id === '') return EMPTY_VALUE_STRING;
    const item = items.find((i) => i.id === id);
    if (item) {
        return item.name;
    }
    throw new Error(`No item name found for id: ${id}`);
}

export function nullBooleanToString(value: boolean | undefined) {
    const fluent = useFluent();
    if (value === undefined || value === null) return EMPTY_VALUE_STRING;
    return value ? fluent.$t('yes') : fluent.$t('no');
}

export const EVENT_MULTILINK_FIELDS = [
    'extraDocuments',
    'extraSystems',
    'externalData',
    'internalData',
    'subeventsLocal',
    'subeventsOtherLocations',
    'fairAbroad',
    'fairAbroadCooperation',
    'competitionNational',
    'otherCompetitionNational',
    'competitionInternational',
    'otherCompetitionInternational',
    'favoredParallelsInternal',
    'favoredParallelsExternal',
    'otherLinksInternal',
    'otherLinksExternal',
];

const LINK_TAB_FIELDS = EVENT_MULTILINK_FIELDS;

export const cardFieldsMapping = new Map([
    [
        'baseData',
        ['nameShort', 'nameLong', 'subtitle', 'executionYear', 'description', 'annualSpecifics', 'iconUpload'],
    ],
    ['organisation', ['companyGroup', 'businessDomain', 'department']],
    ['teamStructure', ['responsible', 'assistant', 'teamChanges']],
    ['links', LINK_TAB_FIELDS],
]);

export const getCardNameFromField = (fieldName: string) => {
    for (const [cardName, fields] of cardFieldsMapping) {
        if (fields.indexOf(fieldName) >= 0) return cardName;
    }
    return '';
};

export const cardSchemaMapping = new Map<string, z.ZodSchema>([
    ['baseData', EventBaseDataSchema],
    ['organisation', EventOrganisationSchema],
    ['teamStructure', EventTeamStructureSchema],
]);

export const getEventSchemaFromCard = (cardId: string) => {
    return cardSchemaMapping.get(cardId);
};

export function eventStatusToBadgeLabel(status: EventStatus) {
    return $t(`event-status-${status}`);
}

export function eventStatusToBadgeVariant(status: EventStatus) {
    switch (status) {
        case 'active-option':
            return 'Green';
        case 'active-fix':
            return 'Green';
        case 'active-idea':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'canceled':
            return 'Purple';
        case 'completed':
            return 'Gray';
        case 'discontinued':
            return 'Red';
    }
}

export function eventSeriesStatusToBadgeVariant(status: EventSeriesStatus) {
    switch (status) {
        case 'active':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'discontinued':
            return 'Red';
    }
}

export function eventVariantStatusToBadgeVariant(status: EventVariantStatus) {
    switch (status) {
        case 'active-1':
            return 'Green';
        case 'active-2':
            return 'Green';
        case 'passive':
            return 'Yellow';
        case 'discontinued':
            return 'Red';
    }
}

export function getEventStatusComboboxItems(): { id: EventStatus; name: string }[] {
    return eventStates.map((s) => ({
        id: s,
        name: $t(`event-status-${s}`),
    }));
}

export function getEventSeriesStatusItems(status: EventSeriesStatus): (BadgeDropDownValue & { isSelected: boolean })[] {
    return eventSeriesStates.map((s) => ({
        id: s,
        label: $t(`event-series-status-${s}`),
        variant: eventSeriesStatusToBadgeVariant(s),
        isSelected: status === s,
    }));
}

export function getEventSeriesStatusItem(status: EventSeriesStatus): BadgeDropDownValue & { tooltip?: string } {
    return { id: status, label: $t(`event-series-status-${status}`), variant: eventSeriesStatusToBadgeVariant(status) };
}

export function getEventStatusItems(status: EventStatus): (BadgeDropDownValue & { isSelected: boolean })[] {
    return eventStates.map((s) => ({
        id: s,
        label: $t(`event-status-${s}`),
        variant: eventStatusToBadgeVariant(s),
        isSelected: status === s,
    }));
}

export function getEventStatusItem(status: EventStatus): BadgeDropDownValue & { tooltip?: string } {
    return { id: status, label: $t(`event-status-${status}`), variant: eventStatusToBadgeVariant(status) };
}

export function getEventVariantStatusLabel(status: EventVariantStatus) {
    return $t(`event-variant-status-${status}`);
}

export function getEventVariantStatusItems(
    status: EventVariantStatus,
): (BadgeDropDownValue & { isSelected: boolean })[] {
    return eventVariantStates.map((s) => ({
        id: s,
        label: getEventVariantStatusLabel(s),
        variant: eventVariantStatusToBadgeVariant(s),
        isSelected: status === s,
    }));
}

export function getEventVariantStatusItem(status: EventVariantStatus): BadgeDropDownValue & { tooltip?: string } {
    return {
        id: status,
        label: getEventVariantStatusLabel(status),
        variant: eventVariantStatusToBadgeVariant(status),
    };
}
