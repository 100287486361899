import { DeletePlotDocument, GetPlotDetailsDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function usePlot(plotId: Ref<string>) {
    const getPlotQuery = useQuery(
        GetPlotDetailsDocument,
        computed(() => ({ id: unref(plotId) })),
    );
    const plot = useResult(getPlotQuery.result, undefined, (data) => data.plot);
    const plotTypes = computed(() => getPlotQuery.result.value?.plotTypes?.nodes ?? []);

    const plotToDelete = computed(() => ({
        __typename: plot.value?.__typename ?? '',
        id: plot.value?.id ?? '',
        nameShort: plot.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeletePlotDocument);
    const routeToParent = computed(() => ({ name: 'area-details', params: { areaId: plot.value?.area.id } }));
    const deletePlot = useDeleteLocation(plotToDelete, deleteMutation, routeToParent);

    return {
        plot,
        plotTypes,
        getPlotQuery,
        deletePlot,
    };
}
