<template>
    <div>
        <div class="text-xs text-center">
            <div v-if="query.loading.value && !query.result.value">{{ $t('loading') }}</div>
            <div v-if="estimatedEmpty && query.result.value">{{ $t('empty') }}</div>
        </div>
        <ErrorCard :apollo-error="query.error.value" class="mx-2" />
        <slot></slot>
    </div>
</template>

<script setup lang="ts">
import { UseQueryReturn } from '@vue/apollo-composable';
import { computed } from 'vue';
import ErrorCard from '../components/ErrorCard.vue';
interface Props {
    query: UseQueryReturn<any, any>;
    empty?: boolean | undefined;
}

const props = withDefaults(defineProps<Props>(), {
    nav: false,
    dense: false,
    empty: undefined,
});

const estimatedEmpty = computed(() => {
    if (props.empty !== undefined) return props.empty;
    if (props.query.result.value === undefined) return false;

    var list = Object.values(props.query.result.value)[0];
    return list instanceof Array && list.length == 0;
});
</script>

<fluent locale="de">
loading=Lädt
empty=Noch keine Einträge
</fluent>
