<template>
    <div
        class="h-[calc(2.5rem+3px)] flex flex-col pb-0.5"
        :class="[backgroundClass, borderTopClass, borderBottomClass]"
    >
        <div class="flex flex-col justify-center h-full w-full" :class="[borderLeftClass, borderRightClass]">
            <div
                class="pt-px text-center text-sm leading-tight font-bold"
                :class="props.isColumnHovered ? 'text-gray-700' : 'text-gray-600'"
            >
                {{ new Intl.NumberFormat('de-DE', { minimumIntegerDigits: 2 }).format(props.dayNumber) }}
            </div>
            <div
                class="text-center text-sm leading-tight font-light uppercase"
                :class="props.isColumnHovered ? 'text-gray-500' : 'text-gray-400'"
                :aria-label="props.dayFullName"
            >
                {{ props.dayAbbreviation }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type HeaderDayProps = {
    isWorkDay: boolean;
    isColumnHovered: boolean;
    isConflictCellHovered: boolean;
    dayNumber: number;
    dayFullName: string;
    dayAbbreviation: string;
    isFirstColumn: boolean;
};

const props = defineProps<HeaderDayProps>();

const backgroundClass = computed(() => {
    if (props.isColumnHovered) {
        if (props.isConflictCellHovered) {
            return 'bg-red-100';
        } else {
            return 'bg-blue-100';
        }
    }

    if (!props.isWorkDay) {
        return 'bg-gray-100';
    }

    return 'bg-gray-50';
});

const borderTopClass = computed(() => {
    if (props.isColumnHovered) {
        if (props.isConflictCellHovered) {
            return 'header-day-border-top header-day-border-top-1 header-day-border-top-red-200';
        } else {
            return 'header-day-border-top header-day-border-top-1 header-day-border-top-blue-200';
        }
    }
});

const borderBottomClass = computed(() => {
    if (props.isColumnHovered) {
        if (props.isConflictCellHovered) {
            return 'header-day-border-bottom header-day-border-bottom-2 header-day-border-bottom-red-600';
        } else {
            return 'header-day-border-bottom header-day-border-bottom-2 header-day-border-bottom-blue-400';
        }
    }
});

const borderLeftClass = computed(() => {
    if (props.isColumnHovered) {
        if (props.isConflictCellHovered) {
            return 'header-day-border-left header-day-border-left-1 header-day-border-left-red-200';
        } else {
            return 'header-day-border-left header-day-border-left-1 header-day-border-left-blue-200';
        }
    }

    // The "middle" borders between the header days are created with right border except for the first column where a left border is necessary
    if (props.isFirstColumn) {
        return 'header-day-border-left header-day-border-left-1 header-day-border-left-gray-200';
    }
});

const borderRightClass = computed(() => {
    if (props.isColumnHovered) {
        if (props.isConflictCellHovered) {
            return 'header-day-border-right header-day-border-right-1 header-day-border-right-red-200';
        } else {
            return 'header-day-border-right header-day-border-right-1 header-day-border-right-blue-200';
        }
    }

    return 'header-day-border-right header-day-border-right-1 header-day-border-right-gray-200';
});
</script>

<style scoped>
/**
 * Note: The double border at the bottom in the "default state" is implemented on the <TimelineHeader /> component for timeline variants without header days.
 */
.header-day-border-top,
.header-day-border-bottom,
.header-day-border-left,
.header-day-border-right {
    position: relative;
}
.header-day-border-left-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: 1px;
    height: 100%;
}

.header-day-border-right-1::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    width: 1px;
    height: 100%;
}
.header-day-border-left-gray-200::before {
    background-color: theme('colors.gray.200');
}
.header-day-border-left-blue-200::before {
    background-color: theme('colors.blue.200');
}
.header-day-border-left-red-200::before {
    background-color: theme('colors.red.200');
}

.header-day-border-right-gray-200::after {
    background-color: theme('colors.gray.200');
}
.header-day-border-right-blue-200::after {
    background-color: theme('colors.blue.200');
}
.header-day-border-right-red-200::after {
    background-color: theme('colors.red.200');
}

.header-day-border-top-1::before {
    content: '';
    position: absolute;
    top: 0;
    left: -1px;
    width: calc(100% + 2px);
    height: 1px;
    z-index: 20;
}
.header-day-border-top-blue-200::before {
    background-color: theme('colors.blue.200');
}
.header-day-border-top-red-200::before {
    background-color: theme('colors.red.200');
}

.header-day-border-bottom-2::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -1px;
    width: calc(100% + 2px);
    height: 2px;
    z-index: 20;
}
.header-day-border-bottom-blue-400::after {
    background-color: theme('colors.blue.400');
}
.header-day-border-bottom-red-600::after {
    background-color: theme('colors.red.600');
}
</style>
