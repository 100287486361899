import { EditableCardStatus } from '@/components/Card/types';
import { InjectionKey, Ref } from 'vue';

type EventActiveCardFunctions = {
    updateActiveCard: (id: string, label: string, status: EditableCardStatus) => void;
};

export const fileUploadLoadingKey = Symbol() as InjectionKey<Ref<boolean>>;
export const iconKey = Symbol() as InjectionKey<Ref<{ url: string; fromParent: boolean }>>;
export const eventCardFunctionsKey = Symbol() as InjectionKey<EventActiveCardFunctions>;
