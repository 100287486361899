import { BadgeDropDownStatus, BadgeDropDownValue } from '@/components/BadgeDropDown/BadgeDropDown.vue';
import { eventVariantStatusToBadgeVariant, getEventVariantRoute } from '@/event/event';
import { eventVariantStates, EventVariantStatus } from '@/event/types';
import {
    MyUserFragment,
    UpdateEventVariantAndCommentsDocument,
    UpdateProjectVariantAndCommentsDocument,
} from '@/generated/graphql';
import { getProjectVariantRoute, projectVariantStatusToBadgeVariant } from '@/project/project';
import { projectVariantStates, ProjectVariantStatus } from '@/project/types';
import { useMutation } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { ref, Ref, toValue } from 'vue';
import { VariantCardVariant, VariantType } from '../types';

export function useVariantStatusDropdown(
    variantTypeRef: Ref<VariantType>,
    variantRef: Ref<VariantCardVariant | undefined>,
    currentUser: Ref<MyUserFragment | undefined | null>,
    variantsRef: Ref<VariantCardVariant[]>,
    setVariantsRef: (variants: VariantCardVariant[]) => void,
) {
    const fluent = useFluent();
    const dropDownStatus: Ref<BadgeDropDownStatus> = ref('Default');
    const mutations = {
        Event: useMutation(UpdateEventVariantAndCommentsDocument),
        Project: useMutation(UpdateProjectVariantAndCommentsDocument),
    };

    function getVariantStatusLabel(status: string) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return fluent.$t(`event-variant-status-${status}`);
            case 'Project':
                return fluent.$t(`project-variant-status-${status}`);
        }
    }

    function variantStatusToBadgeVariant(status: EventVariantStatus | ProjectVariantStatus) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return eventVariantStatusToBadgeVariant(status);
            case 'Project':
                return projectVariantStatusToBadgeVariant(status);
        }
    }

    function getVariantStatusItem(status: EventVariantStatus | ProjectVariantStatus): BadgeDropDownValue {
        return {
            id: status,
            label: getVariantStatusLabel(status),
            variant: variantStatusToBadgeVariant(status),
        };
    }

    function getVariantStatusOptionItems(
        status: EventVariantStatus | ProjectVariantStatus,
    ): (BadgeDropDownValue & { isSelected: boolean })[] {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return eventVariantStates.map((s) => ({
                    id: s,
                    label: getVariantStatusLabel(s),
                    variant: variantStatusToBadgeVariant(s),
                    isSelected: status === s,
                }));
            case 'Project':
                return projectVariantStates.map((s) => ({
                    id: s,
                    label: getVariantStatusLabel(s),
                    variant: variantStatusToBadgeVariant(s),
                    isSelected: status === s,
                }));
        }
    }

    async function handleBadgeDropdown(status: string, variantId: string) {
        const variant = toValue(variantRef);
        if (variant) {
            const oldStatus = variant.status;
            if (oldStatus === status) return;

            dropDownStatus.value = 'Loading';
            const user = toValue(currentUser);

            if (user) {
                const commentsToCreate = [
                    {
                        type: 'new-status',
                        text: '',
                        createdAt: DateTime.now().toISO(),
                        createdBy: user.id,
                        createdByName: user.name,
                        createdByIcon: user.icon?.original,
                        oldStatus,
                        newStatus: status,
                    },
                ];
                const updateMutation = mutations[toValue(variantTypeRef)];
                const result = await updateMutation.mutate({
                    id: variantId,
                    status,
                    commentsToCreate,
                });
                if (result?.errors) {
                    dropDownStatus.value = 'Retry';
                }
                const variant = result?.data?.update?.variant;
                if (variant && variant.comments.nodes) {
                    let variantStatus = undefined;
                    switch (toValue(variantTypeRef)) {
                        case 'Event':
                            variantStatus = variant.status as EventVariantStatus;
                            break;
                        case 'Project':
                            variantStatus = variant.status as ProjectVariantStatus;
                            break;
                    }
                    dropDownStatus.value = 'Default';
                    setVariantsRef(
                        toValue(variantsRef).map((v) =>
                            v.id === variantId
                                ? {
                                      ...v,
                                      status: variantStatus,
                                      comments: {
                                          nodes: variant.comments.nodes.map((comment) => ({
                                              ...comment,
                                              hasUnsavedChanges: 'None',
                                          })),
                                      },
                                  }
                                : v,
                        ),
                    );
                }
            }
        }
    }

    function getVariantRoute(objectId: string, index: number) {
        switch (toValue(variantTypeRef)) {
            case 'Event':
                return getEventVariantRoute(objectId, index);
            case 'Project':
                return getProjectVariantRoute(objectId, index);
        }
    }

    return {
        dropDownStatus,
        getVariantStatusItem,
        getVariantStatusLabel,
        getVariantStatusOptionItems,
        handleBadgeDropdown,
        getVariantRoute,
    };
}
