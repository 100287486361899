<template>
    <div class="space-y-5">
        <div class="font-semibold flex flex-row items-center gap-1">
            {{ label }} <HelpButton v-if="helpButtonText" :text="helpButtonText" />
        </div>
        <div v-for="link in filteredLinks" :key="link.id">
            <LinkItem
                :link="link"
                :disabled-explanation="disabledExplanation"
                @input="$emit('input', $event)"
                @delete="$emit('delete', $event)"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';
import LinkItem from './LinkItem.vue';
import { FormLink, FormMultilink } from './types';

export type MultiLinkProps = {
    multiLink: FormMultilink;
    label: string;
    helpButtonText?: string;
    disabledExplanation?: string;
};

const props = defineProps<MultiLinkProps>();
defineEmits<{
    (e: 'input', v: { event: Event; link: FormLink; fieldName: string }): void;
    (e: 'newLink', v: string): void;
    (e: 'delete', v: FormLink): void;
}>();

const links = computed(() => props.multiLink.links?.nodes ?? []);
const filteredLinks = computed(() => links.value.filter((link) => !(link.status === 'deleted')));
</script>
