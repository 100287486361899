<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="m10.45 11.217.75-.75L8.55 7.8V4.45h-1V8.2l2.9 3.017ZM8 14.667a6.45 6.45 0 0 1-2.583-.525 6.761 6.761 0 0 1-2.125-1.434 6.761 6.761 0 0 1-1.434-2.125A6.45 6.45 0 0 1 1.333 8c0-.911.175-1.772.525-2.583.35-.811.828-1.52 1.434-2.125a6.762 6.762 0 0 1 2.125-1.434A6.45 6.45 0 0 1 8 1.333a6.45 6.45 0 0 1 2.583.525 6.74 6.74 0 0 1 2.125 1.434 6.763 6.763 0 0 1 1.434 2.125A6.45 6.45 0 0 1 14.667 8a6.45 6.45 0 0 1-.525 2.583 6.762 6.762 0 0 1-1.434 2.125 6.762 6.762 0 0 1-2.125 1.434A6.45 6.45 0 0 1 8 14.667Zm0-1c1.556 0 2.889-.556 4-1.667 1.111-1.111 1.667-2.444 1.667-4S13.11 5.111 12 4c-1.111-1.111-2.444-1.667-4-1.667S5.111 2.89 4 4C2.889 5.111 2.333 6.444 2.333 8S2.89 10.889 4 12c1.111 1.111 2.444 1.667 4 1.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ClockIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<ClockIconProps>();
</script>
