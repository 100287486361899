<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M1 14a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h14c.267 0 .5.1.7.3.2.2.3.433.3.7v10c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H1Zm8.85-1H15V3H1v10h.117a5.477 5.477 0 0 1 1.875-1.817 4.854 4.854 0 0 1 2.491-.666c.9 0 1.73.222 2.492.666A5.477 5.477 0 0 1 9.85 13ZM5.483 9.333c.556 0 1.028-.194 1.417-.583.389-.389.583-.861.583-1.417 0-.555-.194-1.027-.583-1.416a1.929 1.929 0 0 0-1.417-.584c-.555 0-1.027.195-1.416.584a1.929 1.929 0 0 0-.584 1.416c0 .556.195 1.028.584 1.417.389.389.86.583 1.416.583ZM12.6 12.2l1.283-1.267-.95-1.35h-1.166c-.1-.277-.17-.536-.209-.775a4.944 4.944 0 0 1-.058-.791c0-.29.02-.55.058-.784.04-.233.109-.494.209-.783h1.166l.95-1.35L12.6 3.833a5.144 5.144 0 0 0-1.425 1.85 5.496 5.496 0 0 0-.508 2.334c0 .822.17 1.6.508 2.333.339.733.814 1.35 1.425 1.85ZM2.383 13h6.2a4.19 4.19 0 0 0-1.405-1.094 3.874 3.874 0 0 0-3.386.002A4.321 4.321 0 0 0 2.383 13Zm3.1-4.667a.968.968 0 0 1-.712-.287.968.968 0 0 1-.288-.713c0-.283.096-.52.288-.712a.968.968 0 0 1 .712-.288c.284 0 .521.096.713.288a.964.964 0 0 1 .287.712.966.966 0 0 1-.287.713.968.968 0 0 1-.713.287Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ServiceCenterIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ServiceCenterIconProps>();
</script>
