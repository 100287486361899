import { GetLocationsForDownloadDocument, GetLocationsForDownloadQuery } from '@/generated/graphql';
import { ApolloQueryResult } from '@apollo/client';
import { useLazyQuery } from '@vue/apollo-composable';

export function useLocationDownload() {
    const { load, refetch, loading } = useLazyQuery(GetLocationsForDownloadDocument);
    const environment = import.meta.env.ENV_NAME;

    function mapResult(
        result: GetLocationsForDownloadQuery | ApolloQueryResult<GetLocationsForDownloadQuery> | undefined,
    ) {
        if (!result) return {};
        let data: GetLocationsForDownloadQuery = {};
        if ('data' in result) {
            data = result.data;
        } else {
            data = result;
        }
        return { data };
    }

    function removeTypename(key: string, value: unknown) {
        if (key === '__typename') return undefined;
        return value;
    }

    async function createJSONDownload() {
        const result = await (load() || refetch());
        const data = mapResult(result);
        const blob = new Blob([JSON.stringify(data, removeTypename)], { type: 'application/json' });
        const dataURL = URL.createObjectURL(blob);
        const filename = `space.cap-locations-${environment}.json`;

        const link = document.createElement('a');
        link.download = filename;
        link.href = dataURL;
        link.click();
        link.remove();
    }

    return {
        loading,
        createJSONDownload,
    };
}
