<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M6.15 8.517a.56.56 0 0 1-.408-.175.56.56 0 0 1-.175-.409.56.56 0 0 1 .175-.408.56.56 0 0 1 .408-.175.56.56 0 0 1 .408.175.56.56 0 0 1 .175.408.56.56 0 0 1-.175.409.56.56 0 0 1-.408.175Zm3.717 0a.56.56 0 0 1-.409-.175.56.56 0 0 1-.175-.409.56.56 0 0 1 .175-.408.56.56 0 0 1 .409-.175.56.56 0 0 1 .408.175.56.56 0 0 1 .175.408.56.56 0 0 1-.175.409.56.56 0 0 1-.408.175ZM2 14v-1h.767V3c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h8.466c.267 0 .5.1.7.3.2.2.3.433.3.7v10H14v1H2Zm1.767-1H7.5V3H3.767v10ZM8.5 13h3.733V3H8.5v10Z"
        />
    </svg>
</template>

<script setup lang="ts">
type EntryIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<EntryIconProps>();
</script>
