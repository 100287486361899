<!-- Uses tailwind ui button group: https://tailwindui.com/components/application-ui/elements/button-groups#component-cbd15ccb80090a5dfb2d647be03b6ee9 -->
<template>
    <div class="inline-flex rounded-md">
        <button
            type="button"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-normal text-gray-700 hover:bg-gray-50"
        >
            <RouterLink :to="to">{{ title }}</RouterLink>
        </button>
        <tooltip>
            <template #trigger>
                <button
                    ref="copyButton"
                    type="button"
                    class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-normal text-gray-700 hover:bg-gray-50"
                    @click="copyLink(to)"
                >
                    <Icon>link</Icon>
                </button>
            </template>
            <template #text>
                {{ $t('copy-report-link') }}
            </template>
        </tooltip>
    </div>
</template>

<script setup lang="ts">
import Icon from '@/components/Icon.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { RouteLocationRaw } from 'vue-router';
import { useReportLink } from '../report';

defineProps<{ to: RouteLocationRaw; title: string }>();
const { copyLink } = useReportLink();
</script>
