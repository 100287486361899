<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="m7.991 16-2.973-2.817.743-.7 2.248 2.1 2.248-2.116.743.7L7.991 16Zm0-3.333L5.018 9.85l.743-.7 2.248 2.1 2.248-2.117.743.7-3.009 2.834Zm-2.23-5.8L5 6.167l3.009-2.834L11 6.15l-.761.717-2.248-2.1-2.23 2.1Zm0-3.334L5 2.833 8.009 0 11 2.817l-.761.716-2.248-2.1-2.23 2.1Z"
        />
    </svg>
</template>

<script setup lang="ts">
type BoulevardIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<BoulevardIconProps>();
</script>
