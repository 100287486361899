<template>
    <TopBar v-if="building" :title="building.nameShort" :breadcrumbs="locationBreadcrumbs(building)">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabledExplanation="disabledExplanation"
                    @click="deleteBuilding"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
                <DropDownSelector :options="actions" :disabled="!isAdmin" />
            </div>
        </template>
    </TopBar>

    <GQLLoading
        :query="getBuildingQuery"
        skeleton-type="table-heading, list-item@3"
        :empty-error-msg="$t('empty-error', { type: 'building', id: buildingId })"
    >
        <LocationDetails
            v-if="building"
            :location="building"
            :locationTypes="buildingTypes"
            :to-edit="{ name: 'building-edit', params: { buildingId } }"
            :can-edit="isAdmin"
        />
    </GQLLoading>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';

import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DropDownSelector from '@/components/DropDownSelector.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { useBuilding } from '../building';

const props = defineProps<{ buildingId: string }>();
const fluent = useFluent();
const isAdmin = auth.isAdmin();

const actions = [
    {
        title: fluent.$t('level'),
        description: fluent.$t('level-add-to-building'),
        to: { name: 'level-add', buildingId: props.buildingId },
    },
];

const { building, buildingTypes, getBuildingQuery, deleteBuilding } = useBuilding(computed(() => props.buildingId));

const canDelete = computed(() => {
    const hasAllocations = building.value?.levels.nodes.some((l) =>
        l.rooms.nodes.some((r) => r.allocationRooms.nodes.length > 0),
    );
    return isAdmin && !hasAllocations;
});

const disabledExplanation = computed(() => {
    if (canDelete.value) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
