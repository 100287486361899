<template>
    <div v-if="series">
        <FlexibleCard v-if="series.events?.nodes.length" headline-id="events" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderStatic>
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('events') }}
                    </CardHeadline>
                </CardHeaderStatic>
            </template>

            <template #body>
                <CardRowColumns
                    v-for="event in series.events.nodes"
                    :key="event.id"
                    :columns-count="2"
                    class="border-b border-b-gray-200 last:border-b-0"
                >
                    <div class="py-2 px-4 items-start flex flex-col">
                        <p class="text-lg text-gray-900 font-bold hyphens-auto">
                            <TextLink :to="getEventMainRoute(event.id)">
                                {{ event.nameShort }}
                            </TextLink>
                        </p>
                        <p class="text-sm text-gray-500 font-light">{{ createSubtitle(event) }}</p>
                    </div>
                    <Badge size="Extra-Small" :variant="eventStatusToBadgeVariant(event.status)">
                        {{ eventStatusToBadgeLabel(event.status) }}
                    </Badge>
                </CardRowColumns>
            </template>
        </FlexibleCard>

        <FlexibleCard v-else headline-id="events" expandable-status="Not-Collapsible">
            <template #header="{ headlineId }">
                <CardHeaderStatic>
                    <CardHeadline :headline-id="headlineId">
                        {{ $t('events') }}
                    </CardHeadline>
                </CardHeaderStatic>
            </template>

            <template #body>
                <CardRowInfo :text="$t('no-events')" :has-border-bottom="false" />
            </template>
        </FlexibleCard>
    </div>
</template>

<script setup lang="ts">
import Badge from '@/components/Badge/Badge.vue';
import CardHeaderStatic from '@/components/Card/CardHeaderStatic.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardRowColumns from '@/components/Card/CardRowColumns.vue';
import CardRowInfo from '@/components/Card/CardRowInfo.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import TextLink from '@/components/TextLink/TextLink.vue';
import { eventStatusToBadgeLabel, eventStatusToBadgeVariant, getEventMainRoute } from '@/event/event';
import { EventSeriesDetails, SeriesDetailsEvent } from '@/event/types';
import { formatDateLong } from '@/utils/date';
defineProps<{ series: EventSeriesDetails }>();

function createSubtitle(event: SeriesDetailsEvent) {
    const variant = event.mainVariant?.nodes[0];
    if (variant) return `${formatDateLong(variant.start)} - ${formatDateLong(variant.end)}`;
    return '';
}
</script>
