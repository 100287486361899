import { Environment } from '@/utils/environment';

const BACKEND_URL = import.meta.env.BACKEND_URL.replace(/\/$/, '');

export type Config = {
    graphqlUrl: string;
    versionJsonUrl: string;
    sentryDsn: string;
    s3PublicUrl: string;
    envName: Environment;
};

export const config: Config = {
    graphqlUrl: `${BACKEND_URL}/graphql`,
    versionJsonUrl: `${BACKEND_URL}/version.json`,
    sentryDsn: import.meta.env.SENTRY_DSN,
    s3PublicUrl: import.meta.env.S3_PUBLIC_URL.replace(/\/$/, ''),
    envName: import.meta.env.ENV_NAME,
};
