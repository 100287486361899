<template>
    <TopBar :title="$t('calendar-event-create')" />
    <div class="p-5">
        <CalendarEventCreateForm :calendarId="calendarId"></CalendarEventCreateForm>
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import CalendarEventCreateForm from '../forms/CalendarEventCreateForm.vue';

defineProps<{ calendarId: string }>();
useHeadSafe({ title: () => `${$t('calendar-event-create')} - ${$t('external-events')}` });
</script>
