<!--
    This example requires Tailwind CSS v2.0+

    https://tailwindui.com/components/application-ui/forms/select-menus
    "Branded with supported text"
-->
<template>
    <Listbox v-model="selected" as="div" :disabled="disabled">
        <ListboxLabel class="sr-only"> Change published status </ListboxLabel>
        <div class="relative">
            <div class="inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                <div class="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-indigo-600">
                    <ListboxButton
                        :class="disabled ? 'bg-primary-500/30' : 'bg-primary-500 hover:bg-primary-400'"
                        class="relative inline-flex items-center p-2 no-border rounded-md text-sm font-normal text-white"
                    >
                        <div class="flex flex-row align-center">
                            <Icon>add</Icon>
                            <slot />
                        </div>
                    </ListboxButton>
                </div>
            </div>

            <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <ListboxOptions
                    class="origin-top-right absolute z-10 right-0 mt-2 w-60 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <router-link v-for="option in options" :key="option.title" :to="option.to">
                        <ListboxOption v-slot="{ active }" as="div" :value="option">
                            <li
                                class="cursor-default select-none relative p-4 text-sm"
                                :class="{ 'bg-primary-500/20': active }"
                            >
                                <div class="flex flex-col">
                                    <div class="flex justify-between">
                                        <p :class="['font-normal']">
                                            {{ option.title }}
                                        </p>
                                    </div>
                                    <p class="text-gray-500">
                                        {{ option.description }}
                                    </p>
                                </div>
                            </li>
                        </ListboxOption>
                    </router-link>
                </ListboxOptions>
            </transition>
        </div>
    </Listbox>
</template>

<script setup lang="ts">
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { ref } from 'vue';
import Icon from './Icon.vue';

type SelectorOption = {
    title: string;
    description: string;
    to: object;
    disabled?: boolean;
};

defineProps<{ options: SelectorOption[]; disabled?: boolean }>();
const selected = ref();
</script>
