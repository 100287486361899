<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2.667 11.667v-1.334H9.33v1.334H2.667ZM6.67 9V7.667h6.66V9H6.67Zm-2-2.667V5h6.66v1.333H4.67ZM2.667 3.667V2.333H9.33v1.334H2.667ZM4.67 13v1.3h6.66V13H4.67Z"
        />
    </svg>
</template>

<script setup lang="ts">
type NestedEntriesIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<NestedEntriesIconProps>();
</script>
