<template>
    <BaseForm v-if="project && errors">
        <template #fields>
            <TextAreaField
                :value="project.notesDayOrder"
                :label="$t('project-notes-dayorder')"
                :errorMessages="errors.notesDayOrder"
                @input="onInput($event, 'notesDayOrder')"
            />
            <TextAreaField
                :value="project.notesAppointment"
                :label="$t('project-notes-appointment')"
                :errorMessages="errors.notesAppointment"
                @input="onInput($event, 'notesAppointment')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import { formFunctions, projectData } from '@/project/keys';
import { ProjectAppointmentSchema, ProjectAppointmentSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectData);
const project = data?.project;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectAppointmentSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectAppointmentSchemaType;
    const schema = ProjectAppointmentSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
