<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3.333 14.667a1.929 1.929 0 0 1-1.416-.584 1.929 1.929 0 0 1-.584-1.416c0-.434.125-.82.375-1.159.25-.338.57-.58.959-.725V5.217a2.06 2.06 0 0 1-.959-.725 1.901 1.901 0 0 1-.375-1.159c0-.555.195-1.027.584-1.416a1.929 1.929 0 0 1 1.416-.584c.434 0 .82.125 1.159.375.338.25.58.57.725.959h5.566c.134-.39.37-.709.709-.959.338-.25.73-.375 1.175-.375.555 0 1.027.195 1.416.584.39.389.584.86.584 1.416 0 .445-.125.836-.375 1.175-.25.34-.57.575-.959.709v5.566c.39.145.709.386.959.725.25.34.375.725.375 1.159 0 .555-.195 1.027-.584 1.416a1.929 1.929 0 0 1-1.416.584 1.9 1.9 0 0 1-1.159-.375 2.06 2.06 0 0 1-.725-.959H5.217a2.06 2.06 0 0 1-.725.959c-.34.25-.725.375-1.159.375ZM3.333 4a.645.645 0 0 0 .475-.192A.645.645 0 0 0 4 3.333a.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.191.645.645 0 0 0-.475.191.645.645 0 0 0-.191.475c0 .19.063.348.191.475A.645.645 0 0 0 3.333 4Zm9.334 0a.645.645 0 0 0 .475-.192.645.645 0 0 0 .191-.475.645.645 0 0 0-.191-.475.645.645 0 0 0-.475-.191.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475c0 .19.064.348.192.475a.645.645 0 0 0 .475.192Zm-7.45 8h5.566A1.946 1.946 0 0 1 12 10.783V5.217A1.946 1.946 0 0 1 10.783 4H5.217A1.946 1.946 0 0 1 4 5.217v5.566A1.946 1.946 0 0 1 5.217 12Zm7.45 1.333a.645.645 0 0 0 .475-.191.645.645 0 0 0 .191-.475.645.645 0 0 0-.191-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192.645.645 0 0 0-.192.475c0 .189.064.347.192.475a.645.645 0 0 0 .475.191Zm-9.334 0a.645.645 0 0 0 .475-.191.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192.645.645 0 0 0-.191.475c0 .189.063.347.191.475a.645.645 0 0 0 .475.191Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ZoneIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ZoneIconProps>();
</script>
