<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3.833 14.667v-1H7.5v-3H6a3.213 3.213 0 0 1-2.358-.975 3.213 3.213 0 0 1-.975-2.359c0-.666.183-1.28.55-1.841.366-.561.86-.97 1.483-1.225.1-.834.464-1.53 1.092-2.092A3.208 3.208 0 0 1 8 1.333c.844 0 1.58.28 2.208.842A3.236 3.236 0 0 1 11.3 4.267a3.172 3.172 0 0 1 1.483 1.225c.367.56.55 1.175.55 1.841 0 .923-.325 1.709-.975 2.359-.65.65-1.436.975-2.358.975H8.5v3h3.833v1h-8.5Zm2.167-5h4c.644 0 1.194-.228 1.65-.684a2.248 2.248 0 0 0 .683-1.65c0-.466-.133-.891-.4-1.275A2.463 2.463 0 0 0 10.9 5.2l-.533-.233-.067-.584a2.316 2.316 0 0 0-.767-1.466A2.22 2.22 0 0 0 8 2.333c-.589 0-1.1.195-1.533.584A2.315 2.315 0 0 0 5.7 4.383l-.067.584L5.1 5.2a2.463 2.463 0 0 0-1.033.858c-.267.384-.4.809-.4 1.275 0 .645.227 1.195.683 1.65A2.248 2.248 0 0 0 6 9.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type NatureIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<NatureIconProps>();
</script>
