<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="m4.667 15.333 1.866-9.4-1.2.467v2.267H4V5.533L7.367 4.1a1.48 1.48 0 0 1 .491-.117c.173-.01.336.011.492.067.156.056.303.133.442.233s.252.228.341.384L9.8 5.733c.289.467.68.85 1.175 1.15.494.3 1.058.45 1.692.45v1.334c-.778 0-1.473-.161-2.084-.484A5.354 5.354 0 0 1 9.017 6.95L8.6 9l1.4 1.333v5H8.667V11l-1.4-1.067-1.2 5.4h-1.4ZM9 3.667c-.367 0-.68-.13-.942-.392a1.284 1.284 0 0 1-.391-.942c0-.366.13-.68.391-.941C8.32 1.13 8.633 1 9 1s.68.13.942.392c.26.26.391.575.391.941 0 .367-.13.68-.391.942A1.284 1.284 0 0 1 9 3.667Z"
        />
    </svg>
</template>

<script setup lang="ts">
type WalkingIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<WalkingIconProps>();
</script>
