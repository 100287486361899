import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import RoomPartitionEditPage from './pages/RoomPartitionEditPage.vue';
import RoomPartitionPage from './pages/RoomPartitionPage.vue';

export const roomPartitionRoutes = [
    {
        path: 'roompartition/:partitionId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'room-partition-detail',
                components: {
                    default: LocationsPage,
                    detail: RoomPartitionPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'room-partition-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: RoomPartitionEditPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
