<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="m4.15 12.55-.7-.7L7.3 8 3.45 4.15l.7-.7L8 7.3l3.85-3.85.7.7L8.7 8l3.85 3.85-.7.7L8 8.7l-3.85 3.85Z"
        />
    </svg>
</template>

<script setup lang="ts">
type CloseIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<CloseIconProps>();
</script>
