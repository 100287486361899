<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M2 13.333a.645.645 0 0 1-.475-.191.645.645 0 0 1-.192-.475c0-.111.022-.214.067-.309a.523.523 0 0 1 .2-.225l5.733-4.3V6.667a.63.63 0 0 1 .2-.475A.673.673 0 0 1 8.017 6c.277 0 .51-.1.7-.3.188-.2.283-.439.283-.717a.937.937 0 0 0-.292-.7A.979.979 0 0 0 8 4a.964.964 0 0 0-.708.292A.964.964 0 0 0 7 5H5.667c0-.644.227-1.194.683-1.65A2.248 2.248 0 0 1 8 2.667c.644 0 1.194.225 1.65.675.455.45.683.997.683 1.641 0 .523-.152.99-.458 1.4a2.327 2.327 0 0 1-1.208.85v.6l5.733 4.3c.089.056.155.13.2.225a.715.715 0 0 1 .067.309.645.645 0 0 1-.192.475.645.645 0 0 1-.475.191H2ZM4 12h8L8 9l-4 3Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ClothesHangerIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ClothesHangerIconProps>();
</script>
