<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M7.333 11.333h1.334v-4H7.333v4ZM8 6a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475A.645.645 0 0 0 8 4.667a.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475c0 .19.064.348.192.475A.645.645 0 0 0 8 6Zm0 8.667a6.492 6.492 0 0 1-2.6-.525 6.732 6.732 0 0 1-2.117-1.425A6.733 6.733 0 0 1 1.858 10.6 6.492 6.492 0 0 1 1.333 8c0-.922.175-1.789.525-2.6a6.732 6.732 0 0 1 1.425-2.117c.6-.6 1.306-1.075 2.117-1.425A6.492 6.492 0 0 1 8 1.333c.922 0 1.789.175 2.6.525.811.35 1.517.825 2.117 1.425.6.6 1.075 1.306 1.425 2.117.35.811.525 1.678.525 2.6 0 .922-.175 1.789-.525 2.6a6.733 6.733 0 0 1-1.425 2.117c-.6.6-1.306 1.075-2.117 1.425a6.491 6.491 0 0 1-2.6.525Zm0-1.334c1.489 0 2.75-.516 3.783-1.55 1.034-1.033 1.55-2.294 1.55-3.783 0-1.489-.516-2.75-1.55-3.783C10.75 3.183 9.49 2.667 8 2.667c-1.489 0-2.75.516-3.783 1.55C3.183 5.25 2.667 6.51 2.667 8c0 1.489.516 2.75 1.55 3.783C5.25 12.817 6.51 13.333 8 13.333Z"
        />
    </svg>
</template>

<script setup lang="ts">
type InfoIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<InfoIconProps>();
</script>
