<!-- eslint-disable vue/valid-v-for -->
<template>
    <Menu as="div" class="relative inline-block text-left">
        <Float portal>
            <MenuButton
                class="inline-flex justify-center items-center w-full py-2 text-sm font-normal text-primary hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
                :class="[disabled ? 'opacity-50' : '', textClass ?? '', dense ? 'px-1' : 'space-x-4 px-4']"
                :disabled="disabled"
                @click="$event.stopPropagation()"
            >
                <Icon v-if="icon" class="text-primary" :icon="icon" />
                {{ title }}
                <ChevronDownIcon v-if="!dense" class="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <MenuItems
                class="origin-top-right absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="py-1 relative z-50">
                    <MenuItem v-for="(item, i) in items">
                        <div>
                            <button
                                class="text-gray-700 block px-4 py-2 text-sm w-full text-left bg-white"
                                @click="$emit('click', item)"
                            >
                                <slot :item="item" :select="() => $emit('click', item)">
                                    <div>
                                        {{ _toString(item) }}
                                    </div>
                                </slot>
                            </button>
                            <hr v-if="dividers === true || (dividers instanceof Array && dividers.includes(i))" />
                        </div>
                    </MenuItem>
                </div>
            </MenuItems>
        </Float>
    </Menu>
</template>

<script setup lang="ts">
import { Float } from '@headlessui-float/vue';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';
import Icon from './Icon.vue';

const props = defineProps<{
    title: string;
    items: readonly object[];
    itemTitle?: string;
    icon?: string;
    dividers?: boolean | number[];
    disabled?: boolean;
    textClass?: string;
    dense?: boolean;
}>();

function _toString(item: object) {
    if (!item) return '';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (props.itemTitle) return (item as any)[props.itemTitle] as string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (item as any).title;
}

defineEmits<{
    (e: 'click', type: object): void;
}>();
</script>
