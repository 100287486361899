<template>
    <BaseForm @submit.prevent="onSubmit">
        <template #fields>
            <TextField :value="calendar.name" :label="$t('name')" :errorMessages="nameErrors" @input="nameChange" />
            <TextField
                :value="calendar.description"
                :label="$t('description')"
                :errorMessages="descriptionErrors"
                @input="descriptionChange"
            />
            <ColorField
                :value="calendar.colorHex"
                :label="$t('color')"
                :errorMessages="colorErrors"
                @input="colorHexChange"
            />
        </template>
        <template #buttons>
            <BaseButton @click="$router.back()" variant="outlined" exact>{{ $t('cancel') }}</BaseButton>
            <BaseButton submit color="primary" type="submit" :loading="createCalendar.loading.value">
                {{ $t('save') }}
            </BaseButton>
        </template>
    </BaseForm>
    <ErrorCard :mutation="createCalendar" />
</template>

<script setup lang="ts">
import BaseButton from '@/components/BaseButton.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import ColorField from '@/components/Form/ColorField.vue';
import TextField from '@/components/Form/TextField.vue';
import { CreateCalendarDocument } from '@/generated/graphql';
import ErrorCard from '@/utils/components/ErrorCard.vue';
import { useMutation } from '@vue/apollo-composable';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { CalendarCreateSchema } from './schemas';

const createCalendar = useMutation(CreateCalendarDocument);
const router = useRouter();
const calendar = ref({ name: '', description: '', colorHex: '' });

const nameErrors = ref<string[]>();
const descriptionErrors = ref<string[]>();
const colorErrors = ref<string[]>();

const nameChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    nameErrors.value = undefined;
    calendar.value.name = value;
    const parse = CalendarCreateSchema.shape.name.safeParse(value);
    if (!parse.success) {
        nameErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const descriptionChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendar.value.description = value;
    const parse = CalendarCreateSchema.shape.description.safeParse(value);
    if (!parse.success) {
        descriptionErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const colorHexChange = (e: Event) => {
    const target = e.target as HTMLInputElement;
    const value = target.value;
    descriptionErrors.value = undefined;
    calendar.value.colorHex = value;
    const parse = CalendarCreateSchema.shape.colorHex.safeParse(value);
    if (!parse.success) {
        colorErrors.value = parse.error.issues.map((i) => i.message);
    }
};

const onSubmit = async () => {
    const parse = CalendarCreateSchema.safeParse(calendar.value);
    if (!parse.success) return;

    let res = await createCalendar.mutate(calendar.value, {
        refetchQueries: ['GetCalendars'],
    });
    const { id, name } = res?.data?.createCalendar?.calendar ?? {};
    if (id && name) {
        router.push({ name: 'calendar-detail', params: { calendarId: id } });
    }
};
</script>
