<template>
    <div style="width: 100%">
        <div loading v-if="query.loading.value && !query.result" />
        <ErrorCard :apollo-error="query.error.value" :error="emptyResultError" class="m-4" />
        <slot v-if="query.result"></slot>
    </div>
</template>

<script setup lang="ts">
import { UseQueryReturn } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import ErrorCard from './ErrorCard.vue';

interface Props {
    query: UseQueryReturn<any, any>;
    skeletonType?: string;
    showErrorOnEmptyResult?: boolean;
    emptyErrorMsg?: string;
}

const props = withDefaults(defineProps<Props>(), {
    skeletonType: 'table-heading, list-item@10',
    showErrorOnEmptyResult: true,
    emptyErrorMsg: undefined,
});

const fluent = useFluent();

const emptyResultError = computed(() => {
    if (!props.showErrorOnEmptyResult) return undefined;
    if (props.query.result.value === undefined) return undefined;

    // get the first result from the query
    var res = Object.values(props.query.result.value)[0];
    if (res !== undefined && res !== null) return undefined;

    return props.emptyErrorMsg ?? fluent.$t('empty-error');
});
</script>

<fluent locale="de">
empty-error=Es wurden keine Ergebnisse gefunden
</fluent>
