<template>
    <div>
        <div class="relative flex items-start">
            <div class="flex h-5 items-center">
                <input
                    :id="inputId"
                    ref="input"
                    :value="value"
                    type="color"
                    :disabled="disabled"
                    :class="styling"
                    :required="required"
                    :aria-invalid="errorMessages && errorMessages.length ? true : undefined"
                    :aria-describedby="description ? `${inputId}-info` : undefined"
                    :aria-errormessage="errorMessages && errorMessages.length ? `${inputId}-error` : undefined"
                    @input="$emit('input', $event)"
                    @change="$emit('change', $event)"
                    @blur="$emit('blur', $event)"
                />
            </div>
            <div class="ml-3 text-sm">
                <div class="flex gap-1">
                    <label :for="inputId" class="font-medium text-gray-700 align-top">{{ label }}</label>
                    <HelpButton v-if="helpButtonText" :text="helpButtonText" class="ml-1" />
                </div>
                <p
                    v-if="description"
                    :id="`${inputId}-info`"
                    :class="[
                        props.status === 'overridden' ? 'mt-2 text-sm text-indigo-600' : 'mt-2 text-sm text-gray-500',
                    ]"
                >
                    {{ description }}
                </p>
            </div>
        </div>
        <div
            v-if="errorMessages && errorMessages.length"
            :id="`${inputId}-error`"
            role="alert"
            class="mt-2 text-sm text-red-600"
        >
            <p v-for="message in errorMessages" :key="message">{{ message }}</p>
        </div>
    </div>
</template>
<script setup lang="ts">
import { nanoid } from 'nanoid';
import { computed } from 'vue';
import HelpButton from '../HelpButton/HelpButton.vue';

type ColorFieldProps = {
    value: string | number;
    label: string;
    disabled?: boolean;
    required?: boolean;
    description?: string;
    helpButtonText?: string;
    errorMessages?: string[];
    status?: string;
};

const props = defineProps<ColorFieldProps>();
defineEmits<{ (e: 'input', v: Event): void; (e: 'change', v: Event): void; (e: 'blur', v: Event): void }>();
const inputId = nanoid();

const styling = computed(() => {
    return [
        'block h-6 w-6 focus:ring-primary-600 focus:outline-offset-1',
        'disabled:cursor-not-allowed',
        'disabled:border-gray-200',
        'disabled:bg-gray-50',
        'disabled:text-gray-500',
        'disabled:opacity-50',
        props.errorMessages && props.errorMessages.length
            ? 'focus:outline-none border-red-300 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500'
            : 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300',
        {
            'focus:outline-none border-blue-600 text-blue-600 focus:ring-blue-600 focus:border-blue-600':
                props.status === 'overridden',
        },
    ];
});
</script>
