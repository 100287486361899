<template>
    <ComboboxField
        :value="selectedItem"
        :items="items"
        :label="field.title"
        :helpButtonText="field.helper"
        :errorMessages="field.errorMessages"
        @change="onChange"
    />
</template>

<script setup lang="ts">
import ComboboxField, { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import { SelectFormField } from '@/location/forms/types';
import { computed } from 'vue';

const props = defineProps<{ field: SelectFormField; items: ComboboxItem[] | readonly ComboboxItem[] }>();
const emit = defineEmits<{ (e: 'valueChanged', id: string, value: string | null, valueLabel: string): void }>();

const selectedItem = computed(() => props.items.find((i) => i.id === props.field.value) ?? null);

const onChange = (item: ComboboxItem | null) => {
    if (item) {
        emit('valueChanged', props.field.dbName, item.id, item.name);
    } else {
        emit('valueChanged', props.field.dbName, '', '');
    }
};
</script>
