<template>
    <TopBar title="Coming Soon: Dashboard" />
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';

const fluent = useFluent();
useHeadSafe({ title: () => fluent.$t('dashboard') });
</script>
