import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import RoomEditPage from '@/room/pages/RoomEditPage.vue';
import RoomPage from '@/room/pages/RoomPage.vue';
import RoomPartitionAddPage from '@/room_partition/pages/RoomPartitionAddPage.vue';

export const roomRoutes = [
    {
        path: 'room/:roomId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'room-details',
                components: {
                    default: LocationsPage,
                    detail: RoomPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'room-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: RoomEditPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'roompartition/add',
                name: 'room-partition-add',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: RoomPartitionAddPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
