import AreaAddPage from '@/area/pages/AreaAddPage.vue';
import LocationsPage from '@/location/pages/LocationsPage.vue';
import MasterDetailsLayout from '@/navigation/components/MasterDetailLayout.vue';
import { requireAdmin } from '@/plugins/router';
import ZoneEditPage from '@/zone/pages/ZoneEditPage.vue';
import ZonePage from '@/zone/pages/ZonePage.vue';

export const zoneRoutes = [
    {
        path: 'zone/:zoneId',
        component: MasterDetailsLayout,
        children: [
            {
                path: '',
                name: 'zone-details',
                components: {
                    default: LocationsPage,
                    detail: ZonePage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'edit',
                name: 'zone-edit',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: ZoneEditPage,
                },
                props: { default: false, detail: true },
            },
            {
                path: 'add-area',
                name: 'area-add',
                beforeEnter: requireAdmin,
                components: {
                    default: LocationsPage,
                    detail: AreaAddPage,
                },
                props: { default: false, detail: true },
            },
        ],
    },
];
