<template>
    <i18n path="allocation-variant-styled" class="text-2xl flex flex-row items-center tabular-nums" tag="div">
        <template #allocationVariantAbbreviation>
            <Tooltip>
                <template #trigger="tooltipProps">
                    <span
                        class="font-light text-gray-400 underline decoration-dashed focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-700 rounded"
                        :aria-labelledby="tooltipProps['aria-labelledby']"
                        tabindex="0"
                    >
                        {{ $t('allocation-variant-abbreviated') }}
                    </span>
                </template>

                <template #text>{{ $t('allocation-variant-full-text') }}</template>
            </Tooltip>
        </template>

        <template #allocationVariantIndex>
            <span class="font-medium text-gray-600">{{ String(props.index).padStart(3, '0') }}</span>
        </template>
    </i18n>
</template>

<script setup lang="ts">
import Tooltip from '../Tooltip/Tooltip.vue';

type ExampleComponentProps = {
    index: number;
};

const props = defineProps<ExampleComponentProps>();
</script>
