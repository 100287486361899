<template>
    <section
        :id="props.id"
        ref="tabPanelRef"
        class="focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700"
        role="tabpanel"
        tabindex="-1"
        :aria-labelledby="headlineId"
    >
        <h2 :id="headlineId" class="sr-only">{{ props.headline }}</h2>

        <slot />
    </section>
</template>

<script setup lang="ts">
import { nanoid } from 'nanoid';
import { ref } from 'vue';

type TabPanelProps = {
    headline: string;
    id: string;
};

const props = defineProps<TabPanelProps>();
const tabPanelRef = ref();

function focus() {
    tabPanelRef.value.focus();
}

defineExpose({
    focus,
});

const headlineId = nanoid();
</script>
