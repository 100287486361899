<template>
    <TopBar :title="$t('event-create')" />
    <div class="p-5">
        <EventCreateForm :seriesId="initialSeriesId" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import EventCreateForm from '../forms/EventCreateForm.vue';

defineProps<{ initialSeriesId: string }>();

useHeadSafe({ title: () => `${$t('event-create')} - ${$t('event-wiki')}` });
</script>
