<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path fill="currentColor" d="m6.25 12-.717-.717 3.3-3.3-3.3-3.3.717-.716 4.017 4.016L6.25 12Z" />
    </svg>
</template>

<script setup lang="ts">
type ChevronRightProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ChevronRightProps>();
</script>
