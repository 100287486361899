<template>
    <div
        class="inline-grid gap-px bg-gray-200 border-b border-b-gray-200 border-r w-max -mt-px"
        :style="gridTemplateStyle"
    >
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

type TimelineBodyProps = {
    daysCount: number;
    gridRows: ('cell' | 'placeholder')[];
};

const props = defineProps<TimelineBodyProps>();

const gridTemplateStyle = computed(() => {
    const gridTemplateRows = props.gridRows.map((row) => {
        if (row === 'cell') {
            return '1fr';
        } else {
            return '1.625rem';
        }
    });

    return `grid-template-columns: 1fr; grid-template-rows: ${gridTemplateRows.join(' ')}`;
});
</script>
