import { RouteRecordRaw } from 'vue-router';
import AllocationDesignerPage from './AllocationDesignerPage.vue';

export const allocationDesignerRoutes = <RouteRecordRaw[]>[
    {
        path: '/allocation-designer',
        children: [
            {
                path: '',
                name: 'allocation-designer',
                component: AllocationDesignerPage,
            },
        ],
    },
];
