<template>
    <div v-if="zone">
        <TopBar :title="$t('area-add')" :breadcrumbs="locationBreadcrumbs(zone)" transparent />
        <LocationCreateForm type="Area" :location-types="areaTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetAreaForCreateDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ zoneId: string }>();

const query = useQuery(GetAreaForCreateDocument, { zoneId: props.zoneId });
const zone = useResult(query.result, undefined, (x) => x.zone);
const areaTypes = useResult(query.result, [], (x) => x.areaTypes.nodes);
const parent = { key: 'zoneId', value: props.zoneId };
</script>

<fluent locale="de">
area-add-header=Außenfläche anlegen
</fluent>
