<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M7.3 3V2h1.383v1H7.3Zm0 11v-1h1.383v1H7.3ZM4.467 3V2H5.85v1H4.467Zm0 11v-1H5.85v1H4.467ZM2 3V2h1v1H2Zm0 11v-1h1v1H2Zm9.15-1H13V3h-1.85v10Zm-1 1V2H14v12h-3.85ZM8.683 8 6.25 5.567l-.7.7L6.767 7.5H2v1h4.767L5.55 9.733l.7.7L8.683 8Z"
        />
    </svg>
</template>

<script setup lang="ts">
type InsertRightIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<InsertRightIconProps>();
</script>
