<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="title">{{ title }}</title>
        <path
            fill="currentColor"
            d="M7.317 14v-1H8.7v1H7.317Zm0-11V2H8.7v1H7.317Zm2.833 11v-1h1.383v1H10.15Zm0-11V2h1.383v1H10.15ZM13 14v-1h1v1h-1Zm0-11V2h1v1h-1ZM2 14V2h3.85v12H2Zm8.45-7.733-.7-.7L7.317 8l2.433 2.433.7-.7L9.233 8.5H14v-1H9.233l1.217-1.233Z"
        />
    </svg>
</template>

<script setup lang="ts">
type InsertLeftFilledIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};
const props = defineProps<InsertLeftFilledIconProps>();
</script>
