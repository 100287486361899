<template>
    <div
        class="flex flex-col justify-end items-center bg-gray-50 content-section-rounded-top content-section-rounded-bottom px-2.5"
    >
        <div
            class="flex flex-row justify-center relative"
            :style="{
                marginLeft: `${props.leftCompensationInRem}rem`,
                marginRight: `${props.rightCompensationInRem}rem`,
                bottom: `-${allocationHeightInRem}rem`,
            }"
        >
            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { allocationHeightInRem } from '../sharedStyles';

type ContentSectionProps = {
    leftCompensationInRem: number;
    rightCompensationInRem: number;
};

const props = defineProps<ContentSectionProps>();

const heightRoundedBottom = computed(() => `${allocationHeightInRem / 2 + 0.0625}rem`);
const bottomRoundedBottom = computed(() => `-${allocationHeightInRem / 2 + 0.0625}rem`);
</script>

<style scoped>
.content-section-rounded-top,
.content-section-rounded-bottom {
    position: relative;
}

.content-section-rounded-top::before {
    position: absolute;
    height: 0.25rem;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: theme('colors.gray.50');
    z-index: 10;
    content: '';

    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.content-section-rounded-bottom::after {
    position: absolute;
    height: v-bind('heightRoundedBottom');
    bottom: v-bind('bottomRoundedBottom');
    left: 0;
    right: 0;
    width: 100%;
    background: theme('colors.gray.50');
    content: '';
    z-index: -20;
    border-bottom-left-radius: 0.375rem;
    border-bottom-right-radius: 0.375rem;
}
</style>
