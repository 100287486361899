<template>
    <div class="flex flex-col">
        <SearchField v-model="search"></SearchField>
        <LocationSearchTree v-if="search" :search="search"></LocationSearchTree>
        <TreeView
            v-else-if="!!tree.root.value"
            :expanded-keys="tree.expandedKeys"
            :childMap="tree.childMap"
            :nodes="tree.root.value ?? []"
            :loading-keys="tree.loadingKeys"
            @expand="tree.expandItem($event.node)"
            @collapse="tree.collapseItem($event.node)"
            class="expanded overflow-y-auto"
        />
    </div>
</template>

<script setup lang="ts">
import SearchField from '@/components/SearchField.vue';
import TreeView from '@/components/TreeView.vue';
import { computed } from '@vue/reactivity';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import LocationSearchTree from '../components/LocationSearchTree.vue';
import { ItemToOpenPath, useLocationTree } from '../composables/LocationTree';

const route = useRoute();
const search = ref('');

const itemToOpen = computed<ItemToOpenPath | undefined>(() => {
    const p = route.params as { [index: string]: string };
    if ('buildingId' in p) return { type: 'Building', id: p['buildingId'] };
    if ('levelId' in p) return { type: 'Level', id: p['levelId'] };
    if ('roomId' in p) return { type: 'Room', id: p['roomId'] };
    if ('zoneId' in p) return { type: 'Zone', id: p['zoneId'] };
    if ('areaId' in p) return { type: 'Area', id: p['areaId'] };
    if ('plotId' in p) return { type: 'Plot', id: p['plotId'] };
    if ('partitionId' in p) return { type: 'RoomPartition', id: p['partitionId'] };
    if ('siteId' in p) return { type: 'Site', id: p['siteId'] };
});

const tree = useLocationTree(itemToOpen);
</script>

<style scoped>
.list-bg {
    background-color: var(--v-primary-lighten4);
}
</style>

<fluent locale="de">
search=
    .label=Suche
    .no-data-text=Keine Suchergebnisse
</fluent>
