<template>
    <div class="p-4 stripes text-center">
        <p class="p-2 bg-red-100 rounded-md border-red-700 border">
            <i18n path="attention-using-other-backend">
                <template #environment>
                    <strong>
                        {{ props.environment }}
                    </strong>
                </template>
            </i18n>
        </p>
    </div>
</template>

<script setup lang="ts">
type EnvironmentWarnBannerProps = {
    environment: 'dev';
};

const props = defineProps<EnvironmentWarnBannerProps>();
</script>

<style scoped>
.stripes {
    background-image: linear-gradient(
        45deg,
        theme('colors.red.700') 25%,
        theme('colors.red.50') 25%,
        theme('colors.red.50') 50%,
        theme('colors.red.700') 50%,
        theme('colors.red.700') 75%,
        theme('colors.red.50') 75%,
        theme('colors.red.50') 100%
    );
    background-size: 2rem 2rem;
}
</style>
