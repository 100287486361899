<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4.667 12.667v-1h4.816c.778 0 1.448-.259 2.009-.775a2.51 2.51 0 0 0 .841-1.925 2.51 2.51 0 0 0-.841-1.925 2.863 2.863 0 0 0-2.009-.775H4.567l1.9 1.9-.7.7-3.1-3.1 3.1-3.1.7.7-1.9 1.9h4.9c1.055 0 1.964.355 2.725 1.066.76.711 1.141 1.59 1.141 2.634 0 1.044-.38 1.922-1.141 2.633-.761.711-1.67 1.067-2.725 1.067h-4.8Z"
        />
    </svg>
</template>

<script setup lang="ts">
type UndoIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<UndoIconProps>();
</script>
