import { ActiveCard, EditableCardStatus } from '@/components/Card/types';
import { FormLink } from '@/components/MultiLink/types';
import { EmitFormField } from '@/event/types';

import { InjectionKey, Ref } from 'vue';
import { z } from 'zod';
import {
    Project,
    ProjectErrors,
    ProjectMultilinks,
    ProjectSeries,
    ProjectSeriesErrors,
    ProjectSeriesMultilinks,
} from './types';

type SeriesInjection = {
    series: Ref<ProjectSeries>;
    errors: Ref<ProjectSeriesErrors>;
    multilinks: Ref<ProjectSeriesMultilinks>;
};

type ProjectInjection = {
    project: Ref<Project>;
    errors: Ref<ProjectErrors>;
    multilinks: Ref<ProjectMultilinks>;
};

type FormFunctions = {
    handleFormField: <T extends z.ZodTypeAny>(formField: EmitFormField, schema: T) => void;
    handleMultilink: (event: Event, link: FormLink, property: string, fieldName: string) => void;
    deleteLink: (link: FormLink) => void;
    isFormTouched: (fields: string[]) => void;
    save: <T extends z.AnyZodObject>(schema: T | undefined, withMultilinks?: boolean) => void;
};

type ActiveCardFunctions = {
    updateCard: (id: string, label: string, status: EditableCardStatus) => void;
    resetCard: () => void;
    discardChanges: <T extends z.AnyZodObject>(schema: T) => void;
};

export const projectSeriesData = Symbol() as InjectionKey<SeriesInjection>;
export const projectData = Symbol() as InjectionKey<ProjectInjection>;
export const formFunctions = Symbol() as InjectionKey<FormFunctions>;
export const activeCardFunctions = Symbol() as InjectionKey<ActiveCardFunctions>;
export const activeCardData = Symbol() as InjectionKey<Ref<ActiveCard>>;
