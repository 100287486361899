<template>
    <!-- FIXME chrome should respect autocomplete=off on input, if the form also has autocomplete off -->
    <TextAreaField
        v-if="field.long"
        :value="field.value ?? field.defaultValue"
        :label="field.title"
        :help-button-text="field.helper"
        :error-messages="field.errorMessages"
        :disabled="field.readonly"
        autocomplete="off"
        @input="onInput"
    />
    <!-- FIXME chrome should respect autocomplete=off on input, if the form also has autocomplete off -->
    <TextField
        v-else
        :value="field.value ?? field.defaultValue"
        :label="field.title"
        :help-button-text="field.helper"
        :error-messages="field.errorMessages"
        :disabled="field.readonly"
        autocomplete="off"
        @input="onInput"
    />
</template>

<script setup lang="ts">
import TextAreaField from '@/components/Form/TextAreaField.vue';
import TextField from '@/components/Form/TextField.vue';
import { StringFormField } from '@/location/forms/types';

const props = defineProps<{ field: StringFormField }>();
const emit = defineEmits<{ (e: 'valueChanged', id: string, value: string): void }>();

const onInput = async (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('valueChanged', props.field.dbName, target.value);
};
</script>
