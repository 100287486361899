<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.167 14.633a6.29 6.29 0 0 1-2.242-.716 6.418 6.418 0 0 1-1.8-1.442 6.654 6.654 0 0 1-1.192-2.017A6.87 6.87 0 0 1 1.5 8a6 6 0 0 1 .692-2.8A8.17 8.17 0 0 1 4.05 2.833H2.017v-1h3.816V5.65h-1V3.5a6.53 6.53 0 0 0-1.7 2.025A5.143 5.143 0 0 0 2.5 8c0 1.467.444 2.72 1.333 3.758.89 1.04 2 1.659 3.334 1.859v1.016Zm3-.466V10.35h1v2.15a6.759 6.759 0 0 0 1.7-2.033c.422-.778.633-1.6.633-2.467 0-1.467-.444-2.72-1.333-3.758-.89-1.04-2-1.659-3.334-1.859V1.367c1.623.2 2.973.916 4.05 2.15C13.961 4.75 14.5 6.244 14.5 8a6 6 0 0 1-.692 2.8 8.17 8.17 0 0 1-1.858 2.367h2.033v1h-3.816Z"
        />
    </svg>
</template>

<script setup lang="ts">
type ReplaceIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<ReplaceIconProps>();
</script>
