import { DeleteZoneDocument, GetZoneDetailsDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useZone(zoneId: Ref<string>) {
    const getZoneQuery = useQuery(GetZoneDetailsDocument, () => ({ id: unref(zoneId) }));
    const zone = useResult(getZoneQuery.result, undefined, (data) => data.zone);
    const zoneTypes = computed(() => getZoneQuery.result.value?.zoneTypes?.nodes ?? []);

    const zoneToDelete = computed(() => ({
        __typename: zone.value?.__typename ?? '',
        id: zone.value?.id ?? '',
        nameShort: zone.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteZoneDocument);
    const routeToParent = computed(() => ({ name: 'site-details', params: { siteId: zone.value?.site.id } }));
    const deleteZone = useDeleteLocation(zoneToDelete, deleteMutation, routeToParent);

    return {
        zone,
        zoneTypes,
        getZoneQuery,
        deleteZone,
    };
}
