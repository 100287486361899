<template>
    <div v-if="site">
        <TopBar :title="$t('zone-add')" :breadcrumbs="locationBreadcrumbs(site)" transparent />
        <LocationCreateForm type="Zone" :location-types="zoneTypes" :parent="parent" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetZoneForCreateDocument } from '@/generated/graphql';
import LocationCreateForm from '@/location/forms/LocationCreateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ siteId: string }>();

const query = useQuery(GetZoneForCreateDocument, { siteId: props.siteId });
const site = useResult(query.result, undefined, (x) => x.site);
const zoneTypes = useResult(query.result, [], (x) => x.zoneTypes.nodes);
const parent = { key: 'siteId', value: props.siteId };
</script>
