import { allocationDesignerRoutes } from '@/allocation-designer/routes';
import auth from '@/auth/auth';
import LoginPage from '@/auth/pages/LoginPage.vue';
import ResetPasswordPage from '@/auth/pages/ResetPasswordPage.vue';
import CalendarPage from '@/calendar/pages/CalendarPage.vue';
import { externalEventRoutes } from '@/constraints/routes';
import DashboardPage from '@/dashboard/pages/DashboardPage.vue';
import { eventRoutes } from '@/event/routes';
import { locationRoutes } from '@/location/routes';
import BaseLayout from '@/navigation/components/BaseLayout.vue';
import NotFoundPage from '@/navigation/pages/NotFoundPage.vue';
import { projectRoutes } from '@/project/routes';
import { reportRoutes } from '@/report/routes';
import { scenarioRoutes } from '@/scenario/routes';
import MyProfilePage from '@/user/pages/MyProfilePage.vue';
import { userRoutes } from '@/user/routes';
import { createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';

export function _requireAuth(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (auth.isLoggedIn()) {
        next();
    } else {
        next({
            path: '/login',
            query: { redirect: to.fullPath !== '/' ? to.fullPath : undefined },
        });
    }
}

/// Route-guard for pages that require admin-permissions
export function requireAdmin(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    if (auth.isAdmin()) {
        next();
    } else {
        next({ path: '/' });
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            component: BaseLayout,
            beforeEnter: _requireAuth,
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: DashboardPage,
                },
                ...eventRoutes,
                ...locationRoutes,
                ...externalEventRoutes,
                {
                    path: '/calendar',
                    name: 'calendar',
                    component: CalendarPage,
                },
                ...projectRoutes,
                ...userRoutes,
                ...scenarioRoutes,
                ...allocationDesignerRoutes,
                {
                    path: '/me',
                    name: 'me',
                    component: MyProfilePage,
                },
            ],
        },
        ...reportRoutes,
        {
            path: '/login',
            name: 'login',
            component: LoginPage,
            props: (x) => ({
                redirect: x.query.redirect,
                initialError: x.query.error,
            }),
        },
        {
            path: '/resetpassword',
            name: 'reset password',
            component: ResetPasswordPage,
        },
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: NotFoundPage,
        },
        {
            path: '/logout',
            name: 'logout',
            redirect: () => {
                auth.logout();
                return { name: 'login' };
            },
        },
    ],
});

export default router;
