<template>
    <component
        :is="isExternal ? 'a' : 'router-link'"
        class="underline text-sky-700 hover:text-sky-900 focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-2 active:text-sky-600"
        :to="props.to"
        :href="href"
        :target="props.target"
    >
        <slot />
    </component>
</template>

<script setup lang="ts">
import router from '@/plugins/router';
import { computed } from 'vue';
import { RouteLocationRaw } from 'vue-router';

type TextLinkProps = {
    to: RouteLocationRaw;
    target?: '_blank';
};

const props = defineProps<TextLinkProps>();
const isExternal = computed(() => typeof props.to === 'string' && props.to.startsWith('http'));
const href = computed(() => {
    if (typeof props.to === 'string') {
        return props.to as string;
    }
    return router.resolve(props.to).href;
});
</script>
