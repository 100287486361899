<template>
    <!-- TODO clearable checkbox field? -->
    <CheckboxField
        :value="field.value"
        :label="field.title"
        :helpButtonText="field.helper"
        :errorMessages="field.errorMessages"
        @input="onInput"
    />
</template>

<script setup lang="ts">
import CheckboxField from '@/components/Form/CheckboxField.vue';
import { CheckboxFormField } from '@/location/forms/types';

const props = defineProps<{ field: CheckboxFormField }>();
const emit = defineEmits<{ (e: 'valueChanged', id: string, value: any): void }>();

const onInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    emit('valueChanged', props.field.dbName, target.checked);
};
</script>
