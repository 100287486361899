<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(title || props['aria-labelledby'])"
        :tabindex="props['aria-labelledby'] ? 0 : undefined"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M8 11.2H9.2V4.8H6.4V6H8V11.2ZM3.6 13.6C3.27 13.6 2.9875 13.4825 2.7525 13.2475C2.5175 13.0125 2.4 12.73 2.4 12.4V3.6C2.4 3.27 2.5175 2.9875 2.7525 2.7525C2.9875 2.5175 3.27 2.4 3.6 2.4H12.4C12.73 2.4 13.0125 2.5175 13.2475 2.7525C13.4825 2.9875 13.6 3.27 13.6 3.6V12.4C13.6 12.73 13.4825 13.0125 13.2475 13.2475C13.0125 13.4825 12.73 13.6 12.4 13.6H3.6ZM3.6 12.4H12.4V3.6H3.6V12.4Z"
        />
    </svg>
</template>

<script setup lang="ts">
type SingleEventIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<SingleEventIconProps>();
</script>
