<template>
    <div style="height: 100vh"></div>
</template>

<script setup lang="ts">
import { useHeadSafe } from '@unhead/vue';
import { useFluent } from 'fluent-vue';

const fluent = useFluent();

useHeadSafe({ title: () => fluent.$t('calendar') });
</script>
