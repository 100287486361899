import { IntervalResponse } from '@/base/types';
import { DateTime } from 'luxon';
import { RouteLocationRaw } from 'vue-router';

export type Constraint = {
    id: string;
    name: string;
    description?: string;
    colorHex: string;
};

export type CalendarEvent = {
    id: string;
    calendarId?: string;
    name: string;
    description?: string;
    start: string;
    end: string;
    duration: IntervalResponse;
};

export function getDurationFromStartEnd(startString: string, endString: string) {
    const end = DateTime.fromISO(endString);
    const start = DateTime.fromISO(startString);
    return end.diff(start, ['seconds', 'minutes', 'hours', 'days', 'months', 'years']).toObject();
}

export function responseDurationToObject(duration: IntervalResponse): IntervalResponse {
    return {
        seconds: duration.seconds ?? undefined,
        minutes: duration.minutes ?? undefined,
        hours: duration.hours ?? undefined,
        days: duration.days ?? undefined,
        months: duration.months ?? undefined,
        years: duration.years ?? undefined,
    };
}

export function getExternalEventLink(externalEventId: string): RouteLocationRaw {
    return `/external-events/${externalEventId}`;
}
