<template>
    <div class="bg-gray-200 flex flex-col gap-px rounded-md overflow-hidden border border-gray-200">
        <div
            v-for="(item, itemIndex) in props.items"
            :key="`${item.label}-${item.variant}-${itemIndex}`"
            class="bg-gray-50 p-2"
        >
            <FileAttachment
                v-if="item.variant === 'File'"
                :label="item.label"
                :file-size="item.fileSize"
                :help-button-text="item.helpButtonText"
            />

            <LinkAttachment
                v-if="item.variant === 'Link-External' || item.variant === 'Link-Internal'"
                :variant="item.variant"
                :label="item.label"
                :link-target="item.linkTarget"
                :help-button-text="item.helpButtonText"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import FileAttachment from './FileAttachment.vue';
import LinkAttachment from './LinkAttachment.vue';

export type LinkAttachmentType = {
    variant: 'Link-External' | 'Link-Internal';
    label: string;
    linkTarget: string;
    helpButtonText?: string;
};

export type FileAttachmentType = {
    variant: 'File';
    label: string;
    fileSize: string;
    helpButtonText?: string;
};

type LinkFileAttachmentGroupProps = {
    items: (LinkAttachmentType | FileAttachmentType)[];
};

const props = defineProps<LinkFileAttachmentGroupProps>();
</script>
