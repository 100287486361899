<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div
        class="md:flex md:items-center md:justify-between p-4"
        :class="{
            'bg-[#FAFAFA]': !transparent,
        }"
    >
        <div class="flex-1 min-w-0 flex flex-col">
            <h2
                class="text-2xl font-semibold leading-7 text-black sm:text-3xl sm:truncate"
                :class="{ 'text-center': centerTitle }"
            >
                {{ title }}
                <slot name="badge">
                    <Badge v-if="badge" :label="badge.label" :text-color="badge.textColor" :bg-color="badge.bgColor" />
                </slot>
            </h2>
            <!-- TODO fix styling of breadcrumbs here overall and especially in combination with a Badge -->
            <breadcrumbs v-if="props.breadcrumbs && props.breadcrumbs.length" :items="props.breadcrumbs" />
        </div>
        <slot name="actions">
            <template v-if="actions">
                <div class="flex space-x-4">
                    <base-button
                        v-if="actions.create === true"
                        variant="primary"
                        icon="add"
                        size="xs"
                        @click="$emit('create')"
                    />
                    <base-button v-if="actions.edit === true" variant="icon" icon="edit" @click="$emit('edit')" />
                    <base-button
                        v-if="actions.copy === true"
                        variant="icon"
                        icon="content_copy"
                        @click="$emit('copy')"
                    />
                    <base-button
                        v-if="actions.delete === true"
                        variant="icon"
                        icon="delete_outline"
                        intent="destructive"
                        @click="$emit('delete')"
                    />
                </div>
            </template>
        </slot>
    </div>
</template>

<script setup lang="ts">
import Badge from '@/components/Badge.vue';
import BaseButton from '@/components/BaseButton.vue';
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import { Breadcrumb } from './Breadcrumbs.vue';

type BadgeType = {
    label: string;
    bgColor: string;
    textColor: string;
};

type TopBarProps = {
    title: string;
    centerTitle?: boolean;
    transparent?: boolean;
    breadcrumbs?: Breadcrumb[];
    actions?: {
        create?: boolean;
        edit?: boolean;
        copy?: boolean;
        delete?: boolean;
    };
    badge?: BadgeType;
};

const props = withDefaults(defineProps<TopBarProps>(), {
    centerTitle: false,
    transparent: false,
});

defineEmits(['create', 'edit', 'delete', 'copy']);
</script>
