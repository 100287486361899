import { ActiveCard } from '@/components/Card/types';
import { EventVariantStatus } from '@/event/types';
import { GetEventVariantsWithCommentsDocument, GetProjectVariantsWithCommentsDocument } from '@/generated/graphql';
import { ProjectVariantStatus } from '@/project/types';
import { VariantCardVariant, VariantType } from '@/variant/types';
import { useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref, toValue } from 'vue';

export function useVariantsWithComments(objectId: Ref<string>, variantType: VariantType) {
    const variantsRef: Ref<VariantCardVariant[]> = ref([]);
    const activeCard: Ref<ActiveCard> = ref({ id: '', label: '', status: 'View' });
    const expandedCards: Ref<string[]> = ref([]);

    const queryDocuments = {
        Event: GetEventVariantsWithCommentsDocument,
        Project: GetProjectVariantsWithCommentsDocument,
    };

    const getVariantsQueryDocument = queryDocuments[variantType];

    const { loading, error, onResult } = useQuery(
        getVariantsQueryDocument,
        computed(() => ({ id: toValue(objectId) })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving project variants and comments');
        }
        const data = result.data;
        if (data) {
            const variants = data.variants?.nodes;
            if (variants) {
                variantsRef.value = variants.map((variant) => ({
                    ...variant,
                    status: variant.status as ProjectVariantStatus | EventVariantStatus,
                    comments: {
                        nodes: variant.comments.nodes.map((comment) => ({
                            ...comment,
                            hasUnsavedChanges: 'None',
                        })),
                    },
                }));
            }
        }
    });

    function setVariantsRef(value: VariantCardVariant[]) {
        variantsRef.value = value;
    }

    function setActiveCard(value: ActiveCard) {
        activeCard.value = value;
    }

    function setExpandedCards(value: string[]) {
        expandedCards.value = value;
    }

    return {
        variantsRef,
        activeCard,
        expandedCards,
        loading,
        error,
        setVariantsRef,
        setActiveCard,
        setExpandedCards,
    };
}
