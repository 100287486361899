<template>
    <TopBar v-if="plot" :title="plot.nameShort" :breadcrumbs="locationBreadcrumbs(plot)">
        <template #actions>
            <div class="flex gap-2">
                <Button
                    size="Base"
                    variant="Danger-Strong"
                    :disabledExplanation="disabledExplanation"
                    @click="deletePlot"
                >
                    <template #icon>
                        <DeleteForeverIcon :title="$t('location-delete')" />
                    </template>
                    {{ $t('delete') }}
                </Button>
            </div>
        </template>
    </TopBar>
    <div class="container">
        <GQLLoading
            :query="getPlotQuery"
            skeleton-type="table-heading, list-item@3"
            :empty-error-msg="$t('empty-error', { type: 'plot', id: plotId })"
        >
            <LocationDetails
                v-if="plot"
                :location="plot"
                :location-types="plotTypes"
                :to-edit="{ name: 'plot-edit', params: { plotId } }"
                :can-edit="isAdmin"
            />
        </GQLLoading>
    </div>
</template>

<script setup lang="ts">
import auth from '@/auth/auth';
import Button from '@/components/Button/Button.vue';
import DeleteForeverIcon from '@/components/Icon/DeleteForeverIcon.vue';
import TopBar from '@/components/TopBar.vue';
import LocationDetails from '@/location/components/LocationDetails.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import GQLLoading from '@/utils/components/GQLLoading.vue';
import { useFluent } from 'fluent-vue';
import { computed } from 'vue';
import { usePlot } from '../plot';

const props = defineProps<{ plotId: string }>();
const { plot, plotTypes, getPlotQuery, deletePlot } = usePlot(computed(() => props.plotId));
const isAdmin = auth.isAdmin();
const fluent = useFluent();

const disabledExplanation = computed(() => {
    if (isAdmin) return '';
    return fluent.$t('location-delete-disabled');
});
</script>
