<template>
    <div v-if="building">
        <TopBar :title="building.nameShort" :breadcrumbs="locationBreadcrumbs(building)" transparent />
        <LocationUpdateForm :location="building" :location-types="buildingTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetBuildingForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';

const props = defineProps<{ buildingId: string }>();

const query = useQuery(GetBuildingForEditDocument, () => ({ id: props.buildingId }));
const building = useResult(query.result, undefined, (x) => x.building);
const buildingTypes = useResult(query.result, [], (x) => x.buildingTypes.nodes);
</script>
