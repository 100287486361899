<template>
    <HeaderCardsLayout :headline="$t('project-create')" variant="No-Logo">
        <template #body>
            <div class="-mx-4 bg-gray-50">
                <div class="px-4 pb-4 pt-4">
                    <ProjectCreateForm :series-id="seriesId" />
                </div>
            </div>
        </template>
    </HeaderCardsLayout>
</template>
<script setup lang="ts">
import HeaderCardsLayout from '@/components/Layout/HeaderCardsLayout.vue';
import { $t } from '@/plugins/fluent';
import { useHeadSafe } from '@unhead/vue';
import ProjectCreateForm from '../forms/project/ProjectCreateForm.vue';

defineProps<{ seriesId: string }>();

useHeadSafe({ title: () => `${$t('project-create')} - ${$t('project-wiki')}` });
</script>
