import { requireAdmin } from '@/plugins/router';
import ProjectDetailsTab from '@/project/tabs/project/ProjectDetailsTab.vue';
import SeriesOverviewTab from '@/project/tabs/series/SeriesOverviewTab.vue';
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router';
import ProjectAddPage from './pages/ProjectAddPage.vue';
import ProjectOverviewPage from './pages/ProjectOverviewPage.vue';
import ProjectPage from './pages/ProjectPage.vue';
import ProjectSeriesAddPage from './pages/ProjectSeriesAddPage.vue';
import ProjectSeriesPage from './pages/ProjectSeriesPage.vue';
import ProjectAllocationsTab from './tabs/project/ProjectAllocationsTab.vue';
import ProjectAppointmentTab from './tabs/project/ProjectAppointmentTab.vue';
import ProjectLinksTab from './tabs/project/ProjectLinksTab.vue';
import ProjectOrgTab from './tabs/project/ProjectOrgTab.vue';
import ProjectVariantsTab from './tabs/project/ProjectVariantsTab.vue';
import SeriesAppointmentTab from './tabs/series/SeriesAppointmentTab.vue';
import SeriesDetailsTab from './tabs/series/SeriesDetailsTab.vue';
import SeriesLinksTab from './tabs/series/SeriesLinksTab.vue';
import SeriesOrgTab from './tabs/series/SeriesOrgTab.vue';

export const projectRoutes = <RouteRecordRaw[]>[
    {
        path: '/projects',
        children: [
            {
                path: '',
                name: 'projects',
                component: ProjectOverviewPage,
            },
            {
                path: 'series/add',
                name: 'project-series-add',
                beforeEnter: requireAdmin,
                component: ProjectSeriesAddPage,
            },
            {
                path: 'add',
                name: 'project-add',
                props: (route: RouteLocationNormalized) => ({ seriesId: route.query.seriesId }),
                beforeEnter: requireAdmin,
                component: ProjectAddPage,
            },
            {
                path: 'series/:seriesId',
                component: ProjectSeriesPage,
                props: true,
                children: [
                    {
                        path: '',
                        redirect: { name: 'project-series-overview-tab' },
                    },
                    {
                        path: 'overview',
                        name: 'project-series-overview-tab',
                        component: SeriesOverviewTab,
                    },
                    {
                        path: 'details',
                        name: 'project-series-details-tab',
                        component: SeriesDetailsTab,
                    },
                    {
                        path: 'org',
                        name: 'project-series-org-tab',
                        component: SeriesOrgTab,
                    },
                    {
                        path: 'appointment-and-location',
                        name: 'project-series-appointment-tab',
                        component: SeriesAppointmentTab,
                    },
                    {
                        path: 'links',
                        name: 'project-series-links-tab',
                        component: SeriesLinksTab,
                    },
                ],
            },
            {
                path: 'project/:projectId',
                component: ProjectPage,
                props: true,
                children: [
                    {
                        path: '',
                        redirect: { name: 'project-details-tab' },
                    },
                    {
                        path: 'details',
                        name: 'project-details-tab',
                        component: ProjectDetailsTab,
                    },
                    {
                        path: 'allocations',
                        name: 'project-overview-tab',
                        component: ProjectAllocationsTab,
                        props: true,
                    },
                    {
                        path: 'org',
                        name: 'project-org-tab',
                        component: ProjectOrgTab,
                    },
                    {
                        path: 'appointment-and-location',
                        name: 'project-appointment-tab',
                        component: ProjectAppointmentTab,
                    },
                    {
                        path: 'links',
                        name: 'project-links-tab',
                        component: ProjectLinksTab,
                    },
                    {
                        path: 'variants',
                        name: 'project-variants-tab',
                        component: ProjectVariantsTab,
                        props: true,
                    },
                ],
            },
        ],
    },
];
