import { DeleteAreaDocument, GetAreaForAreaDetailsDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useArea(areaId: Ref<string>) {
    const getAreaQuery = useQuery(GetAreaForAreaDetailsDocument, () => ({ id: unref(areaId) }));
    const area = useResult(getAreaQuery.result, undefined, (data) => data.area);
    const areaTypes = computed(() => getAreaQuery.result.value?.areaTypes?.nodes ?? []);

    const areaToDelete = computed(() => ({
        __typename: area.value?.__typename ?? '',
        id: area.value?.id ?? '',
        nameShort: area.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteAreaDocument);
    const routeToParent = computed(() => ({ name: 'zone-details', params: { zoneId: area.value?.zone.id } }));
    const deleteArea = useDeleteLocation(areaToDelete, deleteMutation, routeToParent);

    return {
        area,
        areaTypes,
        getAreaQuery,
        deleteArea,
    };
}
