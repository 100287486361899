<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M4 13.333a1.929 1.929 0 0 1-1.417-.583A1.929 1.929 0 0 1 2 11.333H.667V4c0-.367.13-.68.391-.942A1.29 1.29 0 0 1 2 2.667h9.333v2.666h2l2 2.667v3.333H14c0 .556-.194 1.028-.583 1.417a1.928 1.928 0 0 1-1.417.583 1.929 1.929 0 0 1-1.417-.583A1.929 1.929 0 0 1 10 11.333H6c0 .556-.194 1.028-.583 1.417A1.929 1.929 0 0 1 4 13.333ZM4 12a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.191.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475c0 .19.064.348.192.475A.645.645 0 0 0 4 12Zm-2-2h.533c.19-.2.406-.361.65-.483A1.8 1.8 0 0 1 4 9.333c.3 0 .572.061.817.184.244.122.46.283.65.483H10V4H2v6Zm10 2a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.191.645.645 0 0 0-.475.191.645.645 0 0 0-.192.475c0 .19.064.348.192.475A.645.645 0 0 0 12 12Zm-.667-3.333h2.834l-1.5-2h-1.334v2Z"
        />
    </svg>
</template>

<script setup lang="ts">
type TruckIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<TruckIconProps>();
</script>
