<template>
    <BaseForm v-if="project && errors && multilinks">
        <template #fields>
            <MultiLink
                v-if="multilinks.otherSystems"
                :multiLink="multilinks.otherSystems"
                :label="$t('other-systems')"
                @input="onMultilinkInput($event.event, $event.link, $event.fieldName, 'otherSystems')"
                @delete="onDelete($event)"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import MultiLink from '@/components/MultiLink/MultiLink.vue';
import { FormLink } from '@/components/MultiLink/types';
import { formFunctions, projectData } from '@/project/keys';
import { inject } from 'vue';

const data = inject(projectData);
const project = data?.project;
const errors = data?.errors;
const multilinks = data?.multilinks;

const functions = inject(formFunctions);
const formFields = ['otherSystems'];

function onMultilinkInput(event: Event, link: FormLink, property: string, fieldName: string) {
    functions?.handleMultilink(event, link, property, fieldName);
    functions?.isFormTouched(formFields);
}

function onDelete(link: FormLink) {
    functions?.deleteLink(link);
    functions?.isFormTouched(formFields);
}
</script>
