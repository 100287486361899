import { DeleteRoomPartitionDocument, GetRoomPartitionDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useRoomPartition(partitionId: Ref<string>) {
    const getRoomPartitionQuery = useQuery(
        GetRoomPartitionDocument,
        computed(() => ({ partitionId: unref(partitionId) })),
    );

    // TODO replace useResult as soon as possible (deprecated). For now this results in type errors
    // in LocationDetails component and locationBreadcrumbs function
    const partition = useResult(getRoomPartitionQuery.result, undefined, (data) => data.roomPartition);
    const partitionTypes = computed(() => getRoomPartitionQuery.result.value?.roomPartitionTypes?.nodes ?? []);

    const partitionToDelete = computed(() => ({
        __typename: partition.value?.__typename ?? '',
        id: partition.value?.id ?? '',
        nameShort: partition.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteRoomPartitionDocument);
    const routeToParent = computed(() => ({ name: 'room-details', params: { roomId: partition.value?.room.id } }));
    const deleteRoomPartition = useDeleteLocation(partitionToDelete, deleteMutation, routeToParent);

    return {
        partition,
        partitionTypes,
        getRoomPartitionQuery,
        deleteRoomPartition,
    };
}
