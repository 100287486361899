<template>
    <div v-if="level">
        <TopBar :title="level.nameShort ?? ''" :breadcrumbs="locationBreadcrumbs(level)" transparent />
        <LocationUpdateForm :location="level" :location-types="levelTypes" />
    </div>
</template>

<script setup lang="ts">
import TopBar from '@/components/TopBar.vue';
import { GetLevelForEditDocument } from '@/generated/graphql';
import LocationUpdateForm from '@/location/forms/LocationUpdateForm.vue';
import { locationBreadcrumbs } from '@/location/locationBreadcrumbs';
import { useResult } from '@/utils/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';

const props = defineProps<{ levelId: string }>();

const query = useQuery(GetLevelForEditDocument, () => ({ id: props.levelId }));
const level = useResult(query.result, undefined, (x) => x.level);
const levelTypes = computed(() => query.result.value?.levelTypes?.nodes ?? []);
</script>
