import { $t } from '@/plugins/fluent';

export function getUnstyledAllocationVariantIndex(index: number) {
    return $t('allocation-variant-unstyled', { allocationVariantIndex: String(index).padStart(3, '0') });
}

export function getUnstyledAllocationVariantLabel(index: number, name: string) {
    return $t('allocation-variant-index-name-label', {
        allocationVariantIndex: getUnstyledAllocationVariantIndex(index),
        allocationVariantName: name ? name : '-',
    });
}
