<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M7.333 12.95V8.383l-4-2.316v4.566l4 2.317Zm1.334 0 4-2.317V6.067l-4 2.316v4.567Zm-1.334 1.533L2.667 11.8A1.299 1.299 0 0 1 2 10.65v-5.3a1.299 1.299 0 0 1 .667-1.15l4.666-2.683a1.31 1.31 0 0 1 1.334 0L13.333 4.2A1.298 1.298 0 0 1 14 5.35v5.3a1.299 1.299 0 0 1-.667 1.15l-4.666 2.683a1.31 1.31 0 0 1-1.334 0Zm3.334-8.8 1.283-.733L8 2.667l-1.3.75 3.967 2.266ZM8 7.233l1.3-.75L5.35 4.2l-1.3.75L8 7.233Z"
        />
    </svg>
</template>

<script setup lang="ts">
type PackageIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<PackageIconProps>();
</script>
