<template>
    <li>
        <RouterLink v-slot="{ isExactActive, href, navigate }" :to="props.target" aria-current-value="page" custom>
            <a
                class="text-sky-50 rounded leading-normal text-lg font-light flex flex-row items-center gap-2.5 px-3 py-1.5 min-h-[2.5rem] outline-none focus-visible:outline-offset-0 focus-visible:outline-2 focus-visible:outline-sky-50 focus-visible:bg-sky-900 hover:bg-sky-900 active:bg-sky-950 min-w-max"
                :class="{ 'bg-sky-900': isExactActive }"
                :href="href"
                :aria-current="isExactActive ? 'page' : undefined"
                @click="navigate"
            >
                <slot />
            </a>
        </RouterLink>
    </li>
</template>

<script setup lang="ts">
import { RouteLocationRaw, RouterLink } from 'vue-router';

type NavigationItemProps = {
    target: RouteLocationRaw;
};

const props = defineProps<NavigationItemProps>();
</script>
