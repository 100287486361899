<template>
    <FlexibleCard
        :id="variant.index"
        :headline-id="`v${variant.index}`"
        :expandable-status="getExpandStatus()"
        :disabled-explanation-expandable-button="expandableButtonDisabledMessage()"
        @change-expand="onChangeExpand(variant.id)"
    >
        <template #header="{ headlineId }">
            <CardHeaderEditable
                :status="cardStatus"
                :edit-button-disabled-explanation="editButtonDisabledExplanation()"
                :save-button-disabled-explanation="saveButtonDisabledExplanation"
                @enter-edit-mode="onEnterEditMode(variant)"
                @leave-edit-mode="onLeaveEditMode"
                @discard-changes="onDiscardChanges()"
                @save="onSave()"
            >
                <div class="flex flex-wrap items-center gap-x-3 mr-4 grow">
                    <div class="flex space-x-5 w-[69%] items-center">
                        <CardHeadline :headline-id="headlineId">
                            <AllocationVariantIndex :index="variant.index"></AllocationVariantIndex>
                            <span class="text-gray-400 font-light text-2xl">({{ variant.name }})</span>
                        </CardHeadline>
                        <BadgeDropDown
                            :label="$t('event-variant-status-label')"
                            class="text-base font-normal"
                            :current-value="getVariantStatusItem(variant.status)"
                            :values="getVariantStatusOptionItems(variant.status)"
                            :status="dropDownStatus"
                            @change-value="handleBadgeDropdown($event, variant.id)"
                        />
                        <Tooltip
                            v-if="variant.isCurrent"
                            tabindex="0"
                            class="rounded focus-visible:outline-offset-2 focus-visible:outline-2 focus-visible:outline-sky-700"
                        >
                            <template #trigger="tooltipProps">
                                <div
                                    id="staricon"
                                    :class="starIconStyle"
                                    :aria-labelledby="
                                        tooltipProps['aria-labelledby']
                                            ? `${tooltipProps['aria-labelledby']} staricon`
                                            : undefined
                                    "
                                >
                                    <StarFilledIcon class="w-5 h-5" />
                                </div>
                            </template>
                            <template #text>
                                <div class="h-max">{{ $t('main-variant') }}</div>
                            </template>
                        </Tooltip>
                    </div>
                    <div class="w-[29%]">
                        <span v-if="variant.comments.nodes.length > 0" class="text-gray-700">
                            {{ variant.comments.nodes.length }}
                            <span class="text-gray-400 font-light">{{
                                $t('comments', { count: variant.comments.nodes.length })
                            }}</span>
                        </span>
                    </div>
                </div>
            </CardHeaderEditable>
        </template>

        <template #body>
            <template v-if="cardStatus !== 'View'">
                <BaseForm class="p-4">
                    <template #fields>
                        <TextField
                            :value="variantFormData.name"
                            :label="$t('allocation-variant-label')"
                            :error-messages="variantFormErrors.name"
                            @input="(event: Event) => handleNameFieldInput((event.target as HTMLInputElement).value)"
                            @change="(event: Event) => handleNameFieldChange((event.target as HTMLInputElement).value)"
                        />
                        <div>
                            <div class="flex gap-1">
                                <label class="block text-sm font-medium text-gray-700">{{ $t('main-variant') }}</label>
                            </div>
                            <div class="relative mt-1">
                                <div v-if="variant.isCurrent" class="text-gray-400 text-sm font-light">
                                    {{ $t('already-main-variant') }}
                                </div>
                                <Button
                                    v-else
                                    size="Small"
                                    variant="Neutral-Strong"
                                    :disabled-explanation="setCurrentVariantDisabledExplanation"
                                    @click="onSetCurrentVariant(variant.id)"
                                >
                                    <template #icon>
                                        <StarFilledIcon />
                                    </template>
                                    {{ $t('change-to-main-variant') }}
                                </Button>
                            </div>
                        </div>
                    </template>
                </BaseForm>
            </template>
            <template v-else>
                <CardRowText :label="$t('allocation-variant-label')" :text="variant.name" :has-border-bottom="true" />
                <CardRowText
                    v-if="variant.isCurrent"
                    :label="$t('main-variant')"
                    :text="$t('already-main-variant')"
                    text-color="text-gray-400 font-light"
                    :has-border-bottom="isCommentSectionVisible(variant.comments.nodes.length)"
                />
                <CardLabelContent
                    v-else
                    :label="$t('main-variant')"
                    :has-border-bottom="isCommentSectionVisible(variant.comments.nodes.length)"
                    :button-label="$t('change-to-main-variant')"
                >
                    <Button
                        size="Small"
                        variant="Neutral-Strong"
                        :disabled-explanation="setCurrentVariantDisabledExplanation"
                        @click="onSetCurrentVariant(variant.id)"
                    >
                        <template #icon>
                            <StarFilledIcon />
                        </template>
                        {{ $t('change-to-main-variant') }}
                    </Button>
                </CardLabelContent>
            </template>
            <div v-if="isCommentSectionVisible(variant.comments.nodes.length)" class="bg-gray-50 p-4 rounded-lg">
                <Feed>
                    <AddDateCommentEntry
                        v-if="cardStatus != 'View'"
                        @input="
                            (event: Event) => handleAddDateCommentEntryInput((event.target as HTMLInputElement).value)
                        "
                        @create-comment="(text: string) => onCreateComment(variant.id, text)"
                    />
                    <template v-for="comment in variant.comments.nodes" :key="comment.id">
                        <DateCommentEntry
                            v-if="comment.type === 'user-text'"
                            :text="comment.text"
                            :image-url="getImageUrl(comment.createdByIcon)"
                            :name="comment.createdByName"
                            :date-time="DateTime.fromISO(comment.createdAt)"
                            :is-editable="isCommentEditable(comment.type, comment.createdBy)"
                            :has-unsaved-changes="comment.hasUnsavedChanges"
                            @delete-comment="onDeleteComment(variant.id, comment.id)"
                            @update-comment="(text: string) => onUpdateComment(comment.id, text)"
                            @undo-deleting-comment="onUndoDeletingComment(comment.id)"
                        />
                        <NewMainVariantEntry
                            v-if="
                                comment.type === 'new-main-variant' &&
                                comment.oldMainVariantIndex &&
                                comment.newMainVariantIndex
                            "
                            :user-name="comment.createdByName"
                            :previous-main-variant-name="getUnstyledAllocationVariantIndex(comment.oldMainVariantIndex)"
                            :previous-main-variant-link-target="getVariantRoute(objectId, comment.oldMainVariantIndex)"
                            :new-main-variant-name="getUnstyledAllocationVariantIndex(comment.newMainVariantIndex)"
                            :new-main-variant-link-target="getVariantRoute(objectId, comment.newMainVariantIndex)"
                            :date-time="DateTime.fromISO(comment.createdAt)"
                        />
                        <StatusChangeEntry
                            v-if="comment.type === 'new-status'"
                            :new-status="getVariantStatusLabel(comment.newStatus)"
                            :previous-status="getVariantStatusLabel(comment.oldStatus)"
                            :date-time="DateTime.fromISO(comment.createdAt)"
                            :user-name="comment.createdByName"
                        />
                    </template>
                </Feed>
            </div>
        </template>
    </FlexibleCard>
</template>
<script setup lang="ts">
import auth from '@/auth/auth';
import AllocationVariantIndex from '@/components/AllocationVariantIndex/AllocationVariantIndex.vue';
import { getUnstyledAllocationVariantIndex } from '@/components/AllocationVariantIndex/getFullAllocationVariantIndex';
import BadgeDropDown from '@/components/BadgeDropDown/BadgeDropDown.vue';
import Button from '@/components/Button/Button.vue';
import CardHeaderEditable from '@/components/Card/CardHeaderEditable.vue';
import CardHeadline from '@/components/Card/CardHeadline.vue';
import CardLabelContent from '@/components/Card/CardLabelContent.vue';
import CardRowText from '@/components/Card/CardRowText.vue';
import FlexibleCard from '@/components/Card/FlexibleCard.vue';
import { ActiveCard } from '@/components/Card/types';
import AddDateCommentEntry from '@/components/Feed/AddDateCommentEntry.vue';
import DateCommentEntry from '@/components/Feed/DateCommentEntry.vue';
import Feed from '@/components/Feed/Feed.vue';
import NewMainVariantEntry from '@/components/Feed/NewMainVariantEntry.vue';
import StatusChangeEntry from '@/components/Feed/StatusChangeEntry.vue';
import BaseForm from '@/components/Form/BaseForm.vue';
import TextField from '@/components/Form/TextField.vue';
import StarFilledIcon from '@/components/Icon/StarFilledIcon.vue';
import Tooltip from '@/components/Tooltip/Tooltip.vue';
import { MyUserFragment } from '@/generated/graphql';
import { getImageUrl } from '@/utils/s3';
import { useFluent } from 'fluent-vue';
import { DateTime } from 'luxon';
import { computed } from 'vue';
import { useSetCurrentVariant } from '../composables/useSetCurrentVariant';
import { useVariantCard } from '../composables/useVariantCard';
import { useVariantCardForm } from '../composables/useVariantCardForm';
import { useVariantStatusDropdown } from '../composables/useVariantStatusDropdown';
import { VariantCardVariant, VariantType } from '../types';

type VariantCardProps = {
    objectId: string;
    currentUser: MyUserFragment;
    variant: VariantCardVariant;
    variantType: VariantType;
    variantsRef: VariantCardVariant[];
    activeCard: ActiveCard;
    expandedCards: string[];
    setVariantsRef: (value: VariantCardVariant[]) => void;
    setActiveCard: (value: ActiveCard) => void;
    setExpandedCards: (value: string[]) => void;
};

const props = defineProps<VariantCardProps>();
const fluent = useFluent();

const currentUser = computed(() => props.currentUser);
const variantType = computed(() => props.variantType);
const variantsRef = computed(() => props.variantsRef);
const variant = computed(() => props.variant);
const variantId = computed(() => variant.value.id);
const activeCard = computed(() => props.activeCard);
const expandedCards = computed(() => props.expandedCards);

const isAdmin = auth.isAdmin();
const setCurrentVariantDisabledExplanation = computed(() => (isAdmin ? '' : fluent.$t('no-permission-to-edit')));

const cardStatus = computed(() => (activeCard.value.id === variantId.value ? activeCard.value.status : 'View'));

const {
    variantFormData,
    variantFormErrors,
    saveButtonDisabledExplanation,
    formChanged,
    handleNameFieldInput,
    handleNameFieldChange,
    handleAddDateCommentEntryInput,
    resetVariantForm,
    onCreateComment,
    onDeleteComment,
    onUpdateComment,
    onUndoDeletingComment,
    onSave,
} = useVariantCardForm(
    currentUser.value,
    variant,
    variantType.value,
    variantsRef,
    props.setVariantsRef,
    activeCard,
    props.setActiveCard,
);

const {
    getExpandStatus,
    expandableButtonDisabledMessage,
    editButtonDisabledExplanation,
    isCommentSectionVisible,
    onChangeExpand,
    onEnterEditMode,
    onLeaveEditMode,
    onDiscardChanges,
    isCommentEditable,
} = useVariantCard(
    variantId,
    activeCard,
    formChanged,
    resetVariantForm,
    currentUser.value,
    expandedCards,
    props.setActiveCard,
    props.setExpandedCards,
);

const {
    dropDownStatus,
    getVariantStatusItem,
    getVariantStatusLabel,
    getVariantStatusOptionItems,
    handleBadgeDropdown,
    getVariantRoute,
} = useVariantStatusDropdown(variantType, variant, currentUser, variantsRef, props.setVariantsRef);
const { onSetCurrentVariant } = useSetCurrentVariant(
    currentUser.value,
    variantType.value,
    variantsRef,
    props.setVariantsRef,
);

const starIconStyle = `rounded w-10 h-10 m-auto text-base p-[0.5625rem]
    border border-sky-700 bg-sky-700 text-sky-50
    hover:border-sky-800 hover:bg-sky-800 hover:text-sky-50`;
</script>
