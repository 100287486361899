import '@/assets/main.css';
import * as Sentry from '@sentry/vue';
import { createHead } from '@unhead/vue';
import { DefaultApolloClient } from '@vue/apollo-composable';
import { createPinia } from 'pinia';
import { createApp, h, provide } from 'vue';
import Toast from 'vue-toastification';
import App from './App.vue';
import { config } from './config';
import './index.css';
import apollo from './plugins/apollo';
import './plugins/fluent';
import fluent from './plugins/fluent';
import router from './plugins/router';
import toast from './plugins/vue-toastification';

const pinia = createPinia();

export const app = createApp({
    setup() {
        provide(DefaultApolloClient, apollo);
    },
    render: () => h(App),
});

// https://sentry.pyrox.eu/organizations/ldt/projects/spacecap-frontend/getting-started/
if (config.sentryDsn) {
    Sentry.init({
        app,
        dsn: config.sentryDsn,
        environment: config.envName,
        integrations: [],
    });
}

const head = createHead();

app.use(head);
app.use(router);
app.use(fluent);
toast.toastClassName = 'right-12';
app.use(Toast, toast);
app.use(pinia);

app.mount('#app');
export default app;
