<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>

        <path
            fill="currentColor"
            d="M10.933 14 10 13.067 11.05 12 10 10.95l.933-.95L12 11.067 13.05 10l.95.95L12.933 12 14 13.067l-.95.933L12 12.95 10.933 14ZM4 12.667a.645.645 0 0 0 .475-.192.645.645 0 0 0 .192-.475.645.645 0 0 0-.192-.475.645.645 0 0 0-.475-.192.645.645 0 0 0-.475.192.645.645 0 0 0-.192.475c0 .189.064.347.192.475a.645.645 0 0 0 .475.192ZM4 14a1.929 1.929 0 0 1-1.417-.583A1.929 1.929 0 0 1 2 12c0-.556.194-1.028.583-1.417A1.929 1.929 0 0 1 4 10c.411 0 .786.114 1.125.342.339.227.586.536.742.925a1.974 1.974 0 0 0 1.058-.717 1.95 1.95 0 0 0 .408-1.217V6.667c0-.923.325-1.709.975-2.359a3.213 3.213 0 0 1 2.359-.975h.766l-1.05-1.05.95-.95L14 4l-2.667 2.667-.95-.934 1.05-1.066h-.766c-.556 0-1.028.194-1.417.583a1.929 1.929 0 0 0-.583 1.417v2.666c0 .811-.261 1.525-.784 2.142A3.29 3.29 0 0 1 5.9 12.617a1.956 1.956 0 0 1-.725 1C4.825 13.872 4.433 14 4 14ZM2.933 6 2 5.067 3.05 4 2 2.95 2.933 2 4 3.067 5.05 2l.95.95L4.933 4 6 5.067 5.05 6 4 4.95 2.933 6Z"
        />
    </svg>
</template>

<script setup lang="ts">
type TacticIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<TacticIconProps>();
</script>
