<template>
    <BaseForm v-if="series && errors">
        <template #fields>
            <TextAreaField
                :value="series.locationRequirements"
                :label="$t('location-requirements')"
                :errorMessages="errors.locationRequirements"
                @input="onInput($event, 'locationRequirements')"
            />
            <TextAreaField
                :value="series.allocationHints"
                :label="$t('allocation-hints')"
                :errorMessages="errors.allocationHints"
                @input="onInput($event, 'allocationHints')"
            />
        </template>
    </BaseForm>
</template>
<script setup lang="ts">
import BaseForm from '@/components/Form/BaseForm.vue';
import TextAreaField from '@/components/Form/TextAreaField.vue';
import { formFunctions, projectSeriesData } from '@/project/keys';
import { ProjectSeriesLocationSchema, ProjectSeriesLocationSchemaType } from '@/project/schemas';
import { inject } from 'vue';

const data = inject(projectSeriesData);
const series = data?.series;
const errors = data?.errors;

const functions = inject(formFunctions);
const formFields = ProjectSeriesLocationSchema.keyof().options;

function onInput(event: Event, name: string) {
    const key = name as keyof ProjectSeriesLocationSchemaType;
    const schema = ProjectSeriesLocationSchema.shape[key];
    functions?.handleFormField({ event, fieldName: name }, schema);
    functions?.isFormTouched(formFields);
}
</script>
