import {
    MyUserFragment,
    SetCurrentEventVariantWithCommentDocument,
    SetCurrentProjectVariantWithCommentDocument,
    UpdateEventVariantAndCommentsDocument,
    UpdateProjectVariantAndCommentsDocument,
} from '@/generated/graphql';
import { ProjectVariantStatus } from '@/project/types';
import { useMutation } from '@vue/apollo-composable';
import { computed, Ref, toValue } from 'vue';
import { VariantCardVariant, VariantType } from '../types';

export function useSetCurrentVariant(
    currentUser: MyUserFragment,
    variantType: VariantType,
    variantsRef: Ref<VariantCardVariant[]>,
    setVariantsRef: (value: VariantCardVariant[]) => void,
) {
    const currentVariant = computed(() => {
        const variant = toValue(variantsRef).find((v) => v.isCurrent);
        if (!variant) throw new Error('There must be at least one current variant!');
        return variant;
    });

    const mutations = {
        Event: {
            setCurrentVariant: useMutation(SetCurrentEventVariantWithCommentDocument),
            createComments: useMutation(UpdateEventVariantAndCommentsDocument),
        },
        Project: {
            setCurrentVariant: useMutation(SetCurrentProjectVariantWithCommentDocument),
            createComments: useMutation(UpdateProjectVariantAndCommentsDocument),
        },
    } satisfies Record<VariantType, { setCurrentVariant: unknown; createComments: unknown }>;

    async function onSetCurrentVariant(variantId: string) {
        const oldCurrentVariantId = currentVariant.value.id;
        const oldCurrentVariantIndex = currentVariant.value.index;

        const newCurrentVariant = variantsRef.value.find((v) => v.id === variantId);
        if (!newCurrentVariant) throw new Error('New current variant does not exist');

        const setCurrentVariantComment = {
            type: 'new-main-variant',
            createdBy: currentUser.id,
            createdByName: currentUser.name,
            createdByIcon: currentUser.icon?.original ?? '',
            oldMainVariantId: oldCurrentVariantId,
            oldMainVariantIndex: oldCurrentVariantIndex,
            newMainVariantId: newCurrentVariant.id,
            newMainVariantIndex: newCurrentVariant.index,
        };
        const setVariantMutation = mutations[variantType].setCurrentVariant;
        const result = await setVariantMutation.mutate({
            oldCurrentId: oldCurrentVariantId,
            newCurrentId: newCurrentVariant.id,
            commentsToCreate: [setCurrentVariantComment],
        });

        if (result?.errors) throw new Error('Could not set variant to current variant');

        const oldVariant = result?.data?.updateOld?.variant;
        const newVariant = result?.data?.updateNew?.variant;

        if (oldVariant && newVariant) {
            setVariantsRef(
                toValue(variantsRef).map((variant) => {
                    if (variant.id === oldCurrentVariantId) {
                        return {
                            ...oldVariant,
                            status: oldVariant.status as ProjectVariantStatus,
                            comments: {
                                nodes: oldVariant.comments.nodes.map((comment) => ({
                                    ...comment,
                                    hasUnsavedChanges: 'None',
                                })),
                            },
                        };
                    }
                    if (variant.id === newCurrentVariant.id) {
                        return {
                            ...newVariant,
                            status: newVariant.status as ProjectVariantStatus,
                            comments: {
                                nodes: newVariant.comments.nodes.map((comment) => ({
                                    ...comment,
                                    hasUnsavedChanges: 'None',
                                })),
                            },
                        };
                    }
                    return variant;
                }),
            );
        }
    }

    return {
        onSetCurrentVariant,
    };
}
