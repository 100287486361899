<!-- This component is using the following search field from tailwindui
https://tailwindui.com/components/application-ui/navigation/navbars#component-8731614251ceaa0fa262087124b3283b
Only showing bottom border and increasing line height to match the style in Figma
focus border color changed to primary. -->
<template>
    <div class="w-full sm:max-w-xs">
        <div class="relative">
            <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
                id="search"
                name="search"
                autocomplete="off"
                class="block w-full bg-white leading-8 border-l-0 border-r-0 border-t-0 border-b-1 border-gray-300 py-2 pl-10 pr-3 placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:border-primary"
                :placeholder="$t('search')"
                type="search"
                :value="modelValue"
                @input="onInput"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { SearchIcon } from '@heroicons/vue/solid';

defineProps<{ modelValue: string }>();
const emit = defineEmits(['update:modelValue']);

const onInput = (event: Event) => {
    // TS otherwise complains, that event.target does not have a value
    const target = event.target as HTMLInputElement;
    emit('update:modelValue', target.value);
};
</script>
