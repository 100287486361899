import { DeleteBuildingDocument, GetBuildingForDetailsPageDocument } from '@/generated/graphql';
import { useDeleteLocation } from '@/location/location';
import { useResult } from '@/utils/graphql';
import { useMutation, useQuery } from '@vue/apollo-composable';
import { Ref, computed, unref } from 'vue';

export function useBuilding(buildingId: Ref<string>) {
    const getBuildingQuery = useQuery(GetBuildingForDetailsPageDocument, () => ({ id: unref(buildingId) }));
    const building = useResult(getBuildingQuery.result, undefined, (data) => data.building);
    const buildingTypes = computed(() => getBuildingQuery.result.value?.buildingTypes?.nodes ?? []);

    const buildingToDelete = computed(() => ({
        __typename: building.value?.__typename ?? '',
        id: building.value?.id ?? '',
        nameShort: building.value?.nameShort ?? '',
    }));
    const deleteMutation = useMutation(DeleteBuildingDocument);
    const routeToParent = computed(() => ({ name: 'site-details', params: { siteId: building.value?.site.id } }));
    const deleteBuilding = useDeleteLocation(buildingToDelete, deleteMutation, routeToParent);

    return {
        building,
        buildingTypes,
        getBuildingQuery,
        deleteBuilding,
    };
}
