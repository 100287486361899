<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="none"
        viewBox="0 0 16 16"
        :aria-labelledby="props['aria-labelledby']"
        :aria-hidden="!(props.title || props['aria-labelledby'])"
        role="img"
    >
        <title v-if="props.title">{{ props.title }}</title>
        <path
            fill="currentColor"
            d="M3 13h.733l7.384-7.383-.734-.734L3 12.267V13Zm10.233-8.1L11.1 2.767l.7-.7a.95.95 0 0 1 .7-.284.95.95 0 0 1 .7.284l.733.733a.951.951 0 0 1 .284.7.951.951 0 0 1-.284.7l-.7.7Zm-.7.7-8.4 8.4H2v-2.133l8.4-8.4L12.533 5.6Zm-1.783-.35-.367-.367.734.734-.367-.367Z"
        />
    </svg>
</template>

<script setup lang="ts">
type EditIconProps = {
    'aria-labelledby'?: string;
    title?: string;
};

const props = defineProps<EditIconProps>();
</script>
