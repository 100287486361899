<template>
    <dl class="grid gap-x-4 gap-y-2 grid-cols">
        <slot />
    </dl>
</template>

<style scoped>
/* This _should_ be equal to "grid-cols-[minmax(min-content, 120px)_1fr]" from Tailwind, but for some reason the
   tailwind solution does not work. Maybe try again once vuetify is removed.
*/
.grid-cols {
    grid-template-columns: minmax(min-content, 120px) 1fr;
}
</style>
