<template>
    <div class="w-full flex flex-wrap items-center justify-between" @keyup.prevent.esc="emit('discard-changes')">
        <slot />

        <div class="flex gap-2 grow-0">
            <Button
                v-if="props.status === 'View'"
                ref="editButtonRef"
                size="Small"
                variant="Info-Light"
                :disabled-explanation="editButtonDisabledExplanation"
                @click="emit('enter-edit-mode')"
            >
                <template #icon>
                    <EditIcon />
                </template>
                {{ $t('card-edit') }}
            </Button>

            <Button
                v-if="props.status === 'Edit-Untouched'"
                ref="abortButtonRef"
                size="Small"
                variant="Info-Light"
                @click="emit('leave-edit-mode')"
            >
                <template #icon>
                    <UndoIcon />
                </template>
                {{ $t('card-abort') }}
            </Button>

            <Button
                v-if="props.status === 'Edit-Touched'"
                size="Small"
                variant="Danger-Light"
                @click="emit('discard-changes')"
            >
                <template #icon>
                    <UndoIcon />
                </template>
                {{ $t('card-discard') }}
            </Button>

            <Button
                v-if="props.status === 'Edit-Untouched' || props.status === 'Edit-Touched'"
                size="Small"
                variant="Success-Light"
                :disabled-explanation="saveButtonDisabledExplanation"
                @click="$emit('save')"
            >
                <template #icon>
                    <AllDoneIcon />
                </template>

                {{ $t('card-save') }}
            </Button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import Button from '../Button/Button.vue';
import AllDoneIcon from '../Icon/AllDoneIcon.vue';
import EditIcon from '../Icon/EditIcon.vue';
import UndoIcon from '../Icon/UndoIcon.vue';
import { EditableCardStatus } from './types';

type CardHeaderEditableProps = {
    status: EditableCardStatus;
    editButtonDisabledExplanation?: string;
    saveButtonDisabledExplanation?: string;
};

type CardHeaderEditableEmits = {
    (e: 'enter-edit-mode'): void;
    (e: 'leave-edit-mode'): void;
    (e: 'discard-changes'): void;
    (e: 'save'): void;
};

const props = defineProps<CardHeaderEditableProps>();
const emit = defineEmits<CardHeaderEditableEmits>();

const editButtonRef = ref();
const abortButtonRef = ref();

// Focus button after changing into edit mode
watch(
    [() => props.status, abortButtonRef],
    ([newStatus, newAbortButtonRef], [oldStatus]) => {
        if (newStatus === 'Edit-Untouched' && oldStatus === 'View' && newAbortButtonRef) {
            newAbortButtonRef.focus();
        }
    },
    { immediate: true, flush: 'post' },
);

// Focus button after leaving edit mode
watch(
    [() => props.status, editButtonRef],
    ([newStatus, newEditButtonRef], [oldStatus]) => {
        if (newStatus === 'View' && oldStatus === 'Edit-Untouched' && newEditButtonRef) {
            newEditButtonRef.focus();
        }
    },
    { immediate: true, flush: 'post' },
);
</script>
