import { AllocationUsage, TimelineVariant } from '@/components/Timeline/allocation/types';
import {
    GetProjectDataForTimelineDocument,
    TimelineAreaFragment,
    TimelineProjectFragment,
    TimelineRoomFragment,
    TimelineSiteFragment,
} from '@/generated/graphql';
import { useQuery } from '@vue/apollo-composable';
import { computed, Ref, ref, toValue } from 'vue';

export function useProjectTimelineData(projectId: Ref<string>) {
    const variantsRef: Ref<TimelineVariant[]> = ref([]);
    const project: Ref<TimelineProjectFragment | undefined> = ref();
    const rooms: Ref<TimelineRoomFragment[]> = ref([]);
    const areas: Ref<TimelineAreaFragment[]> = ref([]);
    const site: Ref<TimelineSiteFragment | undefined> = ref();
    const usages: Ref<AllocationUsage[]> = ref([]);

    const { loading, error, onResult } = useQuery(
        GetProjectDataForTimelineDocument,
        computed(() => ({ projectId: toValue(projectId) })),
    );

    onResult((result) => {
        if (result.error) {
            throw new Error('Error on receiving project timeline data');
        }
        const data = result.data;
        if (data) {
            project.value = data.project ?? undefined;
            const variants = data.project?.variants.nodes;
            if (variants) {
                variantsRef.value = variants;
            }
            rooms.value = data.rooms?.nodes ?? [];
            areas.value = data.areas?.nodes ?? [];
            const sites = data.sites?.nodes ?? [];
            if (!sites) throw new Error(`Missing site for event: ${toValue(projectId)}`);
            site.value = sites[0];
            usages.value = data.usages?.nodes ?? [];
        }
    });

    function setVariantsRef(value: TimelineVariant[]) {
        variantsRef.value = value;
    }

    return { variantsRef, setVariantsRef, loading, error, onResult, site, project, rooms, areas, usages };
}
