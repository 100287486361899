<template>
    <div class="p-6 flex justify-between content-center print:p-1 print:block">
        <digikap-logo class="text-primary" small></digikap-logo>
        <router-link :to="{ name: 'locations' }" class="text-sky-700 print:hidden">{{ $t('back') }}</router-link>
    </div>
    <div class="p-6 print:p-2 print:mt-10">
        <h1 class="text-2xl font-bold print:hidden">{{ $t('quality-assurance-locations') }}</h1>
        <combobox-field
            :value="selectedType"
            :label="$t('space-type')"
            :items="types"
            class="mt-4 print:hidden"
            @change="onTypeChange"
        />
        <h2 class="text-lg font-bold mt-8 print:mt-0">
            {{ $t('space-type') }}: {{ selectedType?.name }} <span v-if="spaces">({{ spaces.length }})</span>
        </h2>

        <table class="min-w-full divide-y divide-gray-300 mt-8 print:mt-0">
            <thead>
                <tr>
                    <th
                        scope="col"
                        class="py-3 pl-4 pr-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 sm:pl-0"
                    >
                        {{ $t('name-short') }}
                    </th>
                    <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                        {{ $t('name-long') }}
                    </th>
                    <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                        {{ $t('name-marketing') }}
                    </th>
                    <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500 tabular-nums"
                    >
                        {{ $t('cad-gross-space') }}
                    </th>
                    <th
                        scope="col"
                        class="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                    >
                        {{ $t('contact') }}
                    </th>
                </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
                <template v-for="space in spaces" v-if="spaces && spaces.length" :key="space.id">
                    <tr class="bg-gray-50 hover:bg-sky-200 divide-x divide-gray-200">
                        <td
                            class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-semibold text-gray-900 sm:pl-0 hover:underline"
                        >
                            <router-link
                                :to="routeForLocation(space.__typename, space.id)"
                                class="block"
                                target="_blank"
                            >
                                {{ space.nameShort }}
                            </router-link>
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ space.nameLong }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ getTVValue(space.nameMarketing) }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-right tabular-nums">
                            {{ getTVValue(space.cadGrossSpace) }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {{ getSelectValue(space.contactBooking, CONTACT_BOOKING_CHOICES) }}
                        </td>
                    </tr>
                    <tr
                        v-for="subspace in space.subspaces.nodes"
                        :key="subspace.id"
                        class="bg-white hover:bg-sky-100 divide-x divide-gray-200"
                    >
                        <td class="whitespace-nowrap py-2 pl-8 pr-3 text-sm font-normal text-gray-900 sm:pl-3">
                            <div class="flex flex-col">
                                <router-link
                                    :to="routeForLocation(subspace.__typename, subspace.id)"
                                    class="hover:underline"
                                    target="_blank"
                                >
                                    {{ subspace.nameShort }}
                                </router-link>
                                <div class="text-gray-500">{{ subspace.type?.name }}</div>
                            </div>
                        </td>
                        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {{ subspace.nameLong }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500">
                            {{ getTVValue(subspace.nameMarketing) }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500 text-right tabular-nums">
                            {{ getTVValue(subspace.cadGrossSpace) }}
                        </td>
                        <td class="whitespace-nowrap px-3 py-2 text-sm text-gray-500"></td>
                    </tr>
                </template>
                <tr v-else>
                    <td colspan="5">
                        <Message class="text-gray-500" :headline="$t('no-spaces-for-type-headline')" status="info">
                            {{ emptyListInfoMessage }}
                        </Message>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script setup lang="ts">
import ComboboxField, { ComboboxItem } from '@/components/Form/ComboboxField.vue';
import Message from '@/components/Message/Message.vue';
import { GetExhibitionSpacesDocument, GetTypesDocument } from '@/generated/graphql';
import { CONTACT_BOOKING_CHOICES, routeForLocation } from '@/location/location';
import DigikapLogo from '@/utils/components/DigikapLogo.vue';
import { useHeadSafe } from '@unhead/vue';
import { useQuery } from '@vue/apollo-composable';
import { useFluent } from 'fluent-vue';
import { computed, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const fluent = useFluent();

const getTypes = useQuery(GetTypesDocument);

type SpacesItem = {
    __typename?: string;
    id: string;
    name: string;
};

function composeItemName(i: SpacesItem) {
    if (i.__typename) {
        return `${i.name} (${fluent.$t(i.__typename.toLowerCase())})`;
    }
    return i.name;
}

const types = computed(() => {
    const value = getTypes.result.value;
    if (value) {
        const items = [...(value.roomTypes?.nodes ?? []), ...(value.areaTypes?.nodes ?? [])].sort((a, b) =>
            a.name.localeCompare(b.name),
        );
        return items.map((i) => ({
            id: `${i.__typename}:${i.id}`,
            name: composeItemName(i),
        }));
    }
    return [];
});

const selectedType = computed(() => {
    if ('type' in route.query && route.query.type) {
        return (
            types.value.find((r) => {
                const [typename, _id] = r.id.split(':');
                // check also for typename in case of duplicate type names in the future
                return r.name === route.query.type && typename === route.query.typename;
            }) ?? null
        );
    }
    return null;
});

const spacesParams = computed(() => {
    const [typename, id] = selectedType.value?.id.split(':') ?? [];
    if (typename === 'AreaType') {
        return { roomTypeId: undefined, areaTypeId: id };
    } else if (typename == 'RoomType') {
        return { roomTypeId: id, areaTypeId: undefined };
    }
    return { roomTypeId: undefined, areaTypeId: undefined };
});

const getExhibitionSpaces = useQuery(GetExhibitionSpacesDocument, spacesParams);
const spaces = computed(
    () => getExhibitionSpaces.result.value?.rooms?.nodes ?? getExhibitionSpaces.result.value?.areas?.nodes ?? [],
);

const emptyListInfoMessage = computed(() =>
    selectedType.value?.id === '' ? fluent.$t('please-select-type') : fluent.$t('no-spaces-for-type'),
);

type TVField = {
    nodes: { id: string; value: any }[];
};

const getTVValue = (field: TVField) => {
    if (field.nodes.length) {
        return field.nodes[0].value;
    }
    return '';
};

const getSelectValue = (field: TVField, choices: ComboboxItem[]) => {
    if (field.nodes.length) {
        return choices.find((c) => c.id === field.nodes[0].value)?.name;
    }
    return '';
};

const onTypeChange = (item: ComboboxItem | null) => {
    if (item) {
        const [typename, id] = item.id.split(':');
        router.push({ query: { type: item.name, typename: typename } });
    } else {
        router.push({ name: 'spaces' });
    }
};

watchEffect(() => {
    if (selectedType.value?.name) {
        useHeadSafe({ title: () => `${fluent.$t('space-type')}: ${selectedType.value?.name}` });
    } else {
        useHeadSafe({ title: () => fluent.$t('quality-assurance-locations') });
    }
});
</script>
